import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import AttendanceDetailsSearch from './AttendanceDetailsSearch'
import { getDetailAttendance, exportAttendance } from 'libs/apis/smallGroup'
import AttendanceDetailsRow from '../../components/smallGroup/AttendanceDetailsRow'
import { failedMessage } from 'libs/utils/confirmation'
import optionsMonth from 'services/optionsMonth'
import optionsYear from 'services/optionsYear'
import moment from 'moment'
import Loading from 'components/commons/Loading'

class AttendanceDetails extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isExport: false,
			months: optionsMonth(),
			years: optionsYear(),
			monthSelected: '1',
			yearSelected: moment().year(),
			data: [],
			isLoading: false,
		}
	}

	async componentDidMount() {
		this.setState({ ...this.state, isLoading: !this.state.isLoading })
		let res = await getDetailAttendance(this.props.match.params.id)

		if (res.data.message) {
			this.setState({
				...this.state,
				data: res.data.data,
				isLoading: !this.state.isLoading,
			})
		} else {
			failedMessage()
			this.setState({ ...this.state, isLoading: !this.state.isLoading })
		}
	}

	handleExport = () => {
		const { monthSelected, yearSelected } = this.state
		this.setState({ isExport: true }, async () => {
			try {
				const response = await exportAttendance(monthSelected, yearSelected)

				if (response.data.api_status) {
					const link = document.createElement('a')
					link.href = response.data.data
					document.body.appendChild(link)
					link.click()
					this.setState({ isExport: false })
				} else {
					this.setState({ isExport: false })
				}
			} catch (error) {}
		})
	}

	handleChange = ({ currentTarget: input }) => {
		this.setState({ [input.name]: input.value })
	}

	render() {
		const { isExport, data, months, monthSelected, years, yearSelected, isLoading } = this.state

		const breads = [{ url: '', title: 'Details Attendance' }]
		return (
			<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
				<Helmet>
					<title>View Details</title>
				</Helmet>
				<PageBreadcrumbs icon={SGIcon} title="Details Attendance" breads={breads} />
				<AttendanceDetailsSearch
					months={months}
					monthSelected={monthSelected}
					years={years}
					yearSelected={yearSelected}
					handleOnExport={this.handleExport}
					isExport={isExport}
					handleChange={this.handleChange}
				/>
				{isLoading ? (
					<Loading />
				) : (
					<div className="bg-white shadow-lg rounded">
						<table className="w-full">
							<thead className="bt-blueSecondary border-2 border-solid">
								<tr>
									<th className="px-8 py-4 text-left">NAME</th>
									<th className="px-2 py-4 text-center">ROLE</th>
									<th className="px-2 py-4 text-center">ATTENDANCE</th>
									<th />
								</tr>
							</thead>
							<tbody className="border-2 border-solid">
								{data.map((value, i) => (
									<AttendanceDetailsRow key={i} name={value.fullname} role={value.role} status={value.status} />
								))}
							</tbody>
						</table>
					</div>
				)}
			</div>
		)
	}
}

export default AttendanceDetails
