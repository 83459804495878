import React, { Component } from 'react'

class ActionsRow extends Component {
  container = React.createRef()

  state = {
    isOpen: false
  }

  handleToggle = () => {
    this.setState({ isOpen: true })
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.container.current && !this.container.current.contains(event.target)) {
      this.setState({
        isOpen: false
      })
    }
  }

  render() {
    return (
      <td className="px-2 py-4">
        <div className="cursor-pointer" onClick={this.handleToggle}>
          <i className="dropdown-trigger fas fa-ellipsis-v" />
          {this.state.isOpen && (
            <div className="z-20 shadow-md absolute right-20px block" ref={this.container}>
              <ul className="bg-white">
                {this.props.actions.map((action) => (
                  <React.Fragment key={action.key}>{action.helper || action.content()}</React.Fragment>
                ))}
              </ul>
            </div>
          )}
        </div>
      </td>
    )
  }
}

export default ActionsRow
