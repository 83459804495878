import React, { Component } from 'react'
import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { Helmet } from 'react-helmet'
import AttendanceSearch from './AttendanceSearch'
import AttendanceTable from '../../components/smallGroup/AttendanceTable'
import { exportAttendance, getSmallGroupAttendance, deleteAttendance } from 'libs/apis/smallGroup'

import optionsMonth from 'services/optionsMonth'
import optionsYear from 'services/optionsYear'
import moment from 'moment'
import Pagination from 'components/commons/Pagination'
import { failedFetch } from 'libs/utils/messages'
import Loading from 'components/commons/Loading'
import alert from 'services/alert'

class ListAttendance extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: false,
			isRedirect: false,
			error: '',
			data: [],
			currentPage: 0,
			lastPage: 1,
			isExport: false,
			months: optionsMonth(),
			years: optionsYear(),
			monthSelected: '1',
			yearSelected: moment().year(),
		}
	}

	componentDidMount() {
		this.handleLoadData()
	}

	handleChange = (e) => {
		this.setState({ ...this.state, [e.target.name]: e.target.value })
	}

	handleLoadData = async () => {
		this.setState({ isLoading: true }, async () => {
			try {
				const response = await getSmallGroupAttendance(this.state.currentPage)

				if (response.data.api_status) {
					this.setState({
						...this.state,
						isLoading: false,
						data: response.data.data.data,
						currentPage: response.data.data.current_page,
						lastPage: response.data.data.last_page,
					})
				} else {
					this.setState({ isLoading: false, errorMessage: failedFetch })
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.removeItem('token')
						this.setState({ isRedirect: true })
					} else if (error.response.status >= 500) {
						this.setState({ isLoading: false, errorMessage: failedFetch })
					}
				}
			}
		})
	}

	handleExport = () => {
		this.setState({ isExport: true }, async () => {
			try {
				const { monthSelected, yearSelected } = this.state

				const response = await exportAttendance(monthSelected, yearSelected)

				if (response.data.api_status) {
					const link = document.createElement('a')
					link.href = response.data.data
					document.body.appendChild(link)
					link.click()
					this.setState({ isExport: false })
				}
			} catch (error) {
				if (error.response) {
					alert.failed(`Failed`, `Cannot export file`)
					this.setState({ isExport: false })
				}
			}
		})
	}

	handlePageChange = (page) => {
		this.setState({ currentPage: page }, async () => {
			this.handleLoadData()
		})
	}

	handleDelete = async (id) => {
		let res = await alert.confirmDelete()
		if (res.value) {
			let res = await deleteAttendance(id)
			if (res.data.api_status) {
				alert.succeed(`Success`, `Your file has been deleted`)
				this.handleLoadData()
			}
		} else {
			alert.failed(`Failed`, `Cannot delete file`)
		}
	}

	render() {
		const { currentPage, lastPage } = this.state
		const { isExport, data, months, monthSelected, years, yearSelected, isLoading } = this.state
		const breads = [{ url: '', title: 'List Attendance' }]
		const church = JSON.parse(localStorage.getItem('church'))
		const name = church.church_name ? church.church_name : '-'

		return (
			<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
				<Helmet>
					<title>{name} - List of Emails </title>
				</Helmet>
				<PageBreadcrumbs icon={SGIcon} title="Attendance" breads={breads} />
				<AttendanceSearch
					handleOnExport={this.handleExport}
					isExport={isExport}
					months={months}
					monthSelected={monthSelected}
					years={years}
					yearSelected={yearSelected}
					handleChange={this.handleChange}
				/>
				{isLoading ? (
					<Loading />
				) : (
					<div>
						<AttendanceTable list={data} handleDelete={this.handleDelete} />
						<Pagination currentPage={currentPage} lastPage={lastPage} onPageChange={this.handlePageChange} />
					</div>
				)}
			</div>
		)
	}
}

export default ListAttendance
