import React from 'react'

export function InputTextArea({ label, name, value, handleChange }) {
	return (
		<div>
			<label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
			<textarea
				className="appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none"
				name={name}
				value={value}
				onChange={handleChange}
			/>
		</div>
	)
}
