import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { Container, FormTitle, Button } from './styles'
import Loading from 'components/commons/Loading'
import { churchProfile, updateChurchProfile } from 'libs/apis/campus'
import { failedFetch } from 'libs/utils/messages'
import { successMessage, errorMessage, failedPost } from 'libs/utils/confirmation'

class ChurhProfileForm extends Component {
	state = {
		isLoading: false,
		isSubmit: false,
		isRedirect: false,
		errorMessage: '',
		name: '',
		code: '',
		qr: '',
		address: '',
		officeEmail: '',
		phone: '',
		supportEmail: '',
	}

	componentDidMount() {
		this.setState({ isLoading: true }, async () => {
			try {
				const response = await churchProfile()
				if (response.data.api_status) {
					this.setState({
						isLoading: false,
						name: response.data.data.church_name,
						code: response.data.data.church_code,
						qr: response.data.data.church_qr_code_link,
						address: response.data.data.office_address,
						officeEmail: response.data.data.office_email,
						phone: response.data.data.office_phone_number,
						supportEmail: response.data.data.support_email,
					})
				} else {
					this.setState({ isLoading: false, errorMessage: failedFetch })
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						await localStorage.removeItem('token')
						this.setState({ isRedirect: true })
					} else if (error.response.status >= 500) {
						this.setState({ isLoading: false, errorMessage: failedFetch })
					}
				}
			}
		})
	}

	handleOnChange = event => {
		this.setState({ [event.target.name]: event.target.value })
	}

	handleOnSave = () => {
		this.setState({ isSubmit: true }, async () => {
			try {
				const { name, code, qr, address, phone, officeEmail, supportEmail } = this.state
				const response = await updateChurchProfile(
					name,
					code,
					qr,
					address,
					phone,
					officeEmail,
					supportEmail
				)
				if (response.data.api_status) {
					this.setState({ isSubmit: false })
					await successMessage()
				} else {
					this.setState({ isSubmit: false })
					await errorMessage(response)
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						await localStorage.removeItem('token')
						this.setState({ isRedirect: true })
					} else if (error.response.status >= 500) {
						this.setState({ isSubmit: false })
						await failedPost()
					}
				}
			}
		})
	}

	renderForm = () => {
		const { name, code, qr, address, officeEmail, phone, supportEmail, isSubmit } = this.state
		return (
			<Fragment>
				<div className="form-group">
					<label>CHURCH NAME</label>
					<input className="form-control" disabled defaultValue={name} />
				</div>
				<div className="form-group">
					<label>CHURCH CODE</label>
					<input className="form-control" disabled defaultValue={code} />
				</div>
				<div className="form-group">
					<label>QR CODE LINK</label>
					<input className="form-control" disabled defaultValue={qr} />
				</div>
				<div className="form-group">
					<label>ADDRESS</label>
					<textarea
						className="form-control"
						rows={3}
						name="address"
						value={address}
						onChange={this.handleOnChange}
					/>
				</div>
				<div className="form-group">
					<label>EMAIL ADDRESS</label>
					<input
						className="form-control"
						type="email"
						name="officeEmail"
						value={officeEmail}
						onChange={this.handleOnChange}
					/>
				</div>
				<div className="form-group">
					<label>PHONE NUMBER</label>
					<input
						className="form-control"
						name="phone"
						value={phone}
						onChange={this.handleOnChange}
					/>
				</div>
				<div className="form-group">
					<label>EMAIL SUPPORT</label>
					<input
						className="form-control"
						type="email"
						name="supportEmail"
						value={supportEmail}
						onChange={this.handleOnChange}
					/>
				</div>
				<Button saveButton onClick={this.handleOnSave} disabled={isSubmit}>
					{isSubmit ? <Loading secondary /> : 'Save'}
				</Button>
			</Fragment>
		)
	}

	renderError = () => <div className="align-center">{this.state.errorMessage}</div>

	render() {
		const { isLoading, errorMessage, isRedirect } = this.state
		const displayLoading = isLoading && !errorMessage
		const displayForm = !isLoading && !errorMessage
		const displayError = !isLoading && errorMessage

		if (isRedirect) {
			return <Redirect to="/login" />
		}

		return (
			<Container>
				<FormTitle>Church Profile</FormTitle>
				{displayLoading && <Loading />}
				{displayForm && this.renderForm()}
				{displayError && this.renderError()}
			</Container>
		)
	}
}

export default ChurhProfileForm
