import React, { Component } from 'react'
import moment from 'moment'

class ViewRequestTableRow extends Component {
  render() {
    const { name, email, phone, gender, age, status, expired } = this.props
    return (
      <tr className="border border-solid bt-blueSecondary">
        <td className="px-2 py-4">{name}</td>
        <td className="px-2 py-4">{email}</td>
        <td className="px-2 py-4">{phone}</td>
        <td className="px-2 py-4">{gender}</td>
        <td className="px-2 py-4">{age}</td>
        <td className="px-2 py-4">{status}</td>
        <td className="px-2 py-4">{moment(expired, 'DD/MM/YYYY HH:mm').format('D MMM YYYY HH:mm')}</td>
      </tr>
    )
  }
}

export default ViewRequestTableRow
