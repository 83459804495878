import http from 'services/httpServices'
import axios from 'axios'

export const ministryList = async (page, filter_status, filter_keyword, order_by, order_dir) => {
	return await http.search(
		'/ministry',
		{
			page,
			filter_status,
			filter_keyword,
			order_by,
			order_dir,
		},
		localStorage.getItem('token')
	)
}

export const ministryDetail = async (id, filter_keyword) => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/detail/${id}`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: { filter_keyword },
	})
}

export const requestMinistry = async (filter_keyword, order_by, order_dir) => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/request`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: { filter_keyword, order_by, order_dir },
	})
}

export const getListVolunteers = async (filter_keyword, ministry_id) => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/member/list`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: { filter_keyword, ministry_id },
	})
}

export const createMinistry = async formdata => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/create`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: formdata,
	})
}

export const updateMinistry = async formdata => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/update`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: formdata,
	})
}

export const inviteMember = async (ministry_id, user_id) => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/member/invite`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: {
			ministry_id,
			user_id,
		},
	})
}

export const assignMember = async (ministry_id, user_id, church_role_id) => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/member/assign`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: { ministry_id, user_id, church_role_id },
	})
}

export const rejectMember = async (ministry_id, user_id) => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/member/reject`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: { ministry_id, user_id },
	})
}

export const removeMember = async (ministry_id, user_id) => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/member/remove`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: { ministry_id, user_id },
	})
}

export const closeMinistry = async ministry_id => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/close`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: ministry_id,
	})
}

export const searchVolunteers = async (filter_keyword, ministry_id) => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/member/search`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			filter_keyword,
			ministry_id,
		},
	})
}
