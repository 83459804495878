import React from 'react'

export function InputNumber({ label, value, handleChange, min }) {
	return (
		<div>
			<label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
			<input
				className="appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none"
				type="number"
				value={value}
				onChange={handleChange}
				min={min}
			/>
		</div>
	)
}
