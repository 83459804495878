import React from "react";

const ToggleSwitch = ({ label, checked, onToggle }) => {
  return (
    <div>
      <div className="inline-block align-middle text-xs">{label}</div>
      <div
        className={`w-12 h-6 rounded-full shadow inline-block align-middle cursor-pointer ml-2 ${
          checked ? "bg-green-300" : "bg-grey"
        }`}
        onClick={onToggle}
      >
        <div
          className="inline-block w-1/2 h-full bg-white rounded-full shadow"
          style={{ transform: `translateX(${checked ? 100 : 0}%)` }}
        ></div>
      </div>
    </div>
  );
};

export default ToggleSwitch;
