import React from 'react'
import Select from 'react-select'

export function Dropdown({ options, label, handleChange, value }) {
	const customStyles = {
		control: styles => ({
			...styles,
			border: 'none',
		}),
	}
	return (
		<div className="w-1/2">
			<label className="block text-gray-700 text-sm font-bold mt-4 mb-2">{label}</label>
			<Select styles={customStyles} className="rounded-lg pl-2 bg-white border-gray-200 border-2 border-solid" options={options} onChange={handleChange} value={value} />
		</div>
	)
}
