import React from 'react'

const Searchbar = ({ filterKeyword, handleOnSearch }) => (
	<div className="md:w-4/12 w-full">
		<div className="shadow-md flex rounded-lg bg-white px-3 py-2 mx-2 mb-4 sm:mb-0">
			<div className="mr-4 text-gray">
				<i className="fas fa-search" />
			</div>
			<input type="text" className="w-full" placeholder="Search" name="filterKeyword" value={filterKeyword} onChange={handleOnSearch} />
		</div>
	</div>
)

export default Searchbar
