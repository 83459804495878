import React, { Component } from 'react'
import { partial } from 'libs/utils/utils'
import PushNotifRow from './PushNotifRow'

class PushNotifTable extends Component {
	render() {
		const { handleOnSort, list, onDelete } = this.props

		return (
			<div className="bg-white shadow-md mt-10">
				<table className="w-full rounded-lg">
					<TableHeader handleOnSort={handleOnSort} />
					<TableBody handlOnDelete={onDelete} list={list} />
				</table>
			</div>
		)
	}
}

const TableHeader = ({ handleOnSort }) => {
	const handleSortSchedule = partial(handleOnSort, 'created_at')
	// const handleSortCreatedOn = partial(handleOnSort, "created_at");

	return (
		<thead className="bt-blueSecondary border-2 border-solid">
			<tr>
				<th className="py-4 text-left pl-10">NO.</th>
				<th className="text-left pl-6">SUBJECT</th>
				<th className="text-center">CREATED ON {/* <i
            className="fas fa-sort sortby cursor-pointer"
            onClick={handleSortCreatedOn}
          /> */}</th>
				<th className="text-center">LAST UPDATE {/* <i
            className="fas fa-sort sortby cursor-pointer"
            onClick={handleSortSchedule}
          /> */}</th>
				<th className="text-center">
					SCHEDULE <i className="fas fa-sort sortby cursor-pointer" onClick={handleSortSchedule} />
				</th>
				<th className="text-center">STATUS</th>
				<th className="py-4" />
			</tr>
		</thead>
	)
}

const TableBody = ({ list, handlOnDelete }) => (
	<tbody className="border-2 border-solid">
		{list.map(data => (
			<PushNotifRow
				{...data}
				key={data.id}
				no={data.id}
				id={data.id}
				subject={data.title}
				createdBy
				createdOn={data.created_at}
				lastUpdate={data.updated_at}
				schedule={data.schedule}
				status={data.status}
				handleDelete={handlOnDelete}
			/>
		))}
	</tbody>
)

export default PushNotifTable
