import React from 'react'
import moment from 'moment'

const PraiseItem = ({ category, created, description, prayer }) => (
  <div className="bg-white shadow-md mb-4 p-4">
    <div>
      <p className="font-bold">
        {category} | {moment(created, 'YYYY-MM-DD').format('DD MMMM YYYY')}
      </p>
      <p>{description}</p>
    </div>
    {prayer && (
      <div>
        <p className="font-bold">Prayer</p>
        <p>{prayer}</p>
      </div>
    )}
  </div>
)

export default PraiseItem
