import React from 'react'

import MinistryCard from './MinistryCard'
import PaginationPage from 'components/commons/PaginationPage'

function MinistryMemberList(props) {
	const {
		currentPage,
		lastPage,
		activeLink,
		url,
		handleMinistryDetail,
		leaders,
		members,
	} = props

	return (
		<div>
			<div className="flex flex-wrap">
				{leaders.map(leader => (
					<MinistryCard
						key={leader.user_id}
						leader={true}
						{...leader}
						handleMinistryDetail={handleMinistryDetail}
					/>
				))}
				{members.map(member => (
					<MinistryCard
						key={member.user_id}
						member={true}
						{...member}
						handleMinistryDetail={handleMinistryDetail}
					/>
				))}
			</div>
			<PaginationPage
				currentPage={currentPage}
				lastPage={lastPage}
				activeLink={activeLink}
				url={url}
			/>
		</div>
	)
}

export default MinistryMemberList
