import React from 'react'
import { eventDetail, eventCategories, getEventRoles, getEventMarital } from 'libs/apis/events'
import { getListCampuses } from 'libs/apis/campus'
import { getRole } from 'libs/apis/smallGroup'
import moment from 'moment'
import { constants } from 'constants/constant'

export default function eventHOC(WrappedComponent) {
	return class extends React.Component {
		state = {
			eventTitle: '',
			eventShortTitle: '',
			eventDescription: '',
			isEventTermCondition: false,
			eventTermCondition: '',
			eventStartDate: '',
			eventEndDate: '',
			isAllDayEvent: false,
			startTime: '',
			endTime: '',
			venues: [],
			venue: '',
			venueName: '',
			venueAddress: '',
			eventImage: null,
			eventStartRegis: '',
			eventEndRegis: '',
			categories: [],
			eventCategory: '',
			quota: 0,
			startDateShare: '',
			isEndDateShare: false,
			endDateShare: '',
			isHighlight: false,
			isLoading: false,
			isRedirect: false,
			is_send_campaign: false,
			campaign_type: '',
			maritalValue: '',
			maritalValues: [],
			maritalStatusAll: false,
			gender: '',
			event_price: '',
			is_require_payment: false,
			filter_roles: [],
			selectedRoles: [],
			visibleToAll: false,
			errors: {
				eventTitle: '',
				eventShortTitle: '',
				eventCategory: '',
				eventStartDate: '',
				eventEndDate: '',
				startTime: '',
				endTime: '',
				venueName: '',
				venueAddress: '',
				eventImage: '',
				eventStartRegis: '',
				eventEndRegis: '',
				eventPrice: '',
			},
		}

		componentDidMount() {
			this.setState({ isLoading: true }, async () => {
				try {
					await this.handleGetCampuses()
					await this.handleGetEventCategories()
					await this.handleGetRole()

					if (this.props.match.params.id) {
						await this.handleGetMaritalStatus(this.props.match.params.id)
						const form = await eventDetail(this.props.match.params.id)
						const eventRoles = await getEventRoles(this.props.match.params.id)
						if (form.data.api_status) {
							this.setState({
								isLoading: false,
								selectedRoles: eventRoles.data.data,
								eventTitle: form.data.data.title,
								eventShortTitle: form.data.data.short_title,
								eventDescription: form.data.data.description,
								eventTermCondition: form.data.data.terms_and_conditions,
								isEventTermCondition: form.data.data.terms_and_conditions ? true : false,
								eventStartDate: form.data.data.start_date,
								eventEndDate: form.data.data.end_date,
								isAllDayEvent: form.data.data.is_all_day,
								startTime: form.data.data.start_time,
								endTime: form.data.data.end_time,
								venue: form.data.data.venue_id ? form.data.data.venue_id : '',
								venueName: form.data.data.venue_other_name ? form.data.data.venue_other_name : '',
								venueAddress: form.data.data.venue_other_address ? form.data.data.venue_other_address : '',
								eventImage: form.data.data.banner_url,
								eventStartRegis: form.data.data.registration_start_datetime,
								eventEndRegis: form.data.data.registration_end_datetime,
								eventCategory: form.data.data.event_category_id,
								quota: form.data.data.participant_quota,
								startDateShare: form.data.data.publish_start_datetime,
								isEndDateShare: form.data.data.publish_end_datetime ? true : false,
								endDateShare: form.data.data.publish_end_datetime,
								isHighlight: form.data.data.is_highlight,
								is_require_payment: form.data.data.is_require_payment,
								event_price: form.data.data.event_price,
								is_send_campaign: form.data.data.is_send_campaign === 1 ? true : false,
								campaign_type: form.data.data.campaign_type,
								gender: form.data.data.gender,
							})
						} else {
							this.setState({
								isLoading: false,
								error: 'An unexpected error has occurred. We are currently investigating the issue, please try again later.',
							})
						}
					}
					this.setState({ ...this.state, isLoading: false })
				} catch (error) {
					if (error.response) {
						if (error.response.status === 401) {
							await localStorage.removeItem('token')
							this.setState({ isRedirect: true })
						} else if (error.response.status >= 500) {
							this.setState({
								isLoading: false,
								error: 'An unexpected error has occurred. We are currently investigating the issue, please try again later.',
							})
						}
					}
				}
			})
		}

		handleGetMaritalStatus = async (id) => {
			let response = await getEventMarital(id)

			this.setState({ ...this.state, maritalValues: response.data.data })
		}

		handleGetCampuses = async () => {
			let venuesLclStrg = JSON.parse(localStorage.getItem('venues'))
			if (venuesLclStrg === null) {
				let res = await getListCampuses()
				localStorage.setItem('venues', JSON.stringify(res.data.data))
				this.setState({ ...this.state, venues: res.data.data })
			} else {
				let venues = JSON.parse(localStorage.getItem('venues'))
				this.setState({ ...this.state, venues })
			}
		}

		handleGetEventCategories = async () => {
			let categoriesLclStrg = JSON.parse(localStorage.getItem('event_categories'))
			if (categoriesLclStrg === null) {
				let res = await eventCategories()
				localStorage.setItem('event_categories', JSON.stringify(res.data.data))
				this.setState({ ...this.state, categories: res.data.data })
			} else {
				let categories = JSON.parse(localStorage.getItem('event_categories'))
				this.setState({ ...this.state, categories })
			}
		}

		handleGetRole = async () => {
			let rolesLocalStorage = JSON.parse(localStorage.getItem('roles'))
			if (rolesLocalStorage === null) {
				let res = await getRole()
				localStorage.setItem('roles', JSON.stringify(res.data.data))
				this.setState({ ...this.state, filter_roles: res.data.data })
			} else {
				let roles = JSON.parse(localStorage.getItem('roles'))
				this.setState({ ...this.state, filter_roles: roles })
			}
		}

		handleOnChange = (event) => {
			this.setState({ ...this.state, [event.target.name]: event.target.value })
			if (event.target.name === 'eventCategory') {
				if (event.target.value === '1') {
					this.setState({ ...this.state, [event.target.name]: event.target.value, gender: 'M' })
				}
				if (event.target.value === '2') {
					this.setState({ ...this.state, [event.target.name]: event.target.value, gender: 'F' })
				}
				if (event.target.value !== '1' && event.target.value !== '2') {
					this.setState({ ...this.state, [event.target.name]: event.target.value, gender: 'A' })
				}
			}
		}

		handleOnDescription = (value) => {
			this.setState({ ...this.state, eventDescription: value })
		}

		handleOnTermCondition = (value) => {
			this.setState({ ...this.state, eventTermCondition: value })
		}

		handleOnChangeNumber = (event) => {
			if (event.target.value) {
				this.setState({ ...this.state, quota: parseInt(event.target.value) })
				event.target.value = parseInt(event.target.value)
			} else {
				this.setState({ ...this.state, quota: 0 })
			}
		}

		// handle check
		handleOnCheck = (e) => {
			let value = e.target.value === 'false'
			this.setState({
				...this.state,
				[e.target.name]: value,
			})
		}

		handleOnCheckAllDay = () => {
			this.setState({ ...this.state, isAllDayEvent: !this.state.isAllDayEvent }, () => {
				if (this.state.isAllDayEvent) {
					this.setState({ startTime: '', endTime: '' })
				}
			})
		}

		handleOnCheckShare = (event) => {
			let value = event.target.value === 'false'
			this.setState({ ...this.state, isEndDateShare: value }, () => {
				if (!value) {
					this.setState({ ...this.state, endDateShare: '' })
				}
			})
		}

		handleCheckSendAll = () => {
			const { visibleToAll, filter_roles } = this.state
			let selectedRoles = filter_roles.map((role) => role.role_id)

			if (visibleToAll) {
				this.setState({ ...this.state, visibleToAll: false, selectedRoles: [] })
			} else {
				this.setState({ ...this.state, visibleToAll: true, selectedRoles: selectedRoles })
			}
		}

		handleCheckMaritalAll = () => {
			const { maritalStatusAll } = this.state
			const { maritalStatus } = constants

			let maritals = maritalStatus.map((marital) => marital.maritalValue)

			if (maritalStatusAll) {
				this.setState({ ...this.state, maritalStatusAll: false, maritalValues: [] })
			} else {
				this.setState({ ...this.state, maritalStatusAll: true, maritalValues: maritals })
			}
		}

		handleMaritalCheckbox = (e) => {
			if (e.target.checked) {
				this.setState({ ...this.state, maritalValues: [...this.state.maritalValues, e.target.value], maritalStatusAll: false })
			} else {
				this.setState({
					...this.state,
					maritalValues: this.removeItem(this.state.maritalValues, e.target.value),
					maritalStatusAll: false,
				})
			}
		}

		handleCheckboxRole = (e) => {
			if (!e.target.checked) {
				this.setState({
					...this.state,
					selectedRoles: this.removeItem(this.state.selectedRoles, parseInt(e.target.value)),
					visibleToAll: false,
				})
			} else {
				this.setState({
					...this.state,
					selectedRoles: [...this.state.selectedRoles, parseInt(e.target.value)],
					visibleToAll: false,
				})
			}
		}

		// handle time
		handleStartEndDate = (_, value, attribute) => {
			this.setState({
				...this.state,
				[attribute.input.name]: _ ? moment(_[0]).format('DD/MM/YYYY') : '',
			})
		}

		handleStartEndTime = (_, value, attribute) => {
			this.setState({ ...this.state, [attribute.input.name]: moment(_[0]).format('HH:mm') })
		}

		handleStartEndWithSec = (_, value, attribute) => {
			this.setState({
				...this.state,
				[attribute.input.name]: moment(_[0]).format('DD/MM/YYYY HH:mm'),
			})
		}

		handleOnChangeImage = (event) => {
			this.setState({ ...this.state, eventImage: event.target.files[0] })
		}

		removeItem = (arr, value) => {
			console.log(arr, 'array')

			return arr.filter(function (ele) {
				return ele !== value
			})
		}

		handleValidate = (callback) => {
			const {
				eventTitle,
				eventShortTitle,
				eventDescription,
				eventStartDate,
				eventEndDate,
				startTime,
				endTime,
				selectedRoles,
				eventImage,
				eventStartRegis,
				eventEndRegis,
				eventCategory,
				quota,
				startDateShare,
				gender,
				maritalValues,
				venueAddress,
				venueName,
				visibleToAll,
				maritalStatusAll,
			} = this.state

			let errors = []
			if (!eventTitle) {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventTitle: 'Required' },
				}))
				errors.push('Required')
			} else {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventTitle: '' },
				}))
				errors = []
			}

			if (!eventShortTitle) {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventShortTitle: 'Required' },
				}))
				errors.push('Required')
			} else {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventShortTitle: '' },
				}))
				errors = []
			}

			if (!eventDescription) {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventDescription: 'Required' },
				}))
				errors.push('Required')
			} else {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventDescription: '' },
				}))
				errors = []
			}

			if (!eventStartDate) {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventStartDate: 'Required' },
				}))
				errors.push('Required')
			} else {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventStartDate: '' },
				}))
				errors = []
			}

			if (!eventEndDate) {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventEndDate: 'Required' },
				}))
				errors.push('Required')
			} else {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventEndDate: '' },
				}))
				errors = []
			}

			if (!startTime) {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, startTime: 'Required' },
				}))
				errors.push('Required')
			} else {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, startTime: '' },
				}))
				errors = []
			}

			if (!endTime) {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, endTime: 'Required' },
				}))
				errors.push('Required')
			} else {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, endTime: '' },
				}))
				errors = []
			}

			if (!eventImage) {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventImage: 'Required' },
				}))
				errors.push('Required')
			} else {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventImage: '' },
				}))
				errors = []
			}

			if (!eventStartRegis) {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventStartRegis: 'Required' },
				}))
				errors.push('Required')
			} else {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventStartRegis: '' },
				}))
				errors = []
			}

			if (!eventEndRegis) {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventEndRegis: 'Required' },
				}))
				errors.push('Required')
			} else {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventEndRegis: '' },
				}))
				errors = []
			}

			if (!eventCategory) {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventCategory: 'Required' },
				}))
				errors.push('Required')
			} else {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventCategory: '' },
				}))
				errors = []
			}

			if (!quota) {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventQuota: 'Required' },
				}))
				errors.push('Required')
			} else {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventQuota: '' },
				}))
				errors = []
			}

			if (!startDateShare) {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, startDateShare: 'Required' },
				}))
				errors.push('Required')
			} else {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, startDateShare: '' },
				}))
				errors = []
			}

			if (!gender) {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventGender: 'Required' },
				}))
				errors.push('Required')
			} else {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventGender: '' },
				}))
				errors = []
			}

			if (!venueName) {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, venueName: 'Required' },
				}))
				errors.push('Required')
			} else {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, venueName: '' },
				}))
				errors = []
			}

			if (!venueAddress) {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, venueAddress: 'Required' },
				}))
				errors.push('Required')
			} else {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, venueAddress: '' },
				}))
				errors = []
			}

			if (maritalValues.length === 0 && !maritalStatusAll) {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventMarital: 'Required' },
				}))
				errors.push('Required')
			} else {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eventMarital: '' },
				}))
				errors = []
			}

			if (selectedRoles.length === 0 && !visibleToAll) {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eligibleTo: 'Required' },
				}))
				errors.push('Required')
			} else {
				this.setState((prevState) => ({
					errors: { ...prevState.errors, eligibleTo: '' },
				}))
				errors = []
			}

			if (errors.length === 0) {
				callback()
			}
		}

		render() {
			return (
				<WrappedComponent
					{...this.state}
					{...this.props}
					handleMaritalCheckbox={this.handleMaritalCheckbox}
					handleCheckMaritalStatus={this.handleCheckMaritalStatus}
					handleCheckboxRole={this.handleCheckboxRole}
					handleOnChangeImage={this.handleOnChangeImage}
					handleStartEndWithSec={this.handleStartEndWithSec}
					handleStartEndTime={this.handleStartEndTime}
					handleStartEndDate={this.handleStartEndDate}
					handleOnCheckShare={this.handleOnCheckShare}
					handleOnCheckAllDay={this.handleOnCheckAllDay}
					handleOnCheck={this.handleOnCheck}
					handleOnChangeNumber={this.handleOnChangeNumber}
					handleOnTermCondition={this.handleOnTermCondition}
					handleOnDescription={this.handleOnDescription}
					handleOnChange={this.handleOnChange}
					handleCheckSendAll={this.handleCheckSendAll}
					handleCheckMaritalAll={this.handleCheckMaritalAll}
					handleValidate={this.handleValidate}
				/>
			)
		}
	}
}
