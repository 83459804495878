import axios from 'axios'
import search from './search'

export const createEvent = async (formdata) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/event/create`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: formdata,
	})

export const eventList = async (page, filter_keyword, filter_status, order_by, order_dir) =>
	await search(`${process.env.REACT_APP_ENDPOINT}/event?page=${page}&filter_keyword=${filter_keyword}&filter_status=${filter_status}&order_by=${order_by}&order_dir=${order_dir}`)

export const archiveEvent = async (id) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/event/archive/${id}`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const highglightEvent = async (id) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/event/highlight/${id}`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const unhighlightEvent = async (id) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/event/unhighlight/${id}`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const eventCategories = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/event/categories`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const eventDetail = async (id) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/event/detail/${id}`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const updateEvent = async (formdata) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/event/update`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: formdata,
	})

export const getEventRoles = async (id) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/event/role-event/${id}`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const getEventMarital = async (id) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/event/marital-event/${id}`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
