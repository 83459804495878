import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Loading from 'components/commons/Loading'
import NotificationDanger from 'components/commons/NotificationDanger'
import ServiceContainer from './ServiceContainer'
import { partial } from 'libs/utils/utils'

import { CampusItemsContainer, CampusItemCard, CampusItemTitle, CampusButtonWrapper, CampusCardModalTrigger, ModalStyle, ModalCampus, ButtonContainer, DoneModalContainer, Button } from './styles'

import ReactModal from 'react-modal'
import { updateCampus } from '../../libs/apis/campus'
import { failedFetch } from '../../libs/utils/messages'
import { successMessage } from '../../libs/utils/confirmation'

class CampusItem extends Component {
	state = {
		modalCampus: false,
		modalService: false,
		isRedirect: false,
		isSubmit: false,
		errorMessage: '',
		id: '',
		name: '',
		address: '',
		errors: {
			name: '',
			address: '',
		},
	}

	componentDidMount() {
		this.setState({ name: this.props.name, address: this.props.address, id: this.props.id })
	}

	handleOnToogleCampus = () => {
		this.setState({ modalCampus: !this.state.modalCampus })
	}

	handleOnToogleService = () => {
		this.setState({ modalService: !this.state.modalService })
	}

	handleOnChange = (event) => {
		this.setState({ [event.target.name]: event.target.value })
	}

	handleOnSubmit = () => {
		const { name, address } = this.state
		const errors = []
		if (!name) {
			this.setState((prevState) => ({ errors: { ...prevState.errors, name: 'Required' } }))
			errors.push('Name is Required')
		} else {
			this.setState((prevState) => ({ errors: { ...prevState.errors, name: '' } }))
		}

		if (!address) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, address: 'Required' },
			}))
			errors.push('Address is Required')
		} else {
			this.setState((prevState) => ({ errors: { ...prevState.errors, address: '' } }))
		}

		if (name && address) {
			this.handleOnSave()
		}
	}

	handleOnSave = () => {
		this.setState({ isSubmit: true }, async () => {
			try {
				const { id, name, address } = this.state
				const response = await updateCampus(id, name, address, '', '')
				if (response.data.api_status) {
					this.setState({ isSubmit: false, modalCampus: false }, () => {
						this.props.handleUpdateList(id, name, address)
					})
					await successMessage()
				} else {
					this.setState({ isSubmit: false, errorMessage: response.data.message })
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						await localStorage.removeItem('token')
						this.setState({ isRedirect: true })
					} else if (error.response.status >= 500) {
						this.setState({ isSubmit: false, errorMessage: failedFetch })
					}
				}
			}
		})
	}

	render() {
		const { name, address, isSubmit, errorMessage, isRedirect, id } = this.state
		const { modalCampus, modalService } = this.state
		const { handleOnDeleteCampus } = this.props
		const handleDelete = partial(handleOnDeleteCampus, id)

		if (isRedirect) {
			return <Redirect to="/login" />
		}

		return (
			<CampusItemsContainer>
				<CampusItemCard>
					<CampusItemTitle>{this.props.name}</CampusItemTitle>
					{this.props.address}
				</CampusItemCard>
				<CampusButtonWrapper>
					<CampusCardModalTrigger onClick={this.handleOnToogleCampus}>
						<i className="far fa-edit" />
					</CampusCardModalTrigger>
					<CampusCardModalTrigger onClick={this.handleOnToogleService}>
						<i className="far fa-clock" />
					</CampusCardModalTrigger>
					<ReactModal isOpen={modalCampus} style={ModalCampus} ariaHideApp={false}>
						{errorMessage && <NotificationDanger message={errorMessage} />}
						<div className="form-group">
							<label>CAMPUS NAME</label>
							<input className="form-control" name="name" value={name} onChange={this.handleOnChange} />
						</div>
						<div className="form-group">
							<label>ADDRESS</label>
							<textarea className="form-control" name="address" rows={4} value={address} onChange={this.handleOnChange} />
						</div>
						<ButtonContainer>
							<Button cancel onClick={handleDelete}>
								{this.props.isSubmit ? <Loading secondary /> : 'Delete Campus'}
							</Button>
							<Button secondary onClick={this.handleOnToogleCampus}>
								{this.props.isSubmit ? <Loading primary /> : 'Cancel'}
							</Button>
							<Button primary onClick={this.handleOnSubmit} disabled={isSubmit}>
								{isSubmit || this.props.isSubmit ? <Loading secondary /> : 'Save'}
							</Button>
						</ButtonContainer>
					</ReactModal>
					<ReactModal isOpen={modalService} style={ModalStyle} ariaHideApp={false}>
						<i className="fas fa-times" style={{ display: 'flex', justifyContent: 'flex-end' }} onClick={this.handleOnToogleService} />
						<ServiceContainer id={id} />
						<DoneModalContainer>
							<Button primary onClick={this.handleOnToogleService}>
								{isSubmit ? <Loading secondary /> : 'Done'}
							</Button>
						</DoneModalContainer>
					</ReactModal>
				</CampusButtonWrapper>
			</CampusItemsContainer>
		)
	}
}

export default CampusItem
