import React from 'react'
import Plot from 'react-plotly.js'

const monthlyActiveUserChart = ({ data }) => {
  return (
    <Plot
      data={[{ x: data[0], y: data[1], type: 'scatter' }]}
      layout={{
        autosize: true,
        margin: { t: 10, b: 10 },
        xaxis: { autorange: true, rangeslider: {}, tickangle: 15, tickformat: '%d %B (%a)\n %Y' }
      }}
    />
  )
}

export default monthlyActiveUserChart
