import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import { smallGroupDetail } from 'libs/apis/smallGroup'
import Loading from 'components/commons/Loading'
import SGDetails from 'components/smallGroup/SGDetails'
import SGMemberList from 'components/widgets/SGMemberList'
import { Link } from 'react-router-dom'
// import ViewAttendance from "./ListAttendance";

class ViewSmallGroup extends Component {
	state = {
		isLoadingData: false,
		errorMessage: '',
		isRedirect: false,
		smallGroupName: '',
		smallGroupLeader: '',
		smallGroupAddress: '',
		smallGroupRegency: '',
		smallGroupPostal: '',
		smallGroupDay: '',
		smallGroupHour: '00',
		smallGroupMin: '00',
		smallGroupMember: 0,
		smallGroupStatus: '',
		smallGroupType: '',
		smallGroupCreated: '',
		childGroup: [],
		parentGroup: '',
		leaders: [],
		members: [],
		probations: [],
	}

	componentDidMount() {
		const message = 'An unexpected error has occurred. We are currently investigating the issue, please try again later.'
		this.setState({ isLoadingData: true }, async () => {
			try {
				const id = this.props.match.params.id
				const response = await smallGroupDetail(id)

				if (response.data.api_status) {
					const data = response.data.data
					this.setState({
						isLoadingData: false,
						smallGroupName: data.small_group_name,
						smallGroupLeader: data.leaders_name,
						smallGroupAddress: data.long_address,
						smallGroupRegency: data.regency_name,
						smallGroupPostal: data.postal_code,
						smallGroupDay: data.time_day_desc,
						smallGroupHour: data.time_hours < 9 ? `0${data.time_hours}` : data.time_hours.toString(),
						smallGroupMin: data.time_minutes < 9 ? `0${data.time_minutes}` : data.time_minutes.toString(),
						smallGroupMember: data.member_count,
						smallGroupStatus: data.small_group_status,
						smallGroupType: data.category,
						smallGroupCreated: data.created_at ? moment(data.created_at, 'DD/MM/YYYY HH:mm').format('MMMM YYYY') : '-',
						childGroup: data.child_group,
						parentGroup: data.parent_group ? data.parent_group : '-',
						leaders: data.leaders,
						members: data.members,
						probations: data.probations,
					})
				} else {
					this.setState({ isLoading: false })
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						await localStorage.removeItem('token')
						this.setState({ isRedirect: true })
					} else if (error.response.status >= 500) {
						this.setState({ isLoading: false, errorMessage: message })
					}
				}
			}
		})
	}

	renderData = () => {
		const {
			smallGroupName,
			smallGroupLeader,
			smallGroupAddress,
			smallGroupRegency,
			smallGroupPostal,
			smallGroupDay,
			smallGroupHour,
			smallGroupMin,
			smallGroupMember,
			smallGroupStatus,
			smallGroupType,
			smallGroupCreated,
			childGroup,
			parentGroup,
			leaders,
			members,
			probations,
		} = this.state
		return (
			<div className="flex mb-4">
				<div className="w-1/4">
					<SGDetails
						groupName={smallGroupName}
						leader={smallGroupLeader}
						address={smallGroupAddress}
						regency={smallGroupRegency}
						postal={smallGroupPostal}
						day={smallGroupDay}
						hour={smallGroupHour}
						minute={smallGroupMin}
						member={smallGroupMember}
						status={smallGroupStatus}
						type={smallGroupType}
						created={smallGroupCreated}
						child={childGroup}
						parent={parentGroup}
					/>
				</div>

				<div className="w-3/4 ml-4">
					<SGMemberList leaders={leaders} members={members} probations={probations} sgId={this.props.match.params.id} />
				</div>
			</div>
		)
	}

	renderError = () => <div className="align-center">{this.state.errorMessage}</div>

	render() {
		const { isRedirect, isLoadingData, errorMessage } = this.state
		const church = JSON.parse(localStorage.getItem('church'))
		const name = church.church_name ? church.church_name : '-'
		const displayLoading = isLoadingData && !errorMessage
		const displayError = !isLoadingData && errorMessage
		const displayData = !isLoadingData && !errorMessage
		const breads = [
			{ url: '/small-group/list', title: 'List of All Groups' },
			{ url: '', title: 'View Group' },
		]

		if (isRedirect) {
			return <Redirect to="/login" />
		}

		return (
			<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
				<Helmet>
					<title>{name} - View Group</title>
				</Helmet>
				<PageBreadcrumbs icon={SGIcon} title="View Group" breads={breads} />

				<button className="bg-gray-700 hover:bg-gray-600 font-bold py-2 px-4 mb-4 rounded">
					<Link className="text-white" to={`/smallgroup/attendance`}>
						View Attendance
					</Link>
				</button>

				{displayLoading && <Loading />}
				{displayData && this.renderData()}
				{displayError && this.renderError()}
			</div>
		)
	}
}

export default ViewSmallGroup
