import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg'

class HomeMenu extends Component {
	state = {
		isOpenSub: false,
	}

	componentDidMount() {
		const {
			location: { pathname },
		} = this.props
		const menus = ['/', '/input-data', '/first-timer'].filter(menu => menu === pathname)
		if (menus.length > 0) {
			this.setState({ isOpenSub: true })
		}
	}

	handleOpenSub = () => {
		this.setState({ isOpenSub: !this.state.isOpenSub })
	}

	render() {
		const {
			location: { pathname },
		} = this.props
		const { isOpenSub } = this.state

		const menus = ['/', '/input-data', '/first-timer'].filter(menu => menu === pathname)

		const styleIcon = `${menus.length > 0 ? 'w-4 h-4 fill-primary' : 'w-4 h-4 fill-gray'}`
		const styleLinkDataReport = `${pathname === '/' ? 'text-primary' : 'text-gray-800'}`
		const styleLinkInputData = `${pathname === '/input-data' ? 'text-primary' : 'text-gray-800'}`
		const styleLinkInputFirstTimer = `${pathname === '/first-timer' ? 'text-primary' : 'text-gray-800'}`

		return (
			<div className="mb-6">
				<div className="flex cursor-pointer" onClick={this.handleOpenSub}>
					<div className="mr-4">
						<HomeIcon className={styleIcon} />
					</div>
					<div>Home</div>
				</div>
				{isOpenSub && (
					<div className="ml-8 mt-2">
						<ul>
							<li className="mb-4">
								<Link to="/" className={styleLinkDataReport}>
									Data Report
								</Link>
							</li>
							<li className="mb-4">
								<Link to="/input-data" className={styleLinkInputData}>
									Input Data
								</Link>
							</li>
							<li className="mb-4">
								<Link to="/first-timer" className={styleLinkInputFirstTimer}>
									First Timer
								</Link>
							</li>
						</ul>
					</div>
				)}
			</div>
		)
	}
}

export default HomeMenu
