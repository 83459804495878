import React from 'react'
import numeral from 'numeral'
import styled from 'styled-components'

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
`

const Card = styled.div`
	box-shadow: 0 0 12px 0 var(--blackTwo);
	background: var(--whiteOne);
`

const Header = styled.div`
	font-weight: bold;
	padding: 10px 12px;
	background: var(--graySix);
	color: var(--whiteOne);
`

const Body = styled.div`
	padding: 10px 12px;
	display: grid;
	grid-gap: 8px;
`

const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
`

const Label = styled.label`
	font-weight: bold;
`

const Value = styled(Label)`
	color: var(--blueOne);
`

const XContainer = styled.div`
	justify-content: flex-end;
	display: flex;
`

const Line = styled.hr`
	width: 100%;
	margin-top: 0;
	border: solid 1px var(--blueTwo);
`

const BillingInfo = ({ rate, billedUsers }) => (
	<Wrapper>
		<Card>
			<Header>This Month’s Estimation</Header>
			<Body>
				<Container>
					<Label>BILLED USERS</Label>
					<Value>{billedUsers} users</Value>
				</Container>
				{/* <Container>
          <Label>RATE PER USER</Label>
          <Value>Rp. {numeral(rate).format('0,0')}</Value>
        </Container> */}
				<XContainer />
				<Line />
				<Container>
					<Label>TOTAL AMOUNT DUE</Label>
					<Value>Rp. {numeral(rate * billedUsers).format('0,0')}</Value>
				</Container>
			</Body>
		</Card>
		<Card>
			<Header>Current Billing Information</Header>
			<Body>
				<Container>
					<Label>BANK ACCOUNT NAME</Label>
					Bank Account Name
				</Container>
				<Container>
					<Label>BANK NAME</Label>BANK NAME
				</Container>
				<Container>
					<Label>BRANCH</Label>BRANCH
				</Container>
				<Container>
					<Label>A/C</Label>A/C
				</Container>
			</Body>
		</Card>
	</Wrapper>
)

export default BillingInfo
