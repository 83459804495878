import React, { Component } from 'react'
import moment from 'moment'
import AsyncSelect from 'react-select/async'
import ReactQuill from 'react-quill'
import Flatpickr from 'react-flatpickr'
import 'react-quill/dist/quill.snow.css'

import Loading from 'components/commons/Loading'
import { smallGroupOptions, smallGroupSearchUser, createSmallGroups } from 'libs/apis/smallGroup'
import { cancelConfirmation, successMessage, failedMessage, somethingWrong } from 'libs/utils/confirmation'

class SmallGroupMulti extends Component {
	state = {
		isSubmit: false,
		origin: null,
		category: '',
		name: '',
		status: '',
		leader: '',
		leaders: [],
		description: '',
		day: '',
		time: '',
		established_date: '',
		fullAddress: '',
		shortAddress: '',
		region: '',
		postalCode: '',
		errors: {
			origin: '',
			category: '',
			name: '',
			status: '',
			leader: '',
			day: '',
			time: '',
			fullAddress: '',
			shortAddress: '',
			region: '',
			postalCode: '',
			established_date: '',
		},
	}

	handleOnOriginChange = (input) => {
		this.setState({ origin: input }, async () => {
			try {
				const response = await smallGroupSearchUser('', this.state.origin.value)

				if (response.data.api_status) {
					this.setState({ leaders: response.data.data })
				}
			} catch (error) {}
		})
	}

	handleOnDescription = (value) => {
		this.setState({ description: value })
	}

	handleOnLoadOrigin = async (value) => {
		const response = await smallGroupOptions(value)
		if (response.data.api_status) {
			const options = response.data.data.map((item) => ({
				value: item.small_group_id,
				label: item.small_group_name,
			}))
			return options
		}
	}

	handleOnTime = (date) => {
		this.setState({ time: moment(date[0]).format('HH:mm') })
	}

	handleOnChange = (event) => {
		this.setState({ [event.target.name]: event.target.value })
	}

	handleEstabilishDate = (date) => {
		this.setState({
			...this.state,
			established_date: moment(date[0]).format('DD/MM/YYYY'),
		})
	}

	handleCancel = async () => {
		const res = await cancelConfirmation()
		if (res.value) {
			this.props.history.push('/small-group/list')
		}
	}

	handleOnSubmit = () => {
		const { origin, category, name, status, leader, day, time, fullAddress, shortAddress, region, postalCode, established_date } = this.state
		const errors = []
		if (!origin) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, origin: 'Required' },
			}))
			errors.push('Origin Required')
		} else {
			this.setState((prevState) => ({
				errrors: { ...prevState.errors, origin: '' },
			}))
		}

		if (!established_date) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, established_date: 'Required' },
			}))
			errors.push('Estabilished Date Required')
		} else {
			this.setState((prevState) => ({
				errrors: { ...prevState.errors, established_date: '' },
			}))
		}

		if (!category) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, category: 'Required' },
			}))
			errors.push('Category Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, category: '' },
			}))
		}

		if (!name) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, name: 'Required' },
			}))
			errors.push('Group Name is Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, name: '' },
			}))
		}

		if (!status) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, status: 'Required' },
			}))
			errors.push('Status Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, status: '' },
			}))
		}

		if (!leader) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, leader: 'Required' },
			}))
			errors.push('Leader is Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, leader: '' },
			}))
		}

		if (day === '') {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, day: 'Required' },
			}))
			errors.push('Day is Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, day: '' },
			}))
		}

		if (!time) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, time: 'Required' },
			}))
			errors.push('Time is Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, time: '' },
			}))
		}

		if (!fullAddress) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, fullAddress: 'Required' },
			}))
			errors.push('Full Address Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, fullAddress: '' },
			}))
		}

		if (!shortAddress) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, shortAddress: 'Required' },
			}))
			errors.push('Short Address Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, shortAddress: '' },
			}))
		}

		if (!region) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, region: 'Required' },
			}))
			errors.push('region is  Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, region: '' },
			}))
		}

		if (!postalCode) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, postalCode: 'Required' },
			}))
			errors.push('postal code is  Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, postalCode: '' },
			}))
		}

		if (errors.length === 0) {
			this.handlePost()
		}
	}

	handlePost = () => {
		this.setState({ isSubmit: true }, async () => {
			try {
				const { origin, category, name, description, leader, day, time, fullAddress, shortAddress, region, postalCode, status, established_date } = this.state

				const response = await createSmallGroups(
					origin.value,
					parseInt(category),
					name,
					description,
					parseInt(leader),
					parseInt(day),
					parseInt(moment(time, 'HH:mm').format('HH')),
					parseInt(moment(time, 'HH:mm').format('mm')),
					parseInt(region),
					fullAddress,
					shortAddress,
					'',
					'',
					status,
					'',
					'',
					postalCode,
					established_date
				)

				if (response.data.api_status) {
					await successMessage()
					this.props.history.push('/small-group/list')
				} else {
					await failedMessage(response.data.message)
					this.setState({ isSubmit: false })
				}
			} catch (error) {
				await somethingWrong()
				this.setState({ isSubmit: false })
			}
		})
	}

	render() {
		const { isSubmit, errors, origin, category, name, status, leader, leaders, description, day, time, fullAddress, shortAddress, region, postalCode, established_date } = this.state
		const { categories, statuses, regencies } = this.props

		const styleCategorySelect = `form-select block w-full border-2 rounded-lg border-secondary`
		const styleName = `appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none border-secondary`
		const styleStatusSelect = `form-select block w-full border-2 rounded-lg border-secondary`
		const styleLeaderSelect = `form-select block w-full border-2 rounded-lg border-secondary ${!leaders.length ? 'bg-greySecondary' : 'bg-white'}`
		const styleDaySelect = `form-select block w-full border-2 rounded-lg border-secondary`
		const styleTime = `appearance-none border-2 rounded-lg w-full py-2 px-3 focus:outline-none border-secondary`
		const styleAddress = `appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none border-secondary`
		const styleShortAddress = `appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none border-secondary`
		const styleRegionSelect = `form-select block w-full border-2 rounded-lg border-secondary`
		const stylePostalCode = `appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none border-secondary`

		return (
			<div className="bg-white p-4 shadow-md">
				<div className="flex">
					<div className="w-1/2">
						<div className="mb-6">
							<label className="block text-gray-700 text-sm font-bold mb-2">SMALL GROUP ORIGIN</label>
							<AsyncSelect value={origin} loadOptions={this.handleOnLoadOrigin} onChange={this.handleOnOriginChange} />
							{errors.origin && <div className="text-red-500 text-xs italic">{errors.origin}</div>}
						</div>
					</div>
				</div>
				<div className="flex">
					<div className="w-1/2">
						<div className="mb-6">
							<label className="block text-gray-700 text-sm font-bold mb-2">SMALL GROUP CATEGORY</label>
							<select className={styleCategorySelect} name="category" value={category} onChange={this.handleOnChange}>
								<option value="" disabled>
									Choose
								</option>
								{categories.map((item) => (
									<option key={item.id} value={item.id}>
										{item.title}
									</option>
								))}
							</select>
							{errors.category && <div className="text-red-500 text-xs italic">{errors.category}</div>}
						</div>
					</div>
				</div>
				<div className="flex">
					<div className="w-1/2">
						<div className="mb-6">
							<label className="block text-gray-700 text-sm font-bold mb-2">SMALL GROUP NAME</label>
							<input className={styleName} name="name" value={name} onChange={this.handleOnChange} />
							{errors.name && <div className="text-red-500 text-xs italic">{errors.name}</div>}
						</div>
					</div>
				</div>
				<div className="flex">
					<div className="w-1/2">
						<div className="mb-6">
							<label className="block text-gray-700 text-sm font-bold mb-2">SMALL GROUP STATUS</label>
							<select className={styleStatusSelect} name="status" value={status} onChange={this.handleOnChange}>
								<option value="" disabled>
									Choose
								</option>
								{statuses.map((item) => (
									<option key={item.id} value={item.id}>
										{item.title}
									</option>
								))}
							</select>
							{errors.status && <div className="text-red-500 text-xs italic">{errors.status}</div>}
						</div>
					</div>
				</div>
				<div className="flex">
					<div className="w-1/2">
						<div className="mb-6">
							<label className="block text-gray-700 text-sm font-bold mb-2">SMALL GROUP LEADER</label>
							<select className={styleLeaderSelect} name="leader" disabled={!leaders.length} value={leader} onChange={this.handleOnChange}>
								<option value="" disabled>
									Choose
								</option>
								{leaders.map((item) => (
									<option key={item.user_id} value={item.user_id}>
										{item.fullname} - {item.email}
									</option>
								))}
							</select>
							{errors.leader && <div className="text-red-500 text-xs italic">{errors.leader}</div>}
						</div>
					</div>
				</div>
				<div className="flex">
					<div className="w-1/2">
						<div className="mb-6">
							<label className="block text-gray-700 text-sm font-bold mb-2">SMALL GROUP DESCRIPTION</label>
							<ReactQuill value={description} onChange={this.handleOnDescription} />
						</div>
					</div>
				</div>
				<div className="mb-6 w-3/6">
					<label className="block text-gray-700 font-bold pr-6 mb-3 ">ESTABLISHED DATE</label>
					<Flatpickr
						className="appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none"
						value={established_date}
						onChange={this.handleEstabilishDate}
						placeholder="Choose your date"
						options={{
							dateFormat: 'd/m/Y',
							altFormat: 'd M Y',
							time_24hr: true,
							altInput: true,
						}}
					/>
					{errors.established_date && <div className="text-red-500 text-xs italic">{errors.established_date}</div>}
				</div>
				<div className="flex">
					<div className="w-1/2">
						<div className="mb-6 mr-4">
							<label className="block text-gray-700 text-sm font-bold mb-2">SCHEDULE: DAY</label>
							<select className={styleDaySelect} name="day" value={day} onChange={this.handleOnChange}>
								<option value="" disabled>
									Choose day
								</option>
								<option value={1}>Monday</option>
								<option value={2}>Tuesday</option>
								<option value={3}>Wednesday</option>
								<option value={4}>Thursday</option>
								<option value={5}>Friday</option>
								<option value={6}>Saturday</option>
								<option value={0}>Sunday</option>
							</select>
							{errors.day && <div className="text-red-500 text-xs italic">{errors.day}</div>}
						</div>
					</div>
					<div className="w-1/2">
						<div className="mb-6">
							<label className="block text-gray-700 text-sm font-bold mb-2">SCHEDULE: TIME</label>
							<Flatpickr
								value={time}
								className={styleTime}
								options={{
									enableTime: true,
									noCalendar: true,
									time_24hr: true,
									altInput: false,
								}}
								onChange={this.handleOnTime}
								onClose={this.handleOnTime}
							/>
							{errors.time && <div className="text-red-500 text-xs italic">{errors.time}</div>}
						</div>
					</div>
				</div>
				<div className="flex">
					<div className="w-1/2">
						<div className="mb-6 mr-4">
							<label className="block text-gray-700 text-sm font-bold mb-2">FULL ADDRESS</label>
							<textarea className={styleAddress} name="fullAddress" value={fullAddress} onChange={this.handleOnChange} rows={6} />
						</div>
					</div>
					<div className="w-1/2">
						<div className="mb-6">
							<label className="block text-gray-700 text-sm font-bold mb-2">SHORT ADDRESS</label>
							<input className={styleShortAddress} name="shortAddress" value={shortAddress} onChange={this.handleOnChange} />
							{errors.shortAddress && <div className="text-red-500 text-xs italic">{errors.shortAddress}</div>}
						</div>
						<div className="flex">
							<div className="w-1/2">
								<div className="mb-6 mr-4">
									<label className="block text-gray-700 text-sm font-bold mb-2">REGION</label>
									<select className={styleRegionSelect} name="region" value={region} onChange={this.handleOnChange}>
										<option value="" disabled>
											Choose region
										</option>
										{regencies.map((item) => (
											<option key={item.id} value={item.id}>
												{item.name}
											</option>
										))}
									</select>
									{errors.region && <div className="text-red-500 text-xs italic">{errors.region}</div>}
								</div>
							</div>
							<div className="w-1/2">
								<div className="mb-6">
									<label className="block text-gray-700 text-sm font-bold mb-2">POSTAL CODE</label>
									<input className={stylePostalCode} name="postalCode" value={postalCode} onChange={this.handleOnChange} />
									{errors.postalCode && <div className="text-red-500 text-xs italic">{errors.postalCode}</div>}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="flex">
					<div className="w-1/2">
						<button className="bg-grey-300 text-white rounded-lg py-2 px-6" onClick={this.handleCancel}>
							Cancel
						</button>
					</div>
					<div className="w-1/2">
						<button className="float-right bg-primary rounded-lg py-2 px-6 text-white" onClick={this.handleOnSubmit} disabled={isSubmit}>
							{isSubmit ? <Loading secondary /> : 'Save'}
						</button>
					</div>
				</div>
			</div>
		)
	}
}

export default SmallGroupMulti
