import React from 'react'
import { Link } from 'react-router-dom'
import Tippy from '@tippy.js/react'
import moment from 'moment'
import TableBody from '../commons/TableBody'
import ActionsRow from '../commons/ActionsRow'
import TableHeader from '../commons/TableHeader'

const CourseTable = (props) => {
	const columns = [
		{
			key: 'status',
			content: (course) => {
				if (course.status === 'Published') return <td className="px-2 py-4"></td>

				return (
					<td
						className={`relative ${
							course.status === 'Archived' || course.status === 'Completed'
								? 'opacity-25'
								: 'opacity-100'
						}`}
					>
						<Tippy content={course.status}>
							<div className="status-post-tag">
								<div className="ribbon-black-triagle" />
							</div>
						</Tippy>
					</td>
				)
			},
		},
		{
			label: 'ID',
			path: 'class_id',
			key: 'class_id',
			content: (course) => (
				<td
					className={`px-2 py-4 pl-4 ${
						course.status === 'Archived' || course.status === 'Completed'
							? 'opacity-25'
							: 'opacity-100'
					}`}
				>
					{course.class_id}
				</td>
			),
		},
		{
			label: 'TITLE',
			path: 'title',
			key: 'title',
			content: (course) => (
				<td
					className={`px-2 py-4 w-5 truncate max-w-xs text-center ${
						course.status === 'Archived' || course.status === 'Completed'
							? 'opacity-25'
							: 'opacity-100'
					}`}
				>
					<Link to={`/classes/edit/${course.class_id}`}>{course.title}</Link>
				</td>
			),
		},
		{
			label: 'QUOTA',
			path: 'participant_quota',
			key: 'participant_quota',
			content: (course) => (
				<td
					className={`px-2 py-4 text-center ${
						course.status === 'Archived' || course.status === 'Completed'
							? 'opacity-25'
							: 'opacity-100'
					}`}
				>
					{course.participant_quota}
				</td>
			),
		},
		{
			label: 'SESSION',
			path: 'number_of_session',
			key: 'number_of_session',
			content: (course) => (
				<td
					className={`px-2 py-4 text-center ${
						course.status === 'Archived' || course.status === 'Completed'
							? 'opacity-25'
							: 'opacity-100'
					}`}
				>
					{course.number_of_session}
				</td>
			),
		},
		{
			label: 'REGISTER',
			path: 'is_open_for_registration',
			key: 'is_open_for_registration',
			content: (course) => (
				<td
					className={`px-2 py-4 text-center ${
						course.status === 'Archived' || course.status === 'Completed'
							? 'opacity-25'
							: 'opacity-100'
					}`}
				>
					{course.is_open_for_registration ? 'Yes' : 'No'}
				</td>
			),
		},
		{
			label: 'START DATE',
			path: 'start_date',
			content: (course) => {
				const start_date = moment(course.start_date, 'DD-MM-YYYY HH:mm')
				return (
					<td
						className={`px-2 py-4 text-center ${
							course.status === 'Archived' || course.status === 'Completed'
								? 'opacity-25'
								: 'opacity-100'
						}`}
					>
						{start_date.format('D MMM YYYY')}
					</td>
				)
			},
		},
		{
			label: 'END DATE',
			path: 'end_date',
			content: (course) => {
				const end_date = moment(course.end_date, 'DD/MM/YYYY HH:mm')
				return (
					<td
						className={`px-2 py-4 text-center ${
							course.status === 'Archived' || course.status === 'Completed'
								? 'opacity-25'
								: 'opacity-100'
						}`}
					>
						{end_date.format('D MMM YYYY')}
					</td>
				)
			},
		},
		{
			label: 'CREATED ON',
			path: 'created_at',
			content: (course) => (
				<td
					className={`px-2 py-4 text-center ${
						course.status === 'Archived' || course.status === 'Completed'
							? 'opacity-25'
							: 'opacity-100'
					}`}
				>
					<div>
						{moment(course.created_at, 'DD/MM/YYYY HH:mm').format('D MMM YYYY')}
					</div>
					<div className="text-primary">{course.created_by_name}</div>
				</td>
			),
		},
		{
			label: 'LAST UPDATED',
			path: 'updated_at',
			content: (course) => {
				const updated_at = moment(course.updated_at, 'DD/MM/YYYY HH:mm')

				return (
					<td
						className={`px-2 py-4 text-center ${
							course.status === 'Archived' || course.status === 'Completed'
								? 'opacity-25'
								: 'opacity-100'
						}`}
					>
						<div>{updated_at.format('D MMM YYYY')}</div>
						<div className="text-primary">{updated_at.fromNow()}</div>
					</td>
				)
			},
		},
		{
			key: 'action',
			content: (course) => {
				const classes = 'px-4 py-2 block hover:bg-blueSecondary'
				const actions =
					course.status !== 'Archived'
						? [
								{
									key: 'regislist',
									helper: (
										<li className={classes}>
											<Link
												to={`/classes/registrant/${course.class_id}`}
											>
												Registrant List
											</Link>
										</li>
									),
								},
								{
									key: 'viewEdit',
									helper: (
										<li className={classes}>
											<Link
												to={`/classes/edit/${course.class_id}`}
											>
												View & Edit
											</Link>
										</li>
									),
								},
								{
									key: 'archived',
									path: 'Archived',
									helper: (
										<li
											className={classes}
											onClick={() => props.onArchive(course)}
										>
											Archive
										</li>
									),
								},
								{
									key: 'delete',
									path: 'Completed',
									helper: (
										<li
											className={classes}
											onClick={() => props.onComplete(course)}
										>
											Complete Class
										</li>
									),
								},
						  ]
						: [
								{
									key: 'regislist',
									helper: (
										<li className={classes}>
											<Link
												to={`/classes/registrant/${course.class_id}`}
											>
												Registrant List
											</Link>
										</li>
									),
								},
								{
									key: 'archived',
									path: 'Archived',
									helper: (
										<li
											className={classes}
											onClick={() => props.onArchive(course)}
										>
											Archive
										</li>
									),
								},
						  ]

				const filtered = actions.filter((action) => action.path !== course.status)
				return <ActionsRow actions={filtered} />
			},
		},
	]

	return (
		<div className="bg-white shadow-md overflow-x-auto mx-2 md:mx-0 whitespace-no-wrap">
			<table className="min-w-full">
				<TableHeader
					columns={columns}
					sortColumn={props.sortColumn}
					onSort={props.onSort}
				/>
				<TableBody data={props.courses} columns={columns} />
			</table>
		</div>
	)
}

export default CourseTable
