import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import DevotionFormik from 'components/formik/DevotionFormik'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as DevIcon } from 'assets/icons/devotion.svg'
import { devotionCategories } from 'libs/apis/devotion'

class CreateDev extends Component {
	state = {
		categories: [],
	}

	async componentDidMount() {
		try {
			const response = await devotionCategories()
			if (response.data.api_status) {
				this.setState({ categories: response.data.data })
			}
		} catch (error) {}
	}

	render() {
		const church = JSON.parse(localStorage.getItem('church'))
		const name = church.church_name ? church.church_name : '-'
		const breads = [{ url: '', title: 'Create Devotion' }]

		return (
			<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
				<Helmet>
					<title>{name} - Create Devotion</title>
				</Helmet>
				<PageBreadcrumbs icon={DevIcon} title="Create Devotion" breads={breads} />
				<DevotionFormik {...this.props} categories={this.state.categories} isCreateForm={true} />
			</div>
		)
	}
}

export default CreateDev
