import React from 'react'
import TimeItem from './TimeItem'
import { ServiceListContainer } from './styles'

const TimeList = ({ list, isEditTime, handleOnDelete, handleOnEditTime, handleOnCancelEditTime }) => (
  <ServiceListContainer>
    {list
      .filter((item) => !item.special_id)
      .map((service) => (
        <TimeItem
          key={service.id}
          id={service.id}
          day={service.service_time_day_desc}
          hour={service.service_time_hours}
          minute={service.service_time_minutes}
          isEditTime={isEditTime}
          handleOnDelete={handleOnDelete}
          handleOnEditTime={handleOnEditTime}
          handleOnCancelEditTime={handleOnCancelEditTime}
        />
      ))}
  </ServiceListContainer>
)

export default TimeList
