import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as NewsIcon } from 'assets/icons/newsfeed.svg'
import NewsfeedFormik from 'components/formik/NewsfeedFormik'
import { newsfeedsDetail } from 'libs/apis/newsfeeds'
import Loading from 'components/commons/Loading'

class EditNews extends Component {
	state = {
		isRedirect: false,
		isLoading: false,
		errorMessage: '',
		id: '',
		title: '',
		description: '',
		startDate: '',
		endDate: '',
		hashtags: [],
		media: '',
		video: '',
		image: '',
	}

	componentDidMount() {
		this.setState({ isLoading: true }, async () => {
			try {
				const id = this.props.match.params.id
				const response = await newsfeedsDetail(id)
				if (response.data.api_status) {
					const video = response.data.data.media_type === 'Video' ? response.data.data.media_url : ''
					const image = response.data.data.media_type === 'Image' ? response.data.data.media_url : ''
					this.setState({
						isLoading: false,
						id: id,
						title: response.data.data.title,
						startDate: response.data.data.start_post,
						endDate: response.data.data.end_post,
						hashtags: response.data.data.hashtags,
						media: response.data.data.media_type,
						description: response.data.data.description,
						video,
						image,
					})
				} else {
					this.setState({ isLoading: false, errorMessage: response.data.message })
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						await localStorage.removeItem('token')
						this.setState({ isRedirect: true })
					} else if (error.response.status >= 500) {
						this.setState({
							isLoading: false,
							errorMessage: 'An unexpected error has occurred. We are currently investigating the issue, please try again later.',
						})
					}
				}
			}
		})
	}

	render() {
		const { id, title, description, startDate, endDate, hashtags, media, video, image, isRedirect, isLoading, errorMessage } = this.state
		const church = JSON.parse(localStorage.getItem('church'))
		const name = church.church_name ? church.church_name : '-'
		const shouldDisplayLoad = isLoading && !errorMessage
		const shouldDisplayError = !isLoading && errorMessage
		const shouldDisplayForm = !isLoading && !errorMessage
		const redirectMessage = 'We could not verify that you are not authorized to process your request'
		const breads = [
			{ url: '/newsfeed/list', title: 'List of All' },
			{ url: '', title: 'Edit News Item' },
		]

		if (isRedirect) {
			return <Redirect to={{ pathname: '/login', state: redirectMessage }} />
		}

		return (
			<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
				<Helmet>
					<title>{name} - Edit News Item</title>
				</Helmet>
				<PageBreadcrumbs icon={NewsIcon} title="Edit News" breads={breads} />
				{shouldDisplayLoad && <Loading />}
				{shouldDisplayForm && (
					<NewsfeedFormik
						{...this.props}
						id={id}
						title={title}
						description={description}
						startDate={startDate}
						endDate={endDate}
						hashtags={hashtags}
						media={media}
						video={video}
						image={image}
						isCreateForm={false}
					/>
				)}
				{shouldDisplayError && <div className="align-center">{errorMessage}</div>}
			</div>
		)
	}
}

export default EditNews
