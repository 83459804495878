import http from './httpServices'

export const courseCategories = async () => {
	return await http.get('/classes/categories', {
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const createCourse = async formData => {
	return await http.post('/classes/create', formData, {
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const courseDetail = async id => {
	return await http.get(`/classes/detail/${id}`, {
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const updateCourse = async formData => {
	return await http.post('/classes/update', formData, {
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const coursesList = async (page, filter_status, filter_keyword, order_by, order_dir) => {
	return await http.search(
		'/classes',
		{
			page,
			filter_status,
			filter_keyword,
			order_by,
			order_dir,
		},
		localStorage.getItem('token')
	)
}

export const archiveCourse = async id => {
	return await http.post(`/classes/archive/${id}`, {}, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
}

export const completeCourse = async id => {
	return await http.post(`/classes/complete/${id}`, {}, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
}

export const courseDashboard = async id => {
	return await http.get(`/classes/dashboard/${id}`, {
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const registranstList = async (id, page, filter_status, filter_keyword, order_by, order_dir) => {
	return await http.search(
		`/classes/registrant/${id}`,
		{
			page,
			filter_status,
			filter_keyword,
			order_by,
			order_dir,
		},
		localStorage.getItem('token')
	)
}

export const attendanceList = async (id, page, filter_keyword, filter_status, order_by, order_dir) => {
	return await http.search(
		`/classes/attendance/${id}`,
		{
			page,
			filter_keyword,
			filter_status,
			order_by,
			order_dir,
		},
		localStorage.getItem('token')
	)
}

export const attendanceToggle = async (class_id, registrant_id, session_number) => {
	return await http.post(
		'/classes/attendance',
		{
			class_id,
			registrant_id,
			session_number,
		},
		{
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		}
	)
}

export const resendEmail = async registrant_id => {
	return await http.post(
		'/classes/registrant/reconfirm',
		{ registrant_id },
		{
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		}
	)
}

export const rejectRegistrant = async registrant_id => {
	return await http.post(
		'/classes/registrant/remove',
		{ registrant_id },
		{
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		}
	)
}

export const qrcodelist = async id => {
	return await http.get(`/classes/qrcode/${id}`, {
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const registrantExport = async (id, filter_keyword, filter_status, order_by, order_dir) => {
	return await http.get(`/classes/registrant/${id}/export`, {
		params: {
			filter_keyword,
			filter_status,
			order_by,
			order_dir,
		},
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const getCourseRole = async id => {
	return await http.get(`/classes/role-class/${id}`, {
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const getMaritalStatus = async id => {
	return await http.get(`/classes/marital-class/${id}`, {
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}
