import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import { getSmallGroupList, smallGroupExport } from 'libs/apis/smallGroup'
import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import Loading from 'components/commons/Loading'
import SmallGroupTable from 'components/smallGroup/SmallGroupTable'
import SmallGroupSearch from 'components/smallGroup/SmallGroupSearch'
import { closeGroup } from 'libs/apis/smallGroup'
import { failedFetch } from 'libs/utils/messages'
import PaginationPage from 'components/commons/PaginationPage'
import { confirmCloseGroup } from 'libs/utils/confirmation'

// import { findSmallGroupById, closeSmallGroup, updateData } from 'libs/utils/smallgroup'
// import Pagination from "components/commons/pagination";

class ListSmallGroup extends Component {
	state = {
		isLoading: false,
		isRedirect: false,
		isExportSubmit: false,
		errorMessage: '',
		filterKeyword: '',
		filterCategory: '',
		filterDay: '',
		filterTime: '',
		filterStatus: '',
		orderBy: '',
		orderDir: '',
		dataSmallGroups: [],
		currentPage: 1,
		lastPage: 1,
		data: [],
	}

	componentDidMount() {
		this.LoadData()
	}

	componentDidUpdate(prevProps) {
		const { location } = this.props
		if (location.search && location.search !== prevProps.location.search) {
			this.LoadData()
		}
	}

	handleOnChangeTime = dateStr => {
		this.setState({ filterTime: dateStr })
	}

	handleSort = sort => {
		const { orderBy, orderDir, keyword } = this.state

		if (orderBy !== sort) {
			this.setState({ orderBy: sort, orderDir: 'asc' }, () => this.LoadData(keyword))
		} else {
			if (orderDir === 'asc') {
				this.setState({ orderBy: sort, orderDir: 'desc' }, () => this.LoadData(keyword))
			} else if (orderDir === 'desc') {
				this.setState({ orderBy: sort, orderDir: 'asc' }, () => this.LoadData(keyword))
			}
		}
	}

	handleOnExport = () => {
		this.setState({ isExportSubmit: true }, async () => {
			const { filterKeyword, filterCategory, filterDay, filterTime, filterStatus, orderBy, orderDir } = this.state
			const response = await smallGroupExport(filterKeyword, filterCategory, filterDay, filterTime, filterStatus, orderBy, orderDir)
			if (response.data.api_status) {
				const link = document.createElement('a')
				link.href = response.data.data
				document.body.appendChild(link)
				link.click()
				this.setState({ isExportSubmit: false })
			}
		})
	}

	handleOnClose = async id => {
		const confirm = await confirmCloseGroup()
		if (confirm.value) {
			this.setState({ isLoading: true }, async () => {
				try {
					const response = await closeGroup(id)

					if (response.data.api_status) {
						this.LoadData()
					} else {
						this.setState({ isLoading: false })
					}
				} catch (error) {
					if (error.response) {
						if (error.response.status === 401) {
							await localStorage.removeItem('token')
							this.setState({ isRedirect: true })
						} else {
							this.setState({ isLoading: false })
						}
					}
				}
			})
		}
	}

	LoadData = () => {
		this.setState({ isLoading: true }, async () => {
			try {
				const { search } = this.props.location

				const page = search ? queryString.parse(search).page : 0
				const { filterKeyword, filterCategory, filterDay, filterTime, filterStatus, orderBy, orderDir } = this.state
				const response = await getSmallGroupList(page, filterKeyword, filterCategory, filterDay, filterTime, filterStatus, orderBy, orderDir)

				if (response.data.api_status) {
					this.setState({
						isLoading: false,
						dataSmallGroups: response.data.data.data,
						currentPage: response.data.data.current_page,
						lastPage: response.data.data.last_page,
					})
				} else {
					this.setState({ isLoading: false, errorMessage: failedFetch })
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						await localStorage.removeItem('token')
						this.setState({ isRedirect: true })
					} else if (error.response.status >= 500) {
						this.setState({ isLoading: false, errorMessage: failedFetch })
					}
				}
			}
		})
	}

	renderData = () => {
		const { location } = this.props
		const parsed = queryString.parse(location.search)
		const active = `${location.pathname}?page=${parsed.page || 1}`
		const { dataSmallGroups, currentPage, lastPage } = this.state
		return (
			<div>
				<SmallGroupTable list={dataSmallGroups} handleOnSort={this.handleSort} handleOnClose={this.handleOnClose} />
				<PaginationPage currentPage={currentPage} lastPage={lastPage} activeLink={active} length={dataSmallGroups.length} url={location.pathname} />
			</div>
		)
	}

	handleOnChangeFilter = event => this.setState({ [event.target.name]: event.target.value })

	handleOnClearFilter = () => {
		this.setState({
			filterKeyword: '',
			filterCategory: '',
			filterDay: '',
			filterTime: '',
			filterStatus: '',
		})
	}

	handleOnApplyFilter = () => {
		this.setState({ isLoading: true }, async () => {
			try {
				this.props.history.push('/small-group/list')
				const { filterKeyword, filterCategory, filterDay, filterTime, filterStatus, orderBy, orderDir } = this.state
				const response = await getSmallGroupList(1, filterKeyword, filterCategory, filterDay, filterTime, filterStatus, orderBy, orderDir)
				if (response.data.api_status) {
					this.setState({
						isLoading: false,
						dataSmallGroups: response.data.data.data,
						currentPage: response.data.data.current_page,
						lastPage: response.data.data.last_page,
					})
				} else {
					this.setState({ isLoading: false, errorMessage: failedFetch })
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						await localStorage.removeItem('token')
						this.setState({ isRedirect: true })
					} else if (error.response.status >= 500) {
						this.setState({ isLoading: false, errorMessage: failedFetch })
					}
				}
			}
		})
	}

	renderError = () => <div className="text-center">{this.state.errorMessage}</div>

	renderNodata = () => <div className="text-center">No data available</div>

	render() {
		const { isLoading, isRedirect, errorMessage, dataSmallGroups, filterKeyword, filterCategory, filterDay, filterTime, filterStatus, isExportSubmit } = this.state

		const displayLoading = isLoading && !errorMessage
		const displayError = !isLoading && errorMessage
		const displayNoData = !isLoading && !errorMessage && dataSmallGroups.length === 0
		const displayData = !isLoading && !errorMessage && dataSmallGroups.length > 0
		const church = JSON.parse(localStorage.getItem('church'))
		const name = church.church_name ? church.church_name : '-'
		const breads = [{ url: '', title: 'List of All Groups' }]

		if (isRedirect) {
			return <Redirect to="/login" />
		}

		return (
			<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
				<Helmet>
					<title>{name} - Small Group</title>
				</Helmet>
				<PageBreadcrumbs icon={SGIcon} title="List of All Groups" breads={breads} />
				<div>
					<SmallGroupSearch
						filterKeyword={filterKeyword}
						filterCategory={filterCategory}
						filterDay={filterDay}
						filterTime={filterTime}
						filterStatus={filterStatus}
						isExportSubmit={isExportSubmit}
						handleOnChangeFilter={this.handleOnChangeFilter}
						handleOnChangeTime={this.handleOnChangeTime}
						handleOnClearFilter={this.handleOnClearFilter}
						handleOnApplyFilter={this.handleOnApplyFilter}
						handleOnExport={this.handleOnExport}
					/>
					{displayLoading && <Loading />}
					{displayError && this.renderError()}
					{displayNoData && this.renderNodata()}
					{displayData && this.renderData()}
				</div>
			</div>
		)
	}
}

export default ListSmallGroup
