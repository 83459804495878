import React, { Component } from "react";
import AttendanceRow from "./AttendanceRow";

class AttendanceTable extends Component {
  render() {
    const { list, handleDelete } = this.props;
    return (
      <div className="bg-white shadow-lg rounded">
        <table className="w-full">
          <thead className="bt-blueSecondary border-2 border-solid">
            <tr>
              <th className="px-2 py-4 text-center">DATE</th>
              <th className="px-2 py-4 text-center">DAY</th>
              <th className="px-2 py-4 text-center">TIME</th>
              <th className="px-2 py-4 text-center">TOTAL ATTENDEE</th>
              <th className="px-2 py-4 text-center">MEMBER</th>
              <th className="px-2 py-4 text-center">NON MEMBER</th>
              <th />
            </tr>
          </thead>
          <tbody className="border-2 border-solid">
            {list.map(
              ({
                date_attendance,
                day,
                id_attendance,
                small_group_name,
                start_time,
                total_member_attendance,
                total_member_count,
                total_visitor_attendance
              }) => (
                <AttendanceRow
                  key={id_attendance}
                  id={id_attendance}
                  attendanceName={small_group_name}
                  startTime={start_time}
                  day={day}
                  dateAttendance={date_attendance}
                  totalAttendee={total_member_attendance}
                  totalVisitor={total_visitor_attendance}
                  totalMember={total_member_count}
                  handleDelete={handleDelete}
                />
              )
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default AttendanceTable;
