import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

export default function PraiseRepItem({
	user_name,
	user_email,
	category_name,
	description,
	created_at,
	id,
}) {
	const [dropDownOpen, setDropDownOpen] = useState(false)
	const wrapperRef = useRef(null)

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside, false)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside, false)
		}
	}, [])

	const handleClickOutside = event => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setDropDownOpen(false)
		}
	}

	const handleToggle = () => {
		setDropDownOpen(!dropDownOpen)
	}

	const styleDropDownOpen = `z-20 shadow-md absolute right-20px ${
		dropDownOpen ? 'block' : 'hidden'
	}`

	return (
		<tr className="border border-solid bt-blueSecondary">
			<td className="pl-10 py-4">
				<Link to={`/report-history/${id}`}>{user_name}</Link>
			</td>
			<td className="px-2 py-4">{user_email}</td>
			<td className="px-2 py-4">{category_name}</td>
			<td className="px-2 py-4 max-w-xs w-1 truncate">{description}</td>
			<td className="px-2 py-4">{created_at}</td>
			<td className="px-2 py-4">
				<div className="cursor-pointer" onClick={handleToggle}>
					<i className="dropdown-trigger fas fa-ellipsis-v" />
					<div className={styleDropDownOpen}>
						<ul ref={wrapperRef} className="bg-white">
							<li>
								<Link
									className="px-4 py-2 block hover:bg-blueSecondary"
									to={`/report-history/${id}`}
								>
									Report History
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</td>
		</tr>
	)
}
