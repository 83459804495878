import React, { Component } from 'react'
import { partial } from 'libs/utils/utils'
import EmailTableRow from './EmailTableRow'

class EmailListTable extends Component {
	render() {
		const { handleOnSort, list, onDelete } = this.props

		return (
			<div className="bg-white shadow-md">
				<table className="w-full rounded-lg mt-10">
					<TableHeader handleOnSort={handleOnSort} />
					<TableBody onDelete={onDelete} list={list} />
				</table>
			</div>
		)
	}
}

const TableHeader = ({ handleOnSort }) => {
	const handleSortSchedule = partial(handleOnSort, 'schedule')

	return (
		<thead className="bt-blueSecondary border-2 border-solid">
			<tr>
				<th className="py-4 text-left pl-10">NO.</th>
				<th className="text-left pl-6">SUBJECT</th>
				<th className="text-center">
					CREATED ON{' '}
					{/* <i
            className="text-center fas fa-sort sortby cursor-pointer"
            onClick={handleSortCreatedOn}
          /> */}
				</th>
				<th className="text-center">
					LAST UPDATE{' '}
					{/* <i
            className="text-center fas fa-sort sortby cursor-pointer"
            onClick={handleSortSchedule}
          /> */}
				</th>
				<th className="text-center">
					SCHEDULE{' '}
					<i
						className="text-center fas fa-sort sortby cursor-pointer"
						onClick={handleSortSchedule}
					/>
				</th>
				<th className="text-center">STATUS</th>
				<th className="py-4" />
			</tr>
		</thead>
	)
}

const TableBody = ({ list, onDelete }) => (
	<tbody className="border-2 border-solid">
		{list.map(data => (
			<EmailTableRow
				key={data.campaign_id}
				no={data.campaign_id}
				id={data.campaign_id}
				subject={data.subject}
				createdBy
				createdOn={data.created_at}
				lastUpdate={data.updated_at}
				schedule={data.schedule}
				status={data.status}
				onDelete={onDelete}
			/>
		))}
	</tbody>
)

export default EmailListTable
