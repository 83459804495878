import React from 'react'
import moment from 'moment'

const SGDetails = ({
	groupName,
	leader,
	address,
	regency,
	postal,
	day,
	hour,
	minute,
	member,
	status,
	type,
	created,
	child,
	parent,
}) => (
	<div className="bg-white p-4">
		<h3 className="font-bold text-lg mb-4">{groupName}</h3>
		<div className="font-bold mb-2">LEADER DETAIL</div>
		<label className="text-sm font-bold">Small Group Leader</label>
		<div className="text-sm mb-6">{leader}</div>
		<hr className="border-2 border-blueSecondary" />
		<div className="font-bold mb-5 mt-5">SMALL GROUP DETAIL</div>
		<label className="text-sm font-extrabold">Address</label>
		<div className="text-sm mb-3">{address}</div>
		<div className="text-sm mb-3">
			{regency} {postal}
		</div>
		<label className="text-sm font-bold">Day | Time</label>
		<div className="text-sm mb-4">
			{day} | {hour}:{minute}
		</div>
		<label className="text-sm font-bold">Members</label>
		<div className="text-sm mb-4">{member}</div>
		<label className="text-sm font-bold">Status</label>
		<div className="text-sm mb-4">{status}</div>
		<label className="text-sm font-bold">Type</label>
		<div className="text-sm mb-4">{type}</div>
		<label className="text-sm font-bold">Established in</label>
		<div className="text-sm mb-4">{created}</div>
		<hr className="border-2 border-blueSecondary" />
		<div className="font-bold mb-4">MULTIPLICATION DETAIL</div>
		<label className="text-sm font-bold">Groups</label>
		{child.length > 0 ? (
			child.map(group => (
				<div className="text-sm mb-4" key={group.small_group_id}>
					{`${group.small_group_name} | ${moment(
						group.created_at,
						'DD/MM/YYYY HH:mm'
					).format('DD MMMM YYYY')}`}
				</div>
			))
		) : (
			<p>-</p>
		)}
		<label className="text-sm font-bold">Parent Group</label>
		<div className="font-bold mb-4">
			{parent.small_group_name ? parent.small_group_name : '-'}
		</div>
	</div>
)

export default SGDetails
