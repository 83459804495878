import React from 'react'
import moment from 'moment'
import YoutubeIframe from '../commons/YoutubeIframe'
import getYoutubeId from 'libs/utils/getYoutubeId'
import DefaultIframe from '../commons/DefaultIframe'
import ImageIframe from '../commons/ImageIframe'

const NewsfeedPreview = ({ values }) => {
	const youtubeId = getYoutubeId(values.video)

	return (
		<div>
			<h3 className="font-bold mb-4">MOBILE SCREEN PREVIEW</h3>
			<div className="bg-blueSecondary">
				{values.video === '' && values.image === '' && <DefaultIframe />}
				{values.media === 'Video' && values.video && <YoutubeIframe id={youtubeId} />}
				{values.media === 'Image' && values.image && <ImageIframe img={values.image} />}
				<div className="p-3">
					<div className="font-bold break-all">{values.title || 'Title'}</div>
					<div className="text-gray-500">{values.startDate ? moment(values.startDate, 'DD/MM/YYYY').format('MMM DD, YYYY') : 'Post Date'}</div>
					<div className="text-base">
						{values.description ? <div dangerouslySetInnerHTML={{ __html: values.description }} className="body-description break-all overflow-auto h-32 " /> : 'Article'}
					</div>
				</div>
			</div>
		</div>
	)
}

export default NewsfeedPreview
