import http from './httpServices'

export const campusList = async () => {
  return await http.get('/campus', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
}

export const attendanceCampusList = async () => {
  return await http.get('/attendance/campuses', {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
}
