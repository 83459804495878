import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as DevotionIcon } from 'assets/icons/devotion.svg'

class DevotionMenu extends Component {
	state = {
		isOpenSub: false,
	}

	componentDidMount() {
		const {
			match: { path },
		} = this.props
		const menus = ['/devotion/create', '/devotion/list', '/devotion/edit/:id'].filter(menu => menu === path)
		if (menus.length > 0) {
			this.setState({ isOpenSub: true })
		}
	}

	handleOpenSub = () => {
		this.setState({ isOpenSub: !this.state.isOpenSub })
	}

	render() {
		const {
			match: { path },
		} = this.props
		const { isOpenSub } = this.state
		const menus = ['/devotion/create', '/devotion/list', '/devotion/edit/:id'].filter(menu => menu === path)
		const styleIcon = `${menus.length > 0 ? 'w-4 h-4 fill-primary' : 'w-4 h-4 fill-gray'}`
		const styleLinkCreate = `${path === '/devotion/create' ? 'text-primary' : 'text-gray-800'}`
		const styleLinkList = `${path === '/devotion/list' || path === '/devotion/edit/:id' ? 'text-primary' : 'text-gray-800'}`

		return (
			<div className="mb-6">
				<div className="flex cursor-pointer" onClick={this.handleOpenSub}>
					<div className="mr-4">
						<DevotionIcon className={styleIcon} />
					</div>
					<div>Devotion</div>
				</div>
				{isOpenSub && (
					<div className="ml-8 mt-2">
						<ul>
							<li className="mb-4">
								<Link to="/devotion/create" className={styleLinkCreate}>
									Create Devotion
								</Link>
							</li>
							<li className="mb-4">
								<Link to="/devotion/list/" className={styleLinkList}>
									List of All
								</Link>
							</li>
						</ul>
					</div>
				)}
			</div>
		)
	}
}

export default DevotionMenu
