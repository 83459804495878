import axios from "axios";

export const createEmailCampaign = async formdata => {
  return await axios({
    method: "post",
    url: `${process.env.REACT_APP_ENDPOINT}/campaign/create`,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    data: formdata
  });
};

export const updateEmailCampaign = async (id, formdata) => {
  return await axios({
    method: "post",
    url: `${process.env.REACT_APP_ENDPOINT}/campaign/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    data: formdata
  });
};

export const deleteEmailCampaign = async id => {
  return await axios({
    method: "post",
    url: `${process.env.REACT_APP_ENDPOINT}/campaign/delete/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
  });
};

export const getEmailCampaignDetails = async id => {
  return await axios({
    method: "get",
    url: `${process.env.REACT_APP_ENDPOINT}/campaign/detail/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
  });
};

export const getListEmailCampaign = async (
  page,
  filter_keyword,
  order_by,
  order_dir
) => {
  return await axios({
    method: "get",
    url: `${process.env.REACT_APP_ENDPOINT}/campaign`,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    params: {
      page,
      filter_keyword,
      order_by,
      order_dir
    }
  });
};
