import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Field } from 'formik'
import Loading from 'components/commons/Loading'
import 'react-quill/dist/quill.snow.css'
import { cancelConfirmation } from 'libs/utils/confirmation'
import DateTimeSelect from 'components/commons/DateTimeSelect'
import PushNotifPreview from 'components/formik/PushNotifPreview'
import { getRole } from 'libs/apis/smallGroup'
import TextArea from 'components/commons/TextArea'
// import ReactQuill from 'react-quill'

class PushNotifForm extends Component {
	state = {
		isRedirect: false,
		roles: [],
		filter_roles: [],
	}

	async componentDidMount() {
		await this.handleGetRole()
	}

	handleCancel = async (event) => {
		event.preventDefault()
		const response = await cancelConfirmation()
		if (response.value) {
			this.setState({ isRedirect: true })
		}
	}

	handleOnDraft = (e) => {
		const { setFieldValue, handleSubmit } = this.props
		setFieldValue('is_draft', true)
		handleSubmit(e)
	}

	handleOnDescription = (value) => {
		const { setFieldValue } = this.props
		setFieldValue('body', value)
	}

	handleGetRole = async () => {
		let rolesLocalStorage = JSON.parse(localStorage.getItem('roles'))
		if (rolesLocalStorage === null) {
			let res = await getRole()
			localStorage.setItem('roles', JSON.stringify(res.data.data))
			this.setState({ ...this.state, roles: res.data.data })
		} else {
			let roles = JSON.parse(localStorage.getItem('roles'))
			this.setState({ ...this.state, roles: roles })
		}
	}

	handleRole = (props) => {
		for (let i = 0; i < this.props.values.filter_roles.length; i++) {
			if (parseInt(this.props.values.filter_roles[i]) === props.role_id) {
				return true
			}
		}
		return false
	}

	handleOnSubmit = () => {
		const { handleSubmit, setFieldValue } = this.props

		setFieldValue('filter_role[]', this.state.filter_roles)

		handleSubmit()
	}

	render() {
		const { roles } = this.state
		const { errors, handleSubmit, isSubmitting, isRedirect } = this.props

		if (isRedirect) {
			return <Redirect to="/login" />
		}

		return (
			<div className="flex md:-mx-2 flex-wrap">
				<div className="md:w-2/3 w-full px-2 mb-4 md:mb-0">
					<div className="bg-white shadow-md p-6">
						<div className="mb-6">
							<label className="block text-gray-700 font-bold mb-2">TITLE</label>
							<Field name="title" type="text" className="appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none border-secondary" />
							{errors.title && <div className="text-red-500 text-xs italic">{errors.title}</div>}
						</div>
						<div className="mb-6">
							<label className="block text-gray-700 font-bold mb-2">BODY</label>
							<Field name="body" type="text" size="large" component={TextArea} />
							{errors.body && <div className="text-red-500 text-xs italic">{errors.body}</div>}
						</div>

						<div>
							<label className="block text-gray-700 font-bold pr-6 ">RECIPIENTS</label>

							<div className="flex mb-8 ml-2">
								<div className="flex-col">
									{roles.slice(0, roles.length / 2 + 1).map((val, idx) => (
										<div key={idx}>
											<label className="font-bold text-md">
												<Field name="filter_roles" type="checkbox" className="mt-4 mr-2" value={val.role_id} checked={this.handleRole(val)} />
												{val.show_as}
											</label>
										</div>
									))}
								</div>
								<div className="flex-col ml-40">
									{roles.slice(roles.length / 2 + 1).map((val, idx) => (
										<div key={idx}>
											<label className="ml-2 font-bold text-md">
												<Field name="filter_roles" className="mt-4 mr-2" type="checkbox" value={val.role_id} checked={this.handleRole(val)} />
												{val.show_as}
											</label>
										</div>
									))}
								</div>
							</div>
						</div>
						<div className="mb-6 flex">
							<div className="w-full mr-5">
								<label className="block text-gray-700 font-bold mb-2">RECIPIENTS GENDER</label>
								<Field component="select" className="form-select block w-full border-secondary border-2 rounded-lg" name="filter_gender">
									<option value="ALL">Both (Male & Female)</option>
									<option value="F">Female</option>
									<option value="M">Male</option>
								</Field>
								{errors.filter_gender && <div className="text-red-500 text-xs italic">{errors.filter_gender}</div>}
							</div>
							<div className="w-full">
								<label className="block text-gray-700 font-bold mb-2">RECIPIENTS MARIAGE STATUS</label>
								<Field component="select" className="form-select block w-full border-secondary border-2 rounded-lg" name="filter_marital_status">
									<option value="ALL">Both (Single & Married)</option>
									<option value="Single">Single</option>
									<option value="Married">Married</option>
								</Field>
								{errors.filter_marital_status && <div className="text-red-500 text-xs italic">{errors.filter_marital_status}</div>}
							</div>
						</div>

						<div className="w-3/6">
							<label className="block text-gray-700 font-bold mb-2">SCHEDULE</label>
							<Field name="schedule" component={DateTimeSelect} />
						</div>

						<div className="flex py-5">
							<div className="w-1/2">
								<button type="submit" className="font-bold bg-grey-300 text-white rounded-lg py-2 px-6 focus:outline-none" onClick={this.handleCancel}>
									Cancel
								</button>
							</div>
							<div className="w-1/2">
								<div className="flex justify-end">
									<button
										type="submit"
										className="mr-3 font-bold bg-white border-primary border-solid border-2 text-primary rounded-lg py-2 px-6 focus:outline-none"
										onClick={this.handleOnDraft}
										disabled={isSubmitting}
									>
										{isSubmitting ? <Loading /> : 'Save as Draft'}
									</button>
									<button
										type="submit"
										className="ml-3 font-bold bg-primary rounded-lg py-2 px-6 text-white focus:outline-none"
										onClick={handleSubmit}
										disabled={isSubmitting}
									>
										{isSubmitting ? <Loading secondary /> : 'Publish'}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="md:w-1/3 w-full px-2">
					<PushNotifPreview {...this.props} />
				</div>
			</div>
		)
	}
}

export default PushNotifForm
