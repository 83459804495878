import React, { useState, useEffect } from 'react'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { Helmet } from 'react-helmet'
import { ReactComponent as CampaignIcon } from 'assets/icons/file.svg'
import Loading from 'components/commons/Loading'
import FileListTable from 'components/listFile/FileListTable'
import queryString from 'query-string'
import PaginationPage from 'components/commons/PaginationPage'
import alert from 'services/alert'
import { getFileList, deleteFile } from 'libs/apis/file'
import { Redirect } from 'react-router-dom'
import FileUpload from 'screens/file/FileUpload'

export default function ListFile(props) {
	const [data, setData] = useState([])
	const [filterKeyword, setFilterKeyword] = useState('')
	const [error, setError] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [isRedirect, setIsRedirect] = useState(false)
	const [currentPage, setCurrentPage] = useState(0)
	const [lastPage, setLastPage] = useState(1)

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [{ url: '', title: 'List File' }]

	const { location } = props
	const { search } = props.location

	const parsed = queryString.parse(location.search)
	const active = `${location.pathname}?page=${parsed.page || 1}`
	const page = search ? queryString.parse(search).page : 0

	const displayLoading = isLoading && !error
	const displayData = !isLoading && !error && data.length > 0
	const displayNoData = !isLoading && !error && !data.length
	const displayError = !isLoading && error

	const handleLoadData = React.useCallback(async () => {
		setIsLoading(true)
		try {
			let res = await getFileList(page, filterKeyword)

			if (res.data.data.data.length != null) {
				setData(res.data.data.data)
				setCurrentPage(res.data.data.current_page)
				setLastPage(res.data.data.last_page)
				setIsLoading(false)
			} else {
				setIsLoading(false)

				alert.failed(`Failed`, 'Cannot retrieve data from server')
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.removeItem('token')
					setIsRedirect(true)
				} else if (error.response.status >= 500) {
					setIsLoading(false)
					setError('Cannot retrieve data from server')
				}
			}
		}
	}, [page, filterKeyword])

	const handleSearch = event => {
		setFilterKeyword(event.target.value)
	}

	const handleDelete = async id => {
		let res = await deleteFile(id)

		if (res.data.api_status) {
			handleLoadData()

			alert.succeed(`Success`, `Your file has been deleted`)
		} else {
			alert.failed(`Failed`, `Cannot delete file`)
		}
	}

	useEffect(() => {
		handleLoadData()
	}, [props.location.search, filterKeyword, handleLoadData])

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - List File</title>
			</Helmet>
			<PageBreadcrumbs icon={CampaignIcon} title="List File" breads={breads} />

			<div className="mt-4 mb-4">
				<div className="flex flex-wrap md:-mx-2">
					<div className="md:w-4/12 w-full">
						<div className="shadow-md flex rounded-lg bg-white px-3 py-2 mx-2 mb-4 sm:mb-0">
							<div className="mr-4 text-gray">
								<i className="fas fa-search" />
							</div>
							<input type="text" className="w-full" placeholder="Search" name={filterKeyword} onChange={handleSearch} />
						</div>
					</div>
					<div className="md:w-4/12 w-full"></div>
					<div className="md:w-4/12 w-full">
						<div className="inline-block float-right mr-3">
							<button className="bg-greyExport hover:bg-gray-700 text-white rounded-lg py-2 px-6">
								<FileUpload handleLoadData={handleLoadData} data={data} />
							</button>
						</div>
					</div>
				</div>
			</div>

			<div>
				{displayLoading && <Loading />}
				{displayData && (
					<div>
						<FileListTable data={data} handleLoadData={handleLoadData} handleDelete={handleDelete} handleOnSort={handleSearch} />
						<PaginationPage currentPage={currentPage} lastPage={lastPage} activeLink={active} url={location.pathname} />
					</div>
				)}

				{displayNoData && <div className="text-center">No Data Available</div>}
				{displayError && <div className="text-center">{error}</div>}
			</div>
		</div>
	)
}
