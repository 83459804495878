import React from 'react'
import 'react-tippy/dist/tippy.css'
import EventPreview from './EventPreview'
import { Tooltip } from 'react-tippy'
import { TextInput } from 'components/commons/TextInput'
import { DescriptionBox } from 'components/commons/DescriptionBox'
import InputCheckBox from 'components/commons/InputCheckBox'
import { InputDate } from 'components/commons/InputDate'
import { InputTime } from 'components/commons/InputTime'
import { InputTextArea } from 'components/commons/InputTextArea'
import { InputNumber } from 'components/commons/InputNumber'
import { Button } from 'components/commons/Button'
import Loading from 'components/commons/Loading'
import SelectOptions from 'components/commons/SelectOptions'
import { constants } from 'constants/constant'

export default function EventForm({
	eventTitle,
	eventShortTitle,
	eventDescription,
	isEventTermCondition,
	eventTermCondition,
	eventStartDate,
	eventEndDate,
	isAllDayEvent,
	startTime,
	endTime,
	venue,
	venueName,
	venueAddress,
	eventImage,
	eventStartRegis,
	eventEndRegis,
	eventCategory,
	quota,
	startDateShare,
	isEndDateShare,
	endDateShare,
	isHighlight,
	handleOnChange,
	handleOnDescription,
	handleOnTermCondition,
	handleOnCheckAllDay,
	handleOnChangeImage,
	handleOnChangeNumber,
	handleOnCheckShare,
	handleOnCheck,
	handleOnCancel,
	handleOnDraft,
	handleSubmit,
	campaign_type,
	venues,
	categories,
	errors,
	is_send_campaign,
	handleCheckboxRole,
	filter_roles,
	createEvent,
	gender,
	handleMaritalCheckbox,
	loading,
	handleStartEndDate,
	handleStartEndTime,
	handleStartEndWithSec,
	selectedRoles,
	maritalValues,
	visibleToAll,
	handleCheckSendAll,
	maritalStatusAll,
	handleCheckMaritalAll,
}) {
	const fileName = eventImage ? eventImage.name || eventImage.substring(eventImage.lastIndexOf('/') + 1) : 'Click to upload your image here'
	const { campaigns, genders, maritalStatus } = constants

	return (
		<div className="flex md:-mx-2 flex-wrap">
			<div className="md:w-2/3 w-full px-2 mb-4 md:mb-0">
				<div className="bg-white shadow-md p-6">
					<div className="mb-6">
						<TextInput label="EVENT TITLE" size="large" handleChange={handleOnChange} value={eventTitle} name="eventTitle" disabled={true} />
						{errors.eventTitle && <div className="text-red-500 text-xs italic">{errors.eventTitle}</div>}
					</div>

					<div className="mb-6">
						<TextInput label="SHORT TITLE" size="large" handleChange={handleOnChange} value={eventShortTitle} name="eventShortTitle" />
						{errors.eventShortTitle && <div className="text-red-500 text-xs italic">{errors.eventShortTitle}</div>}
					</div>

					<div className="mb-6">
						<SelectOptions name="eventCategory" label="CATEGORY" options={categories} value={eventCategory} onChange={handleOnChange} />
						{errors.eventCategory && <div className="text-red-500 text-xs italic">{errors.eventCategory}</div>}
					</div>
					<div className="mb-6">
						<DescriptionBox label="DESCRIPTION" value={eventDescription} handleChange={handleOnDescription} size="large" />
						{errors.eventDescription && <div className="text-red-500 text-xs italic">{errors.eventDescription}</div>}
					</div>
					<div className="mb-6">
						<InputCheckBox name="isEventTermCondition" value={isEventTermCondition} checked={isEventTermCondition} onChange={handleOnCheck} label="TERMS AND CONDITIONS" />
						{isEventTermCondition && <DescriptionBox value={eventTermCondition} handleChange={handleOnTermCondition} size="large" />}
					</div>
					<div className="mb-6">
						<div className="flex flex-wrap md:-mx-2">
							<div className="md:w-1/2 w-full mb-6 md:mb-0 md:px-2">
								<InputDate name="eventStartDate" label="START DATE" value={eventStartDate} handleChange={handleStartEndDate} withSec={false} />
								{errors.eventStartDate && <div className="text-red-500 text-xs italic">{errors.eventStartDate}</div>}
							</div>
							<div className="md:w-1/2 w-full md:px-2">
								<InputDate name="eventEndDate" label="END DATE" value={eventEndDate} handleChange={handleStartEndDate} withSec={false} />
								{errors.eventEndDate && <div className="text-red-500 text-xs italic">{errors.eventEndDate}</div>}
							</div>
						</div>
					</div>
					<div className="mb-6">
						<div className="flex">
							<div className="w-1/2">
								<label className="block text-gray-700 text-sm font-bold mb-2">ALL DAY EVENT</label>
								<div className="flex">
									<div className="w-1/2">
										<InputCheckBox checked={isAllDayEvent} value={isAllDayEvent} onChange={handleOnCheckAllDay} label="YES" />
									</div>
									<div className="w-1/2">
										<InputCheckBox checked={!isAllDayEvent} value={!isAllDayEvent} onChange={handleOnCheckAllDay} label="NO" />
									</div>
								</div>
							</div>
						</div>
					</div>
					{!isAllDayEvent && (
						<div className="mb-6">
							<div className="flex flex-wrap md:-mx-2">
								<div className="md:w-1/2 w-full mb-6 md:mb-0 md:px-2">
									<InputTime name="startTime" label="START TIME" value={startTime} handleChange={handleStartEndTime} />
									{errors.startTime && <div className="text-red-500 text-xs italic">{errors.startTime}</div>}
								</div>
								<div className="md:w-1/2 w-full md:px-2">
									<InputTime name="endTime" label="END TIME" value={endTime} handleChange={handleStartEndTime} />
									{errors.endTime && <div className="text-red-500 text-xs italic">{errors.endTime}</div>}
								</div>
							</div>
						</div>
					)}
					<div className="mb-6">
						<div className="flex">
							<div className="w-1/2">
								<div>
									<label className="block text-gray-700 text-sm font-bold mb-2">VENUE</label>
									<select name="venue" className="form-select block w-full border-secondary border-2 rounded-lg" value={venue} onChange={handleOnChange}>
										{venues.map((item) => (
											<option key={item.id} value={item.id}>
												{item.campus_name}
											</option>
										))}
										<option value="">Others</option>
									</select>
								</div>
							</div>
						</div>
					</div>
					{!venue && (
						<div>
							<div className="mb-6">
								<TextInput label="VENUE NAME" name="venueName" value={venueName} handleChange={handleOnChange} />
								{errors.venueName && <div className="text-red-500 text-xs italic">{errors.venueName}</div>}
							</div>
							<div className="mb-6">
								<InputTextArea label="VENUE ADDRESS" name="venueAddress" value={venueAddress} handleChange={handleOnChange} />
								{errors.venueAddress && <div className="text-red-500 text-xs italic">{errors.venueAddress}</div>}
							</div>
						</div>
					)}
					<div className="mb-6">
						<label className="block text-gray-700 text-sm font-bold mb-2">UPLOAD IMAGE</label>
						<div className="p-0 rounded-none border-0 relative inline-block w-full border-secondary">
							<Tooltip title="Recommended image size 900x506 pixels" position="bottom" trigger="mouseenter">
								<input type="file" accept="image/*" multiple={false} onChange={handleOnChangeImage} className="relative z-10 w-full h-full opacity-0 py-6 cursor-pointer" />
							</Tooltip>
							<label className="flex items-center justify-center border-2 rounded-lg border-dashed text-center h-full w-full absolute inset-x-0 top-0">
								<span className="text-secondary">{fileName}</span>
							</label>
						</div>
						{errors.eventImage && <div className="text-red-500 text-xs italic">{errors.eventImage}</div>}
					</div>

					<div className="mb-6">
						<div className="flex flex-wrap md:-mx-2">
							<div className="md:w-1/2 w-full mb-6 md:mb-0 md:px-2">
								<InputDate name="eventStartRegis" label="REGISTRATION START DATE" value={eventStartRegis} handleChange={handleStartEndWithSec} withSec={false} />
								{errors.eventStartRegis && <div className="text-red-500 text-xs italic">{errors.eventStartRegis}</div>}
							</div>
							<div className="md:w-1/2 w-full md:px-2">
								<InputDate name="eventEndRegis" label="REGISTRATION END DATE" value={eventEndRegis} handleChange={handleStartEndWithSec} withSec={false} />
								{errors.eventEndRegis && <div className="text-red-500 text-xs italic">{errors.eventEndRegis}</div>}
							</div>
						</div>
					</div>
					<div className="mb-6">
						<div className="flex md:-mx-2">
							<div className="md:w-1/2 w-full md:px-2">
								<InputNumber label="EVENT PARTICIPANT QUOTA" value={quota} handleChange={handleOnChangeNumber} min={0} />
								{errors.eventQuota && <div className="text-red-500 text-xs italic">{errors.eventQuota}</div>}
							</div>
						</div>
					</div>
					<div className="mb-6">
						<div className="flex flex-wrap md:-mx-2">
							<div className="md:w-1/2 w-full mb-6 md:mb-0 md:px-2">
								<InputDate label="START SHARING ON" name="startDateShare" value={startDateShare} handleChange={handleStartEndWithSec} withSec={true} />
								{errors.startDateShare && <div className="text-red-500 text-xs italic">{errors.startDateShare}</div>}
							</div>
							<div className="md:w-1/2 w-full md:px-2">
								<InputCheckBox name="isEndDateShare" value={isEndDateShare} checked={isEndDateShare} onChange={handleOnCheckShare} label="END SHARING ON" />

								{isEndDateShare && <InputDate name="endDateShare" value={endDateShare} handleChange={handleStartEndWithSec} withSec={true} />}
							</div>
						</div>
					</div>
					<div className="mt-4">
						<InputCheckBox name="isHighlight" label="HIGHLIGHT" value={isHighlight} checked={isHighlight} onChange={handleOnCheck} />
					</div>

					<div className="mt-3 mb-4">
						<label className="items-center">
							<InputCheckBox name="is_send_campaign" label="SEND AS CAMPAIGN" onChange={handleOnCheck} value={is_send_campaign} checked={is_send_campaign} />
							{is_send_campaign && (
								<div className="flex mt-2">
									<div className="w-1/2">
										<SelectOptions name="campaign_type" options={campaigns} value={campaign_type} onChange={handleOnChange} />
									</div>
								</div>
							)}
						</label>
					</div>
					{/* <div className="mt-4 mb-4 md:w-1/2 w-full">
                           <InputCheckBox name="is_require_payment" label="EVENT PAYMENT" onChange={handleCheckPayment} value={is_require_payment} checked={is_require_payment} />
                           {is_require_payment && (
                               <input
                                   className="appearance-none border-2 rounded-lg w-full p-3 mt-1 leading-tight focus:outline-none"
                                   name="event_price"
                                   value={event_price}
                                   onChange={handleOnChange}
                               />
                           )}
                       </div> */}

					<label className="block text-gray-700 text-sm font-bold mb-2">
						ELIGIBLE TO : {errors.eligibleTo && <label className="text-red-500 text-xs italic">{errors.eligibleTo}</label>}
					</label>

					<div className="flex mb-5">
						<div className="flex-col">
							<InputCheckBox
								value={visibleToAll}
								checked={visibleToAll || selectedRoles.length === filter_roles.length}
								onChange={handleCheckSendAll}
								label="All"
								name="visibleToAll"
							/>
							{filter_roles.slice(0, filter_roles.length / 2).map((val, idx) => (
								<div key={idx}>
									<div className="mt-2">
										<InputCheckBox
											id={val.role_id}
											value={val.role_id}
											onChange={handleCheckboxRole}
											checked={selectedRoles.includes(val.role_id)}
											label={val.show_as}
											className="mb-4"
										/>
									</div>
								</div>
							))}
						</div>
						<div className="flex-col ml-40">
							{filter_roles.slice(filter_roles.length / 2).map((val, idx) => (
								<div key={idx}>
									<div className="mt-2">
										<InputCheckBox
											id={val.role_id}
											value={val.role_id}
											onChange={handleCheckboxRole}
											checked={selectedRoles.includes(val.role_id)}
											label={val.show_as}
											className="mb-4"
										/>
									</div>
								</div>
							))}
						</div>
					</div>

					<div className="mb-5">
						<div className="flex">
							<div className="w-1/2">
								<SelectOptions
									name="gender"
									label="GENDER"
									options={genders}
									disabled={eventCategory === '1' || eventCategory === '2'}
									value={gender}
									onChange={handleOnChange}
								/>
								{errors.eventGender && <div className="text-red-500 text-xs italic">{errors.eventGender}</div>}
							</div>
						</div>
					</div>

					<div className="mb-6">
						<div className="w-1/2">
							<div>
								<label className={`block text-gray-700 text-sm font-bold mb-2`}>
									MARITAL STATUS : {errors.eventMarital && <label className="text-red-500 text-xs italic">{errors.eventMarital}</label>}
								</label>

								<InputCheckBox
									value={maritalStatusAll}
									checked={maritalStatusAll || maritalValues.length === maritalStatus.length}
									onChange={handleCheckMaritalAll}
									label="All"
									name="maritalStatusAll"
								/>
								{maritalStatus.map((val, idx) => (
									<div key={idx}>
										<div className="mt-2">
											<InputCheckBox
												id={val.id}
												value={val.maritalValue}
												onChange={handleMaritalCheckbox}
												checked={maritalValues.includes(val.maritalValue)}
												label={val.maritalValue}
												className="mb-4"
											/>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>

					<div className="flex">
						<div className="w-1/2">
							<Button type="cancel" handleClick={handleOnCancel}>
								{loading ? <Loading secondary /> : 'Cancel'}
							</Button>
						</div>
						<div className="w-1/2">
							<div className="flex justify-end">
								<Button handleClick={handleOnDraft} type="secondary">
									{loading ? <Loading primary /> : 'Save As Draft'}
								</Button>

								<Button handleClick={handleSubmit} type="primary" disabled={true}>
									{loading ? <Loading secondary /> : 'Publish'}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="md:w-1/3 w-full px-2">
				<EventPreview
					eventBanner={eventImage}
					eventTitle={eventTitle}
					eventCategory={eventCategory}
					categories={categories}
					startDate={eventStartDate}
					endDate={eventEndDate}
					startTime={startTime}
					endTime={endTime}
					venues={venues}
					venue={venue}
					venueName={venueName}
					venueAddress={venueAddress}
					eventDescription={eventDescription}
					// eventPrice={event_price}
				/>
			</div>
		</div>
	)
}
