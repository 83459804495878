import axios from 'axios'
import search from './search'

export const getSmallGroupList = async (
	page,
	filter_keyword,
	filter_category,
	filter_day,
	filter_time,
	filter_status,
	order_by,
	order_dir
) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			page,
			filter_keyword,
			filter_category,
			filter_day,
			filter_time,
			filter_status,
			order_by,
			order_dir,
		},
	})

export const smallGroupExport = async (
	filter_keyword,
	filter_category,
	filter_day,
	filter_time,
	filter_status,
	order_by,
	order_dir
) => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/export`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			filter_keyword,
			filter_category,
			filter_day,
			filter_time,
			filter_status,
			order_by,
			order_dir,
		},
	})
}

export const smallGroupDetail = async id => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/detail/${id}`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const viewRequest = async (id, page, filter_keyword, order_by, order_dir) =>
	await search(
		`${process.env.REACT_APP_ENDPOINT}/smallgroup/request/${id}?page=${page}&filter_keyword=${filter_keyword}&order_by=${order_by}&order_dir=${order_dir}`
	)

export const viewRequestExport = async (id, filter_keyword, order_by, order_dir) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/request/${id}/export`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			filter_keyword,
			order_by,
			order_dir,
		},
	})

export const smallGroupCategories = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/categories`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const smallGroupStatuses = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/statuses`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const smallGroupSearchUser = async (filter_keyword, small_group_id) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/users`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: { filter_keyword, small_group_id },
	})

export const smallGroupRegencies = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/regencies`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const createSmallGroups = async (
	small_group_parent_id,
	small_group_category_id,
	small_group_name,
	small_group_description,
	small_group_leader_id,
	time_day,
	time_hours,
	time_minutes,
	regency_id,
	long_address,
	short_address,
	longitude,
	latitude,
	small_group_status,
	age_range_from,
	age_range_to,
	postal_code,
	established_date
) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/create`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: {
			small_group_parent_id,
			small_group_category_id,
			small_group_name,
			small_group_description,
			small_group_leader_id,
			time_day,
			time_hours,
			time_minutes,
			established_date,
			regency_id,
			long_address,
			short_address,
			longitude,
			latitude,
			small_group_status,
			age_range_from,
			age_range_to,
			postal_code,
		},
	})

export const smallGroupOptions = async filter_keyword =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/options`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: { filter_keyword },
	})

export const updateSmallGroup = async (
	small_group_id,
	small_group_category_id,
	small_group_name,
	small_group_description,
	small_group_leader_id,
	time_day,
	time_hours,
	time_minutes,
	established_date,
	regency_id,
	long_address,
	short_address,
	longitude,
	latitude,
	small_group_status,
	age_range_from,
	age_range_to,
	postal_code
) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/update`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: {
			small_group_id,
			small_group_category_id,
			small_group_name,
			small_group_description,
			small_group_leader_id,
			time_day,
			time_hours,
			time_minutes,
			regency_id,
			long_address,
			short_address,
			longitude,
			latitude,
			small_group_status,
			age_range_from,
			age_range_to,
			postal_code,
			established_date,
		},
	})

export const closeGroup = async id =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/close/${id}`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const getTotalAttendance = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/total-attendance`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const getSmallGroupAttendance = async (page, filter_keyword, order_by, order_dir) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/attendance`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: { page, filter_keyword, order_by, order_dir },
	})

export const getDetailAttendance = async id =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/attendance-detail/${id}`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const exportAttendance = async (monthSelected, yearSelected) => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/export-attendance/${monthSelected}/${yearSelected}`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const deleteAttendance = async id => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/delete-attendance/${id}`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const getRole = async () => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/role`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const getSmallGroupAttendanceWeek = async (
	year_from,
	year_to,
	month_from,
	month_to,
	last_month
) => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/total-attendance`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: { year_from, year_to, month_from, month_to, last_month },
	})
}
