import axios from 'axios'

export const createDevotion = async (formdata) => {
  return await axios({
    method: 'post',
    url: `${process.env.REACT_APP_ENDPOINT}/devotion/create`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    data: formdata
  })
}

export const updateDevotion = async (id, formdata) => {
  return await axios({
    method: 'post',
    data: formdata,
    url: `${process.env.REACT_APP_ENDPOINT}/devotion/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
}

export const devotionCategories = async () => {
  return await axios({
    method: 'get',
    url: `${process.env.REACT_APP_ENDPOINT}/devotion/categories`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
}

export const devotionDetails = async (id) => {
  return await axios({
    method: 'get',
    url: `${process.env.REACT_APP_ENDPOINT}/devotion/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
}
