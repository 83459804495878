import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import ReactModal from 'react-modal'
import Loading from 'components/commons/Loading'
import NotificationDanger from 'components/commons/NotificationDanger'
import CampusList from './CampusList'
import { CampusWrapper, CampusWrapperTitle, ButtonModal, ButtonContainer, Button, ModalCampus } from './styles'
import { createCampus, deleteCampus, getListCampuses } from 'libs/apis/campus'
import { successMessage } from 'libs/utils/confirmation'
import { failedFetch } from 'libs/utils/messages'
import { deleteItemConfirmation, defaultErrorMessage } from 'libs/utils/confirmation'

class CampusSection extends Component {
	state = {
		showModal: false,
		isSubmit: false,
		isRedirect: false,
		name: '',
		address: '',
		errorSubmit: '',
		errors: { name: '', address: '' },
		data: [],
		errorMessage: '',
	}

	componentDidMount() {
		this.setState({ isSubmit: true }, async () => {
			try {
				const response = await getListCampuses()
				if (response.data.api_status) {
					this.setState({ data: response.data.data, isSubmit: false })
				} else {
					this.setState({ isSubmit: false, errorMessage: failedFetch })
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						await localStorage.removeItem('token')
						this.setState({ isRedirect: true })
					} else if (error.response.status >= 500) {
						this.setState({ isSubmit: false, errorMessage: failedFetch })
					}
				}
			}
		})
	}

	handleOnToogleModal = () => {
		this.setState({ showModal: !this.state.showModal })
	}

	handleOnChange = (event) => {
		this.setState({ [event.target.name]: event.target.value })
	}

	handleOnSubmit = () => {
		const { name, address } = this.state
		const errors = []
		if (!name) {
			this.setState((prevState) => ({ errors: { ...prevState.errors, name: 'Required' } }))
			errors.push('Name is Required')
		} else {
			this.setState((prevState) => ({ errors: { ...prevState.errors, name: '' } }))
		}

		if (!address) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, address: 'Required' },
			}))
			errors.push('Address is Required')
		} else {
			this.setState((prevState) => ({ errors: { ...prevState.errors, address: '' } }))
		}

		if (name && address) {
			this.handleOnSave()
		}
	}

	handleOnSave = () => {
		this.setState({ isSubmit: true }, async () => {
			try {
				const { name, address } = this.state
				const response = await createCampus(name, address, '', '')
				if (response.data.api_status) {
					this.setState({ isSubmit: true }, async () => {
						try {
							const response = await getListCampuses()
							if (response.data.api_status) {
								this.setState({ data: response.data.data, isSubmit: false, showModal: false, name: '', address: '' })
							} else {
								this.setState({ isSubmit: false, errorMessage: failedFetch })
							}
						} catch (error) {
							if (error.response) {
								if (error.response.status === 401) {
									await localStorage.removeItem('token')
									this.setState({ isRedirect: true })
								} else if (error.response.status >= 500) {
									this.setState({
										isSubmit: false,
										errorMessage: failedFetch,
									})
								}
							}
						}
					})
					await successMessage()
				} else {
					this.setState({ isSubmit: false, errorSubmit: response.data.message })
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						await localStorage.removeItem('token')
						this.setState({ isRedirect: true })
					} else if (error.response.status >= 500) {
						this.setState({ isSubmit: false, errorSubmit: failedFetch })
					}
				}
			}
		})
	}

	handleOnDeleteCampus = async (id) => {
		const result = await deleteItemConfirmation()
		if (result.value) {
			const response = await deleteCampus(id)
			if (response.data.api_status) {
				this.setState({ isSubmit: true }, async () => {
					try {
						const response = await getListCampuses()
						if (response.data.api_status) {
							this.setState({ data: response.data.data, isSubmit: false })
						} else {
							this.setState({ isSubmit: false, errorMessage: failedFetch })
						}
					} catch (error) {
						if (error.response) {
							if (error.response.status === 401) {
								await localStorage.removeItem('token')
								this.setState({ isRedirect: true })
							} else if (error.response.status >= 500) {
								this.setState({
									isSubmit: false,
									errorMessage: failedFetch,
								})
							}
						}
					}
				})
			} else {
				await defaultErrorMessage()
			}
		}
	}

	handleUpdateList = (id, name, address) => {
		const { data } = this.state
		const dataById = data.find((item) => item.id === id)
		const updated = { ...dataById, campus_name: name, address }
		const updatedIndex = data.findIndex((item) => item.id === updated.id)
		const updatedData = [...data.slice(0, updatedIndex), updated, ...data.slice(updatedIndex + 1)]
		this.setState({ data: updatedData })
	}

	render() {
		const { showModal, name, address, errors, isSubmit, errorSubmit, isRedirect, data, errorMessage } = this.state
		const styleCampusName = errors.name && 'invalid'.trim()
		const styleCampusAddress = errors.address && 'invalid'.trim()

		if (isRedirect) {
			return <Redirect to="/login" />
		}

		return (
			<CampusWrapper>
				<CampusWrapperTitle>CAMPUSES</CampusWrapperTitle>
				<ButtonModal onClick={this.handleOnToogleModal}>+ Add Campus</ButtonModal>
				<ReactModal isOpen={showModal} style={ModalCampus} ariaHideApp={false}>
					{errorSubmit && <NotificationDanger message={errorSubmit} />}
					<div className="form-group">
						<label>CAMPUS NAME</label>
						<input className={`form-control ${styleCampusName}`} type="text" name="name" value={name} onChange={this.handleOnChange} />
						{errors.name && <div className="text-invalid">{errors.name}</div>}
					</div>
					<div className="form-group">
						<label>ADDRESS</label>
						<textarea className={`form-control ${styleCampusAddress}`} rows={5} name="address" value={address} onChange={this.handleOnChange} />
						{errors.address && <div className="text-invalid">{errors.address}</div>}
					</div>
					<ButtonContainer>
						<Button secondary onClick={this.handleOnToogleModal}>
							{isSubmit ? <Loading primary /> : 'Cancel'}
						</Button>
						<Button primary onClick={this.handleOnSubmit} disabled={isSubmit}>
							{isSubmit ? <Loading secondary /> : 'Save'}
						</Button>
					</ButtonContainer>
				</ReactModal>
				<CampusList
					data={data}
					handleOnDeleteCampus={this.handleOnDeleteCampus}
					handleUpdateList={this.handleUpdateList}
					isSubmit={isSubmit}
					isRedirect={isRedirect}
					errorMessage={errorMessage}
				/>
			</CampusWrapper>
		)
	}
}

export default CampusSection
