import React from 'react'

function AttendanceDetailsRow({ name, status, role }) {
	const statusStyle = `fa fa-check-square ${status === 0 ? 'text-gray-500' : 'text-green-500'}`
	return (
		<tr className="border border-solid bt-blueSecondary">
			<td className="px-8 py-4 text-left">{name}</td>
			<td className="px-2 py-4 text-center">{role}</td>
			<td className="py-4 px-10 text-center">
				<i className={statusStyle} />
			</td>
		</tr>
	)
}

export default AttendanceDetailsRow
