import React from 'react'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'

export function InputDate({ name, label, value, handleChange, withSec }) {
	let optionsWithsec = {
		dateFormat: 'd/m/Y H:i',
		altInput: true,
		altFormat: 'd M Y H:i',
		enableTime: true,
		time_24hr: true,
		defaultHour: moment().format('HH'),
		defaultMinute: moment().format('mm'),
	}
	let optionsNosec = {
		dateFormat: 'd/m/Y',
		altFormat: 'd M Y',
		enableTime: false,
		altInput: true,
	}
	return (
		<div>
			{label && <label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>}
			<Flatpickr
				className="appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none"
				name={name}
				value={value}
				onChange={handleChange}
				options={withSec ? optionsWithsec : optionsNosec}
				placeholder="Choose date"
			/>
		</div>
	)
}
