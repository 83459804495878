import axios from 'axios'

export const getUserList = async (page, filter_name, filter_email, filter_phone, filter_gender, filter_status, filter_role, order_by, order_dir) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/users?page=${page}&filter_name=${filter_name}&filter_email=${filter_email}&filter_phone=${filter_phone}&filter_gender=${filter_gender}&filter_status=${filter_status}&filter_role=${filter_role}&order_by=${order_by}&order_dir=${order_dir}`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const getRoles = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/users/roles`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const getUserDetail = async (id) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/users/detail/${id}`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
