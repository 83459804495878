import React from 'react'
import styled, { keyframes } from 'styled-components'

const Expand = keyframes`
0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.55);
  }
`

const Bubble = styled.div`
	animation: ${Expand} 0.75s ease-in-out infinite;
	border-radius: 20px;
	transform-origin: center center;
	margin: 0 3px;
	width: 10px;
	height: 10px;

	:nth-child(2) {
		animation-delay: 180ms;
	}

	:nth-child(3) {
		animation-delay: 360ms;
	}

	:nth-child(4) {
		animation-delay: 540ms;
	}
`

export const Loading = ({ secondary }) => (
	<div className="text-center">
		<Bubble className={`inline-block ${secondary ? 'bg-white' : 'bg-primary'}`} />
		<Bubble className={`inline-block ${secondary ? 'bg-white' : 'bg-primary'}`} />
		<Bubble className={`inline-block ${secondary ? 'bg-white' : 'bg-primary'}`} />
	</div>
)

export default Loading
