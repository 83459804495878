import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import Loading from 'components/commons/Loading'
import RequestHistoryProfile from 'components/prayerRequest/RequestHistoryProfile'
import PrayerList from 'components/prayerRequest/PrayerList'
import PaginationPage from 'components/commons/PaginationPage'
import { ReactComponent as PrayerPraiseIcon } from 'assets/icons/prayerpraise.svg'
import { prayerListUser } from 'libs/apis/prayerpraise'
import photoplaceholder from 'assets/images/photo-placeholder.png'

class RequestHistory extends Component {
	state = {
		isLoading: false,
		isRedirect: false,
		userName: '',
		userEmail: '',
		userPhoto: photoplaceholder,
		currentPage: 1,
		lastPage: 1,
		data: [],
	}

	componentDidMount() {
		this.LoadData()
	}

	componentDidUpdate(prevProps) {
		const { location } = this.props
		if (location.search && location.search !== prevProps.location.search) {
			this.LoadData()
		}
	}

	LoadData = () => {
		this.setState({ isLoading: true }, async () => {
			try {
				const parsed = queryString.parse(this.props.location.search)
				const page = parsed.page || 1
				const response = await prayerListUser(page, this.props.match.params.id)
				if (response.data.api_status) {
					this.setState({
						isLoading: false,
						userName: response.data.data.user.user_name,
						userEmail: response.data.data.user.user_email,
						userPhoto: response.data.data.user.user_photo ? response.data.data.user.user_photo : photoplaceholder,
						currentPage: response.data.data.prayers.current_page,
						lastPage: response.data.data.prayers.last_page,
						data: response.data.data.prayers.data,
					})
				} else {
					this.setState({ isLoading: false })
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.removeItem('token')
						this.setState({ isRedirect: true, isLoading: false })
					} else if (error.response.status >= 500) {
						this.setState({
							isLoading: false,
							error: 'An unexpected error has occurred. We are currently investigating the issue, please try again later.',
						})
					}
				}
			}
		})
	}

	renderData = () => {
		const { location } = this.props
		const parsed = queryString.parse(location.search)
		const active = `${location.pathname}?page=${parsed.page || 1}`
		const { userName, userEmail, userPhoto, currentPage, lastPage, data } = this.state
		return (
			<div>
				<RequestHistoryProfile userName={userName} userEmail={userEmail} userPhoto={userPhoto} />
				<PrayerList list={data} />
				<PaginationPage currentPage={currentPage} lastPage={lastPage} activeLink={active} length={data.length} url={location.pathname} />
			</div>
		)
	}

	render() {
		const church = JSON.parse(localStorage.getItem('church'))
		const breads = [{ url: '', title: 'Request History' }]
		const { isLoading, data, isRedirect } = this.state
		const displayLoading = isLoading
		const displayData = !isLoading && data.length > 0

		if (isRedirect) {
			return <Redirect to="/login" />
		}

		return (
			<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
				<Helmet>
					<title>{church.church_name} - Request History</title>
				</Helmet>
				<PageBreadcrumbs icon={PrayerPraiseIcon} title="Request History" breads={breads} />
				<div>
					{displayLoading && <Loading />}
					{displayData && this.renderData()}
				</div>
			</div>
		)
	}
}

export default RequestHistory
