import React from 'react'
import Loading from 'components/commons/Loading'
import SelectOptions from 'components/commons/SelectOptions'
import { Button } from 'components/commons/Button'

const AttendanceDetailsSearch = ({ handleOnExport, isExport, months, monthSelected, years, yearSelected, handleChange }) => (
	<div className="mb-6">
		<div className="flex flex-row">
			<div className="w-1/5">
				<div className="mr-4">
					<SelectOptions options={months} value={monthSelected} name="monthSelected" onChange={handleChange} />
				</div>
			</div>
			<div className="w-1/5">
				<div className="mr-4">
					<SelectOptions options={years} value={yearSelected} name="yearSelected" onChange={handleChange} />
				</div>
			</div>
			<div className="inline-block" />
			<div className="inline-block" />
			<div className="inline-block float-right">
				<Button type="export" handleClick={handleOnExport}>
					{isExport ? <Loading secondary /> : 'Export'}
				</Button>
			</div>
		</div>
	</div>
)

export default AttendanceDetailsSearch
