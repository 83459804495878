import React from 'react'
import CourseForm from './CourseForm'
import moment from 'moment'
import HeadTitle from 'components/commons/HeadTitle'
import BreadCrumb from 'components/commons/Breadcrumb'
import { ReactComponent as CoursesIcon } from 'assets/icons/classes.svg'
import Loading from 'components/commons/Loading'
import { courseCategories, createCourse, courseDetail, updateCourse, getCourseRole, getMaritalStatus } from 'services/courseServices'
import { getRole } from 'libs/apis/smallGroup'
import { campusList } from 'services/campusServices'
import '../../index.css'
import alert from 'services/alert'
import { ReactComponent as SelectLocation } from 'assets/icons/selectlocation.svg'
import { constants } from 'constants/constant'

class CreateUpdateCourseForm extends CourseForm {
	state = {
		data: {
			title: '',
			shortTitle: 'ST',
			description: '',
			categoryId: '',
			startDate: '',
			endDate: '',
			days: [],
			startTime: '',
			endTime: '',
			venueId: '',
			venueName: '',
			venueAddress: '',
			isOpenRegis: '',
			regisStartDate: '',
			regisEndDate: '',
			participantQuota: '',
			numberSessions: '',
			minSessions: '',
			startShare: '',
			isEndShare: false,
			endShare: '',
			is_require_payment: false,
			class_price: '',
			is_send_campaign: false,
			campaign_type: '',
			filter_role: [],
			is_draft: 0,
			gender: '',
			maritalStatus: '',
			marital_status: [],
			visibleToAll: false,
			maritalStatusAll: false,
		},
		isLoading: false,
		isSubmit: false,
		isDraft: false,
		categories: [],
		venues: [],
		errors: {
			title: '',
			shortTitle: '',
			description: '',
			startDate: '',
			endDate: '',
			startTime: '',
			endTime: '',
			venueId: '',
			venueName: '',
			venueAddress: '',
			regisStartDate: '',
			regisEndDate: '',
			participantQuota: '',
			numberSessions: '',
			minSessions: '',
			startShare: '',
			endShare: '',
			days: '',
			categoryId: '',
			filter_role: '',
			marital_status: '',
			gender: '',
		},
		roles: [],
	}

	componentDidMount() {
		this.setState({ isLoading: true }, async () => {
			try {
				await this.handleLoadCourse()
			} catch (error) {
				if (error.response && error.response.status === 401) {
					this.props.history.replace('/login')
				}
				this.setState({ isLoading: false })
			}
		})
	}

	componentDidUpdate(prevProps) {
		if ((prevProps.match.params.id && !this.props.match.params.id) || prevProps.location.pathname !== this.props.location.pathname) {
			let data = { ...this.state.data }
			data.title = ''
			data.shortTitle = ''
			data.description = ''
			data.categoryId = ''
			data.startDate = ''
			data.endDate = ''
			data.days = []
			data.startTime = ''
			data.endTime = ''
			data.venueId = ''
			data.venueName = ''
			data.venueAddress = ''
			data.isOpenRegis = ''
			data.regisStartDate = ''
			data.regisEndDate = ''
			data.participantQuota = ''
			data.numberSessions = ''
			data.minSessions = ''
			data.startShare = ''
			data.isEndShare = false
			data.endShare = ''
			data.is_require_payment = false
			data.class_price = ''
			data.is_send_campaign = false
			data.campaign_type = ''
			data.filter_role = []
			data.marital_status = []
			data.gender = ''
			this.setState({ data })
		}
	}

	handleGetCourseCategories = async () => {
		let rolesLocalStorage = JSON.parse(localStorage.getItem('course_categories'))
		if (rolesLocalStorage === null) {
			const {
				data: { data: categories },
			} = await courseCategories()

			localStorage.setItem('course_categories', JSON.stringify(categories))
			this.setState({ ...this.state, categories })
		} else {
			let categories = JSON.parse(localStorage.getItem('course_categories'))
			this.setState({ ...this.state, categories })
		}
	}

	handleGetRole = async () => {
		let rolesLocalStorage = JSON.parse(localStorage.getItem('roles'))
		if (rolesLocalStorage === null) {
			let res = await getRole()
			localStorage.setItem('roles', JSON.stringify(res.data.data))
			this.setState({ ...this.state, roles: res.data.data })
		} else {
			let roles = JSON.parse(localStorage.getItem('roles'))
			this.setState({ ...this.state, roles: roles })
		}
	}

	handleGetCampuses = async () => {
		let campusesLclStrg = JSON.parse(localStorage.getItem('course_campuses'))
		if (campusesLclStrg === null) {
			const {
				data: { data: campuses },
			} = await campusList()

			const venues = campuses.map((campus) => ({
				id: campus.id,
				title: campus.campus_name,
				address: campus.address,
			}))
			venues.push({ id: 'others', title: 'Others' })
			localStorage.setItem('course_campuses', JSON.stringify(venues))

			this.setState({
				venues,
			})
		} else {
			let venues = JSON.parse(localStorage.getItem('course_campuses'))

			this.setState({ ...this.state, venues })
		}
	}

	handleLoadCourse = async () => {
		const id = this.props.match.params.id

		const {
			data: { data: course },
		} = await courseDetail(id)

		await this.handleGetCampuses()

		await this.handleGetRole()

		await this.handleGetCourseCategories()

		let courseRole = await getCourseRole(id)
		let maritalStatus = await getMaritalStatus(id)

		const data = { ...this.state.data }
		data.title = course.title
		data.shortTitle = course.short_title
		data.description = course.description
		data.categoryId = course.classes_category_id.toString()
		data.startDate = course.start_date
		data.endDate = course.end_date
		data.days = course.class_days
		data.startTime = course.start_time
		data.endTime = course.end_time
		data.venueId = course.venue_id ? course.venue_id.toString() : 'others'
		data.venueName = course.venue_name ? course.venue_name : course.venue_other_name
		data.venueAddress = course.venue_address ? course.venue_address : course.venue_other_address
		data.isOpenRegis = course.is_open_for_registration ? '1' : '0'
		data.regisStartDate = course.is_open_for_registration ? course.registration_start_datetime : ''
		data.regisEndDate = course.is_open_for_registration ? course.registration_end_datetime : ''
		data.participantQuota = course.participant_quota
		data.numberSessions = course.number_of_session
		data.minSessions = course.session_completion_requirement
		data.startShare = course.publish_start_datetime
		data.endShare = course.publish_end_datetime ? course.publish_end_datetime : ''
		data.isEndShare = course.publish_end_datetime ? true : false
		data.is_require_payment = course.is_require_payment
		data.class_price = course.class_price
		data.is_send_campaign = course.is_send_campaign === 1 ? true : false
		data.campaign_type = course.campaign_type
		data.filter_role = courseRole.data.data
		data.is_draft = course.is_draft
		data.marital_status = maritalStatus.data.data
		data.gender = course.gender

		this.setState({ data, isLoading: false })
	}

	handleCreateClass = () => {
		this.setState({ ...this.state, isSubmit: true }, async () => {
			try {
				const {
					title,
					description,
					startDate,
					endDate,
					startTime,
					endTime,
					venueId,
					venueName,
					venueAddress,
					isOpenRegis,
					regisStartDate,
					regisEndDate,
					participantQuota,
					numberSessions,
					minSessions,
					startShare,
					endShare,
					days,
					categoryId,
					isEndShare,
					is_require_payment,
					class_price,
					is_send_campaign,
					campaign_type,
					filter_role,
					marital_status,
					gender,
					visibleToAll,
					maritalStatusAll,
				} = this.state.data

				const { isDraft, roles } = this.state
				let formData = new FormData()
				formData.append('title', title)
				formData.append('short_title', 'ST')
				formData.append('description', description)
				formData.append('start_date', startDate)
				formData.append('end_date', endDate)
				formData.append('start_time', startTime)
				formData.append('end_time', endTime)
				formData.append('venue_id', venueId === 'others' ? '' : venueId)
				formData.append('venue_other_name', venueName)
				formData.append('venue_other_address', venueAddress)
				formData.append('is_open_for_registration', isOpenRegis)
				formData.append('registration_start_datetime', regisStartDate)
				formData.append('registration_end_datetime', regisEndDate)
				formData.append('participant_quota', participantQuota)
				formData.append('number_of_session', numberSessions)
				formData.append('session_completion_requirement', minSessions)
				formData.append('publish_start_datetime', startShare)
				if (isEndShare) {
					formData.append('publish_end_datetime', endShare)
				}
				formData.append('is_draft', isDraft ? 1 : 0)
				for (let index = 0; index < days.length; index++) {
					formData.append('class_days[]', days[index])
				}
				formData.append('classes_category_id', categoryId)
				formData.append('is_require_payment', is_require_payment ? 1 : 0)
				formData.append('class_price', class_price ? 10000 : 10000)
				formData.append('is_send_campaign', is_send_campaign ? 1 : 0)
				formData.append('campaign_type', campaign_type)
				formData.append('gender', gender)

				if (maritalStatusAll) {
					for (let index = 0; index < constants.maritalStatus.length; index++) {
						formData.append('marital_status[]', constants.maritalStatus[index].maritalValue)
					}
				} else {
					for (let index = 0; index < marital_status.length; index++) {
						formData.append('marital_status[]', marital_status[index])
					}
				}

				if (visibleToAll) {
					for (let index = 0; index < roles.length; index++) {
						formData.append('filter_role[]', roles[index].role_id)
					}
				} else {
					for (let index = 0; index < filter_role.length; index++) {
						formData.append('filter_role[]', filter_role[index])
					}
				}

				const id = this.props.match.params.id
				if (id) {
					formData.append('class_id', id)
					const { data: response } = await updateCourse(formData)
					this.handleResponse(response)
				} else {
					const { data: response } = await createCourse(formData)
					this.handleResponse(response)
				}
				this.setState({ isSubmit: false })
			} catch (error) {
				if (error.response && error.response.status === 401) {
					this.props.history.replace('/login')
				}
				this.setState({ isSubmit: false })
			}
		})
	}

	handleOnSubmit = async () => {
		const res = await alert.confirmComplete('Are you sure wanna proceed the data')
		if (res.value) {
			// this.handleValidate(this.handleCreateClass)
			this.handleCreateClass()
		}
	}

	handleOnDraft = () => {
		this.setState({ ...this.state, isSubmit: true, isDraft: true }, async () => {
			// this.handleValidate(this.handleCreateClass)
			this.handleCreateClass()
		})
	}

	handleResponse = async (response) => {
		if (response.api_status) {
			await alert.succeed('Success', 'Your Class has been saved.')
			this.props.history.replace('/classes')
		} else {
			alert.failed('Failed', response.message)
			return
		}
	}

	handleCancel = async () => {
		const result = await alert.confirmCancel()
		if (result.value) {
			this.props.history.replace('/classes')
		}
	}

	handleCheck = (e) => {
		if (e.target.name === 'is_send_campaign') {
			this.setState({
				...this.state,
				is_send_campaign: !this.state.is_send_campaign,
			})
		}
	}

	handleSendAsCampaign = (e) => {
		this.setState({
			...this.state,
			campaign_type: e.target.value,
		})
	}

	renderForm() {
		const { data, errors, categories, venues } = this.state
		const isSubmit = this.state.isSubmit
		const campaigns = [
			{ id: 'email', title: 'Create campaign by email' },
			{ id: 'pushnotif', title: 'Create campaign notification' },
			{ id: 'ALL', title: 'Create both campaigns' },
		]

		return (
			<div className="bg-white shadow-md p-6">
				<div>
					{this.renderInput('title', 'CLASS TITLE')}
					{this.renderInputHTML('description', 'DESCRIPTION')}
					{this.renderSelect('categoryId', 'CATEGORY', categories)}
					<div className="flex -mx-2">
						<div className="w-1/2 mx-2">{this.renderDateInput('startDate', 'START DATE')}</div>
						<div className="w-1/2 mx-2">{this.renderDateInput('endDate', 'END DATE')}</div>
					</div>
					<div className="mb-6">
						<label className="block text-gray-700 text-sm font-bold">CLASS DAY</label>
						<label className="block text-gray-700 text-sm font-bold mb-2">(You may choose more than one)</label>
						<div className="flex">
							<div className="w-1/2 flex">
								<div className="w-1/2">
									<div>{this.renderCheckBoxDay('days', 'Monday', 1, data.days.includes(1))}</div>
									<div>{this.renderCheckBoxDay('days', 'Tuesday', 2, data.days.includes(2))}</div>
									<div>{this.renderCheckBoxDay('days', 'Wednesday', 3, data.days.includes(3))}</div>
									<div>{this.renderCheckBoxDay('days', 'Thursday', 4, data.days.includes(4))}</div>
								</div>
								<div className="w-1/2">
									<div>{this.renderCheckBoxDay('days', 'Friday', 5, data.days.includes(5))}</div>
									<div>{this.renderCheckBoxDay('days', 'Saturday', 6, data.days.includes(6))}</div>
									<div>{this.renderCheckBoxDay('days', 'Sunday', 0, data.days.includes(0))}</div>
								</div>
							</div>
						</div>
						{errors.days && <div className="text-red-500 text-xs italic">{errors.days}</div>}
					</div>
					<div className="flex -mx-2">
						<div className="w-1/2 mx-2">{this.renderTimeInput('startTime', 'START TIME')}</div>
						<div className="w-1/2 mx-2">{this.renderTimeInput('endTime', 'END TIME')}</div>
					</div>
					{this.renderSelect('venueId', 'VENUE', venues)}
					{data.venueId === 'others' && (
						<div>
							{this.renderInput('venueName', 'VENUE NAME')}
							{this.renderTextArea('venueAddress', 'VENUE ADDRESS')}
						</div>
					)}
					<div className="flex mb-4">
						<div className="w-1/2 mb-2">
							<label className="block text-gray-700 text-sm font-bold mb-2">OPEN FOR REGISTRATION</label>
							<div className="flex">
								<div className="w-1/2 mr-4">
									<div className="inline-flex items-center">{this.renderCheckBox('isOpenRegis', '1', data.isOpenRegis === '1', 'Yes', this.handleChange)}</div>
								</div>
								<div className="w-1/2 mr-4">
									<div className="inline-flex items-center">{this.renderCheckBox('isOpenRegis', '0', data.isOpenRegis === '0', 'No', this.handleChange)}</div>
								</div>
							</div>
							{errors.isOpenRegis && <div className="text-red-500 text-xs italic">{errors.isOpenRegis}</div>}
						</div>
					</div>
					{data.isOpenRegis === '1' && (
						<div>
							<div className="flex">
								<div className="w-1/2 mb-6 mr-4">{this.renderDateTimeInput('regisStartDate', 'REGISTRATION START DATE')}</div>
								<div className="w-1/2 mb-6">{this.renderDateTimeInput('regisEndDate', 'REGISTRATION END DATE')}</div>
							</div>
						</div>
					)}
					{this.renderInput('participantQuota', 'CLASS PARTICIPANT QUOTA', 'number')}
					{this.renderInput('numberSessions', 'NUMBER OF SESSIONS', 'number')}
					{this.renderInput('minSessions', 'MINIMUM SESSION COMPLETION', 'number')}
					<div className="flex flex-wrap -mx-3">
						<div className="w-full md:w-1/2 mt-2 px-2">{this.renderDateTimeInput('startShare', 'START SHARING ON')}</div>
						<div className="w-full md:w-1/2 px-2">
							{this.renderCheckBox('isEndShare', data.isEndShare, data.isEndShare, 'END SHARING ON', this.handleChecked)}
							{data.isEndShare && this.renderDateTimeInput('endShare')}
						</div>
					</div>

					<div className="mt-5 mb-4">
						{this.renderCheckBox('is_send_campaign', data.is_send_campaign, data.is_send_campaign, 'SEND AS CAMPAIGN', this.handleChecked)}
						{data.is_send_campaign && this.renderSelect('campaign_type', '', campaigns)}
					</div>

					{/* <div className="mt-5 mb-5">
						{this.renderCheckBox('is_require_payment', data.is_require_payment, data.is_require_payment, 'CLASS PAYMENT', this.handleChecked)}
						{data.is_require_payment !== false && this.renderInput('class_price')}
					</div> */}
					<label className="block text-gray-700 text-sm font-bold mb-2">
						ELIGIBLE TO : {errors['filter_role'] && <span className="text-red-500 text-xs italic">{errors['filter_role']}</span>}
					</label>
					<div className="flex mb-5">
						<div className="flex-col">
							{this.renderCheckBox('visibleToAll', data.visibleToAll, data.visibleToAll || this.state.roles.length === data.filter_role.length, 'All', this.handleCheckRoleAll)}
							{this.state.roles.slice(0, this.state.roles.length / 2).map((role) => (
								<div key={role.role_id} className="mt-2">
									{this.renderCheckBoxRole('filter_role', `${role.show_as}`, role.role_id, data.filter_role.includes(role.role_id))}
								</div>
							))}
						</div>
						<div className="flex-col ml-40">
							{this.state.roles.slice(this.state.roles.length / 2).map((role) => (
								<div key={role.role_id} className="mt-2">
									{this.renderCheckBoxRole('filter_role', `${role.show_as}`, role.role_id, data.filter_role.includes(role.role_id))}
								</div>
							))}
						</div>
					</div>
					{this.renderSelect('gender', 'GENDER', constants.genders, data.categoryId === '1' || data.categoryId === '2')}
					<label className="block text-gray-700 text-sm font-bold mb-2">
						MARITAL STATUS :{errors['marital_status'] && <span className="text-red-500 text-xs italic">{errors['marital_status']}</span>}
					</label>
					<div className="flex-col mb-4">
						{this.renderCheckBox(
							'maritalStatusAll',
							data.maritalStatusAll,
							data.maritalStatusAll || constants.maritalStatus.length === data.marital_status.length,
							'All',
							this.handleMaritalAll
						)}
						{constants.maritalStatus.map((marital) => (
							<div key={marital.id} className="mt-2">
								{this.renderCheckMarital('marital_status', `${marital.maritalValue}`, marital.maritalValue, data.marital_status.includes(marital.maritalValue))}
							</div>
						))}
					</div>
				</div>
				<div className="flex">
					<div className="w-1/2">
						<button type="submit" className="font-bold bg-grey-300 text-white rounded-lg py-2 px-6 focus:outline-none" onClick={this.handleCancel}>
							{isSubmit ? <Loading secondary /> : 'Cancel'}
						</button>
					</div>
					<div className="w-1/2">
						<div className="flex justify-end">
							<button
								type="submit"
								className="mr-3 font-bold bg-white border-primary border-solid border-2 text-primary rounded-lg py-2 px-6 focus:outline-none"
								onClick={this.handleOnDraft}
								disabled={isSubmit}
							>
								{isSubmit ? <Loading primary /> : 'Save as Draft'}
							</button>
							<button type="submit" className="ml-3 font-bold bg-primary rounded-lg py-2 px-6 text-white focus:outline-none" onClick={this.handleOnSubmit} disabled={isSubmit}>
								{isSubmit ? <Loading secondary /> : 'Publish'}
							</button>
						</div>
					</div>
				</div>
			</div>
		)
	}

	renderPreviewForm() {
		const { data, venues } = this.state

		const days = data.days
			.concat()
			.sort()
			.map((day) => moment(day, 'd').format('dddd'))

		const selectedVenue = venues.filter((venue) => venue.id === parseInt(data.venueId))

		return (
			<div className="bg-white shadow-md p-6">
				<p className="font-bold mb-4">MOBILE SCREEN PREVIEW</p>
				<div className="bg-blueSecondary">
					<div className="p-3 break-words overflow-auto h-64">
						<div className="font-bold text-md">{data.title ? `${data.title}` : 'Class Title'}</div>
						<div className="my-4 border-2 border-dashed" />
						<div className="flex -mx-2 text-gray-500 text-xs">
							<div className="w-1/12 px-2">
								<i className="far fa-calendar-alt" />
							</div>
							<div className="w-11/12 px-2">
								<div className="text-gray-500 text-xs">
									Day
									<div className="flex flex-wrap">
										{days.map((day, idx) => (
											<div key={idx} className="mr-1">
												{day}
												{'-'}
											</div>
										))}
									</div>
								</div>
								<div>
									{data.startDate && data.endDate ? `${moment(data.startDate, 'DD/MM/YYYY').format('ll')} - ${moment(data.endDate, 'DD/MM/YYYY').format('ll')}` : 'Date'}
								</div>
								<div>{data.startTime && data.endTime ? `${moment(data.startTime, 'HH:mm').format('HH:mm')} - ${moment(data.endTime, 'HH:mm').format('HH:mm')}` : 'Time'}</div>
							</div>
						</div>
						<div className="my-4 border-2 border-dashed" />
						<div className="flex -mx-2">
							<div className="w-1/12 px-2">
								<div>
									<SelectLocation width="10px" height="14px" />
								</div>
							</div>
							<div className="w-11/12 px-2">
								{selectedVenue.length > 0 ? (
									selectedVenue.map((venue) => (
										<div key={venue.id}>
											<div className="text-gray-500 text-xs">{venue.title || data.venueName || 'Venue Name'}</div>
											<div className="text-gray-500 text-xs">{venue.address || data.venueAddress || 'Address'}</div>
										</div>
									))
								) : (
									<>
										<div className="text-gray-500 text-xs">{data.venueName || data.venueName || 'Venue Name'}</div>
										<div className="text-gray-500 text-xs">{data.venueAddress || data.venueAddress || 'Address'}</div>
									</>
								)}
							</div>
						</div>
						<div className="my-4 border-2 border-dashed" />

						<div className="flex -mx-2">
							<div className="w-11/12 px-2">
								<div className="font-bold text-gray-500 text-xs">Open Registration</div>
								<div className="text-gray-500 text-xs">
									{data.regisStartDate && data.regisEndDate
										? `${moment(data.regisStartDate, 'DD/MM/YYYY').format('ll')} - ${moment(data.regisEndDate, 'DD/MM/YYYY').format('ll')}`
										: 'No'}
								</div>
							</div>
						</div>
						<div className="my-4 border-2 border-dashed" />
						<div className="font-bold text-xs">Description</div>
						{data.description && <div className="text-xs" dangerouslySetInnerHTML={{ __html: data.description }} />}
					</div>
				</div>
			</div>
		)
	}

	render() {
		const id = this.props.match.params.id
		const name = JSON.parse(localStorage.getItem('church')).church_name ? JSON.parse(localStorage.getItem('church')).church_name : '-'
		const breads = id
			? [
					{ label: 'List of All', path: '/classes' },
					{ key: 'editclass', label: 'Edit Class' },
			  ]
			: [{ key: 'createclass', label: 'Create Class' }]

		return (
			<div className="main-content flex-1 bg-gray-100 pb-24 md:p-6">
				<HeadTitle title={`${name} - ${id ? 'Edit Class' : 'Create Class'}`} />
				<BreadCrumb title={`${id ? 'Edit Class' : 'Create Class'}`} breads={breads} icon={CoursesIcon} />
				{this.state.isLoading ? (
					<Loading />
				) : (
					<div className="flex md:-mx-2 flex-wrap">
						<div className="md:w-2/3 w-full px-2 mb-4 md:mb-0">{this.renderForm()}</div>
						<div className="md:w-1/3 w-full px-2">{this.renderPreviewForm()}</div>
					</div>
				)}
			</div>
		)
	}
}

export default CreateUpdateCourseForm
