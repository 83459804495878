import React from 'react'

export function TextInput({ label, size, handleChange, value, name }) {
	const small = `${size === 'small' ? 'w-64' : ''}`
	const medium = `${size === 'medium' ? 'w-3/6' : ''}`
	const large = `${size === 'large' ? 'w-full' : ''}`

	return (
		<div className="flex flex-col pt-2">
			<label className="mb-3 ml-1 font-bold">{label}</label>
			<input type="text" name={name} className={`${small || medium || large} rounded-lg py-2 pl-3 bg-white border-secondary border-2`} value={value} onChange={handleChange} />
		</div>
	)
}
