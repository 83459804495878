import React from 'react'
import { Link } from 'react-router-dom'
import QRCode from 'qrcode.react'

const QRCodesList = ({ qrcode }) => {
	return (
		<div className="w-1/3 mb-4 px-2">
			<div className="border-2 border-greySecondary">
				<div className="flex p-4">
					<div className="px-2">
						<QRCode value={qrcode.qrcode} />
					</div>
					<div>
						<h3 className="font-bold text-lg">{qrcode.title}</h3>
						{qrcode.start_time} - {qrcode.end_time}
					</div>
				</div>
				<div className="bg-greySecondary px-4 py-2">
					<div className="flex justify-end">
						<Link to={`/view-qr/${qrcode.title}/${qrcode.qrcode}/${qrcode.start_time}/${qrcode.end_time}`} className="bg-white p-2">
							View QR
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default QRCodesList
