import React from 'react'
import moment from 'moment'
import TableBody from '../commons/TableBody'
import ActionsRow from '../commons/ActionsRow'
import TableHeader from '../commons/TableHeader'

const RegistrantTable = (props) => {
	const columns = [
		{ label: 'Name', path: 'fullname', key: 'fullname' },
		{
			label: 'GROUP NAME',
			key: 'small_group_name',
			content: (registrant) => {
				return <td className="px-2 py-4">{registrant.small_group_name ? registrant.small_group_name : '-'}</td>
			},
		},
		{
			label: 'ROLE',
			key: 'role_name',
			content: (registrant) => {
				const style = registrant.role_name !== 'Admin' ? 'text-black' : 'text-primary'
				return (
					<td className="px-2 py-4">
						<div className={style}>{registrant.role_name}</div>
					</td>
				)
			},
		},
		{
			label: 'APPLIED ON',
			path: 'created_at',
			content: (registrant) => {
				const created_at = moment(registrant.created_at, 'DD/MM/YYYY HH:mm')
				return (
					<td className="px-2 py-4">
						<div className="text-center">
							<div>{created_at.format('D MMM YYYY')}</div>
							<div className="text-primary">{created_at.fromNow()}</div>
						</div>
					</td>
				)
			},
		},
		{
			label: 'PAYMENT VALID UNTIL',
			key: 'payment_valid_until',
			content: (registrant) => {
				return <td className="px-2 py-4">-</td>
			},
		},
		{ label: 'INVOICE ID', key: 'invoice_id' },
		{
			label: 'PAYMENT STATUS',
			key: 'payment_status',
		},
		{
			label: 'STATUS',
			path: 'status',
			content: (registrant) => {
				let style = ''
				switch (registrant.status) {
					case 'Approved':
						style = 'text-green-500'
						break
					case 'Applied':
						style = 'text-yellow-500'
						break
					case 'Pending':
						style = 'text-grey-300'
						break
					case 'Rejected':
						style = 'text-red-500'
						break
					case 'Failed':
						style = 'text-red-500'
						break
					case 'Passed':
						style = 'text-green-500'
						break
					default:
						break
				}
				return (
					<td className="px-2 py-4">
						<i className={`fas fa-circle ${style}`}></i>
						{` ${registrant.status}`}
					</td>
				)
			},
		},
		{
			key: 'action',
			content: (registrant) => {
				const classes = 'px-4 py-2 block hover:bg-blueSecondary'
				const actions = [
					{
						key: 'resend',
						path: 'resend',
						helper: (
							<li className={classes} onClick={() => props.onResendEmail(registrant)}>
								Resend Confirmation Email
							</li>
						),
					},
					{
						key: 'reject',
						path: 'reject',
						helper: (
							<li className={classes} onClick={() => props.onReject(registrant)}>
								Reject Registrant
							</li>
						),
					},
				]
				return <ActionsRow actions={actions} />
			},
		},
	]

	return (
		<div className="bg-white shadow-md overflow-x-auto mx-2 md:mx-0 whitespace-no-wrap">
			<table className="min-w-full">
				<TableHeader columns={columns} sortColumn={props.sortColumn} onSort={props.onSort} />
				<TableBody data={props.registrants} columns={columns} />
			</table>
		</div>
	)
}

export default RegistrantTable
