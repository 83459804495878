import React from 'react'
import { Link } from 'react-router-dom'
import Joi from 'joi-browser'
import queryString from 'query-string'
import HeadTitle from 'components/commons/HeadTitle'
import Form from 'components/commons/Form'
import InputPassword from 'components/commons/InputPassword'
import Loading from 'components/commons/Loading'
import logo from 'assets/icons/logo.svg'
import { resetPassword } from 'services/authServices'
import message from 'services/alert'

class ResetForm extends Form {
	state = {
		data: { newPassword: '' },
		errors: {},
		isSubmit: false,
		isShowPassword: false,
	}

	schema = {
		newPassword: Joi.string().required(),
	}

	handlePassword = () => {
		this.setState({ isShowPassword: !this.state.isShowPassword })
	}

	doSubmit = () => {
		this.setState({ isSubmit: true }, async () => {
			try {
				const { token } = queryString.parse(this.props.location.search)
				const { data } = await resetPassword(token, this.state.data.newPassword)
				if (data.api_status) {
					message.succeed('Success', 'Your password has been changed.')
					this.props.history.replace('/login')
				} else {
					message.failed('Failed', data.message)
				}
			} catch (error) {
				// do Something?
			}
			this.setState({ isSubmit: false })
		})
	}

	render() {
		const {
			data: { newPassword },
			errors,
			isShowPassword,
			isSubmit,
		} = this.state

		return (
			<div className="h-screen">
				<HeadTitle title="Skyportal | Reset Password" />
				<div className="container auth-container">
					<div className="md:w-1/2">
						<div className="flex justify-center">
							<img src={logo} alt="skyporter logo" className="w-16" />
						</div>
						<h3 className="auth-title">
							Reset Your Password
							<p className="text-xs mt-2">Type a new password for your account.</p>
						</h3>
						<form onSubmit={this.handleSubmit}>
							<InputPassword
								type={isShowPassword ? 'text' : 'password'}
								name="newPassword"
								value={newPassword}
								placeholder="new password"
								onChange={this.handleChange}
								onFocus={this.handleFocus}
								onBlur={this.handleBlur}
								isShow={isShowPassword}
								onClick={this.handlePassword}
								error={errors.newPassword}
							/>
							<button disabled={isSubmit} className="bg-primary text-white flex w-full py-5 px-3 justify-center rounded-lg focus:outline-none" onClick={this.handleSubmit}>
								{isSubmit ? <Loading /> : 'Reset password'}
							</button>
						</form>
						<div className="mt-6 flex">
							<Link to="/login" className="text-primary">
								Back to Log in
							</Link>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ResetForm
