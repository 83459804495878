import axios from 'axios'

export const getFileRole = async resources_id => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/role-file`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: { resources_id },
	})
}

export const deleteFile = async resources_id => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/delete-file`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: { resources_id },
	})
}

export const renameFile = async (resources_id, name) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/rename-file`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: { resources_id, name },
	})

export const uploadFile = async form_data =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/upload-file`,
		headers: {
			Authorization: `Bearer ${localStorage.getItem('token')}`,
		},
		data: form_data,
	})

export const getFileList = async (page, filter_keyword) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/file`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: { page, filter_keyword },
	})

export const updateRoleResources = async (resources_id, filter_role) => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/update-role-resources`,
		headers: {
			Authorization: `Bearer ${localStorage.getItem('token')}`,
		},
		params: { resources_id, filter_role },
	})
}
