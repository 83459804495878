import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";

function AttendanceRow({
  id,
  // attendanceName,
  startTime,
  day,
  dateAttendance,
  totalAttendee,
  totalVisitor,
  totalMember,
  handleDelete
}) {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const handleToggle = () => {
    setDropDownOpen(!dropDownOpen);
  };

  const styleDropDownOpen = `z-20 shadow-md absolute right-20px ${
    dropDownOpen ? "block" : "hidden"
  }`;

  return (
    <tr className="border border-solid bt-blueSecondary text-center">
      <td className="px-2 py-4">
        {moment(dateAttendance, "YYYY-MM-DD").format("D MMM YYYY")}
      </td>
      <td className="px-2 py-4">{day}</td>
      <td className="px-2 py-4">{startTime}</td>
      <td className="px-6 py-4">
        {totalAttendee}/{totalMember}
      </td>
      <td className="px-4 py-4">{totalMember}</td>
      <td className="px-4 py-4">{totalVisitor}</td>
      <td className="px-2 py-4">
        <div className="cursor-pointer" onClick={handleToggle}>
          <i className="dropdown-trigger fas fa-ellipsis-v" />
          <div className={styleDropDownOpen}>
            <ul className="bg-white">
              <li>
                <Link
                  className="px-4 py-2 block hover:bg-blueSecondary"
                  to={`/smallgroup/attendance-detail/${id}`}
                >
                  View Details
                </Link>
              </li>
              <li
                className="px-4 py-2 block hover:bg-blueSecondary"
                onClick={() => handleDelete(id)}
              >
                Delete
              </li>
            </ul>
          </div>
        </div>
      </td>
    </tr>
  );
}

export default AttendanceRow;
