import React, { Component } from 'react'
import SmallGroupTableRow from './SmallGroupTableRow'
import { partial } from 'libs/utils/utils'

class SmallGroupTable extends Component {
	render() {
		const { list, handleOnSort, handleOnClose } = this.props

		return (
			<div className="bg-white shadow-md rounded">
				<table className="w-full">
					<TableHeader handleOnSort={handleOnSort} />
					<TableBody list={list} handleOnClose={handleOnClose} />
				</table>
			</div>
		)
	}
}

const TableHeader = ({ handleOnSort }) => {
	const handleSortTime = partial(handleOnSort, 'time')
	const handleSortCreated = partial(handleOnSort, 'created_at')

	return (
		<thead className="bt-blueSecondary py-4 border-2 border-solid">
			<tr>
				<th className="text-left" />
				<th className="text-left pl-8">GROUP NAME</th>
				<th className="text-left">TYPE</th>
				<th className="text-left">LEADER</th>
				<th className="text-left">MEMBERS</th>
				<th className="text-left">DAY</th>
				<th className="text-left">
					TIME{' '}
					<i
						className="fas fa-sort sortby cursor-pointer"
						onClick={handleSortTime}
					/>
				</th>
				<th className="px-2 py-4 text-left">
					DATE CREATED{' '}
					<i
						className="fas fa-sort sortby cursor-pointer"
						onClick={handleSortCreated}
					/>
				</th>
				<th className="px-2 py-4 text-left">STATUS</th>
				<th className="px-2 py-4" />
			</tr>
		</thead>
	)
}

const TableBody = ({ list, handleOnClose }) => (
	<tbody className="border-2 border-solid">
		{list.map(item => (
			<SmallGroupTableRow
				key={item.small_group_id}
				id={item.small_group_id}
				name={item.small_group_name}
				category={item.category}
				leader={item.leaders_name}
				members={item.member_count}
				day={item.time_day_desc}
				time={item.time}
				created={item.created_at}
				status={item.small_group_status}
				handleOnClose={handleOnClose}
			/>
		))}
	</tbody>
)

export default SmallGroupTable
