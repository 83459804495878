import { withFormik } from 'formik'
import validationSchema from './PushNotifValidSchema'
import { createPushNotif, updatePushNotif } from 'libs/apis/pushNotif'
import {
	saveDraftConfirmation,
	successMessage,
	failedMessage,
	somethingWrong,
} from '../../libs/utils/confirmation'
import PushNotifForm from '../forms/PushNotifForm'

const CampaignPushNotifFormik = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,
	mapPropsToValues: props => ({
		id: props.id || '',
		title: props.title || '',
		body: props.body || '',
		status: props.status || '',
		filter_gender: props.filter_gender || 'ALL',
		filter_marital_status: props.filter_marital_status || 'ALL',
		filter_roles: props.filter_roles || [],
		filter_role: '',
		schedule: props.schedule || '',
		is_draft: false,
		isCreateForm: props.isCreateForm || false,
	}),
	validationSchema: validationSchema,
	async handleSubmit(values, { props, setSubmitting, setFieldValue }) {
		const {
			id,
			title,
			body,
			schedule,
			isCreateForm,
			is_draft,
			filter_roles,
			status,
			// filter_role,
			filter_gender,
			filter_marital_status,
		} = values

		let formdata = new FormData()
		formdata.append('title', title)
		formdata.append('body', body)
		formdata.append('schedule', schedule)
		formdata.append('filter_gender[]', filter_gender)
		formdata.append('filter_marital_status[]', filter_marital_status)

		for (let i = 0; i < filter_roles.length; i++) {
			if (filter_roles[i] !== '' && filter_roles[i] !== "''") {
				formdata.append('filter_role[]', filter_roles[i])
			}
		}

		if (is_draft) {
			formdata.append('is_draft', 1)
		} else {
			// formdata.append('is_draf', false)
			formdata.append('status', status)
		}

		try {
			if (isCreateForm) {
				if (is_draft) {
					const confirm = await saveDraftConfirmation()
					if (confirm.value) {
						const response = await createPushNotif(formdata)
						if (response.data.api_status) {
							await successMessage()
							props.history.push('/pushnotif/')
						} else {
							failedMessage(response.data.message)
						}
					}
					setFieldValue('is_draft', 0)
				} else if (!is_draft) {
					const response = await createPushNotif(formdata)
					if (response.data.api_status) {
						await successMessage()
						props.history.push('/pushnotif/')
					} else {
						failedMessage(response.data.message)
					}
				}
			} else if (!isCreateForm) {
				if (is_draft) {
					const confirm = await saveDraftConfirmation()
					if (confirm.value) {
						const response = await updatePushNotif(id, formdata)
						if (response.data.api_status) {
							await successMessage()
							props.history.push('/pushnotif/')
						} else {
							failedMessage(response.data.message)
						}
					}
					setFieldValue('is_draft', 0)
				} else if (!is_draft) {
					const response = await updatePushNotif(id, formdata)
					if (response.data.api_status) {
						await successMessage()
						props.history.push('/pushnotif/')
					} else {
						failedMessage(response.data.message)
					}
				}
			}
			setSubmitting(false)
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
					props.history.push('/login')
				} else {
					setSubmitting(false)
					await somethingWrong()
				}
			}
		}
	},
})(PushNotifForm)

export default CampaignPushNotifFormik
