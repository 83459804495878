import React from 'react'

const TextArea = ({ name, value, onChange, error, field, size }) => {
	const defaultStyle = `appearance-none border-2 rounded-lg h-40 p-3 leading-tight focus:outline-none ${error ? 'border-red-500' : 'border-secondary'} `

	let sizearea = {
		large: 'w-full',
		medium: 'w-1/2',
	}

	const style = [defaultStyle, sizearea[size]].join(' ')

	return <textarea className={style} name={name || field.name} value={value || field.value} onChange={onChange || field.onChange} />
}

export default TextArea
