import React from 'react'

const RequestHistoryProfile = ({ userName, userEmail, userPhoto }) => (
  <div className="rounded-l-full bg-white mb-6 inline-block">
    <div className="flex">
      <div className="mr-2">
        <img className="rounded-full h-24 w-24" src={userPhoto} alt="avatar" />
      </div>
      <div className="p-4">
        <p className="font-bold">{userName}</p>
        <p className="font-bold">{userEmail}</p>
      </div>
    </div>
  </div>
)

export default RequestHistoryProfile
