import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import photoplaceholder from 'assets/images/photo-placeholder.png'
import { Link } from 'react-router-dom'

const RoleContainer = styled.div`
	width: 0;
	height: 0;
	border-top: 50px solid #ffcc00;
	border-bottom: 50px solid transparent;
	border-right: 50px solid transparent;
	margin-bottom: -100px;
`
const Role = styled.span`
	color: #fff;
	position: relative;
	top: -30px;
	width: 0;
	left: 8px;
	text-align: center;
	font-size: 16px;
	font-family: arial;
	transform: rotate(-45deg);
	display: block;
`

const SGUserCard = ({ role, color, profile, fullname, dob, email, phone, id, sgId }) => (
	<div className="w-2/6">
		<div className="bg-white mb-4 mr-2 pb-2">
			{role && (
				<RoleContainer style={{ borderTop: `50px solid ${color}` }}>
					<Role>{role}</Role>
				</RoleContainer>
			)}
			<div className="pt-4 flex justify-center">
				<Link to={`/small-group/view-member/${sgId}/${id}`}>
					<img src={profile ? profile : photoplaceholder} className="rounded-full flex items-center justify-center w-32 h-32" alt="user-small-group" />
				</Link>
			</div>
			<div className="font-bold text-center my-2">
				<Link to={`/small-group/view-member/${sgId}/${id}`}>{fullname}</Link>
			</div>
			<hr className="border-2 border-blueSecondary mx-4" />
			<div className="text-center">{dob ? moment(dob, 'YYYY-MM-DD').format('DD MMMM YYYY') : '-'}</div>
			<div className="text-center">{email}</div>
			<div className="text-center">{phone}</div>
		</div>
	</div>
)

export default SGUserCard
