import React from 'react'

function UsersSearch({ handleOnClear, handleOnFilter, filter, handleOnChange, roles }) {
	const { name, phone, role, email, gender, status } = filter

	return (
		<div className="flex mb-6">
			<div className="w-3/4 mr-4">
				<div className="flex flex-wrap -mx-3">
					<div className="w-full md:w-1/3 px-3 mb-6">
						<input
							className="form-input block shadow-md rounded-lg border-0 w-full"
							type="text"
							placeholder="First or full name"
							name="name"
							value={name}
							onChange={handleOnChange}
						/>
					</div>
					<div className="w-full md:w-1/3 px-3 mb-6">
						<input className="form-input block shadow-md rounded-lg border-0 w-full" type="text" placeholder="Phone number" name="phone" value={phone} onChange={handleOnChange} />
					</div>
					<div className="w-full md:w-1/3 px-3 mb-6">
						<select className="form-select border-0 rounded-lg shadow-md w-full" name="role" value={role} onChange={handleOnChange}>
							<option value="">Role</option>
							{roles.map((item) => (
								<option key={item.id} value={item.id}>
									{item.title}
								</option>
							))}
						</select>
					</div>
					<div className="w-full md:w-1/3 px-3">
						<input className="form-input block shadow-md rounded-lg border-0 w-full" type="email" placeholder="Email address" name="email" value={email} onChange={handleOnChange} />
					</div>
					<div className="w-full md:w-1/3 px-3">
						<select className="form-select border-0 rounded-lg shadow-md w-full" name="gender" value={gender} onChange={handleOnChange}>
							<option value="">Gender</option>
							<option value="M">Male</option>
							<option value="F">Female</option>
						</select>
					</div>
					<div className="w-full md:w-1/3 px-3">
						<select className="form-select border-0 rounded-lg shadow-md w-full" name="status" value={status} onChange={handleOnChange}>
							<option value="">Status</option>
							<option value="0">Inactive</option>
							<option value="1">Active</option>
						</select>
					</div>
				</div>
			</div>
			<div className="w-1/4">
				<div className="flex flex-col justify-between h-full">
					<button className="bg-grey-300 text-white rounded-lg py-2 px-6 mb-6" onClick={handleOnClear}>
						Clear Filter
					</button>
					<button className="bg-primary rounded-lg py-2 px-6 text-white" onClick={handleOnFilter}>
						Apply Filter
					</button>
				</div>
			</div>
		</div>
	)
}

export default UsersSearch
