import React from 'react'

export function Button({ type, handleClick, children }) {
	const defaultStyle = 'ml-3 font-bold rounded-lg py-2 px-6 text-white focus:outline-none'

	const buttonType = {
		primary: 'bg-primary border-primary border-2 text-white',
		secondary: 'border-primary border-solid border-2 text-primary',
		cancel: 'border-2 border-grey-300 bg-grey-300 text-white',
		export: 'bg-greyExport text-white',
	}

	const classname = [defaultStyle, buttonType[type]].join(' ')

	return (
		<div>
			<button className={classname} onClick={handleClick}>
				{children}
			</button>
		</div>
	)
}
