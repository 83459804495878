import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'

const PaginationPage = ({ currentPage, lastPage, url, activeLink }) => {
	const startIndex = 1
	const [startPage, setStartPage] = useState(0)
	const [endPage, setEndPage] = useState(0)

	useEffect(() => {
		if (currentPage > 0) {
			if (lastPage <= 4) {
				setStartPage(1)
				setEndPage(lastPage)
			} else {
				if (currentPage <= 2) {
					setStartPage(1)
					setEndPage(4)
				} else if (currentPage + 2 >= lastPage) {
					let startPage
					let endPage
					startPage = lastPage - 3
					endPage = lastPage
					setStartPage(startPage)
					setEndPage(endPage)
				} else {
					let startPage
					let endPage
					startPage = currentPage - 1
					endPage = currentPage + 2
					setStartPage(startPage)
					setEndPage(endPage)
				}
			}
		}
	}, [currentPage, lastPage])

	const stylePagePrev = ` bg-white text-gray-700 border-solid shadow-lg p-3 rounded block cursor-pointer ${currentPage > 1 ? 'bg-primary text-white' : 'bg-white text-gray-700'}`

	const stylePageNext = ` bg-primary text-white border-solid shadow-lg p-3 rounded block cursor-pointer ${currentPage === lastPage ? 'text-gray-700' : 'bg-white text-gray-700'}`
	return (
		<nav className="block mx-2 md:mx-0">
			<div className="md:flex justify-between">
				<ul className="flex my-6">
					{startIndex > 1 && (
						<Fragment>
							<li className="inline-block mr-4">
								<Link to={`${url}?page=1`} className="p-3 bg-white rounded text-gray-700 block">
									1
								</Link>
							</li>
							<li className="mr-4 inline-block">...</li>
						</Fragment>
					)}
					{[...Array(endPage + 1 - startPage)].map((x, index) => {
						const stylePage = `p-3 rounded shadow-lg border-solid hover:bg-gray-300 ${
							activeLink === `${url}?page=${startPage + index}` ? 'block bg-primary text-white' : 'block bg-white text-gray-700'
						}`
						return (
							<li key={index} className="inline-block mr-2">
								<Link disabled={activeLink === `${url}?page=${startPage + index}`} className={stylePage} to={`${url}?page=${startPage + index}`}>
									{startPage + index}
								</Link>
							</li>
						)
					})}
					{lastPage !== endPage && (
						<Fragment>
							<li className="mx-4 inline-block">...</li>
							<li className="inline-block mr-4">
								<Link className="p-3 bg-white rounded text-gray-700 block" to={`${url}?page=${lastPage}`}>
									{lastPage}
								</Link>
							</li>
						</Fragment>
					)}
				</ul>
				<ul className="flex my-6">
					<li className="inline-block mr-4">
						<Link className={stylePagePrev} disabled={currentPage === 1} to={`${url}?page=${currentPage - 1}`}>
							Previous
						</Link>
					</li>
					<li className="inline-block">
						{currentPage === lastPage ? (
							<button className="bg-white border-solid shadow-lg p-3 rounded block cursor-not-allowed">Next</button>
						) : (
							<Link className={stylePageNext} to={`${url}?page=${currentPage + 1}`}>
								Next
							</Link>
						)}
					</li>
				</ul>
			</div>
		</nav>
	)
}

export default PaginationPage
