import React from 'react'

const InputEmail = ({ error, ...rest }) => {
  const classes = `appearance-none border-2 rounded-lg w-full py-5 px-3 leading-tight focus:outline-none text-center ${
    error ? 'border-red-500' : 'border-secondary'
  }`

  return (
    <div className="mb-6">
      <input type="email" className={classes} {...rest} />
      {error && <div className="text-red-500 text-xs italic">{error}</div>}
    </div>
  )
}

export default InputEmail
