import React, { Component } from 'react'
import { getSmallGroupAttendanceWeek } from 'libs/apis/smallGroup'
import moment from 'moment'
import SelectOptions from 'components/commons/SelectOptions'
import optionsYear from 'services/optionsYear'
import optionsMonth from 'services/optionsMonth'
import BarChart from 'components/skeleton/BarChart'
import SmallGroupAttendanceCart from 'components/smallGroup/SmallGroupAttendanceCart'

class SmallGroupAttendance extends Component {
	state = {
		optionsSelected: '',
		monthFrom: moment().month() + 1,
		monthTo: moment().month() + 1,
		yearFrom: moment().year(),
		yearTo: moment().year(),
		smallGroupAttendance: [],
		isChartBar: true,
		isLoading: false,
		months: optionsMonth(),
		years: optionsYear(),
		data: [],
	}

	async componentDidMount() {
		this.handleLoadData()
	}

	handleLoadData = async () => {
		const { monthFrom, monthTo, yearFrom, yearTo } = this.state
		this.setState({ ...this.state, isLoading: true })
		try {
			let res = await getSmallGroupAttendanceWeek(yearFrom, yearTo, monthFrom, monthTo)
			this.setState({ ...this.state, data: res.data.data, isLoading: false })
		} catch (error) {
			if (error.response && error.response.status === 401) {
				this.props.history.push('/login')
				localStorage.removeItem('token')
				this.setState({ ...this.state, isLoading: false })
			}
		}
	}

	handleRenderCart = () => {
		const { data } = this.state
		let result = []

		let member = {
			name: 'Member',
			type: 'bar',
			x: this.handleValueX(data),
			y: this.handleValueMember(data),
		}

		let visitor = {
			name: 'Visitor',
			type: 'bar',
			x: this.handleValueX(data),
			y: this.handleValueVisitor(data),
		}

		result = [member, visitor]
		if (!data.length) return <div className="text-center">No data available</div>

		return <SmallGroupAttendanceCart data={result} />
	}

	handleValueX = (data) => {
		let x = []
		data.map((value) => x.push(`Week ${value.week}`))
		return x
	}

	handleValueMember = (data) => {
		let member = []
		data.map((value) => member.push(parseInt(value.total_attendance[0].count_total_member_attendance)))
		return member
	}

	handleValueVisitor = (data) => {
		let visitor = []
		data.map((value) => visitor.push(parseInt(value.total_attendance[0].count_total_visitor_attendance)))
		return visitor
	}

	handleToggle = () => {
		this.setState({ isChartBar: !this.state.isChartBar })
	}

	handleChange = ({ currentTarget: input }) => {
		this.setState({ [input.name]: input.value }, () => {
			this.handleLoadData()
		})
	}

	render() {
		const { monthFrom, monthTo, yearFrom, yearTo, months, years, isLoading } = this.state
		if (isLoading) return <BarChart />

		return (
			<div className="bg-white rounded-lg shadow-md mt-6 p-6">
				<h3 className="font-bold mb-6">Small Group Attendance</h3>
				<div className="flex">
					<div className="pr-6">
						<SelectOptions options={months} value={monthFrom} name="monthFrom" onChange={this.handleChange} />
						<label className="text-xs text-gray-500 ml-3">Month From</label>
					</div>
					<div className="pr-6">
						<SelectOptions options={years} value={yearFrom} name="yearFrom" onChange={this.handleChange} />
						<label className="text-xs text-gray-500 ml-3">Year From</label>
					</div>
					<div className="pr-6">
						<SelectOptions options={months} value={monthTo} name="monthTo" onChange={this.handleChange} />
						<label className="text-xs text-gray-500 ml-3">Month To</label>
					</div>
					<div className="pr-6">
						<SelectOptions options={years} value={yearTo} name="yearTo" onChange={this.handleChange} />
						<label className="text-xs text-gray-500 ml-3">Year To</label>
					</div>
				</div>{' '}
				{this.handleRenderCart()}
			</div>
		)
	}
}

export default SmallGroupAttendance
