import React, { Component } from 'react'

class FileUpload extends Component {
	handleOnChange = event => {
		const { field, form } = this.props

		form.setFieldValue(field.name, event.currentTarget.files[0])
	}

	render() {
		const { field } = this.props

		const fileName = field.value
			? field.value.name || field.value.substring(field.value.lastIndexOf('/') + 1)
			: 'Click to upload your file here'

		return (
			<div className="p-0 rounded-none border-0 relative inline-block w-full">
				<input
					type="file"
					name={field.name}
					accept="file/*"
					multiple={false}
					onChange={this.handleOnChange}
					className="relative z-10 w-full h-full opacity-0 py-6 cursor-pointer"
				/>
				<label className="flex items-center justify-center border-2 rounded-lg border-dashed text-center h-full w-full absolute inset-x-0 top-0">
					<span className="text-secondary">{fileName}</span>
				</label>
			</div>
		)
	}
}

export default FileUpload
