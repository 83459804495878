import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as NewsIcon } from 'assets/icons/newsfeed.svg'
import NewsfeedFormik from 'components/formik/NewsfeedFormik'

class CreateNews extends Component {
	render() {
		const church = JSON.parse(localStorage.getItem('church'))
		const name = church.church_name ? church.church_name : '-'
		const breads = [{ url: '', title: 'Create News Item' }]

		return (
			<div className="main-content rounded-lg bg-gray-100 pb-24 md:p-6 md:w-5/6">
				<Helmet>
					<title>{name} - Create News Item</title>
				</Helmet>
				<PageBreadcrumbs icon={NewsIcon} title="Create News Item" breads={breads} />
				<NewsfeedFormik {...this.props} isCreateForm={true} />
			</div>
		)
	}
}

export default CreateNews
