import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Loading from 'components/commons/Loading'
import { findService, updateCounter, updateData } from 'libs/utils/attendanceHelp'
import { saveCounterAttendance } from 'libs/apis/campus'

class AttendanceContainer extends Component {
	state = {
		id: this.props.id,
		year: this.props.year,
		week: this.props.week,
		day: this.props.day,
		hour: this.props.hour,
		minute: this.props.minute,
		isInputted: this.props.isInputted,
		attendances: this.props.attendances,
		isRedirect: false,
		isSubmit: false,
		errorMessage: '',
	}

	handleOnChange = evt => {
		if (evt.target.value) {
			evt.target.value = parseInt(evt.target.value)
		} else {
			evt.target.value = 0
		}

		const service = findService(evt.target.name, this.state.attendances)
		const updatedCounter = updateCounter(parseInt(evt.target.value), service)
		const attendances = updateData(this.state.attendances, updatedCounter)
		this.setState({ attendances })
	}

	handleOnSubmit = () => {
		this.setState({ isSubmit: true }, async () => {
			try {
				const { week, year, attendances } = this.state
				const response = await saveCounterAttendance(week, year, attendances)
				if (response.data.api_status) {
					this.setState({ isInputted: true, isSubmit: false })
				} else {
					this.setState({ isSubmit: false, errorMessage: response.data.message })
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						await localStorage.removeItem('token')
						this.setState({ isRedirect: true })
					} else {
						const message =
							'An unexpected error has occurred. We are currently investigating the issue, please try again later.'
						this.setState({
							isSubmit: false,
							errorMessage: message,
							isRedirect: true,
						})
					}
				}
			}
		})
	}

	handleToggle = () => {
		this.setState({ isInputted: !this.state.isInputted })
	}

	render() {
		const {
			day,
			hour,
			minute,
			attendances,
			isInputted,
			isRedirect,
			errorMessage,
			isSubmit,
		} = this.state

		if (isRedirect) {
			return (
				<Redirect
					to={{
						pathname: '/login',
						state:
							'We could not verify that you are not authorized to process your request',
					}}
				/>
			)
		}

		return (
			<div className="w-1/4">
				{attendances.length > 0 && (
					<div className="mx-2 bg-white p-4 mb-6 rounded-lg shadow-md">
						<div className="font-bold">
							{day} | {hour}:{minute}
						</div>
						<hr className="border-2 border-blueSecondary" />
						{attendances.map(attendance => {
							const styleInputDisable = `appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none border-secondary ${
								isInputted ? 'bg-greySecondary' : 'bg-white'
							}`
							return (
								<div className="mb-6" key={attendance.service_type}>
									<label className="block text-gray-700 text-sm font-bold mb-2">
										{attendance.service_type_name}
									</label>
									<input
										value={attendance.counter}
										className={styleInputDisable}
										type="number"
										name={attendance.service_type_name}
										onChange={this.handleOnChange}
										disabled={isInputted}
										min={0}
									/>
								</div>
							)
						})}
						<div className="text-center">
							{!isInputted ? (
								<SaveButton
									isSubmit={isSubmit}
									handleOnSubmit={this.handleOnSubmit}
								/>
							) : (
								<EditButton handleClick={this.handleToggle} />
							)}
						</div>
						{errorMessage && (
							<div className="text-red-500 text-xs italic">{errorMessage}</div>
						)}
					</div>
				)}
			</div>
		)
	}
}

const SaveButton = ({ isSubmit, handleOnSubmit }) => (
	<button
		className="bg-primary rounded-lg py-2 px-6 text-white focus:outline-none"
		disabled={isSubmit}
		onClick={handleOnSubmit}
	>
		{isSubmit ? <Loading secondary /> : 'Save'}
	</button>
)

const EditButton = ({ handleClick }) => (
	<button
		className="bg-grey-300 text-white rounded-lg py-2 px-6 focus:outline-none"
		onClick={handleClick}
	>
		Edit
	</button>
)

export default AttendanceContainer
