import http from './httpServices'

export const newsfeedList = async (page, filter_status, filter_keyword, order_by, order_dir) => {
  return await http.search(
    '/newsfeed',
    {
      page,
      filter_status,
      filter_keyword,
      order_by,
      order_dir
    },
    localStorage.getItem('token')
  )
}

export const newsfeedArchive = async (id) => {
  return await http.post(`/newsfeed/archive/${id}`, {}, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
}

export const newsfeedDelete = async (id) => {
  return await http.post(`/newsfeed/delete/${id}`, {}, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
}
