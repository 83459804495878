import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as MinistryIcon } from 'assets/icons/ministry.svg'
import Loading from 'components/commons/Loading'
import MinistryDetails from 'components/ministry/MinistryDetails'
import MinistryMemberList from 'components/ministry/MinistryMemberList'
import InviteVolunteer from 'components/ministry/InviteVolunteer'
import { ministryDetail } from 'libs/apis/ministry'
import MinistryRequestList from 'components/ministry/MinistryRequestList'
import queryString from 'query-string'
import Searchbar from 'components/commons/Searchbar'

export const MinistryContext = React.createContext(null)

function ViewMinistry(props) {
	const [ministry, setMinistry] = useState({
		ministry_id: 0,
		name: '',
		description: '',
		status: '',
		image_url: null,
		leaders_name: '',
	})
	const [ministryLeaders, setMinistryLeaders] = useState([])
	const [ministryMembers, setMinistryMembers] = useState([])
	const [ministryRequest, setMinistryRequest] = useState([])
	const [filterKeyword, setFilterKeyword] = useState('')

	const [isLoadingData, setIsLoadingData] = useState(false)
	const [isRedirect, setIsRedirect] = useState(false)
	const [errorMessage, setErrorMessage] = useState(false)
	const [currentPage, setCurrentPage] = useState(1)
	const [lastPage] = useState(1)

	const handleMinistryDetail = React.useCallback(async () => {
		const message = 'An unexpected error has occurred. We are currently investigating the issue, please try again later.'
		try {
			setIsLoadingData(true)
			const response = await ministryDetail(props.match.params.id, filterKeyword)
			const {
				data: {
					api_status,
					data: { ministry_id, ministry_name, description, status, leaders, image_url, leaders_name, members, request },
				},
			} = response

			if (api_status) {
				setMinistryLeaders(leaders)
				setMinistry({
					ministry_id: ministry_id,
					name: ministry_name,
					description: description,
					status: status,
					leaders_name: leaders_name,
					image_url: image_url,
				})
				setMinistryMembers(members)
				setMinistryRequest(request)
				setIsLoadingData(false)
			} else {
				setIsLoadingData(false)
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.removeItem('token')
					setIsRedirect(true)
				} else if (error.response.status >= 500) {
					setErrorMessage(message)
					setIsLoadingData(false)
				}
			}
		}
	}, [props.match.params.id, filterKeyword])

	const handleMinistrySearch = async e => {
		setFilterKeyword(e.target.value)
	}

	const handlePageChange = async page => {
		setCurrentPage(page)
	}

	useEffect(() => {
		handleMinistryDetail()
	}, [filterKeyword, props.match.params.id, handleMinistryDetail])

	const { location } = props
	const parsed = queryString.parse(location.search)
	const active = `${location.pathname}?page=${parsed.page || 1}`

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const displayLoading = isLoadingData && !errorMessage
	const displayError = !isLoadingData && errorMessage
	const displayData = !isLoadingData && !errorMessage
	const breads = [
		{ url: '/ministry', title: 'List Ministry' },
		{ url: '', title: 'View Ministry' },
	]

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<MinistryContext.Provider value={ministry}>
			<div className="main-content bg-gray-200 pb-24 md:p-6 md:w-5/6">
				<Helmet>
					<title>{name} - View Ministry</title>
				</Helmet>
				<PageBreadcrumbs icon={MinistryIcon} title="View Ministry" breads={breads} />

				<div className="flex justify-between">
					<InviteVolunteer ministry_id={ministry.ministry_id} handleMinistryDetail={handleMinistryDetail} />
					<Searchbar filterKeyword={filterKeyword} handleOnSearch={handleMinistrySearch} />
				</div>

				{displayLoading && <Loading />}
				{displayData && (
					<div className="flex mb-4">
						<div className="w-1/4">
							<MinistryDetails {...ministry} />
						</div>

						<div className="w-3/4 ml-4">
							<MinistryMemberList
								currentPage={currentPage}
								lastPage={lastPage}
								activeLink={active}
								url={location.pathname}
								handleMinistryDetail={handleMinistryDetail}
								handlePageChange={handlePageChange}
								members={ministryMembers}
								leaders={ministryLeaders}
							/>
							<hr className="border-2 blue mx-4" />

							<MinistryRequestList
								currentPage={currentPage}
								lastPage={lastPage}
								activeLink={active}
								handleMinistryDetail={handleMinistryDetail}
								handlePageChange={handlePageChange}
								requests={ministryRequest}
								url={location.pathname}
							/>
						</div>
					</div>
				)}
				{displayError && <div className="align-center">{errorMessage}</div>}
			</div>
		</MinistryContext.Provider>
	)
}

export default ViewMinistry
