import axios from 'axios'

export const billingList = async (page, filter_status, order_by, order_dir) => {
  return await axios({
    method: 'get',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    url: `${process.env.REACT_APP_ENDPOINT}/billing`,
    params: {
      page,
      filter_status,
      order_by,
      order_dir
    }
  })
}

export const exportPDF = async (id) => {
  return await axios({
    method: 'get',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    url: `${process.env.REACT_APP_ENDPOINT}/billing/invoice/${id}`
  })
}
