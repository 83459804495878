import React, { Component } from 'react'
import HeadTitle from 'components/commons/HeadTitle'
import BreadCrumb from 'components/commons/Breadcrumb'
import Loading from 'components/commons/Loading'
import Pagination from 'components/commons/Pagination'
import CourseInfoCard from 'components/courses/CourseInfoCard'
import CourseStatusCard from 'components/courses/CourseStatusCard'
import RegistrantTable from 'components/registrants/RegistrantTable'
import AttendanceTable from 'components/registrants/AtttendanceTable'
import QRCodesList from 'components/registrants/QRcodesList'
import { ReactComponent as CourseIcon } from 'assets/icons/classes.svg'
import { ReactComponent as AppliedIcon } from 'assets/icons/applied.svg'
import { ReactComponent as ApprovedIcon } from 'assets/icons/check-approved.svg'
import { ReactComponent as QuotaIcon } from 'assets/icons/quota.svg'
import { ReactComponent as PendingIcon } from 'assets/icons/pending.svg'
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg'
import { ReactComponent as PassFailIcon } from 'assets/icons/pass-fail.svg'
import { courseDashboard, registranstList, attendanceList, registrantExport, attendanceToggle, resendEmail, rejectRegistrant, qrcodelist } from 'services/courseServices'
import alert from 'services/alert'
import Searchbar from 'components/commons/Searchbar'
import Select from 'components/commons/Select'
import { Button } from 'components/commons/Button'
import { constants } from 'constants/constant'

class Registrans extends Component {
	state = {
		isLoading: false,
		courseName: '',
		startDate: '',
		endDate: '',
		startTime: '',
		endTime: '',
		venueName: '',
		venueAddress: '',
		participantQouta: 0,
		registrantCounter: {},
		filterKeyword: '',
		filterStatus: '',
		isExported: false,
		navTab: 'registrant',
		currentPage: 1,
		lastPage: 1,
		sortColumn: { path: '', order: '' },
		sortColumnAttendance: { path: '', order: '' },
		registrants: [],
		attendances: [],
		qrcodes: [],
	}

	componentDidMount() {
		this.setState({ isLoading: true }, async () => {
			try {
				const id = this.props.match.params.id

				const {
					data: { data: dashboard },
				} = await courseDashboard(id)
				this.doLoad()
				this.setState({
					courseName: dashboard.class_detail.title,
					startDate: dashboard.class_detail.start_date,
					endDate: dashboard.class_detail.end_date,
					startTime: dashboard.class_detail.start_time,
					endTime: dashboard.class_detail.end_time,
					venueName: dashboard.class_detail.venue_name,
					venueAddress: dashboard.class_detail.venue_address,
					registrantCounter: dashboard.registrant_counter,
					participantQouta: dashboard.class_detail.participant_quota,
					isLoading: false,
				})
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						return this.props.history.replace('/login')
					}
				}
				this.setState({ isLoading: false })
			}
		})
	}

	doLoad = async (page) => {
		const id = this.props.match.params.id
		const { currentPage, filterKeyword, filterStatus, sortColumn } = this.state
		this.setState({ isLoading: true }, async () => {
			try {
				const {
					data: { data: registrant },
				} = await registranstList(id, page ? page : currentPage, filterStatus, filterKeyword, sortColumn.path, sortColumn.order)
				this.setState({
					isLoading: false,
					currentPage: registrant.current_page,
					lastPage: registrant.last_page,
					registrants: registrant.data,
				})
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						return this.props.history.replace('/login')
					}
				}
				this.setState({ isLoading: false })
			}
		})
	}

	doLoadAttendance = async (page) => {
		const id = this.props.match.params.id
		const { currentPage, filterKeyword, filterStatus, sortColumnAttendance } = this.state

		const status = filterStatus === 'Failed' || filterStatus === 'Passed' || filterStatus === 'Approved' || filterStatus === '' ? filterStatus : ''

		this.setState({ isLoading: true }, async () => {
			try {
				const {
					data: { data: attendance },
				} = await attendanceList(id, page ? page : currentPage, filterKeyword, status, sortColumnAttendance.path, sortColumnAttendance.order)
				this.setState({
					isLoading: false,
					currentPage: attendance.attendance_data.current_page,
					lastPage: attendance.attendance_data.last_page,
					attendances: attendance.attendance_data.data,
					sessions: attendance.number_of_session,
				})
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						return this.props.history.replace('/login')
					}
				}
				this.setState({ isLoading: false })
			}
		})
	}

	doLoadQR = () => {
		this.setState({ isLoading: true }, async () => {
			try {
				const id = this.props.match.params.id
				const { data: qrcodes } = await qrcodelist(id)
				this.setState({ isLoading: false, qrcodes: qrcodes.data })
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						return this.props.history.replace('/login')
					}
				}
				this.setState({ isLoading: false })
			}
		})
	}

	handlePageChange = (page) => {
		this.setState({ currentPage: page }, async () => {
			this.doLoad()
		})
	}

	handlePageChangeAttendance = (page) => {
		this.setState({ currentPage: page }, async () => {
			this.doLoadAttendance(this.state.currentPage)
		})
	}

	handleSearch = ({ currentTarget: input }) => {
		this.setState({ [input.name]: input.value, currentPage: 1 }, () => {
			if (this.state.navTab === 'attendance') {
				this.doLoadAttendance(1)
			} else {
				this.doLoad()
			}
		})
	}

	handleOnToogle = (event) => {
		this.setState({ navTab: event.target.id }, () => {
			if (this.state.navTab === 'registrant') {
				this.doLoad(1)
			} else if (this.state.navTab === 'attendance') {
				this.doLoadAttendance(1)
			} else if (this.state.navTab === 'checkin') {
				this.doLoadQR()
			}
		})
	}

	handleExport = () => {
		this.setState({ isExported: true }, async () => {
			try {
				const { filterKeyword, filterStatus, sortColumn } = this.state
				const { data } = await registrantExport(this.props.match.params.id, filterKeyword, filterStatus, sortColumn.order, sortColumn.path)
				if (data.api_status) {
					const link = document.createElement('a')
					link.href = data.data
					document.body.appendChild(link)
					link.click()
				}
				this.setState({ isExported: false })
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						return this.props.history.replace('/login')
					}
				}
				this.setState({ isExported: false })
			}
		})
	}

	handleSort = (sortColumn) => {
		this.setState({ sortColumn }, () => {
			this.doLoad()
		})
	}

	handleResendEmail = async (registrant) => {
		const response = await alert.confirmResendEmail()
		if (response.value) {
			try {
				const { data: response } = await resendEmail(registrant.id)
				if (response.api_status) {
					alert.succeed('Success', 'Email has been sent.')
				} else {
					alert.failed('Failed', response.message)
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						return this.props.history.replace('/login')
					}
				}
			}
		}
	}

	handleReject = async (registrant) => {
		const response = await alert.confirmReject()
		if (response.value) {
			try {
				const { data: response } = await rejectRegistrant(registrant.id)
				if (response.api_status) {
					if (this.state.navTab === 'registrant') {
						const registrants = [...this.state.registrants]
						const index = registrants.indexOf(registrant)
						registrants[index] = { ...registrants[index] }
						registrants[index].status = 'Rejected'
						this.setState({ registrants })
					} else {
						const { currentPage, lastPage, attendances } = this.state
						if (currentPage === lastPage && attendances.length === 1) {
							this.setState({ currentPage: currentPage - 1 })
						}
						this.doLoadAttendance(this.state.currentPage)
					}
				} else {
					alert.failed('Failed', response.message)
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						return this.props.history.replace('/login')
					}
				}
			}
		}
	}

	handleCheck = async (registrant, session) => {
		try {
			const id = this.props.match.params.id
			const { data: response } = await attendanceToggle(id, registrant.id, session)
			if (response.api_status) {
				const attendances = [...this.state.attendances]

				const index = attendances.indexOf(registrant)

				attendances[index] = { ...attendances[index] }
				attendances[index].attendance.push(session)
				this.setState({ attendances })
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					return this.props.history.replace('/login')
				}
			}
		}
	}

	handleUnCheck = async (registrant, session) => {
		try {
			const id = this.props.match.params.id
			const { data: response } = await attendanceToggle(id, registrant.id, session)
			if (response.api_status) {
				const attendances = [...this.state.attendances]

				const index = attendances.indexOf(registrant)

				attendances[index] = { ...attendances[index] }
				const indexArr = attendances[index].attendance.indexOf(session)

				if (indexArr > -1) {
					attendances[index].attendance.splice(indexArr, 1)
				}

				this.setState({ attendances })
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					return this.props.history.replace('/login')
				}
			}
		}
	}

	renderOverview() {
		const { courseName, startDate, endDate, startTime, endTime, venueName, venueAddress, participantQouta, registrantCounter } = this.state

		return (
			<div className="flex -mx-2">
				<div className="md:w-4/12 px-2">
					<CourseInfoCard
						color="bg-greyExport"
						courseName={courseName}
						startDate={startDate}
						endDate={endDate}
						startTime={startTime}
						endTime={endTime}
						venueName={venueName}
						venueAddress={venueAddress}
					/>
				</div>
				<div className="md:w-8/12 px-2">
					<div className="flex flex-wrap -mb-4 content-between h-full">
						<CourseStatusCard color="bg-yellow-300" title="Applied" value={registrantCounter.applied} icon={<AppliedIcon fill="#fde81a" />} />
						<CourseStatusCard color="bg-green-300" title="Approved" value={registrantCounter.approved} icon={<ApprovedIcon fill="#58c340" />} />
						<CourseStatusCard color="bg-primary" title="Quota" value={participantQouta} icon={<QuotaIcon fill="#4da5dc" />} />
						<CourseStatusCard color="bg-grey-300" title="Pending" value={registrantCounter.pending} icon={<PendingIcon fill="#989898" />} />
						<CourseStatusCard color="bg-red-500" title="Rejected" value={registrantCounter.rejected} icon={<CancelIcon fill="#e34f4f" />} />
						<CourseStatusCard
							color="bg-black"
							title="Pass | Fail"
							// value={registrantCounter.passed || registrantCounter.failed ? `${registrantCounter.passed} | ${registrantCounter.failed}` : ''}
							icon={<PassFailIcon fill="#404040" />}
						/>
					</div>
				</div>
			</div>
		)
	}

	renderRegistrantHelper() {
		const { filterKeyword, filterStatus, isExported } = this.state

		return (
			<div className="flex mb-6 mt-6">
				<Searchbar filterKeyword={filterKeyword} filterStatus={filterStatus} handleOnSearch={this.handleSearch} />
				<Select options={constants.options2} name="filterStatus" value={filterStatus} onChange={this.handleSearch} />
				<div className="inline-block m-auto" />
				<div className="inline-block">
					{/* <button className="bg-greyExport text-white rounded-lg py-2 px-6" onClick={this.handleExport} disabled={isExported}>
						{isExported ? <Loading secondary /> : 'Export'}
					</button> */}
					<Button type="export" handleClick={this.handleExport}>
						{isExported ? <Loading secondary /> : 'Export'}
					</Button>
				</div>
			</div>
		)
	}

	renderNavTab() {
		const navTab = this.state.navTab
		const registrantStyle = `nav-link ${navTab === 'registrant' ? 'active' : ''}`.trim()
		const attendanceStyle = `nav-link ${navTab === 'attendance' ? 'active' : ''}`.trim()
		const checkInStyle = `nav-link ${navTab === 'checkin' ? 'active' : ''}`.trim()

		return (
			<ul className="nav nav-tabs">
				<li className="nav-item">
					<div className={registrantStyle} id="registrant" onClick={this.handleOnToogle}>
						REGISTRANT
					</div>
				</li>
				<li className="nav-item">
					<div className={attendanceStyle} id="attendance" onClick={this.handleOnToogle}>
						ATTENDANCE
					</div>
				</li>
				<li className="nav-item">
					<div className={checkInStyle} id="checkin" onClick={this.handleOnToogle}>
						CHECK-IN
					</div>
				</li>
			</ul>
		)
	}

	renderData() {
		const { length: count } = this.state.registrants
		const { currentPage, lastPage, registrants, sortColumn, isLoading } = this.state

		if (isLoading) return <Loading />
		if (!count) return <div className="text-center">No data available</div>
		return (
			<div>
				<RegistrantTable registrants={registrants} sortColumn={sortColumn} onSort={this.handleSort} onResendEmail={this.handleResendEmail} onReject={this.handleReject} />
				<Pagination currentPage={currentPage} lastPage={lastPage} onPageChange={this.handlePageChange} />
			</div>
		)
	}

	renderAttendance() {
		const { length: count } = this.state.attendances
		const { currentPage, lastPage, attendances, sortColumnAttendance, isLoading, sessions } = this.state

		if (isLoading) return <Loading />
		if (!count) return <div className="text-center">No data available</div>
		return (
			<div>
				<AttendanceTable
					attendances={attendances}
					sortColumn={sortColumnAttendance}
					onSort={this.handleSort}
					sessions={sessions}
					handleCheck={this.handleCheck}
					handleUnCheck={this.handleUnCheck}
					onResendEmail={this.handleResendEmail}
					onReject={this.handleReject}
				/>
				<Pagination currentPage={currentPage} lastPage={lastPage} onPageChange={this.handlePageChangeAttendance} />
			</div>
		)
	}

	renderQR() {
		const { length: count } = this.state.qrcodes
		const { isLoading, qrcodes } = this.state

		if (isLoading) return <Loading />
		if (!count) return <div className="text-center">No data available</div>
		return (
			<div className="bg-white shadow-md">
				<div className="p-4">
					<div className="flex flex-wrap -mb-4 -mx-2">
						{qrcodes.map((qrcode) => (
							<QRCodesList key={qrcode.qrcode} qrcode={qrcode} />
						))}
					</div>
				</div>
			</div>
		)
	}

	render() {
		const name = JSON.parse(localStorage.getItem('church')).church_name ? JSON.parse(localStorage.getItem('church')).church_name : '-'
		const breads = [
			{ path: '/classes', label: 'List of All Classes' },
			{
				key: 'listofall',
				label: 'Registrant List',
			},
		]
		return (
			<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
				<HeadTitle title={`${name} - Class`} />
				<BreadCrumb title="Registrant List" breads={breads} icon={CourseIcon} />
				{this.renderOverview()}
				{this.renderRegistrantHelper()}
				{this.renderNavTab()}
				{this.state.navTab === 'registrant' && this.renderData()}
				{this.state.navTab === 'attendance' && this.renderAttendance()}
				{this.state.navTab === 'checkin' && this.renderQR()}
			</div>
		)
	}
}

export default Registrans
