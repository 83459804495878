import axios from 'axios'

const updateUserStatus = async (user_ids, user_status) =>
  await axios({
    method: 'post',
    data: { user_ids, user_status },
    url: `${process.env.REACT_APP_ENDPOINT}/users/update/status/confirm`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })

export default updateUserStatus
