import React, { useContext } from 'react'
import { assignMember, rejectMember } from 'libs/apis/ministry'
import { MinistryContext } from 'screens/ministry/ViewMinistry'
import MinistryCard from './MinistryCard'
import alert from 'services/alert'
import PaginationPage from 'components/commons/PaginationPage'

function MinistryRequestList(props) {
	const { requests } = props
	const { ministry_id } = useContext(MinistryContext)

	const handleAccept = async user_id => {
		/*	
			ministry_id (from ministryDetailsContext)
			user_id (from props.user_id)
			
		*/
		let confirm = await alert.confirmAccept()
		if (confirm.value) {
			let res = await assignMember(ministry_id, user_id)

			if (res.data.api_status) {
				alert.succeed(`Success`, `Request has been accepted`)
				props.handleMinistryDetail()
			}
		}
	}

	const handleReject = async user_id => {
		/*	
			ministry_id (from ministryDetailsContext)
			user_id (from props.user_id)
		*/
		let confirm = await alert.confirmReject()
		if (confirm.value) {
			let res = await rejectMember(ministry_id, user_id)

			if (res.data.api_status) {
				alert.succeed(`Success`, `Request has been rejected`)
				props.handleMinistryDetail()
			}
		}
	}

	return (
		<div>
			{requests.length !== 0 && (
				<div>
					<h2 className="mt-3 mb-3 font-bold">REQUEST</h2>
					<div className="flex flex-wrap">
						{requests.map((data, idx) => (
							<MinistryCard
								key={idx}
								{...data}
								handleAccept={handleAccept}
								handleReject={handleReject}
							/>
						))}
					</div>

					<PaginationPage
						currentPage={props.currentPage}
						lastPage={props.lastPage}
						activeLink={props.activeLink}
						url={props.url}
					/>
				</div>
			)}
		</div>
	)
}

export default MinistryRequestList
