import React, { Component } from 'react'
import HeadTitle from 'components/commons/HeadTitle'
import BreadCrumb from 'components/commons/Breadcrumb'
import { ReactComponent as NewsIcon } from 'assets/icons/newsfeed.svg'
import NewsfeedTable from 'components/devotions/NewsfeedTable'
import PaginationPage from 'components/commons/PaginationPage'
import Loading from 'components/commons/Loading'
import { newsfeedList, newsfeedArchive, newsfeedDelete } from 'services/newsServices'
import alert from 'services/alert'
import queryString from 'query-string'
import Searchbar from 'components/commons/Searchbar'
import Select from 'components/commons/Select'
import { constants } from 'constants/constant'

class News extends Component {
	state = {
		filterKeyword: '',
		filterStatus: '',
		newsfeeds: [],
		currentPage: 1,
		lastPage: 1,
		sortColumn: { path: '', order: '' },
		isLoading: false,
	}

	componentDidMount() {
		this.doLoad()
	}

	doLoad = () => {
		this.setState({ isLoading: true }, async () => {
			try {
				const { currentPage, filterStatus, filterKeyword, sortColumn } = this.state
				const {
					data: { data: result },
				} = await newsfeedList(currentPage, filterStatus, filterKeyword, sortColumn.path, sortColumn.order)
				this.setState({
					isLoading: false,
					newsfeeds: result.data,
					currentPage: result.current_page,
					lastPage: result.last_page,
				})
			} catch (error) {
				if (error.response && error.response.status === 401) {
					this.props.history.replace('/login')
				}
			}
		})
	}

	handlePageChange = (page) => {
		this.setState({ currentPage: page }, async () => {
			this.doLoad()
		})
	}

	handleSearch = ({ currentTarget: input }) => {
		this.setState({ [input.name]: input.value, currentPage: 1 }, () => {
			this.doLoad()
		})
	}

	handleDelete = async (devotion) => {
		const { value } = await alert.confirmDelete()
		if (value) {
			this.setState({ isLoading: true }, async () => {
				try {
					const { data } = await newsfeedDelete(devotion.news_id)
					if (data.api_status) {
						const { currentPage, lastPage, newsfeeds } = this.state
						if (currentPage === lastPage && newsfeeds.length === 1) {
							this.setState({ currentPage: currentPage - 1 })
						}
						this.doLoad()
					} else {
						this.setState({ isLoading: false })
					}
				} catch (error) {}
			})
		}
	}

	handleArchive = async (newsfeed) => {
		const { value } = await alert.confirmArchive()
		if (value) {
			this.setState({ isLoading: true }, async () => {
				try {
					const { data } = await newsfeedArchive(newsfeed.news_id)
					if (data.api_status) {
						const newsfeeds = [...this.state.newsfeeds]
						const index = newsfeeds.indexOf(newsfeed)
						newsfeeds[index] = { ...newsfeeds[index] }
						newsfeeds[index].status = 'Archived'
						this.setState({ newsfeeds })
					}
					this.setState({ isLoading: false })
				} catch (error) {}
			})
		}
	}

	handleSort = (sortColumn) => {
		this.setState({ sortColumn }, () => {
			this.doLoad()
		})
	}

	renderData = () => {
		const { length: count } = this.state.newsfeeds
		const { location } = this.props
		const parsed = queryString.parse(location.search)
		const active = `${location.pathname}?page=${parsed.page || 1}`
		const { currentPage, lastPage, newsfeeds, isLoading, sortColumn } = this.state

		if (isLoading) return <Loading />
		if (!count) return <div className="text-center">No data available</div>
		return (
			<div>
				<NewsfeedTable newsfeeds={newsfeeds} sortColumn={sortColumn} onSort={this.handleSort} onArchive={this.handleArchive} onDelete={this.handleDelete} />
				<PaginationPage currentPage={currentPage} lastPage={lastPage} activeLink={active} url={location.pathname} />
			</div>
		)
	}

	render() {
		const name = JSON.parse(localStorage.getItem('church')).church_name ? JSON.parse(localStorage.getItem('church')).church_name : '-'
		const breads = [{ key: 'listofall', label: 'List of All' }]

		return (
			<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
				<HeadTitle title={`${name} - News`} />
				<BreadCrumb title="List of All" breads={breads} icon={NewsIcon} />
				<div className="flex flex-wrap md:-mx-2 mb-6 pl-2">
					<Searchbar filterKeyword={this.state.filterKeyword} handleOnSearch={this.handleSearch} />
					<Select name="filterStatus" value={this.state.filterStatus} onChange={this.handleSearch} options={constants.options1} />
				</div>
				{this.renderData()}
			</div>
		)
	}
}

export default News
