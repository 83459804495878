import React from 'react'
import SGUserCard from 'components/widgets/SGUserCard'

const SGMemberList = ({ leaders, members, probations, sgId }) => (
  <div>
    <div style={{ marginBottom: '12px', fontWeight: 'bold', display: 'grid' }}>MEMBERS</div>
    <div className="flex flex-wrap">
      {leaders.length > 0 &&
        leaders.map((item) => (
          <SGUserCard
            sgId={sgId}
            key={item.user_id}
            id={item.user_id}
            role={item.role}
            color={item.role_color}
            profile={item.profile_photo}
            fullname={item.fullname}
            dob={item.dob}
            email={item.email}
            phone={item.phone_number}
          />
        ))}
      {members.length > 0 &&
        members.map((item) => (
          <SGUserCard
            sgId={sgId}
            key={item.user_id}
            id={item.user_id}
            role={item.role}
            color={item.role_color}
            profile={item.profile_photo}
            fullname={item.fullname}
            dob={item.dob}
            email={item.email}
            phone={item.phone_number}
          />
        ))}
    </div>

    {probations.length > 0 && (
      <div style={{ marginTop: '12px', marginBottom: '12px', fontWeight: 'bold', display: 'grid' }}>NEW MEMBERS</div>
    )}
    <div className="flex flex-wrap">
      {probations.length > 0 &&
        probations.map((item) => (
          <SGUserCard
            sgId={sgId}
            key={item.user_id}
            id={item.user_id}
            role={item.role}
            color={item.role_color}
            profile={item.profile_photo}
            fullname={item.fullname}
            dob={item.dob}
            email={item.email}
            phone={item.phone_number}
          />
        ))}
    </div>
  </div>
)

export default SGMemberList
