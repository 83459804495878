export const constants = {
	campaigns: [
		{ id: 1, campaign_type: 'Email', title: 'Create campaign by email' },
		{ id: 2, campaign_type: 'Pushnotif', title: 'Create campaign notification' },
		{ id: 3, campaign_type: 'ALL', title: 'Create both campaigns' },
	],
	genders: [
		{ value: 'A', gender: 'All', title: 'All' },
		{ value: 'M', gender: 'Male', title: 'Male' },
		{ value: 'F', gender: 'Female', title: 'Female' },
	],
	maritalStatus: [
		{ id: 1, maritalValue: 'Single' },
		{ id: 2, maritalValue: 'Married' },
		{ id: 3, maritalValue: 'Divorced' },
		{ id: 4, maritalValue: 'Widowed' },
	],
	days: [
		{ id: 1, title: 'Monday' },
		{ id: 2, title: 'Tuesday' },
		{ id: 3, title: 'Wednesday' },
		{ id: 4, title: 'Thursday' },
		{ id: 5, title: 'Friday' },
		{ id: 6, title: 'Saturday' },
		{ id: 0, title: 'Sunday' },
	],
	options1: [
		{
			value: 'Published',
			show_as: 'Published',
		},
		{
			value: 'Draft',
			show_as: 'Draft',
		},
		{
			value: 'Archived',
			show_as: 'Archived',
		},
	],
	options2: [
		{ value: 'Applied', show_as: 'Applied' },
		{ value: 'Approved', show_as: 'Approved' },
		{ value: 'Rejected', show_as: 'Rejected' },
		// { value: 'Failed', show_as: 'Failed' },
		// { value: 'Passed', show_as: 'Passed' },
	],
}
