import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import ScrollToTop from './ScrollToTop'
import LoginForm from './screens/auth/loginForm'
import ForgotForm from './screens/auth/forgotForm'
import ResetForm from './screens/auth/resetForm'
import DataReport from './screens/home/DataReport'
import News from './screens/newsfeed/News'
import Devotions from './screens/devotion/Devotions'
import CreateUpdateCourseForm from './screens/courses/CreateUpdateCourseForm'
import Courses from './screens/courses/Courses'
import Registrants from './screens/courses/Registrants'
import EventRegistrants from './screens/events/EventRegistrants'
import { InputData, SpecialService, FirstTimer } from './screens/home'
import { CreateNews, EditNews } from './screens/newsfeed'
import { EditDev, CreateDev } from './screens/devotion'
import { ListUsers, ViewUser } from './screens/userManagement/index'
import { ChurchProfile } from './screens/settings'
import { PrayerRequest, PraiseReport, RequestHistory, ReportHistory } from 'screens/prayerPraise'
import { ListSmallGroup, ViewRequest, CreateSmallGroup, EditSmallGroup, SmallGroupViewUser, ViewSmallGroup } from 'screens/smallGroup'
import { ViewQR } from 'screens/courses'
import { ListEvent, EditEvent, CreateEvent } from 'screens/events'
import SideNav from './components/sidenav/SideNav'
import Billing from './screens/Billing'
import 'flatpickr/dist/flatpickr.min.css'
import CreateCampaign from './screens/campaign/CreateCampaign'
import ListEmailCampaign from './screens/campaign/ListEmailCampaign'
import ListOfPushNotif from './screens/campaign/ListPushNotif'
import ListAttendance from 'screens/smallGroup/ListAttendance'
import AttendanceDetails from 'screens/smallGroup/AttendanceDetails'
import EditCampaingn from 'screens/campaign/EditCampaingn'
import EditPushNotif from 'screens/campaign/EditPushNotif'
import ListFile from 'screens/file/ListFile'
import ListMinistry from 'screens/ministry/ListMinistry'
import CreateMinistry from 'screens/ministry/CreateMinistry'
import ViewMinistry from 'screens/ministry/ViewMinistry'
import EditMinistry from 'screens/ministry/EditMinistry'

function App() {
	return (
		<ScrollToTop>
			<Switch>
				<PrivateRoute exact path="/" component={DataReport} />
				<PrivateRoute exact path="/input-data" component={InputData} />
				<PrivateRoute exact path="/input-data/special-service" component={SpecialService} />
				<PrivateRoute exact path="/first-timer" component={FirstTimer} />

				<PrivateRoute exact path="/newsfeed/create" component={CreateNews} />
				<PrivateRoute exact path="/newsfeed/list" component={News} />
				<PrivateRoute exact path="/newsfeed/edit/:id" component={EditNews} />

				<PrivateRoute exact path="/devotion/create" component={CreateDev} />
				<PrivateRoute exact path="/devotion/list" component={Devotions} />
				<PrivateRoute exact path="/devotion/edit/:id" component={EditDev} />

				<PrivateRoute exact path="/classes/create" component={CreateUpdateCourseForm} />
				<PrivateRoute exact path="/classes" component={Courses} />
				<PrivateRoute exact path="/classes/edit/:id" component={CreateUpdateCourseForm} />
				<PrivateRoute exact path="/classes/registrant/:id" component={Registrants} />

				<PrivateRoute exact path="/prayer-praise/prayer-request" component={PrayerRequest} />
				<PrivateRoute exact path="/prayer-praise/praise-report" component={PraiseReport} />

				<PrivateRoute exact path="/request-history/:id" component={RequestHistory} />
				<PrivateRoute exact path="/report-history/:id" component={ReportHistory} />

				<PrivateRoute exact path="/user-management/list" component={ListUsers} />
				<PrivateRoute exact path="/user-management/users/:id" component={ViewUser} />

				<PrivateRoute exact path="/small-group/create" component={CreateSmallGroup} />
				<PrivateRoute exact path="/small-group/list" component={ListSmallGroup} />
				<PrivateRoute exact path="/small-group/edit/:id" component={EditSmallGroup} />
				<PrivateRoute exact path="/small-group/request/:id" component={ViewRequest} />
				<PrivateRoute exact path="/small-group/view/:id" component={ViewSmallGroup} />
				<PrivateRoute exact path="/small-group/view-member/:sgId/:id" component={SmallGroupViewUser} />
				<PrivateRoute exact path="/smallgroup/attendance" component={ListAttendance} />
				<PrivateRoute exact path="/smallgroup/attendance-detail/:id" component={AttendanceDetails} />
				<PrivateRoute exact path="/smallgroup/file" component={ListFile} />

				<PrivateRoute exact path="/settings/billing" component={Billing} />
				<PrivateRoute exact path="/settings/church" component={ChurchProfile} />

				<PrivateRoute exact path="/events/list" component={ListEvent} />
				<PrivateRoute exact path="/events/edit/:id" component={EditEvent} />
				<PrivateRoute exact path="/events/create" component={CreateEvent} />
				<PrivateRoute exact path="/events/registrant/:id" component={EventRegistrants} />

				<PrivateRoute exact path="/campaign" component={ListEmailCampaign} />
				<PrivateRoute exact path="/campaign/create" component={CreateCampaign} />
				<PrivateRoute exact path="/campaign/:id" component={EditCampaingn} />

				<PrivateRoute exact path="/pushnotif" component={ListOfPushNotif} />
				<PrivateRoute exact path="/pushnotif/create" component={CreateCampaign} />
				<PrivateRoute exact path="/pushnotif/:id" component={EditPushNotif} />
				<PrivateRoute exact path="/ministry" component={ListMinistry} />
				<PrivateRoute exact path="/ministry/create" component={CreateMinistry} />
				<PrivateRoute exact path="/ministry/detail/:id" component={ViewMinistry} />
				<PrivateRoute exact path="/ministry/edit/:id" component={EditMinistry} />

				<Route path="/view-qr/:eventname/:qrcode/:starttime/:endtime" component={ViewQR} />
				<Route path="/login" component={LoginForm} />
				<Route path="/forgot-password" component={ForgotForm} />
				<Route path="/password/reset" component={ResetForm} />
			</Switch>
		</ScrollToTop>
	)
}

const PrivateRoute = ({ component: Component, ...rest }) => {
	const token = localStorage.getItem('token')
	const access_list = localStorage.getItem('access_list')
	return (
		<Route
			{...rest}
			render={(props) =>
				token !== null && token !== undefined && token && access_list !== null && access_list !== undefined && access_list ? (
					<div className="flex flex-col md:flex-row">
						<SideNav {...props} />
						<Component {...props} />
					</div>
				) : (
					<Redirect to={{ pathname: '/login', state: { from: props.location } }} />
				)
			}
		/>
	)
}

export default App
