import React from 'react'

const SelectOptions = ({ options, value, onChange, name, label, disabled }) => {
	return (
		<div className="w-full">
			{label && <label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>}
			<select className="form-select block w-full border-secondary border-2 rounded-lg" name={name} value={value} onChange={onChange}>
				<option value="" disabled={disabled}>
					Choose
				</option>
				{options.map((option, idx) => (
					<option disabled={disabled} key={idx || option.value} value={option.id || option.value || option.campaign_type}>
						{option.label || option.title || option.campaign_type}
					</option>
				))}
			</select>
		</div>
	)
}

export default SelectOptions
