import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import alert from 'services/alert'
import { getRole } from 'libs/apis/smallGroup'
import { updateRoleResources, renameFile, getFileRole } from 'libs/apis/file'
import { Button } from 'components/commons/Button'
import Loading from 'components/commons/Loading'
import InputCheckBox from 'components/commons/InputCheckBox'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		height: '390px',
		width: '550px',
		transform: 'translate(-50%, -50%)',
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.6)',
	},
}

export default function FileViewEdit(props) {
	const [roles, setRoles] = useState([])
	const [selectedRoles, setSelectedRoles] = useState([])
	const [fileName, setFileName] = useState(props.fileName)
	const [visibleToAll, setVisibleToAll] = useState(false)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		const handleGetRoles = async () => {
			let res = await getRole()
			setRoles(res.data.data)
		}
		handleGetRoles()
	}, [])

	useEffect(() => {
		const handleGetFileRole = async () => {
			let res = await getFileRole(props.fileId)
			setSelectedRoles(res.data.data)
		}
		handleGetFileRole()
	}, [props.fileId])

	const handleTogleModal = () => {
		setModalIsOpen(!modalIsOpen)
	}

	const handleCheckbox = (e) => {
		if (!e.target.checked) {
			setVisibleToAll(false)
			setSelectedRoles(removeItem(selectedRoles, parseInt(e.target.value)))
		} else {
			setVisibleToAll(false)
			setSelectedRoles([...selectedRoles, parseInt(e.target.value)])
		}
	}

	const handleCheckSendAll = () => {
		let selectedRoles = roles.map((role) => role.role_id)

		if (visibleToAll) {
			setVisibleToAll(false)
			setSelectedRoles([])
		} else {
			setVisibleToAll(true)
			setSelectedRoles(selectedRoles)
		}
	}

	const handleChangeFileName = (e) => {
		setFileName(e.target.value)
	}

	const handleSubmit = async () => {
		const { fileId, handleLoadData } = props

		if (selectedRoles.length === 0) {
			this.setState({ ...this.state, isLoading: true, visibleToAll: true })
			setIsLoading(true)
			setVisibleToAll(true)

			let resRename = await renameFile(fileId, fileName)
			let resUpdate = await updateRoleResources(fileId, [])

			if (resRename.data.api_status && resUpdate.data.api_status) {
				alert.succeed(`Success`, `Your file successfuly updated`)
				handleLoadData()
				setIsLoading(false)
			}
		} else {
			setIsLoading(true)
			setVisibleToAll(false)

			let resRename = await renameFile(fileId, fileName)
			let resUpdate = await updateRoleResources(fileId, selectedRoles)

			if (resRename.data.api_status && resUpdate.data.api_status) {
				alert.succeed(`Success`, `Your file successfuly updated`)
				handleLoadData()

				setIsLoading(false)
			}
		}
	}

	const removeItem = (arr, value) => {
		return arr.filter(function (ele) {
			return ele !== value
		})
	}

	return (
		<div>
			<button onClick={handleTogleModal}> View {'&'} Edit</button>
			<Modal isOpen={modalIsOpen} style={customStyles} contentLabel="Example Modal" appElement={document.getElementById('root')}>
				<h2 className="font-bold mb-2">FILE EDIT</h2>
				<hr className="border-2 border-blueSecondary" />
				<div className="flex mt-3 ml-4">
					<div className="fa fa-file mt-1" />
					<input className="ml-5 text-black w-4/5" type="text" name="file" placeholder={fileName} value={fileName} disabled={true} />
				</div>

				<hr className="border-2 border-blueSecondary mt-3" />

				<div className="flex mt-3 ml-4">
					<div className="fa fa-file mt-1" />
					<input className="ml-5 text-black" type="text" name="fileName" placeholder="New Name" onChange={handleChangeFileName} />
				</div>
				<hr className="border-2 border-blueSecondary mt-3" />
				<h2 className="font-bold mb-2 ml-5 mt-2">VISIBLE TO:</h2>

				<div className="flex ml-12">
					<div className="flex-col">
						<InputCheckBox value={visibleToAll} onChange={handleCheckSendAll} checked={visibleToAll || roles.length === selectedRoles.length} label="All" className="mb-4" />

						{roles.slice(0, roles.length / 2).map((val, idx) => (
							<InputCheckBox
								id={val.role_id}
								key={val.role_id}
								value={val.role_id}
								onChange={handleCheckbox}
								checked={selectedRoles.includes(val.role_id)}
								label={val.show_as}
								className="mb-4"
							/>
						))}
					</div>
					<div className="flex-col ml-12">
						{roles.slice(roles.length / 2).map((val, idx) => (
							<InputCheckBox
								id={val.role_id}
								key={val.role_id}
								value={val.role_id}
								onChange={handleCheckbox}
								checked={selectedRoles.includes(val.role_id)}
								label={val.show_as}
								className="mb-4"
							/>
						))}
					</div>
				</div>

				<div className="flex justify-center mt-4">
					<Button isLoading={isLoading} type="cancel" handleClick={handleTogleModal}>
						{isLoading ? <Loading secondary /> : 'Close'}
					</Button>
					<Button type="primary" handleClick={handleSubmit}>
						{isLoading ? <Loading secondary /> : 'Save'}
					</Button>
				</div>
			</Modal>
		</div>
	)
}
