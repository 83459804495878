import React from 'react'

const InputCheckBox = ({ name, value, onChange, checked, label }) => (
	<div className="mb-1">
		<input type="checkbox" className="form-checkbox border-2" name={name} value={value} onChange={onChange} checked={checked} />
		{label && <span className="text-gray-700 text-sm font-bold ml-2">{label}</span>}
	</div>
)

export default InputCheckBox
