import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import moment from 'moment'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as PrayerPraiseIcon } from 'assets/icons/prayerpraise.svg'
import Loading from 'components/commons/Loading'
import PaginationPage from 'components/commons/PaginationPage'
import PrayerReqTable from 'components/prayerRequest/PrayerReqTable'
import PrayerReqSearch from 'components/prayerRequest/PrayerReqSearch'
import { prayerCategories, prayerRequest, exportPrayer } from 'libs/apis/prayerpraise'

export default function PrayerRequest(props) {
	const [isLoad, setIsLoad] = useState(false)
	const [isRedirect, setIsRedirect] = useState(false)
	const [error, setError] = useState('')
	const [data, setData] = useState([])
	const [dateFrom, setDateFrom] = useState('')
	const [dateTo, setDateTo] = useState('')
	const [category, setCategory] = useState('')
	const [categories, setCategories] = useState([])
	const [orderBy, setOrderBy] = useState('')
	const [orderDir, setOrderDir] = useState('')
	const [currentPage, setCurrentPage] = useState(0)
	const [lastPage, setLastPage] = useState(1)
	const [isExport, setIsExport] = useState(false)

	const church = JSON.parse(localStorage.getItem('church'))
	const parsed = queryString.parse(props.location.search)

	const breads = [{ url: '', title: 'Praise Report' }]
	const active = `${props.location.pathname}?page=${parsed.page || 1}`
	const page = parsed.page || 1

	const handleSort = sort => {
		if (orderBy !== sort) {
			setOrderBy(sort)
			setOrderDir('asc')
		} else {
			if (orderDir === 'asc') {
				setOrderBy(sort)
				setOrderDir('desc')
			} else if (orderDir === 'desc') {
				setOrderBy(sort)
				setOrderDir('asc')
			}
		}
	}

	const handleDate = date => {
		if (date.length === 2) {
			setDateFrom(moment(date[0]).format('YYYY-MM-DD'))
			setDateTo(moment(date[1]).format('YYYY-MM-DD'))
		} else if (!date.length) {
			setDateFrom('')
			setDateTo('')
		}
	}

	const handleCategory = event => {
		setCategory(event.target.value)
	}

	const handleExport = async () => {
		try {
			setIsExport(true)

			const response = await exportPrayer(dateFrom, dateTo, category, orderBy, orderDir)

			if (response.data.api_status) {
				const link = document.createElement('a')
				link.href = response.data.data
				document.body.appendChild(link)
				link.click()
				setIsExport(false)
			} else {
				setIsExport(false)
			}
		} catch (error) {
			props.history.push('/prayer-praise/prayer-request')
		}
	}

	useEffect(() => {
		const handleFetchData = async () => {
			try {
				setIsLoad(true)
				const res = await prayerRequest(page, dateFrom, dateTo, category, orderBy, orderDir)
				const categories = await prayerCategories()
				if (res.data.api_status) {
					setIsLoad(false)
					setData(res.data.data.data)
					setCurrentPage(res.data.data.current_page)
					setLastPage(res.data.data.last_page)
					setCategories(categories.data.data)
				} else {
					setIsLoad(false)
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						localStorage.removeItem('token')
						setIsRedirect(true)
					} else if (error.response.status >= 500) {
						setIsLoad(false)
						setError('An unexpected error has occurred. We are currently investigating the issue, please try again later.')
					}
				}
			}
		}
		handleFetchData()
	}, [dateFrom, dateTo, orderDir, orderBy, page, category])

	const shouldDisplayLoad = isLoad && !error
	const shouldDisplayNoData = !isLoad && !error && !data.length
	const shouldDisplayData = !isLoad && !error && data.length > 0
	const shouldDisplayErr = !isLoad && error

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{church.church_name} - Prayer Request</title>
			</Helmet>
			<PageBreadcrumbs icon={PrayerPraiseIcon} title="Prayer Request" breads={breads} />
			<div>
				<PrayerReqSearch handleOnDate={handleDate} category={category} categories={categories} handleOnCategory={handleCategory} handleOnExport={handleExport} isExport={isExport} />
				{shouldDisplayLoad && <Loading />}
				{shouldDisplayNoData && <div className="text-center">No data available</div>}
				{shouldDisplayErr && <div className="text-center">{error}</div>}
				{shouldDisplayData && (
					<div>
						<PrayerReqTable list={data} handleSort={handleSort} />

						<PaginationPage currentPage={currentPage} lastPage={lastPage} activeLink={active} length={data.length} url={props.location.pathname} />
					</div>
				)}
			</div>
		</div>
	)
}
