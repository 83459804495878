import React, { Component } from 'react'
import moment from 'moment'
import BarChart from 'components/skeleton/BarChart'
import SelectOptions from 'components/commons/SelectOptions'
import MonthlyActiveUserChart from 'components/dataReport/MonthlyActiveUserChart'
import { activeUsers } from 'services/reportServices'
import optionsYear from 'services/optionsYear'
import optionsMonth from 'services/optionsMonth'

class MonthlyActiveUser extends Component {
	state = {
		months: optionsMonth(),
		years: optionsYear(),
		monthSelected: '',
		yearSelected: '',
		isLoading: false,
		data: [],
	}

	componentDidMount() {
		this.setState(
			{
				monthSelected: moment().format('M'),
				yearSelected: moment().format('YYYY'),
			},
			() => {
				this.handleLoadData()
			}
		)
	}

	handleLoadData = () => {
		this.setState({ isLoading: true }, async () => {
			const { monthSelected, yearSelected } = this.state
			try {
				const {
					data: { data: results },
				} = await activeUsers(yearSelected, monthSelected)

				const x = results.map((item) => moment(item.count_date, 'DD/MM/YYYY').format('YYYY-MM-DD'))
				const y = results.map((item) => item.active_user_count)
				const data = [x, y]
				this.setState({ isLoading: false, data })
			} catch (error) {
				if (error.response && error.response.status === 401) {
					this.props.history.replace('/login')
				}
				this.setState({ isLoading: false })
			}
		})
	}

	handleChange = ({ currentTarget: input }) => {
		this.setState({ [input.name]: input.value }, () => {
			this.handleLoadData()
		})
	}

	renderData() {
		const { length: count } = this.state.data
		const { data } = this.state

		if (!count) return <div className="text-center">No data available</div>

		return <MonthlyActiveUserChart data={data} />
	}

	render() {
		const { monthSelected, isLoading, months, years, yearSelected } = this.state
		if (isLoading) return <BarChart />

		return (
			<div className="bg-white rounded-lg shadow-md p-6">
				<h3 className="font-bold mb-6">Active Users</h3>
				<div className="flex">
					<div className="pr-6">
						<SelectOptions options={months} value={monthSelected} name="monthSelected" onChange={this.handleChange} />
					</div>
					<div>
						<SelectOptions options={years} value={yearSelected} name="yearSelected" onChange={this.handleChange} />
					</div>
				</div>{' '}
				{this.renderData()}
			</div>
		)
	}
}

export default MonthlyActiveUser
