import axios from 'axios'

const makeRequestCreator = () => {
  let cancel
  return async (query) => {
    if (cancel) {
      // Cancel the previous request before making a new request
      cancel.cancel()
    }
    // Create a new CancelToken
    cancel = axios.CancelToken.source()
    const res = await axios.get(query, {
      cancelToken: cancel.token,
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    })
    return res
  }
}

const search = makeRequestCreator()

export default search
