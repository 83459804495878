import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import ReactQuill from 'react-quill'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'
import 'react-quill/dist/quill.snow.css'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import Loading from 'components/commons/Loading'
import SGCategories from 'components/smallGroup/SGCategories'
import SGStatuses from 'components/smallGroup/SGStatuses'
import { cancelConfirmation, successMessage, failedMessage, somethingWrong } from 'libs/utils/confirmation'
import { smallGroupDetail, smallGroupCategories, smallGroupStatuses, smallGroupRegencies, updateSmallGroup } from 'libs/apis/smallGroup'

class EditSmallGroup extends Component {
	state = {
		isRedirect: false,
		isLoading: false,
		isSubmit: false,
		errorMessage: '',
		id: '',
		categories: [],
		statuses: [],
		regencies: [],
		category: '',
		name: '',
		description: '',
		leader: '',
		day: '',
		time: '',
		established_date: '',
		region: '',
		fullAddress: '',
		shortAddress: '',
		longitude: '',
		latitude: '',
		status: '',
		postalCode: '',
		leaders: [],
		members: [],
		errors: {
			name: '',
			leader: '',
			day: '',
			time: '',
			fullAddress: '',
			shortAddress: '',
			region: '',
			established_date: '',
		},
	}

	componentDidMount() {
		this.setState({ isLoading: true }, async () => {
			try {
				const id = this.props.match.params.id
				const response = await smallGroupDetail(id)
				const categories = await smallGroupCategories()
				const statuses = await smallGroupStatuses()
				const regencies = await smallGroupRegencies()

				if (response.data.api_status && categories.data.api_status && statuses.data.api_status && regencies.data.api_status) {
					this.setState({
						isLoading: false,
						id: response.data.data.small_group_id,
						category: response.data.data.small_group_category_id,
						name: response.data.data.small_group_name,
						description: response.data.data.small_group_description,

						leader: response.data.data.leaders.length > 0 ? response.data.data.leaders[0].user_id : '-',
						day: response.data.data.time_day,
						time:
							(response.data.data.time_hours < 10 ? '0' + response.data.data.time_hours : response.data.data.time_hours) +
							':' +
							(response.data.data.time_minutes < 10 ? '0' + response.data.data.time_minutes : response.data.data.time_minutes),
						established_date: response.data.data.established_date,
						region: response.data.data.regency_id,
						status: response.data.data.small_group_status,
						fullAddress: response.data.data.long_address,
						shortAddress: response.data.data.short_address,
						postalCode: response.data.data.postal_code,
						leaders: response.data.data.leaders.length > 0 ? response.data.data.leaders : [],
						members: response.data.data.members,
						categories: categories.data.data,
						statuses: statuses.data.data,
						regencies: regencies.data.data,
					})
				} else {
					this.setState({ isLoading: false, errorMessage: response.data.message })
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						await localStorage.removeItem('token')
						this.setState({ isRedirect: true })
					} else if (error.response.status >= 500) {
						this.setState({
							isLoading: false,
							errorMessage: 'An unexpected error has occurred. We are currently investigating the issue, please try again later.',
						})
					}
				}
			}
		})
	}

	handleOnChange = event => {
		this.setState({ [event.target.name]: event.target.value })
	}

	handleOnDescription = value => {
		this.setState({ description: value })
	}

	handleOnTime = time => {
		this.setState({ time: moment(time[0]).format('HH:mm') })
	}

	handleEstabilishDate = date => {
		this.setState({
			...this.state,
			established_date: moment(date[0]).format('DD/MM/YYYY'),
		})
	}

	handleOnSubmit = () => {
		const { category, name, status, leader, day, time, fullAddress, shortAddress, region, postalCode, established_date } = this.state

		const errors = []
		if (!category) {
			this.setState(prevState => ({
				errors: { ...prevState.errors, category: 'Required' },
			}))
			errors.push('Category Required')
		} else {
			this.setState(prevState => ({ errors: { ...prevState.errors, category: '' } }))
		}

		if (!name) {
			this.setState(prevState => ({ errors: { ...prevState.errors, name: 'Required' } }))
			errors.push('Group Name is Required')
		} else {
			this.setState(prevState => ({ errors: { ...prevState.errors, name: '' } }))
		}

		if (!status) {
			this.setState(prevState => ({ errors: { ...prevState.errors, status: 'Required' } }))
			errors.push('Status Required')
		} else {
			this.setState(prevState => ({ errors: { ...prevState.errors, status: '' } }))
		}

		if (!leader) {
			this.setState(prevState => ({ errors: { ...prevState.errors, leader: 'Required' } }))
			errors.push('Leader is Required')
		} else {
			this.setState(prevState => ({ errors: { ...prevState.errors, leader: '' } }))
		}

		if (day === '') {
			this.setState(prevState => ({ errors: { ...prevState.errors, day: 'Required' } }))
			errors.push('Day is Required')
		} else {
			this.setState(prevState => ({ errors: { ...prevState.errors, day: '' } }))
		}

		if (!time) {
			this.setState(prevState => ({ errors: { ...prevState.errors, time: 'Required' } }))
			errors.push('Time is Required')
		} else {
			this.setState(prevState => ({ errors: { ...prevState.errors, time: '' } }))
		}

		if (!established_date) {
			this.setState(prevState => ({
				errors: { ...prevState.errors, established_date: 'Required' },
			}))
			errors.push('Estabilished Date Required')
		} else {
			this.setState(prevState => ({
				errors: { ...prevState.errors, established_date: '' },
			}))
		}

		if (!fullAddress) {
			this.setState(prevState => ({
				errors: { ...prevState.errors, fullAddress: 'Required' },
			}))
			errors.push('Full Address Required')
		} else {
			this.setState(prevState => ({ errors: { ...prevState.errors, fullAddress: '' } }))
		}

		if (!shortAddress) {
			this.setState(prevState => ({
				errors: { ...prevState.errors, shortAddress: 'Required' },
			}))
			errors.push('Short Address Required')
		} else {
			this.setState(prevState => ({ errors: { ...prevState.errors, shortAddress: '' } }))
		}

		if (!region) {
			this.setState(prevState => ({ errors: { ...prevState.errors, region: 'Required' } }))
			errors.push('region is  Required')
		} else {
			this.setState(prevState => ({ errors: { ...prevState.errors, region: '' } }))
		}

		if (!postalCode) {
			this.setState(prevState => ({
				errors: { ...prevState.errors, postalCode: 'Required' },
			}))
			errors.push('postal code is  Required')
		} else {
			this.setState(prevState => ({ errors: { ...prevState.errors, postalCode: '' } }))
		}

		if (errors.length === 0) {
			this.handlePost()
		}
	}

	handleCancel = async () => {
		const res = await cancelConfirmation()
		if (res.value) {
			this.props.history.push('/small-group/list')
		}
	}

	handlePost = () => {
		this.setState({ isSubmit: true }, async () => {
			try {
				const { id, category, name, description, leader, day, time, fullAddress, shortAddress, region, postalCode, status, established_date } = this.state

				const response = await updateSmallGroup(
					id,
					category,
					name,
					description,
					leader,
					parseInt(day),
					parseInt(moment(time, 'HH:mm').format('HH')),
					parseInt(moment(time, 'HH:mm').format('mm')),
					established_date,
					parseInt(region),
					fullAddress,
					shortAddress,
					'',
					'',
					status,
					'',
					'',
					postalCode
				)

				if (response.data.api_status) {
					await successMessage()
					this.props.history.push('/small-group/list')
				} else {
					await failedMessage(response.data.message)
					this.setState({ isSubmit: false })
				}
			} catch (error) {
				await somethingWrong()
				this.setState({ isSubmit: false })
			}
		})
	}

	renderForm = () => {
		const {
			categories,
			category,
			errors,
			name,
			statuses,
			status,
			leaders,
			members,
			leader,
			description,
			day,
			time,
			established_date,
			fullAddress,
			shortAddress,
			region,
			regencies,
			postalCode,
			isSubmit,
		} = this.state

		const styleCategorySelect = `form-select block w-full border-secondary border-2 rounded-lg`
		const styleSmallGroupName = `appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none border-secondary`
		const styleStatusSelect = `form-select block w-full border-secondary border-2 rounded-lg`
		const styleSelect = `form-select block w-full border-secondary border-2 rounded-lg`
		const styleTime = `appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none border-secondary`
		const styleFullAddress = `appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none border-secondary`
		const styleShortAddress = `appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none border-secondary`
		const stylePostalCode = `appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none border-secondary`
		const styleEstabilished_date = `form-select block w-full border-2 rounded-lg ${errors.established_date ? 'border-red-500' : 'border-secondary'}`

		return (
			<div className="bg-white shadow-md p-6">
				<div className="flex">
					<div className="w-1/2">
						<div className="mb-6">
							<label className="block text-gray-700 text-sm font-bold mb-2">SMALL GROUP CATEGORY</label>
							<select className={styleCategorySelect} name="category" value={category} onChange={this.handleOnChange}>
								{categories.map(item => (
									<SGCategories key={item.id} value={parseInt(item.id)} title={item.title} />
								))}
							</select>
						</div>
						<div className="mb-6">
							<label className="block text-gray-700 text-sm font-bold mb-2">SMALL GROUP NAME</label>
							<input className={styleSmallGroupName} name="name" value={name} onChange={this.handleOnChange} />
							{errors.name && <div className="text-red-500 text-xs italic">{errors.name}</div>}
						</div>
						<div className="mb-6">
							<label className="block text-gray-700 text-sm font-bold mb-2">SMALL GROUP STATUS</label>
							<select className={styleStatusSelect} name="status" value={status} onChange={this.handleOnChange}>
								{statuses.map(item => (
									<SGStatuses key={item.id} value={item.id} title={item.title} />
								))}
							</select>
						</div>
						<div className="mb-6">
							<label className="block text-gray-700 text-sm font-bold mb-2">SMALL GROUP LEADER</label>
							<select className={styleSelect} name="leader" value={leader} onChange={this.handleOnChange}>
								{leaders.map(item => (
									<option key={item.user_id} value={item.user_id}>
										{item.fullname} - {item.email}
									</option>
								))}
								{members.map(item => (
									<option key={item.user_id} value={item.user_id}>
										{item.fullname} - {item.email}
									</option>
								))}
							</select>
						</div>
						<div className="mb-6">
							<ReactQuill value={description} onChange={this.handleOnDescription} />
						</div>
					</div>
				</div>
				<div className="mb-6 w-3/6">
					<label className="block text-gray-700 text-sm font-bold mb-2">ESTABLISHED DATE</label>
					<Flatpickr
						className={styleEstabilished_date}
						value={established_date}
						onChange={this.handleEstabilishDate}
						placeholder="Choose your date"
						options={{
							dateFormat: 'd/m/Y',
							altInput: true,
							altFormat: 'd M Y',
							enableTime: false,
						}}
					/>
					{errors.styleEstabilished_date && <div className="text-red-500 text-xs italic">{errors.styleEstabilished_date}</div>}
				</div>
				<div className="flex">
					<div className="w-1/2 mr-4">
						<div className="mb-6">
							<label className="block text-gray-700 text-sm font-bold mb-2">SCHEDULE: DAY</label>
							<select className={styleSelect} name="day" value={day} onChange={this.handleOnChange}>
								<option value={1}>Monday</option>
								<option value={2}>Tuesday</option>
								<option value={3}>Wednesday</option>
								<option value={4}>Thursday</option>
								<option value={5}>Friday</option>
								<option value={6}>Saturday</option>
								<option value={0}>Sunday</option>
							</select>
						</div>
					</div>
					<div className="w-1/2">
						<div className="mb-6">
							<label className="block text-gray-700 text-sm font-bold mb-2">SCHEDULE: TIME</label>
							<Flatpickr className={styleTime} value={time} onChange={this.handleOnTime} options={{ enableTime: true, noCalendar: true }} />
						</div>
					</div>
				</div>
				<div className="flex">
					<div className="w-1/2 mr-4">
						<div className="mb-6">
							<label className="block text-gray-700 text-sm font-bold mb-2">FULL ADDRESS</label>
							<textarea type="text" name="fullAddress" className={styleFullAddress} rows={5} value={fullAddress} onChange={this.handleOnChange} />
							{errors.fullAddress && <div className="text-red-500 text-xs italic">{errors.fullAddress}</div>}
						</div>
					</div>
					<div className="w-1/2">
						<div className="mb-6">
							<label className="block text-gray-700 text-sm font-bold mb-2">SHORT ADDRESS</label>
							<input type="text" name="shortAddress" className={styleShortAddress} value={shortAddress} onChange={this.handleOnChange} />
							{errors.shortAddress && <div className="text-red-500 text-xs italic">{errors.shortAddress}</div>}
						</div>
						<div className="flex">
							<div className="w-1/2 mr-4">
								<div className="mb-6">
									<label className="block text-gray-700 text-sm font-bold mb-2">REGION</label>
									<select className={styleSelect} name="region" value={region} onChange={this.handleOnChange}>
										{regencies.map(item => (
											<option key={item.id} value={item.id}>
												{item.name}
											</option>
										))}
									</select>
								</div>
							</div>
							<div className="w-1/2 mr-4">
								<div className="mb-6">
									<label className="block text-gray-700 text-sm font-bold mb-2">POSTAL CODE</label>
									<input type="text" name="postalCode" className={stylePostalCode} value={postalCode} onChange={this.handleOnChange} />
									{errors.postalCode && <div className="text-red-500 text-xs italic">{errors.postalCode}</div>}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="flex justify-between">
					<div>
						<button className="bg-grey-300 text-white rounded-lg py-2 px-6" onClick={this.handleCancel}>
							Cancel
						</button>
					</div>
					<div>
						<button className="bg-primary rounded-lg py-2 px-6 text-white" onClick={this.handleOnSubmit} disabled={isSubmit}>
							{isSubmit ? <Loading secondary /> : 'Save'}
						</button>
					</div>
				</div>
			</div>
		)
	}

	render() {
		const { isLoading, errorMessage, isRedirect } = this.state
		const church = JSON.parse(localStorage.getItem('church'))
		const name = church.church_name ? church.church_name : '-'
		const displayLoading = isLoading && !errorMessage
		const displayErrorMessage = !isLoading && errorMessage
		const displayForm = !isLoading && !errorMessage

		if (isRedirect) {
			return <Redirect to="/login" />
		}

		return (
			<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
				<Helmet>
					<title>{name} - Edit Small Group</title>
				</Helmet>
				<PageBreadcrumbs
					icon={SGIcon}
					title="Edit Group"
					breads={[
						{ url: '/small-group/list', title: 'List of All' },
						{ url: '', title: 'Edit Group' },
					]}
				/>
				{displayLoading && <Loading />}
				{displayErrorMessage && <div className="text-center">{errorMessage}</div>}
				{displayForm && this.renderForm()}
			</div>
		)
	}
}

export default EditSmallGroup
