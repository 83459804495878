import * as Yup from 'yup'

const validationSchema = () =>
	Yup.object().shape({
		// image: Yup.mixed().when("media", {
		//   is: "Image",
		//   then: Yup.mixed()
		//     .required("Required")
		//     .test(
		//       "imageSize",
		//       "Image size is too large, max 5 MB",
		//       value => (value && value.size <= 5242880) || typeof value === "string"
		//     ),
		//   otherwise: Yup.mixed().notRequired()
		// }),
		// image: Yup.file().required("Required"),
		subject: Yup.string().required('Required'),
		content: Yup.string().required('Required'),
		schedule: Yup.string().required('Required'),
	})

export default validationSchema
