import axios from 'axios'
import message from './alert'
axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT

axios.interceptors.response.use(null, error => {
	if (error) {
		return Promise.reject(error)
	}

	if (error.message === 'Operation canceled due to new request.') {
		return Promise.reject(error)
	}

	const expectedError =
		error.response && error.response.status >= 400 && error.response.status <= 500

	if (!error.response.status === 401) {
		localStorage.clear()
		message.failed(
			'Failed',
			'Sorry something went wrong,please try again or check your internet connection'
		)
	}

	//status

	if (!expectedError) {
		// Display Generic Error Message & Log Service (upcoming)
		message.failed(
			'Failed',
			'Sorry something went wrong,please try again or check your internet connection'
		)
	}

	return Promise.reject(error)
})

const makeRequestCreator = () => {
	let cancel
	return async (query, params, token) => {
		if (cancel) {
			// Cancel the previous request before making a new request
			cancel.cancel('Operation canceled due to new request.')
		}
		// Create a new CancelToken
		cancel = axios.CancelToken.source()
		const res = await axios.get(query, {
			params,
			cancelToken: cancel.token,
			headers: { Authorization: `Bearer ${token}` },
		})

		return res
	}
}

const search = makeRequestCreator()

export default {
	get: axios.get,
	post: axios.post,
	search,
	axios: axios,
}
