import React, { Component } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

class PushNotifRow extends Component {
	constructor() {
		super()
		this.state = {
			dropDownOpen: false,
		}
	}

	handleToggle = () => {
		this.setState({ dropDownOpen: !this.state.dropDownOpen })
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside)
	}

	handleClickOutside = event => {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({ dropDownOpen: false })
		}
	}

	setWrapperRef = node => {
		this.wrapperRef = node
	}

	handleClose = () => {
		this.props.handleOnClose(this.props.id)
	}

	render() {
		const {
			id,
			no,
			subject,
			createdOn,
			lastUpdate,
			schedule,
			status,
			handleDelete,
		} = this.props

		const { dropDownOpen } = this.state
		const styleDropDownOpen = `z-20 shadow-md absolute right-20px ${
			dropDownOpen ? 'block' : 'hidden'
		}`

		return (
			<tr className="border border-solid bt-blueSecondary">
				<td className="pl-10">{no}</td>
				<td className="text-left pl-6">{subject}</td>
				<td className="text-center">{moment(createdOn).format('ll')}</td>
				<td className="text-center">
					{moment(lastUpdate).format('ll')}
					<div className="text-primary">{moment(lastUpdate).fromNow()}</div>
				</td>
				<td className="text-center">
					{/*moment(schedule).format("ll")*/}
					{moment(schedule, 'DD/MM/YYYY HH:mm').format('D MMM YYYY')}
				</td>
				<td className="text-center">{status}</td>
				<td className="px-2 py-4">
					<div className="cursor-pointer" onClick={this.handleToggle}>
						<i className="dropdown-trigger fas fa-ellipsis-v" />
						<div className={styleDropDownOpen}>
							<ul ref={this.setWrapperRef} className="bg-white">
								<li>
									<Link
										className="px-4 py-2 block hover:bg-blueSecondary"
										to={`/pushnotif/${id}`}
									>
										View {'&'} Edit
									</Link>
								</li>

								<li
									className="px-4 py-2 block hover:bg-blueSecondary"
									onClick={() => handleDelete(id)}
								>
									Delete
								</li>
							</ul>
						</div>
					</div>
				</td>
			</tr>
		)
	}
}

export default PushNotifRow
