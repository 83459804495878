import React, { Component } from 'react'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import Loading from 'components/commons/Loading'
import EventForm from 'components/forms/EventForm'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ReactComponent as EventIcon } from 'assets/icons/events.svg'
import { updateEvent } from 'libs/apis/events'
import { successMessage, failedMessage } from 'libs/utils/confirmation'
import { cancelConfirmation } from 'libs/utils/confirmation'
import alert from 'services/alert'
import eventHOC from '../../hoc/eventHOC'
import { constants } from 'constants/constant'

class EditEvent extends Component {
	state = {
		loading: false,
		isRedirect: false,
	}

	handleUpdateEvent = async () => {
		const {
			eventTitle,
			eventShortTitle,
			eventDescription,
			eventTermCondition,
			eventStartDate,
			eventEndDate,
			isAllDayEvent,
			startTime,
			endTime,
			venue,
			venueName,
			venueAddress,
			eventImage,
			eventStartRegis,
			eventEndRegis,
			eventCategory,
			startDateShare,
			quota,
			isEndDateShare,
			endDateShare,
			isHighlight,
			is_require_payment,
			event_price,
			is_send_campaign,
			campaign_type,
			selectedRoles,
			maritalValues,
			gender,
			visibleToAll,
			filter_roles,
			maritalStatusAll,
		} = this.props

		try {
			this.setState({ ...this.state, loading: true })
			const formdata = new FormData()
			formdata.append('event_id', this.props.match.params.id)
			formdata.append('title', eventTitle)
			formdata.append('short_title', eventShortTitle)
			formdata.append('description', eventDescription)
			formdata.append('terms_and_conditions', eventTermCondition)
			formdata.append('start_date', eventStartDate)
			formdata.append('end_date', eventEndDate)
			if (isAllDayEvent) {
				formdata.append('is_all_day', 1)
			} else {
				formdata.append('is_all_day', 0)
				formdata.append('start_time', startTime)
				formdata.append('end_time', endTime)
			}

			if (venue) {
				formdata.append('venue_id', parseInt(venue))
				formdata.append('venue_other_name', venueName)
				formdata.append('venue_other_address', venueAddress)
			} else {
				formdata.append('venue_id', '')
				formdata.append('venue_other_name', venueName)
				formdata.append('venue_other_address', venueAddress)
			}
			formdata.append('banner_image', eventImage)
			formdata.append('registration_start_datetime', eventStartRegis)
			formdata.append('registration_end_datetime', eventEndRegis)
			formdata.append('participant_quota', quota)
			formdata.append('publish_start_datetime', startDateShare)

			if (isEndDateShare) {
				formdata.append('publish_end_datetime', endDateShare)
			}
			formdata.append('is_draft', 0)
			formdata.append('is_highlight', isHighlight ? 1 : 0)
			formdata.append('event_category_id', parseInt(eventCategory))
			formdata.append('is_send_campaign', is_send_campaign ? 1 : 0)
			formdata.append('campaign_type', campaign_type)
			formdata.append('is_require_payment', is_require_payment ? 1 : 0)
			formdata.append('event_price', event_price ? 0 : 10000)
			formdata.append('gender', gender)

			if (visibleToAll) {
				for (let i = 0; i < filter_roles.length; i++) {
					formdata.append('filter_role[]', filter_roles[i].role_id)
				}
			} else {
				for (let i = 0; i < selectedRoles.length; i++) {
					formdata.append('filter_role[]', selectedRoles[i])
				}
			}

			if (maritalStatusAll) {
				for (let i = 0; i < constants.maritalStatus.length; i++) {
					formdata.append('marital_status[]', constants.maritalStatus[i].maritalValue)
				}
			} else {
				for (let i = 0; i < maritalValues.length; i++) {
					formdata.append('marital_status[]', maritalValues[i])
				}
			}

			const response = await updateEvent(formdata)
			if (response.data.api_status) {
				await successMessage()
				this.props.history.push('/events/list')
				this.setState({ ...this.state, loading: false })
			} else {
				await failedMessage(response.data.message)
				this.setState({ ...this.state, loading: false })
			}
			this.setState({ ...this.state, loading: false })
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
					this.setState({ ...this.state, isRedirect: true })
				} else if (error.response.status >= 500) {
					this.setState({ ...this.state, loading: false })

					await failedMessage('An unexpected error has occurred. We are currently investigating the issue, please try again later.')
				}
			}
		}
	}

	handleOnSubmit = async () => {
		const res = await alert.confirmComplete('Are you sure wanna proceed the data')
		if (res.value) {
			try {
				this.handleUpdateEvent()
			} catch (error) {
				alert.failed(`Failed`, error)
			}
		}
	}

	handleOnDraft = async () => {
		const {
			eventTitle,
			eventShortTitle,
			eventDescription,
			eventTermCondition,
			eventStartDate,
			eventEndDate,
			isAllDayEvent,
			startTime,
			endTime,
			venue,
			venueName,
			venueAddress,
			eventImage,
			eventStartRegis,
			eventEndRegis,
			eventCategory,
			startDateShare,
			quota,
			isEndDateShare,
			endDateShare,
			isHighlight,
			is_require_payment,
			event_price,
			is_send_campaign,
			campaign_type,
			selectedRoles,
			maritalValues,
			gender,
			visibleToAll,
			filter_roles,
			maritalStatusAll,
		} = this.props

		try {
			this.setState({ ...this.state, loading: true })

			const formdata = new FormData()
			formdata.append('event_id', this.props.match.params.id)
			formdata.append('title', eventTitle)
			formdata.append('short_title', eventShortTitle)
			formdata.append('description', eventDescription)
			formdata.append('terms_and_conditions', eventTermCondition)
			formdata.append('start_date', eventStartDate)
			formdata.append('end_date', eventEndDate)
			if (isAllDayEvent) {
				formdata.append('is_all_day', 1)
			} else {
				formdata.append('is_all_day', 0)
				formdata.append('start_time', startTime)
				formdata.append('end_time', endTime)
			}

			if (venue) {
				formdata.append('venue_id', parseInt(venue))
				formdata.append('venue_other_name', venueName)
				formdata.append('venue_other_address', venueAddress)
			} else {
				formdata.append('venue_id', '')
				formdata.append('venue_other_name', venueName)
				formdata.append('venue_other_address', venueAddress)
			}
			formdata.append('banner_image', eventImage)
			formdata.append('registration_start_datetime', eventStartRegis)
			formdata.append('registration_end_datetime', eventEndRegis)
			formdata.append('participant_quota', quota)
			formdata.append('publish_start_datetime', startDateShare)

			if (isEndDateShare) {
				formdata.append('publish_end_datetime', endDateShare)
			}
			formdata.append('is_draft', 1)
			formdata.append('is_highlight', isHighlight ? 1 : 0)
			formdata.append('event_category_id', parseInt(eventCategory))
			formdata.append('is_send_campaign', is_send_campaign ? 1 : 0)
			formdata.append('campaign_type', campaign_type)
			formdata.append('is_require_payment', is_require_payment ? 1 : 0)
			formdata.append('event_price', event_price ? 0 : 10000)
			formdata.append('gender', gender)

			if (visibleToAll) {
				for (let i = 0; i < filter_roles.length; i++) {
					formdata.append('filter_role[]', filter_roles[i].role_id)
				}
			} else {
				for (let i = 0; i < selectedRoles.length; i++) {
					formdata.append('filter_role[]', selectedRoles[i])
				}
			}

			if (maritalStatusAll) {
				for (let i = 0; i < constants.maritalStatus.length; i++) {
					formdata.append('marital_status[]', constants.maritalStatus[i].maritalValue)
				}
			} else {
				for (let i = 0; i < maritalValues.length; i++) {
					formdata.append('marital_status[]', maritalValues[i])
				}
			}

			const response = await updateEvent(formdata)
			if (response.data.api_status) {
				this.setState({ ...this.state, loading: false })

				await successMessage()
				this.props.history.push('/events/list')
			} else {
				await failedMessage(response.data.message)
				this.setState({ ...this.state, loading: false })
			}
			this.setState({ ...this.state, loading: false })
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
					this.setState({ ...this.state, isRedirect: false })
				} else if (error.response.status >= 500) {
					this.setState({ ...this.state, loading: false })

					await failedMessage('An unexpected error has occurred. We are currently investigating the issue, please try again later.')
				}
			}
		}
	}

	handleOnCancel = async () => {
		const res = await cancelConfirmation()
		// muncul confirmationnya dulu jika yes maka redirect ke list
		if (res.value) {
			this.props.history.push('/events/list')
		}
	}

	render() {
		const { error, isLoading } = this.props
		const { isRedirect } = this.state

		const church = JSON.parse(localStorage.getItem('church'))
		const name = church.church_name ? church.church_name : '-'
		const breads = [
			{ url: '/events/list', title: 'List of All' },
			{ url: '', title: 'Edit Event' },
		]
		const displayLoading = isLoading && !error
		const displayForm = !isLoading && !error
		const displayError = !isLoading && error

		if (isRedirect) {
			return <Redirect to="/login" />
		}
		return (
			<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
				<Helmet>
					<title>{name} - Edit Event</title>
				</Helmet>
				<PageBreadcrumbs icon={EventIcon} title="Edit Event" breads={breads} />
				{displayLoading && <Loading />}
				{displayError && <div className="text-center">{error}</div>}
				{displayForm && <EventForm {...this.props} {...this.state} handleSubmit={this.handleOnSubmit} handleOnDraft={this.handleOnDraft} handleOnCancel={this.handleOnCancel} />}
			</div>
		)
	}
}

export default eventHOC(EditEvent)
