import React from 'react'

export function FileInput({ fileName, handleOnChange }) {
	return (
		<div className="p-0 rounded-none border-0 relative inline-block w-full">
			<input id="fileUpload" type="file" onChange={handleOnChange} className="relative z-10 w-full h-full opacity-0 py-4 cursor-pointer" accept=".docx, .doc, .pdf" />

			<label className="flex items-center justify-center border-2 rounded-lg border-dashed text-center h-full w-full absolute inset-x-0 top-0">
				<span className="text-secondary">{fileName ? fileName : 'Add File'}</span>
			</label>
		</div>
	)
}
