import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { deleteSpecialServiceType } from 'libs/apis/campus'
import { deleteItemConfirmation } from 'libs/utils/confirmation'
import { ServiceItemContainer, XIconService } from './styles'

class SpecialItem extends Component {
	state = { isDeleteLoading: false, errorDeleteMessage: '', isRedirect: false, isCurrentEdit: false }

	handleOnDelete = async () => {
		const result = await deleteItemConfirmation()
		if (result.value) {
			this.setState({ isDeleteLoading: true }, async () => {
				try {
					const response = await deleteSpecialServiceType(this.props.id)
					if (response.data.api_status) {
						this.props.handleOnDelete(this.props.id)
					} else {
						this.setState({ isDeleteLoading: false, errorDeleteMessage: response.data.message })
					}
				} catch (error) {
					if (error.response) {
						if (error.response.status === 401) {
							await localStorage.removeItem('token')
							this.setState({ isRedirect: true })
						} else if (error.response.status >= 500) {
							this.setState({ isDeleteLoading: false, errorDeleteMessage: 'Error!' })
						}
					}
				}
			})
		}
	}

	handleOnEdit = () => {
		const data = this.props.typeList.find(item => item.id === this.props.id)
		const time = this.props.hour + ':' + this.props.minute

		this.setState({ isCurrentEdit: true }, () => {
			this.props.handleOnEdit(this.props.id, data.title, this.props.day, time)
		})
	}

	handleOnEditCancel = () => {
		this.setState({ isCurrentEdit: false }, () => {
			this.props.handleOnCancel()
		})
	}

	renderXIcon = () => <XIconService className="fas fa-times" style={{ color: '#4da5dc' }} onClick={this.handleOnDelete} />

	renderXIconGrey = () => <XIconService className="fas fa-times" style={{ color: '#b5b5b5' }} />

	render() {
		const {
			id,
			typeList,
			// day,
			dayDesc,
			hour,
			minute,
			isEditSpecial,
		} = this.props

		const { isDeleteLoading, errorDeleteMessage, isRedirect, isCurrentEdit } = this.state

		if (isRedirect) {
			return <Redirect to="/login" />
		}

		return (
			<ServiceItemContainer>
				{typeList
					.filter(type => type.id === id)
					.map(item => (
						<Fragment key={item.id}>
							{item.title} - {dayDesc} {hour}:{minute}
							{isCurrentEdit && <EditIcon fill="#4da5dc" stroke="#4da4dc" strokeWidth=".3px" onClick={this.handleOnEditCancel} />}
							{!isCurrentEdit && isEditSpecial && <EditIcon fill="#b5b5b5" stroke="#b5b5b5" strokeWidth=".3px" />}
							{!isEditSpecial && <EditIcon fill="#4da5dc" stroke="#4da4dc" strokeWidth=".3px" onClick={this.handleOnEdit} />}
							{isDeleteLoading && 'Loading'}
							{!isDeleteLoading && !isEditSpecial && this.renderXIcon()}
							{!isDeleteLoading && isEditSpecial && this.renderXIconGrey()}
							{errorDeleteMessage && <div className="text-invalid">{errorDeleteMessage}</div>}
						</Fragment>
					))}
			</ServiceItemContainer>
		)
	}
}

export default SpecialItem
