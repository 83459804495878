import React from 'react'
import styled from 'styled-components'
import { Head, Body } from './index'

const Wrapper = styled.div`
	box-shadow: 0 0 12px 0 var(--blackTwo);
	background-color: var(--whiteOne);
`

const Title = styled.div`
	color: var(--whiteOne);
	font-weight: bold;
	padding: 10px 12px;
	background-color: var(--graySix);
`

const PaymentHistory = ({ data, handleDetails }) => (
	<Wrapper>
		<Title>Payment History</Title>
		<table className="table">
			<Head />
			<Body data={data} handleDetails={handleDetails} />
		</table>
	</Wrapper>
)

export default PaymentHistory
