import React from 'react'
import ReactQuill from 'react-quill'

export function DescriptionBox({ label, size, handleChange, value }) {
	const btnSize = {
		small: 'w-64 mt-3',
		medium: 'w-3/6 mt-3',
		large: 'w-full mt-3',
	}

	return (
		<div>
			{label && <label className="mb-3 ml-1 font-bold">{label}</label>}
			<ReactQuill className={btnSize[size]} value={value || ''} onChange={handleChange} />
		</div>
	)
}
