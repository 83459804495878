import React, { useState, useEffect } from 'react'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { Helmet } from 'react-helmet'
import { ReactComponent as MinistryIcon } from 'assets/icons/ministry.svg'
import MinistryTable from 'components/ministry/MinistryTable'
import { ministryList, closeMinistry } from 'libs/apis/ministry'
import Loading from 'components/commons/Loading'
import { Redirect } from 'react-router-dom'
import alert from 'services/alert'
import queryString from 'query-string'
import PaginationPage from 'components/commons/PaginationPage'
import Searchbar from 'components/commons/Searchbar'

export const MinistryContext = React.createContext()

function ListMinistry(props) {
	const [ministryData, setMinistryData] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [isRedirect, setIsRedirect] = useState(false)
	const [filterKeyword, setFilterKeyword] = useState('')
	const [filterStatus, setFilterStatus] = useState('')
	const [error, setError] = useState('')
	const [currentPage, setCurrentPage] = useState(1)
	const [lastPage, setLastPage] = useState(1)
	const [orderDir, setOrderDir] = useState('')
	const [orderBy, setOrderBy] = useState('')

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [{ url: '', title: 'List of Ministry' }]

	const { location } = props
	const parsed = queryString.parse(location.search)
	const active = `${location.pathname}?page=${parsed.page || 1}`
	const { search } = props.location
	const page = search ? queryString.parse(search).page : 1

	const handleLoadData = React.useCallback(async () => {
		setIsLoading(true)
		try {
			let res = await ministryList(
				parseInt(page),
				filterStatus,
				filterKeyword,
				orderBy,
				orderDir
			)

			if (res.data.api_status) {
				setMinistryData(res.data.data.data)
				setCurrentPage(res.data.data.current_page)
				setLastPage(res.data.data.last_page)
				setIsLoading(false)
			} else {
				setIsLoading(false)
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
					setIsRedirect(true)
				} else if (error.response.status <= 500) {
					setIsLoading(false)
					setError(
						'There is an error from our server, we currently investigate the problem'
					)
				}
			}
		}
	}, [page, filterStatus, filterKeyword, orderBy, orderDir])

	const handleSearch = (e) => {
		setFilterKeyword(e.currentTarget.value)
	}

	const handleCloseGroup = async (ministry_id) => {
		let res = await alert.confirmCloseMinistry()

		if (res.value) {
			let formdata = new FormData()

			formdata.set('ministry_id', ministry_id)

			let res = await closeMinistry(formdata)

			if (res.data.api_status) {
				alert.succeed('Success', 'Ministry has been closed')
				handleLoadData()
			}
		}
	}

	const handleSort = async (sortColumn) => {
		if (orderBy !== sortColumn) {
			setOrderBy(sortColumn)
			setOrderDir('asc')
		} else {
			if (orderDir === 'asc') {
				setOrderBy(sortColumn)
				setOrderDir('desc')
			} else if (orderDir === 'desc') {
				setOrderBy(sortColumn)
				setOrderDir('asc')
			}
		}
	}

	const handleSortByStatus = () => {
		if (filterStatus === '') {
			setFilterStatus('Open')
		}
		if (filterStatus === 'Open') {
			setFilterStatus('Closed')
		}
		if (filterStatus === 'Closed') {
			setFilterStatus('')
		}
	}

	useEffect(() => {
		handleLoadData()
	}, [filterStatus, filterKeyword, page, orderDir, orderBy, handleLoadData])

	const shouldDisplayLoad = isLoading && !error
	const shouldDisplayNoData = !isLoading && !error && !ministryData.length
	const shouldDisplayData = !isLoading && !error && ministryData.length > 0
	const shouldDisplayErr = !isLoading && error

	if (isRedirect) {
		return <Redirect to="/login" />
	}
	return (
		<MinistryContext.Provider value={ministryData}>
			<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
				<Helmet>
					<title>{name} - List of Ministry</title>
				</Helmet>
				<PageBreadcrumbs icon={MinistryIcon} title="List of Ministry" breads={breads} />
				<Searchbar handleSearch={handleSearch} />
				<div>
					{shouldDisplayLoad && <Loading />}
					{shouldDisplayNoData && (
						<div className="text-center">No data available</div>
					)}
					{shouldDisplayErr && <div className="text-center">{error}</div>}
					{shouldDisplayData && (
						<div>
							<MinistryTable
								handleSort={handleSort}
								handleCloseGroup={handleCloseGroup}
								handleSortByStatus={handleSortByStatus}
							/>
							<PaginationPage
								currentPage={currentPage}
								lastPage={lastPage}
								activeLink={active}
								length={ministryData.length}
								url={location.pathname}
							/>
						</div>
					)}
				</div>
			</div>
		</MinistryContext.Provider>
	)
}

export default ListMinistry
