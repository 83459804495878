import React, { Component } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Loading from 'components/commons/Loading'
import ImageUpload from 'components/commons/ImageUpload'
import DateTimeSelect from 'components/commons/DateTimeSelect'
import FileUpload from '../formik/FileUpload'
import CampaignEmailPreview from 'components/formik/CampaignEmailPreview'
import { getRole } from 'libs/apis/smallGroup'
import { cancelConfirmation } from 'libs/utils/confirmation'
import { Redirect } from 'react-router-dom'
import { Field } from 'formik'

class CampaignEmailForm extends Component {
	state = {
		isRedirect: false,
		roles: [],
		filter_roles: [],
		checked: true,
		attachImg: this.props.values.image_url ? true : false,
		attachFile: this.props.values.attachment ? true : false,
		visibleToAll: this.props.values.visibleToAll,
	}

	async componentDidMount() {
		await this.handleGetRole()
		if (this.props.values.filter_roles.length !== 0) {
			this.setState({ ...this.state, visibleToAll: false })
		}
	}

	handleCancel = async e => {
		e.preventDefault()
		const res = await cancelConfirmation()
		if (res.value) {
			this.props.history.push('/campaign')
			this.setState({ isRedirect: true })
		}
	}

	handleOnDraft = e => {
		e.preventDefault()
		const { setFieldValue, handleSubmit } = this.props
		setFieldValue('is_draft', true)
		handleSubmit(e)
	}

	handleOnSubmit = () => {
		const { setFieldValue, handleSubmit } = this.props

		setFieldValue('filter_role[]', this.state.filter_roles)

		handleSubmit()
	}

	handleCheckSendAll = () => {
		this.setState({ ...this.state, visibleToAll: !this.state.visibleToAll })
	}

	handleOnDescription = value => {
		this.props.setFieldValue('content', value)
	}

	handleGetRole = async () => {
		let rolesLocalStorage = JSON.parse(localStorage.getItem('roles'))
		if (rolesLocalStorage === null) {
			let res = await getRole()
			localStorage.setItem('roles', JSON.stringify(res.data.data))
			this.setState({ ...this.state, roles: res.data.data })
		} else {
			let roles = JSON.parse(localStorage.getItem('roles'))
			this.setState({ ...this.state, roles: roles })
		}
	}

	handleCheckFile = () => {
		this.setState({
			...this.state,
			attachFile: !this.state.attachFile,
		})
	}

	handleCheckImg = () => {
		this.setState({
			...this.state,
			attachImg: !this.state.attachImg,
		})
	}

	handleRole = props => {
		for (let i = 0; i < this.props.values.filter_roles.length; i++) {
			if (parseInt(this.props.values.filter_roles[i]) === props.role_id) {
				return true
			}
		}
		return false
	}

	render() {
		const { values, errors, isSubmitting } = this.props
		const { isRedirect, roles } = this.state

		const styleTitle = `appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none ${errors.title ? 'border-red-500' : 'border-secondary'}`.trim()

		if (isRedirect) {
			return <Redirect to="/campaign/" />
		}

		return (
			<div className="flex md:-mx-2 flex-wrap">
				<div className="md:w-2/3 w-full px-2 mb-4 md:mb-0">
					<div className="bg-white p-6 shadow-md">
						<div className="mb-6">
							<label className="block text-gray-700 font-bold mb-2">SUBJECT</label>
							<Field name="subject" type="text" value={values.subject} className={styleTitle} />
							{errors.subject && <div className="text-red-500 text-xs italic">{errors.subject}</div>}
						</div>
						<div className="mb-6">
							<label className="block text-gray-700 font-bold mb-2">CONTENT</label>
							<ReactQuill value={values.content} onChange={this.handleOnDescription} />
							{}
							{errors.content && <div className="text-red-500 text-xs italic">{errors.content}</div>}
						</div>

						<div className="mb-6 flex-col">
							<div>
								<span className="text-gray-700 text-md font-bold ml-2">ATTACH IMAGE</span>
							</div>

							<div className="flex flex-row">
								<div className="mb-5 mt-3">
									<label className="items-center mb-2 ml-2 font-bold">
										<input type="checkbox" checked={this.state.attachImg} name="checkimg" className="form-checkbox border-2 mr-2" onChange={this.handleCheckImg} />
										YES
									</label>
								</div>

								<div className="mb-5 mt-3 ml-48">
									<label className="items-center mb-2 ml-2 font-bold">
										<input type="checkbox" checked={!this.state.attachImg} name="checkimg" className="form-checkbox border-2 mr-2" onChange={this.handleCheckImg} />
										NO
									</label>
								</div>
							</div>

							{this.state.attachImg ? <RenderImageUpload /> : <div />}
						</div>

						<div className="mb-6 flex-col">
							<div>
								<span className="text-gray-700 text-md font-bold ml-2">ATTACH FILE</span>
							</div>

							<div className="flex flex-row">
								<div className="mb-5 mt-3">
									<label className="items-center mb-2 ml-2 font-bold">
										<input type="checkbox" name="checkimg" className="form-checkbox border-2 mr-2" checked={this.state.attachFile} onChange={this.handleCheckFile} />
										YES
									</label>
								</div>

								<div className="mb-5 mt-3 ml-48">
									<label className="items-center mb-2 ml-2 font-bold">
										<input type="checkbox" name="checkimg" className="form-checkbox border-2 mr-2" checked={!this.state.attachFile} onChange={this.handleCheckFile} />
										NO
									</label>
								</div>
							</div>

							{this.state.attachFile ? <RenderFileUpload /> : null}
						</div>

						<div>
							<label className="block text-gray-700 font-bold pr-6 ">RECIPIENTS</label>

							<div className="flex mb-8 ml-2">
								<div className="flex-col">
									{roles.slice(0, roles.length / 2 + 1).map((val, idx) => (
										<div key={idx}>
											<label className="font-bold text-md">
												<Field name="filter_roles" type="checkbox" className="mt-4 mr-2" value={val.role_id} checked={this.handleRole(val)} />
												{val.show_as}
											</label>
										</div>
									))}
								</div>
								<div className="flex-col ml-40">
									{roles.slice(roles.length / 2 + 1).map((val, idx) => (
										<div key={idx}>
											<label className="ml-2 font-bold text-md">
												<Field name="filter_roles" className="mt-4 mr-2" type="checkbox" value={val.role_id} checked={this.handleRole(val)} />
												{val.show_as}
											</label>
										</div>
									))}
								</div>
							</div>
						</div>

						<div className="mb-6 flex">
							<div className="w-full mr-5">
								<label className="block text-gray-700 font-bold mb-2">RECIPIENTS GENDER</label>
								<Field component="select" className="form-select block w-full border-secondary border-2 rounded-lg" name="filter_gender">
									<option value="ALL">Both (Male & Female)</option>
									<option value="F">Female</option>
									<option value="M">Male</option>
								</Field>
								{errors.filter_gender && <div className="text-red-500 text-xs italic">{errors.filter_gender}</div>}
							</div>
							<div className="w-full">
								<label className="block text-gray-700 font-bold mb-2">RECIPIENTS MARIAGE STATUS</label>
								<Field component="select" className="form-select block w-full border-secondary border-2 rounded-lg" name="filter_marital_status">
									<option value="ALL">Both (Single & Married)</option>
									<option value="Single">Single</option>
									<option value="Married">Married</option>
								</Field>
								{errors.filter_marital_status && <div className="text-red-500 text-xs italic">{errors.filter_marital_status}</div>}
							</div>
						</div>

						<div className="w-3/6">
							<label className="block text-gray-700 font-bold mb-2">SCHEDULE</label>
							<Field name="schedule" component={DateTimeSelect} />
						</div>

						<div className="flex py-5">
							<div className="w-1/2">
								<button type="submit" className="font-bold bg-grey-300 text-white rounded-lg py-2 px-6 focus:outline-none" onClick={this.handleCancel}>
									Cancel
								</button>
							</div>
							<div className="w-1/2">
								<div className="flex justify-end">
									<button
										type="submit"
										className="mr-3 font-bold bg-white border-primary border-solid border-2 text-primary rounded-lg py-2 px-6 focus:outline-none"
										onClick={this.handleOnDraft}
										disabled={isSubmitting}
									>
										{isSubmitting ? <Loading /> : 'Save as Draft'}
									</button>
									<button
										type="submit"
										className="ml-3 font-bold bg-primary rounded-lg py-2 px-6 text-white focus:outline-none"
										onClick={this.handleOnSubmit}
										disabled={isSubmitting}
									>
										{isSubmitting ? <Loading secondary /> : 'Publish'}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="md:w-1/3 w-full px-2">
					<CampaignEmailPreview {...this.props} />
				</div>
			</div>
		)
	}
}

export default CampaignEmailForm

function RenderImageUpload() {
	return (
		<div>
			<div className="mb-6">
				<label className="block font-bold mb-2 ml-2">UPLOAD IMAGE</label>
				<Field name="image_url" component={ImageUpload} />
			</div>
		</div>
	)
}

function RenderFileUpload() {
	return (
		<div>
			<div className="mb-6">
				<label className="block text-gray-700 font-bold mb-2 ml-2">UPLOAD FILE</label>
				<Field name="attachment" component={FileUpload} />
			</div>
		</div>
	)
}
