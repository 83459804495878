import React from 'react'
import PrayerItem from './PrayerItem'

const PrayerList = ({ list }) => (
  <div>
    {list.map((item) => (
      <PrayerItem
        key={item.id}
        category={item.category_name}
        created={item.created_at}
        description={item.description}
        praise={item.praise}
      />
    ))}
  </div>
)

export default PrayerList
