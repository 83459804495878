import http from './httpServices'

export const devotionList = async (page, filter_status, filter_keyword, order_by, order_dir) => {
  return await http.search(
    '/devotion',
    {
      page,
      filter_status,
      filter_keyword,
      order_by,
      order_dir
    },
    localStorage.getItem('token')
  )
}

export const devotionArchive = async (id) => {
  return await http.post(`/devotion/archive/${id}`, {}, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
}

export const devotionDelete = async (id) => {
  return await http.post(`/devotion/delete/${id}`, {}, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
}
