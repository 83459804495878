import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import CampaignEmailFormik from 'components/formik/CampaignEmailFormik'
import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import Loading from 'components/commons/Loading'
import { getEmailCampaignDetails } from 'libs/apis/emailCampaign'
import { Redirect } from 'react-router-dom'

export default function EditCampaingn(props) {
	const { campaignDetails, errorMessage, isLoading, isRedirect } = useCampaignDetails(props.match.params.id)
	const { id, subject, content, image_url, attachment, schedule, status, filter_gender, filter_marital_status, filter_roles } = campaignDetails

	const breads = [
		{ url: '/campaign/', title: 'Campaign' },
		{ url: '', title: 'Edit Campaign' },
	]
	const shouldDisplayError = !isLoading && errorMessage
	const shouldDisplayLoad = isLoading && !errorMessage
	const shouldDisplayForm = !isLoading && !errorMessage

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>Edit Email Campaign</title>
			</Helmet>
			<PageBreadcrumbs icon={SGIcon} title="Edit Campaign" breads={breads} />
			{shouldDisplayLoad && <Loading />}
			{shouldDisplayForm && (
				<CampaignEmailFormik
					{...props}
					id={id}
					subject={subject}
					content={content}
					image_url={image_url}
					status={status}
					attachment={attachment}
					schedule={schedule}
					filter_gender={filter_gender}
					filter_marital_status={filter_marital_status}
					filter_roles={filter_roles}
					isCreatedForm={false}
					isEdit={true}
				/>
			)}
			{shouldDisplayError && <div className="align-center">{errorMessage}</div>}
		</div>
	)
}

function useCampaignDetails(campaignID) {
	const [campaign, setCampaign] = useState({
		id: '',
		subject: '',
		content: '',
		image_url: '',
		attachment: '',
		schedule: '',
		status: '',
		filter_gender: '',
		filter_marital_status: '',
		filter_roles: [],
	})
	const [errorMessage, setErrorMessage] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [isRedirect, setIsRedirect] = useState(false)

	useEffect(() => {
		const fetchCampaignDetails = async () => {
			try {
				setIsLoading(true)
				const response = await getEmailCampaignDetails(campaignID)

				const { campaign_id, subject, content, image_url, status, attachment, schedule, gender, marital, role_id } = response.data.data[0]

				setCampaign({
					id: campaign_id,
					subject,
					content,
					image_url,
					status,
					attachment,
					schedule,
					filter_gender: gender,
					filter_marital_status: marital,
					filter_roles: role_id,
				})
				setIsLoading(false)
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						await localStorage.removeItem('token')
						setIsRedirect(true)
					} else if (error.response.status >= 500) {
						setErrorMessage('Cannot retrieve data from server, our team is currently working on it')
						setIsLoading(false)
					}
				}
			}
		}

		fetchCampaignDetails()
	}, [campaignID])

	return {
		campaignDetails: { ...campaign },
		errorMessage,
		isLoading,
		isRedirect,
	}
}
