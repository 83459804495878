import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  margin-bottom: 12px;
  padding: 8px 12px;
  background-color: #e34f4f;
  color: #fff;
  border-radius: 10px;
`

const NotificationDanger = ({ message }) => <Container>{message}</Container>

export default NotificationDanger
