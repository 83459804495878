import React, { Component } from 'react'
import { Field } from 'formik'
import { partial } from '../../libs/utils/utils'

class HashTagsForm extends Component {
	handleOnChange = event => {
		const value = event.target.value
		const { form } = this.props
		value[value.length - 1] === ' '
			? this.handleSubmitHashTag(value)
			: form.setFieldValue('hashtag', value)
	}

	handleSubmitHashTag(hashtag) {
		const { form, push } = this.props
		push(hashtag.trim())
		form.setFieldValue('hashtag', '')
	}

	handleOnDelete = index => {
		const { remove } = this.props
		remove(index)
	}

	render() {
		const { form } = this.props
		return (
			<div className="appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none">
				<List hashtags={form.values.hashtags} handleOnDelete={this.handleOnDelete} />
				<Field
					name="hashtag"
					type="text"
					placeholder="Press space to separate tags"
					className="w-full"
					maxLength="50"
					onChange={this.handleOnChange}
				/>
			</div>
		)
	}
}

const List = ({ hashtags, handleOnDelete }) => {
	return (
		<ul className="flex flex-wrap">
			{hashtags.map((hashtag, index) => (
				<Item
					title={hashtag}
					key={index}
					index={index}
					handleOnDelete={handleOnDelete}
				/>
			))}
		</ul>
	)
}

const Item = ({ title, index, handleOnDelete }) => {
	const handleDelete = partial(handleOnDelete, index)
	return (
		<li className="inline-flex mx-2 mb-4 bg-secondary rounded-full py-2 px-4">
			{title}{' '}
			<i className="delete fas fa-times ml-2 cursor-pointer" onClick={handleDelete} />
		</li>
	)
}

export default HashTagsForm
