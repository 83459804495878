import React, { Component } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Tippy from '@tippy.js/react'
import { partial } from 'libs/utils/utils'

const EventListTable = ({
	events,
	handleArchive,
	handleOnSort,
	handleOnHighlight,
	handleOnUnhighlight,
}) => (
	<div className="bg-white shadow-md overflow-x-auto mx-2 md:mx-0">
		<table className="w-full">
			<Head handleOnSort={handleOnSort} />
			<tbody className="border-2 border-solid">
				{events.map(item => (
					<EventItem
						key={item.event_id}
						status={item.status}
						isHighlight={item.is_highlight}
						id={item.event_id}
						title={item.title}
						startDate={item.start_date}
						endDate={item.end_date}
						startPost={item.created_at}
						createdBy={item.created_by_name}
						updatedPost={item.updated_at}
						handleArchive={handleArchive}
						handleOnHighlight={handleOnHighlight}
						handleOnUnhighlight={handleOnUnhighlight}
					/>
				))}
			</tbody>
		</table>
	</div>
)

const Head = ({ handleOnSort }) => {
	const handleSortStartDate = partial(handleOnSort, 'start_date')
	const handleSortEndDate = partial(handleOnSort, 'end_date')
	const handleSortCreatedAt = partial(handleOnSort, 'created_at')
	const handleSortUpdatedAt = partial(handleOnSort, 'updated_at')
	return (
		<thead className="bt-blueSecondary border-2 border-solid">
			<tr>
				<th className="px-2 py-4" />
				<th className="px-2 py-4 text-left">ID</th>
				<th className="px-2 py-4 text-left">TITLE</th>
				<th className="px-2 py-4">
					START DATE{' '}
					<i className="fas fa-sort cursor-pointer" onClick={handleSortStartDate} />
				</th>
				<th className="px-2 py-4">
					END DATE{' '}
					<i className="fas fa-sort cursor-pointer" onClick={handleSortEndDate} />
				</th>
				<th className="px-2 py-4">
					CREATED ON{' '}
					<i className="fas fa-sort cursor-pointer" onClick={handleSortCreatedAt} />
				</th>
				<th className="px-2 py-4">
					LAST UPDATED{' '}
					<i className="fas fa-sort cursor-pointer" onClick={handleSortUpdatedAt} />
				</th>
				<th className="px-2 py-4" />
			</tr>
		</thead>
	)
}

class EventItem extends Component {
	constructor() {
		super()
		this.state = {
			dropDownOpen: false,
		}
		this.setWrapperRef = this.setWrapperRef.bind(this)
		this.handleClickOutside = this.handleClickOutside.bind(this)
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside)
	}

	handleToggle = () => {
		this.setState({ dropDownOpen: !this.state.dropDownOpen })
	}

	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({ dropDownOpen: false })
		}
	}

	setWrapperRef(node) {
		this.wrapperRef = node
	}

	render() {
		const {
			id,
			status,
			title,
			startDate,
			endDate,
			startPost,
			createdBy,
			updatedPost,
			handleArchive,
			isHighlight,
			handleOnHighlight,
			handleOnUnhighlight,
		} = this.props
		const { dropDownOpen } = this.state
		const handlePostArchive = partial(handleArchive, id)
		const handleHighlight = partial(handleOnHighlight, id)
		const handleUnHighlight = partial(handleOnUnhighlight, id)

		const styleTableRow = `border border-solid bt-blueSecondary`
		const styleTdStatus = isHighlight ? 'relative mr-6' : 'absolute mr-6'
		const styleDropDownOpen = `z-20 shadow-md absolute right-20px ${
			dropDownOpen ? 'block' : 'hidden'
		}`
		const styleStatusArchive = `${status === 'Archived' ? 'opacity-50' : 'opacity-100'}`

		return (
			<tr className={styleTableRow}>
				<td className={styleTdStatus}>
					{status === 'Draft' && (
						<Tippy content="Draft">
							<div className="status-post-tag">
								<div className="ribbon-black-triagle" />
							</div>
						</Tippy>
					)}
					{status === 'Archived' && (
						<Tippy content="Archived">
							<div className="status-post-tag">
								<div className={styleStatusArchive}>
									<div className="ribbon-black-triagle" />
								</div>
							</div>
						</Tippy>
					)}
					{isHighlight && (
						<Tippy content="Highlight" theme="star">
							<i className="fas fa-star text-yellow-300 self-center flex" />
						</Tippy>
					)}
				</td>
				<td className="px-2 py-4">
					<div className={styleStatusArchive}>{id}</div>
				</td>
				<td className="px-2 py-4 max-w-xs w-1 text-left truncate">
					<div className={styleStatusArchive}>
						<Link to={`/events/edit/${id}`} title={title}>
							{title}
						</Link>
					</div>
				</td>
				<td className="px-2 py-4 text-center">
					<div className={styleStatusArchive}>
						{moment(startDate, 'DD/MM/YYYY').format('DD MMM YYYY')}
					</div>
				</td>
				<td className="px-2 py-4 text-center">
					<div className={styleStatusArchive}>
						{endDate ? moment(endDate, 'DD/MM/YYYY').format('DD MMM YYYY') : '-'}
					</div>
				</td>
				<td className="px-2 py-4 text-center">
					<div className={styleStatusArchive}>
						<div>
							{moment(startPost, 'DD/MM/YYYY HH:mm').format('DD MMM YYYY')}
						</div>
						<div className="text-primary">by {createdBy}</div>
					</div>
				</td>
				<td className="px-2 py-4 text-center">
					<div className={styleStatusArchive}>
						<div>
							{moment(updatedPost, 'DD/MM/YYYY HH:mm').format('DD MMM YYYY')}
						</div>
						<div className="text-primary">
							{moment(updatedPost, 'DD/MM/YYYY HH:mm').fromNow()}
						</div>
					</div>
				</td>
				<td className="px-2 py-4">
					<div className="cursor-pointer" onClick={this.handleToggle}>
						<i className="dropdown-trigger fas fa-ellipsis-v" />
						<div className={styleDropDownOpen}>
							<ul ref={this.setWrapperRef} className="bg-white">
								<li>
									<Link
										className="px-4 py-2 block hover:bg-blueSecondary"
										to={`/events/registrant/${id}`}
									>
										Registrant List
									</Link>
								</li>
								{status !== 'Archived' && (
									<Link
										className="px-4 py-2 block hover:bg-blueSecondary"
										to={{
											pathname: `/events/edit/${id}`,
											state: { status: status },
										}}
									>
										View & Edit
									</Link>
								)}
								{status !== 'Archived' && (
									<li
										className="px-4 py-2 block hover:bg-blueSecondary"
										onClick={handlePostArchive}
									>
										Archive
									</li>
								)}
								{status === 'Published' && !isHighlight && (
									<li
										className="px-4 py-2 block hover:bg-blueSecondary"
										onClick={handleHighlight}
									>
										Set Highlight
									</li>
								)}
								{status === 'Published' && isHighlight && (
									<li
										className="px-4 py-2 block hover:bg-blueSecondary"
										onClick={handleUnHighlight}
									>
										Set Unhighlight
									</li>
								)}
							</ul>
						</div>
					</div>
				</td>
			</tr>
		)
	}
}

export default EventListTable
