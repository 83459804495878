import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { getListVolunteers, inviteMember } from 'libs/apis/ministry'
import photoplaceholder from 'assets/images/photo-placeholder.png'
import alert from 'services/alert'
import { Button } from 'components/commons/Button'

function InviteVolunteer({ ministry_id, handleMinistryDetail }) {
	const [listVolunteers, setListVolunteers] = useState([])
	const [inputValue, setInputValue] = useState('')
	const [modalOpen, setModalOpen] = useState(false)

	const customStyles = {
		content: {
			top: '40%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			height: `${listVolunteers.length !== 0 ? '210px' : 'auto'}`,
			width: '550px',
			transform: 'translate(-50%, -50%)',
		},
		overlay: {
			backgroundColor: 'rgba(0,0,0,0.6)',
		},
	}

	useEffect(() => {
		const fetchVolunteer = async () => {
			let res = await getListVolunteers(inputValue, ministry_id)
			if (res.data.data !== null) {
				setListVolunteers(res.data.data.data)
			} else {
				setListVolunteers('')
			}
		}
		fetchVolunteer()
	}, [inputValue, ministry_id])

	function handleChange(e) {
		setInputValue(e.target.value)
	}

	function handleTogle() {
		setModalOpen(!modalOpen)
		setInputValue('')
	}

	async function handleInvite(user_id) {
		let res = await inviteMember(ministry_id, listVolunteers[0].user_id || user_id)
		if (res.data.api_status) {
			await alert.succeed(`Success`, `Volunteer has been invite`)
			handleMinistryDetail()
			setInputValue('')
			handleTogle()
		} else {
			await alert.failed(`Failed`, `Failed invite volunteer`)
			setInputValue('')
			handleTogle()
		}
	}

	async function handleSubmitForm(e) {
		e.preventDefault()
		let res = await inviteMember(ministry_id, listVolunteers[0].user_id)
		if (res.data.api_status) {
			await alert.succeed(`Success`, `Volunteer has been invite`)
			handleMinistryDetail()
			setInputValue('')
			handleTogle()
		} else {
			await alert.failed(`Failed`, `Failed invite volunteer`)
			setInputValue('')
			handleTogle()
		}
	}

	function handleRemove(user_id) {
		let res = listVolunteers.filter(val => val.user_id !== user_id)
		setListVolunteers(res)
	}

	return (
		<div className="bg-gray-700 hover:bg-gray-600 cursor-pointer font-bold py-3 px-8 mb-4 rounded">
			<div onClick={handleTogle} className="text-white">
				Invite Volunteer
			</div>
			<Modal isOpen={modalOpen} style={customStyles} contentLabel="Example Modal" onRequestClose={handleTogle} appElement={document.getElementById('root')}>
				<h2 className="font-bold mb-2 ml-5">INVITE VOLUNTEER</h2>
				<form onSubmit={handleSubmitForm}>
					<div className="flex mt-3 ml-5 mr-5">
						<input
							name={inputValue}
							className="rounded-lg border-2 border-gray-500 w-full py-3 text-center"
							placeholder="Type name here"
							value={inputValue}
							onChange={handleChange}
						/>
					</div>
				</form>

				{inputValue && <Volunteers data={[...listVolunteers]} handleTogle={handleTogle} handleInvite={handleInvite} handleRemove={handleRemove} />}

				<div className="flex justify-between mx-5 mt-4">
					<Button type="cancel" handleClick={handleTogle} small>
						Cancel
					</Button>
					<Button type="primary" handleClick={handleInvite}>
						Invite
					</Button>
				</div>
			</Modal>
		</div>
	)
}

function Volunteers(props) {
	return (
		<div>
			{props.data.map(val => (
				<div key={val.user_id}>
					<div className="flex flex-row justify-arround mt-5 mx-5 hover:bg-gray-200 rounded-lg cursor-pointer" onClick={() => props.handleInvite(val.user_id)}>
						<div className="flex flex-row">
							<img src={val.profile_photo === null ? photoplaceholder : val.profile_photo} className="rounded-full flex items-center justify-center w-12 h-12" alt="volunteer" />
							<p className="text-gray-400 font-medium ml-4 pt-3">{val.fullname}</p>
						</div>

						<div className="fas fa-times ml-auto mt-5 pr-5" onClick={() => props.handleRemove(val.user_id)} />
					</div>
					<hr className="border-2 border-gray-200 mt-5 ml-5 mr-5" />
				</div>
			))}
		</div>
	)
}

export default InviteVolunteer
