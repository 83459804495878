import React, { Component, Fragment } from 'react'
import Flatpickr from 'react-flatpickr'
import { smallGroupCategories, smallGroupStatuses } from 'libs/apis/smallGroup'
import { constants } from 'constants/constant'
class smallGroupSearch extends Component {
	state = {
		categories: [],
		statuses: [],
	}

	async componentDidMount() {
		try {
			const response = await smallGroupCategories()
			const status = await smallGroupStatuses()
			if (response.data.api_status) {
				this.setState({ categories: response.data.data, statuses: status.data.data })
			}
		} catch (error) {}
	}

	renderCategories = () => {
		const { categories } = this.state
		return (
			<Fragment>
				{categories.map(item => (
					<option value={item.id} key={item.id}>
						{item.title}
					</option>
				))}
			</Fragment>
		)
	}

	renderDays = () => {
		return (
			<Fragment>
				{constants.days.map(item => (
					<option value={item.id} key={item.id}>
						{item.title}
					</option>
				))}
			</Fragment>
		)
	}

	renderStatuses = () => {
		const { statuses } = this.state
		return (
			<Fragment>
				{statuses.map(item => (
					<option value={item.id} key={item.id}>
						{item.title}
					</option>
				))}
			</Fragment>
		)
	}

	render() {
		const { filterKeyword, filterCategory, filterDay, filterTime, filterStatus, handleOnChangeFilter, handleOnChangeTime, handleOnClearFilter, handleOnApplyFilter, handleOnExport } = this.props

		return (
			<div className="flex flex-wrap -mx-3">
				<div className="w-full md:w-1/4 px-3 mb-6">
					<input className="form-input block shadow-md rounded-lg border-0 w-full" placeholder="Group name" name="filterKeyword" value={filterKeyword} onChange={handleOnChangeFilter} />
				</div>
				<div className="w-full md:w-1/4 px-3 mb-6">
					<select className="form-select border-0 rounded-lg shadow-md w-full" name="filterCategory" value={filterCategory} onChange={handleOnChangeFilter}>
						<option value="">Group type</option>
						{this.renderCategories()}
					</select>
				</div>
				<div className="w-full md:w-1/4 px-3 mb-6">
					<select className="form-select border-0 rounded-lg shadow-md w-full" name="filterDay" value={filterDay} onChange={handleOnChangeFilter}>
						<option value="">Day</option>
						{this.renderDays()}
					</select>
				</div>
				<div className="w-full md:w-1/4 px-3 mb-6">
					<button className="flex-1 w-full bg-grey-300 shadow-lg text-white rounded-lg h-full" onClick={handleOnClearFilter}>
						Clear Filter
					</button>
				</div>
				<div className="w-full md:w-1/4 px-3 mb-6">
					<Flatpickr
						value={filterTime}
						placeholder="Time"
						className="form-input block shadow-md rounded-lg border-0 w-full"
						width="100%"
						options={{
							onClose: handleOnChangeTime,
							enableTime: true,
							noCalendar: true,
							dateFormat: 'H:i',
						}}
					/>
				</div>
				<div className="w-full md:w-1/4 px-3 mb-6">
					<select className="form-select border-0 rounded-lg shadow-md w-full" name="filterStatus" value={filterStatus} onChange={handleOnChangeFilter}>
						<option value="">Status</option>
						{this.renderStatuses()}
					</select>
				</div>
				<div className="w-full md:w-1/4 px-3 mb-6">
					<button className="bg-greyExport shadow-lg text-white rounded-lg py-2 px-6" onClick={handleOnExport} style={{ width: '100%' }}>
						Export
					</button>
				</div>
				<div className="w-full md:w-1/4 px-3 mb-6">
					<button className="w-full bg-primary shadow-lg rounded-lg text-white h-full" onClick={handleOnApplyFilter}>
						Apply Filter
					</button>
				</div>
			</div>
		)
	}
}

export default smallGroupSearch
