import React from 'react'
import QRCode from 'qrcode.react'
import { ViewQRContainer, TitleQR } from './Styles'

const ViewQR = (props) => {
	return (
		<ViewQRContainer>
			<TitleQR>{props.match.params.eventname}</TitleQR>
			<QRCode value={props.match.params.qrcode} />
			<p>
				{props.match.params.starttime} - {props.match.params.endtime}
			</p>
		</ViewQRContainer>
	)
}

export default ViewQR
