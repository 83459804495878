import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import photoplaceholder from 'assets/images/photo-placeholder.png'
import { logOutConfirmation } from 'libs/utils/confirmation'

const SideNavSettings = styled.div`
	bottom: 0;
	position: fixed;
	margin-left: 25px;
`

const SideNavDropMenu = styled.div`
	display: grid;
	grid-template-columns: 50px 1fr;
	margin-bottom: 10px;
	&:hover {
		cursor: pointer;
	}
`

const SideNavAvatar = styled.img`
	border-radius: 50%;
`

const SideNavUserName = styled.span`
	top: 50%;
	display: inline-block;
	vertical-align: middle;
	margin-left: 10px;
`

const DropDownMenu = styled.div`
	width: auto;
	background-color: #575961;
	color: #fff;
	margin-bottom: 20px;
	z-index: 3;
	padding: 21px 0;
	border-radius: 10px;
	position: fixed;
	bottom: 0px;
	left: 70px;
`

const DropDownMenus = styled.ul`
	margin: 0;
	padding: 0;
`

const DropDownMenuLi = styled.li`
	padding: 8px;

	&:hover {
		cursor: pointer;
		background-color: rgba(255, 255, 255, 0.2);
	}
`

const DropDownMenuLink = styled(Link)`
	display: block;
	color: #fff;
`

class SideNavMenu extends Component {
	constructor() {
		super()
		this.state = {
			isShow: false,
			isRedirect: false,
		}

		this.setWrapperRef = this.setWrapperRef.bind(this)
		this.handleClickOutside = this.handleClickOutside.bind(this)
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside)
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.setState({ isShow: false })
		}
	}

	handleOnShow = () => {
		this.setState(prevState => ({ isShow: !prevState.isShow }))
	}

	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({ isShow: false })
		}
	}

	handleLogOut = async () => {
		const confirm = await logOutConfirmation()
		if (confirm.value) {
			await localStorage.removeItem('token')
			await localStorage.removeItem('course_campuses')
			await localStorage.removeItem('event_categories')
			await localStorage.removeItem('course_categories')
			await localStorage.removeItem('venues')
			await localStorage.removeItem('roles')
			this.setState({ isRedirect: true })
		}
	}

	setWrapperRef(node) {
		this.wrapperRef = node
	}

	render() {
		const user = JSON.parse(localStorage.getItem('user'))
		const access_list = JSON.parse(localStorage.getItem('access_list'))
		const photo = user.profile_photo !== null ? user.profile_photo : photoplaceholder
		const { isShow, isRedirect } = this.state

		if (isRedirect) {
			return <Redirect to="/login" />
		}

		return (
			<SideNavSettings ref={this.setWrapperRef}>
				<SideNavDropMenu onClick={this.handleOnShow}>
					<SideNavAvatar src={photo} alt="profile" width="35px" />
					<SideNavUserName>{user.fullname}</SideNavUserName>
				</SideNavDropMenu>
				{isShow && (
					<DropDownMenu>
						<DropDownMenus>
							{access_list && access_list.includes('church-profile') && (
								<DropDownMenuLi>
									<DropDownMenuLink to="/settings/church">Church Profile</DropDownMenuLink>
								</DropDownMenuLi>
							)}
							{access_list && access_list.includes('billing') && (
								<DropDownMenuLi>
									<DropDownMenuLink to="/settings/billing">Billing</DropDownMenuLink>
								</DropDownMenuLi>
							)}
							<DropDownMenuLi onClick={this.handleLogOut}>Log out</DropDownMenuLi>
						</DropDownMenus>
					</DropDownMenu>
				)}
			</SideNavSettings>
		)
	}
}

export default SideNavMenu
