import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

const Pagination = ({ currentPage, lastPage, onPageChange }) => {
	let startIndex = 1
	let endIndex = 1
	if (lastPage === 1) return null

	if (lastPage <= 4) {
		startIndex = 1
		endIndex = lastPage
	}

	if (lastPage > 4) {
		if (currentPage <= 2) {
			startIndex = 1
			endIndex = 4
		} else if (currentPage + 2 >= lastPage) {
			// near end page
			startIndex = lastPage - 3
			endIndex = lastPage
		} else {
			// middle page
			startIndex = currentPage - 1
			endIndex = currentPage + 2
		}
	}

	const pages = _.range(startIndex, endIndex + 1)
	const navs = [
		{ page: currentPage, index: 1, label: 'Previous', to: currentPage - 1 },
		{ page: currentPage, index: lastPage, label: 'Next', to: currentPage + 1 },
	]
	return (
		<nav className="block mx-2 md:mx-0">
			<div className="md:flex justify-between">
				<ul className="flex my-6">
					{startIndex > 1 && (
						<>
							<li className="mx-4 inline-block">...</li>
							<PageNumbers page={1} onPageChange={onPageChange} />
						</>
					)}
					{pages.map((page) => (
						<PageNumbers key={page} page={page} currentPage={currentPage} onPageChange={onPageChange} />
					))}
					{endIndex < lastPage && (
						<>
							<li className="mx-4 inline-block">...</li>
							<PageNumbers page={lastPage} onPageChange={onPageChange} />
						</>
					)}
				</ul>
				<ul className="flex my-6 md:-mx-2 cursor-not-allowed">
					{navs.map((nav) => (
						<PageText key={nav.label} nav={nav} onPageChange={onPageChange} />
					))}
				</ul>
			</div>
		</nav>
	)
}

const PageNumbers = ({ page, currentPage, onPageChange }) => {
	const classes = `p-3 rounded block mr-2 shadow-lg ${page === currentPage ? 'bg-primary text-white pointer-events-none' : 'bg-white text-gray-700 cursor-pointer'}`
	return (
		<li onClick={() => onPageChange(page)} className={classes}>
			{page}
		</li>
	)
}

const PageText = ({ nav, onPageChange }) => {
	const classes = `p-3 rounded block mx-2 shadow-lg ${nav.page === nav.index ? 'bg-white text-gray-700 pointer-events-none' : 'bg-primary text-white cursor-pointer'}`
	return (
		<li key={nav.page} nav={nav.page} className={classes} onClick={() => onPageChange(nav.to)}>
			{nav.label}
		</li>
	)
}

Pagination.propTypes = {
	currentPage: PropTypes.number.isRequired,
	lastPage: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
}

export default Pagination
