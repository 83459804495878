import React from 'react'
import { TextInput } from 'components/commons/TextInput'
import { Button } from 'components/commons/Button'
import { DescriptionBox } from 'components/commons/DescriptionBox'
import { DropdownAsync } from 'components/commons/DropdownAsync'
import { Dropdown } from 'components/commons/DropdownItem'
import { UploadImage } from 'components/commons/UploadImage'
import Loading from 'components/commons/Loading'

function MinistryForm({
	ministryName,
	handleMinistryName,
	ministryHead,
	handleOnLeaderChange,
	handleOnLoadLeaders,
	ministryDescription,
	handleDescription,
	handleChangeSelect,
	imagePreview,
	handleFileChange,
	handleCancel,
	isLoading,
	ministryStatus,
	handleSubmit,
	statuses,
	errorMessage,
}) {
	return (
		<div className="bg-white p-6 shadow-md">
			<TextInput label="MINISTRY NAME" value={ministryName} handleChange={handleMinistryName} size="medium" />
			<DropdownAsync label="MINISTRY HEAD" onChange={handleOnLeaderChange} value={ministryHead} loadOption={handleOnLoadLeaders} size="medium" />
			{errorMessage && <p className="text-red-200">{errorMessage}</p>}
			<DescriptionBox label="DESCRIPTION" value={ministryDescription} handleChange={handleDescription} size="medium" />
			<Dropdown label="RECRUITMENT STATUS" options={statuses} value={ministryStatus} handleChange={handleChangeSelect} />

			<UploadImage imageUrl={imagePreview} handleFileChange={handleFileChange} />

			<div className="flex flex-row justify-between mt-4">
				<Button type="cancel" handleClick={handleCancel}>
					Cancel
				</Button>
				<Button type="primary" handleClick={handleSubmit}>
					{isLoading ? <Loading secondary /> : 'Publish'}
				</Button>
			</div>
		</div>
	)
}

export default MinistryForm
