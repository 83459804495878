import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import Flatpickr from 'react-flatpickr'
import weekSelectPlugin from 'flatpickr/dist/plugins/weekSelect/weekSelect'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import Loading from 'components/commons/Loading'
import AttendanceCustomContainer from 'components/inputData/AttendanceCustomContainer'
import AttendanceContainer from 'components/inputData/AttendanceContainer'
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg'
import { campusList, getCampusServiceList, getCampusCustomServiceList } from 'libs/apis/campus'

class InputData extends Component {
	state = {
		isRedirect: false,
		isLoading: false,
		campuses: [],
		campusSelected: '',
		week: '',
		year: '',
		campusServices: [],
		customServices: [],
		errorMessage: '',
	}

	async componentDidMount() {
		try {
			const campuses = await campusList()
			if (campuses.data.api_status) {
				this.setState({ campuses: campuses.data.data })
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
					this.props.history.push('/login')
					this.setState({ isRedirect: true })
				}
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { year, week, campusSelected } = this.state
		if (year && week && campusSelected && (prevState.campusSelected !== campusSelected || prevState.week !== week || prevState.year !== year)) {
			this.setState({ isLoading: true }, async () => {
				const campusServiceList = await getCampusServiceList(campusSelected, year, week)
				const customServiceList = await getCampusCustomServiceList(campusSelected, year, week)
				if (campusServiceList.data.api_status && customServiceList.data.api_status) {
					this.setState({
						isLoading: false,
						campusServices: campusServiceList.data.data,
						customServices: customServiceList.data.data ? customServiceList.data.data : [],
					})
				} else {
					this.setState({ isLoading: false })
				}
				try {
				} catch (error) {}
			})
		}
	}

	handleOnSelectedCampus = event => {
		this.setState({ campusSelected: event.target.value })
	}

	handleOnDate = date => {
		const newYear = parseInt(moment(date[0]).format('YYYY'))
		const newWeek = moment(date[0]).isoWeek()
		this.setState({ year: newYear, week: newWeek })
	}

	render() {
		const church = JSON.parse(localStorage.getItem('church'))
		const { isRedirect, isLoading, campusServices, customServices, errorMessage, campuses, campusSelected, week, year } = this.state
		const displayLoading = isLoading && !errorMessage
		const diplayData = !isLoading && !errorMessage && campusServices.length > 0
		const displayNoData = !isLoading && !errorMessage && !campusServices.length
		const displayError = !isLoading && errorMessage

		if (isRedirect) {
			return <Redirect to="/login" />
		}

		return (
			<div className="main-content flex-1 bg-gray-100 pb-24 md:p-6">
				<Helmet>
					<title>{church.church_name} - Input Data</title>
				</Helmet>
				<PageBreadcrumbs title="Input Data" icon={HomeIcon} breads={[{ url: '', title: 'Input Data' }]} />
				<div className="mb-6">
					<div className="inline-block">
						<select className="form-select border-0 rounded-lg shadow-md mr-6" onChange={this.handleOnSelectedCampus} value={campusSelected}>
							<option disabled value="">
								Choose
							</option>
							{campuses.map(item => (
								<option key={item.id} value={item.id}>
									{item.title}
								</option>
							))}
						</select>
					</div>
					<div className="inline-block">
						<div className="shadow-md flex rounded-lg bg-white px-3 py-2 mr-6">
							<Flatpickr
								placeholder="Choose"
								options={{
									onChange: this.handleOnDate,
									plugins: [new weekSelectPlugin({})],
									altInput: true,
									weekNumbers: true,
									altFormat: '\\Week: W \\Year: Y',
									dateFormat: 'd/m/Y',
									locale: { firstDayOfWeek: 1 },
								}}
							/>
							<div>
								<i className="far fa-calendar-alt" />
							</div>
						</div>
					</div>
					<div className="inline-block">
						<Link to="/input-data/special-service">
							<button className="bg-greyExport text-white rounded-lg py-2 px-6 focus:outline-none">Other</button>
						</Link>
					</div>
				</div>
				{displayLoading && <Loading />}
				{diplayData && <AttendanceInput campusServices={campusServices} week={week} year={year} customServices={customServices} />}
				{displayNoData && <div className="text-center">No Data available</div>}
				{displayError && this.renderErrorMessage()}
			</div>
		)
	}
}

const AttendanceInput = ({ week, year, campusServices, customServices }) => (
	<div className="flex flex-wrap">
		{campusServices.map((item, index) => (
			<AttendanceContainer
				key={index}
				id={item.campus_id}
				year={year}
				week={week}
				day={item.service_time_day_desc}
				hour={item.service_time_hours}
				minute={item.service_time_minutes}
				isInputted={item.is_inputted}
				attendances={item.attendances}
			/>
		))}
		{customServices.map((item, index) => (
			<AttendanceCustomContainer
				key={index}
				id={item.campus_id}
				label={item.service_label}
				date={item.service_date}
				day={item.service_time_day_desc}
				isInputted={item.is_inputted}
				attendances={item.attendances}
			/>
		))}
	</div>
)

export default InputData
