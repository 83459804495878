import { withFormik } from 'formik'
import validationSchema from './DevValidSchema'
import DevotionForm from 'components/forms/DevotionForm'
import { createDevotion, updateDevotion } from 'libs/apis/devotion'
import { saveDraftConfirmation, successMessage, failedMessage, somethingWrong } from 'libs/utils/confirmation'

const DevotionFormik = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,
	mapPropsToValues: (props) => ({
		id: props.id || '',
		media: props.media || 'Image',
		image: props.image || '',
		video: props.video || '',
		categories: props.categories || '',
		category: props.category || '',
		title: props.title || '',
		description: props.description || '',
		hashtags: props.hashtags || [],
		hashtag: '',
		startDate: props.startDate || '',
		endDateCheck: props.endDate ? true : false,
		endDate: props.endDate || '',
		isDraft: false,
		isPublish: props.isPublish || false,
		isHighLight: props.isHighLight || false,
		isCreateForm: props.isCreateForm || false,
	}),
	validationSchema: validationSchema,
	async handleSubmit(values, { props, setSubmitting, setFieldValue }) {
		const { id, media, image, video, category, title, description, hashtags, startDate, endDateCheck, endDate, isDraft, isPublish, isHighLight, isCreateForm } = values

		let formdata = new FormData()
		formdata.append('media_type', media)
		media === 'Image' ? formdata.append('media_url', image) : formdata.append('media_url', video)
		formdata.append('category_id', category)
		formdata.append('title', title)
		formdata.append('description', description)
		if (hashtags.length > 0) {
			for (let index = 0; index < hashtags.length; index++) {
				formdata.append('hashtags[]', hashtags[index])
			}
		}
		formdata.append('start_post', startDate)

		if (endDateCheck) {
			formdata.append('end_post', endDate)
		}

		if (isDraft) {
			formdata.append('is_draft', 1)
			formdata.append('is_publish', 0)
			formdata.append('is_highlight', isHighLight ? 1 : 0)
		} else {
			isPublish ? formdata.append('is_publish', 1) : formdata.append('is_publish', 0)
			isHighLight ? formdata.append('is_highlight', 1) : formdata.append('is_highlight', 0)
			formdata.append('is_draft', 0)
		}

		try {
			if (isCreateForm) {
				if (isDraft) {
					const confirm = await saveDraftConfirmation()
					if (confirm.value) {
						const response = await createDevotion(formdata)
						if (response.data.api_status) {
							await successMessage()
							props.history.push('/devotion/list')
						} else {
							failedMessage(response.data.message)
						}
					}
					setFieldValue('isDraft', false)
				} else if (!isDraft) {
					const response = await createDevotion(formdata)
					if (response.data.api_status) {
						await successMessage()
						props.history.push('/devotion/list')
					} else {
						failedMessage(response.data.message)
					}
				}
			} else if (!isCreateForm) {
				if (isDraft) {
					const confirm = await saveDraftConfirmation()
					if (confirm.value) {
						const response = await updateDevotion(id, formdata)
						if (response.data.api_status) {
							await successMessage()
							props.history.push('/devotion/list')
						} else {
							failedMessage(response.data.message)
						}
					}
					setFieldValue('isDraft', false)
				} else if (!isDraft) {
					const response = await updateDevotion(id, formdata)
					if (response.data.api_status) {
						await successMessage()
						props.history.push('/devotion/list')
					} else {
						failedMessage(response.data.message)
					}
				}
			}
			setSubmitting(false)
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.removeItem('token')
					props.history.push('/login')
				} else {
					setSubmitting(false)
					somethingWrong()
				}
			}
		}
	},
})(DevotionForm)

export default DevotionFormik
