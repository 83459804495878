import React, { Component } from 'react'
import PiechartSkeleton from 'components/skeleton/PiechartSkeleton'
import PieChart from 'components/commons/PieChart'
import { ageGroupData } from 'services/reportServices'

class AgeGroupContainer extends Component {
	state = {
		isLoading: false,
		data: [],
	}

	componentDidMount() {
		this.setState({ isLoading: true }, async () => {
			try {
				const {
					data: { data: results },
				} = await ageGroupData()

				const values = results.map((item) => parseInt(item.total))
				const labels = results.map((item) => item.age)
				const data = [{ values, labels, type: 'pie' }]

				this.setState({ data, isLoading: false })
			} catch (error) {
				if (error.response && error.response.status === 401) {
					this.props.history.push('/login')
					localStorage.removeItem('token')
					this.setState({ ...this.state, isLoading: false })
				}
				this.setState({ isLoading: false })
			}
		})
	}

	renderData() {
		const { length: count } = this.state.data
		const { data } = this.state

		if (!count) return <div className="text-center">No data available</div>

		return <PieChart data={data} />
	}

	render() {
		const { isLoading } = this.state
		return (
			<>
				{isLoading ? (
					<PiechartSkeleton small={true} height={355} width={400} />
				) : (
					<div className="bg-white rounded-lg shadow-md text-center mt-5">
						<h3 className="font-bold mb-6">Age Group Status</h3>
						{this.renderData()}
					</div>
				)}
			</>
		)
	}
}

export default AgeGroupContainer
