import React, { useState, useEffect } from 'react'
import Loading from 'components/commons/Loading'
import HeadTitle from 'components/commons/HeadTitle'
import BreadCrumb from 'components/commons/Breadcrumb'
import CourseTable from 'components/courses/CourseTable'
import PaginationPage from 'components/commons/PaginationPage'
import { ReactComponent as CourseIcon } from 'assets/icons/classes.svg'
import { coursesList, archiveCourse, completeCourse } from 'services/courseServices'
import alert from 'services/alert'
import queryString from 'query-string'
import Searchbar from 'components/commons/Searchbar'
import Select from 'components/commons/Select'
import { constants } from 'constants/constant'

function Courses(props) {
	const [courses, setCourses] = useState([])
	const [filter, setFilter] = useState({ filterKeyword: '', filterStatus: '' })
	const [currentPage, setCurrentPage] = useState(1)
	const [lastPage, setLastPage] = useState(1)
	const [sortColumn, setSortColumn] = useState({ path: '', order: '' })
	const [isLoading, setIsLoading] = useState(false)

	const { search } = props.location
	const page = search ? queryString.parse(search).page : 1

	const handleSearch = (event) => {
		const { value, name } = event.target
		const { history, location } = props

		setIsLoading(true)
		setFilter({ ...filter, [name]: value })
		history.push(`${location.pathname}?page=1`)
	}

	const handleComplete = async (course) => {
		const { value } = await alert.confirmComplete('Are you sure?')
		if (value) {
			try {
				setIsLoading(true)
				const { data } = await completeCourse(course.class_id)
				if (data.api_status) {
					const courseses = [...courses]
					const index = courseses.indexOf(course)
					courseses[index] = { ...courseses[index] }
					courseses[index].status = 'Completed'
					setCourses(courseses)
				} else {
					alert.warningMessage(data.message)
				}
				setIsLoading(false)
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						return props.history.replace('/login')
					}
				}
				setIsLoading(false)
			}
		}
	}

	const handleArchive = async (course) => {
		const { value } = await alert.confirmArchive()
		if (value) {
			try {
				setIsLoading(true)
				const { data } = await archiveCourse(course.class_id)
				if (data.api_status) {
					const courses = [...this.state.courses]
					const index = courses.indexOf(course)
					courses[index] = { ...courses[index] }
					courses[index].status = 'Archived'
					this.setState({ courses })
				}
				setIsLoading(false)
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						return props.history.replace('/login')
					}
				}
				setIsLoading(false)
			}
		}
	}

	const handleSort = (sortColumn) => {
		setSortColumn(sortColumn)
	}

	useEffect(() => {
		const handleFetchData = async () => {
			try {
				setIsLoading(true)
				const {
					data: { data: result },
				} = await coursesList(page, filter.filterStatus, filter.filterKeyword, sortColumn.path, sortColumn.order)
				setCourses(result.data)
				setCurrentPage(result.current_page)
				setLastPage(result.last_page)
				setIsLoading(false)
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						return props.history.replace('/login')
					}
				}
				setIsLoading(false)
			}
		}
		handleFetchData()
	}, [page, filter.filterStatus, filter.filterKeyword, sortColumn.path, sortColumn.order, props.history])

	const { location } = props
	const parsed = queryString.parse(location.search)
	const active = `${location.pathname}?page=${parsed.page || 1}`

	const name = JSON.parse(localStorage.getItem('church')).church_name ? JSON.parse(localStorage.getItem('church')).church_name : '-'
	const breads = [{ key: 'listofall', label: 'List of All' }]

	const displayNoData = !isLoading && !courses.length
	const displayData = !isLoading && courses.length > 0

	return (
		<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
			<HeadTitle title={`${name} - Class`} />
			<BreadCrumb title="List of All" breads={breads} icon={CourseIcon} />
			<div className="flex mt-4 mb-8">
				<Searchbar filterKeyword={filter.filterKeyword} handleOnSearch={handleSearch} />
				<Select name="filterStatus" value={filter.filterStatus} onChange={handleSearch} options={constants.options1} />
			</div>
			{isLoading && <Loading />}
			{displayNoData && <div className="text-center">No data available</div>}
			{displayData && (
				<div>
					<CourseTable courses={courses} sortColumn={sortColumn} onSort={handleSort} onArchive={handleArchive} onComplete={handleComplete} />

					<PaginationPage currentPage={currentPage} lastPage={lastPage} length={courses.length} activeLink={active} url={location.pathname} />
				</div>
			)}
		</div>
	)
}

export default Courses
