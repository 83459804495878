import React, { useState, useRef } from 'react'
import { testPushNotif } from 'libs/apis/pushNotif'
import Loading from 'components/commons/Loading'
import alert from 'services/alert'
import { Editable } from 'components/commons/Editable'

const PushNotifPreview = (props) => {
	const [emailNotif, setEmailNotif] = useState('')
	const [description, setDescription] = useState('')
	const [isEditing, setEditing] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const church = JSON.parse(localStorage.getItem('church'))

	const placeholder = `Skyporter App will launch a new feature on Monday, next week! Be sure to update your app through App Store or Play Store, to experience it yourself!`

	const textareaRef = useRef()

	const handleSetEmail = (event) => {
		setEmailNotif(event.target.value)
	}

	const handleSetDescription = (event) => {
		setDescription(event.target.value)
	}

	const handleEditing = (props) => {
		setEditing(props)
	}

	const handleSubmit = async () => {
		try {
			setIsLoading(true)
			let formdata = new FormData()

			formdata.append('title', props.values.title ? props.values.title : `Push Notification ${church.church_name}`)
			formdata.append('body', description ? description : props.values.body)
			formdata.append('email', emailNotif)

			let response = await testPushNotif(formdata)
			if (response.data.api_status) {
				alert.succeed(`Success`, 'Success')
				setIsLoading(false)
			} else {
				alert.failed(`Failed`, response.data.message)
				setIsLoading(false)
			}
		} catch (error) {
			alert.failed(`Failed`, `Cannot test pushnotif`)
			setIsLoading(false)
		}
	}

	return (
		<div className="bg-white shadow-md p-6">
			<h3 className="font-bold mb-4 ml-4">NOTIFICATION MOCKUP</h3>
			<div className="p-3 break-words h-auto">
				<div className="bg-gray-100">
					<div className="flex justify-between h-12 rounded">
						<div className="font-bold text-md text-black-200 ml-2 pt-2">{church.church_name || 'Skyporter'}</div>
					</div>
					<div className="my-2 border-2 border-solid" />
					<div className="text-gray-500 text-xs">
						<div className="text-md font-bold ml-2">{props.values.title || 'New Feature Announchment'}</div>

						{props.values.body === '' ? (
							<Editable text={description} childRef={textareaRef} placeholder={placeholder} type="textarea" handleEditing={handleEditing} isEditing={isEditing}>
								<textarea
									ref={textareaRef}
									name="description"
									className="rounded w-full py-2 px-3 text-gray-700 leading-tight"
									rows="5"
									value={description}
									onChange={handleSetDescription}
								/>
							</Editable>
						) : (
							// <ReactQuill modules={{ toolbar: false }} className="-mt-2 text-sm h-24 -ml-2" theme={null} value={props.values.body} formats={null} />
							<div className="ml-2">{props.values.body}</div>
						)}
					</div>
				</div>

				<div className="my-2 border-2 border-solid" />
				<div className="text-md font-bold ml-2">SEND NOTIFICATION MOCK-UP</div>
				<input
					className="appearance-none border-2 rounded-lg p-3 mt-2 w-full leading-tight focus:outline-none"
					name="notification-mockup-input"
					placeholder="Type email here"
					onChange={handleSetEmail}
					value={emailNotif}
				/>
				<div className="flex justify-end mt-3">
					<button className="ml-3 font-bold bg-primary rounded-lg py-2 px-6 text-white focus:outline-none" onClick={handleSubmit}>
						{isLoading ? <Loading secondary /> : 'Send'}
					</button>
				</div>
			</div>
		</div>
	)
}

export default PushNotifPreview
