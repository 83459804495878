import React from 'react'
import { Link } from 'react-router-dom'
import Tippy from '@tippy.js/react'
import moment from 'moment'
import TableBody from '../commons/TableBody'
import ActionsRow from '../commons/ActionsRow'
import TableHeader from '../commons/TableHeader'

const NewsfeedTable = (props) => {
	const columns = [
		{
			key: 'status',
			content: (newsfeed) => {
				if (newsfeed.status === 'Published') return <td></td>

				return (
					<td
						className={`absolute ${
							newsfeed.status === 'Archived' ? 'opacity-25' : 'opacity-100'
						}`}
					>
						<Tippy content={newsfeed.status}>
							<div className="status-post-tag">
								<div className="ribbon-black-triagle" />
							</div>
						</Tippy>
					</td>
				)
			},
		},
		{
			label: 'ID',
			path: 'news_id',
			key: 'news_id',
			content: (newsfeed) => <td className="px-2 py-4 text-center">{newsfeed.news_id}</td>,
		},
		{
			label: 'TITLE',
			path: 'title',
			content: (newsfeed) => (
				<td
					className={`px-2 py-4 w-5 truncate max-w-xs ${
						newsfeed.status === 'Archived' ? 'opacity-25' : 'opacity-100'
					}`}
				>
					<Link to={`/newsfeed/edit/${newsfeed.news_id}`}>{newsfeed.title}</Link>
				</td>
			),
		},
		{
			label: 'START SHARING',
			path: 'start_post',
			content: (newsfeed) => {
				const start_post = moment(newsfeed.start_post, 'DD-MM-YYYY HH:mm')
				return (
					<td
						className={`px-2 py-4 ${
							newsfeed.status === 'Archived' ? 'opacity-25' : 'opacity-100'
						}`}
					>
						<div className="text-center">
							<div>{start_post.format('D MMM YYYY')}</div>
							<div>{start_post.format('HH:mm')}</div>
						</div>
					</td>
				)
			},
		},
		{
			label: 'END SHARING',
			path: 'end_post',
			content: (newsfeed) => {
				if (newsfeed.end_post) {
					const end_post = moment(newsfeed.end_post, 'DD/MM/YYYY HH:mm')
					return (
						<td
							className={`px-2 py-4 ${
								newsfeed.status === 'Archived'
									? 'opacity-25'
									: 'opacity-100'
							}`}
						>
							<div className="text-center">
								<div>{end_post.format('D MMM YYYY')}</div>
								<div>{end_post.format('HH:mm')}</div>
							</div>
						</td>
					)
				}
				return (
					<td
						className={`px-2 py-4 text-center ${
							newsfeed.status === 'Archived' ? 'opacity-25' : 'opacity-100'
						}`}
					>
						-
					</td>
				)
			},
		},
		{
			label: 'CREATED ON',
			path: 'created_at',
			content: (newsfeed) => (
				<td
					className={`px-2 py-4 text-center ${
						newsfeed.status === 'Archived' ? 'opacity-25' : 'opacity-100'
					}`}
				>
					<div>{moment(newsfeed.created_at).format('D MMM YYYY')}</div>
					<div className="text-primary">{newsfeed.created_by_name}</div>
				</td>
			),
		},
		{
			label: 'LAST UPDATED',
			path: 'updated_at',
			content: (newsfeed) => {
				const updated_at = moment(newsfeed.updated_at)

				return (
					<td
						className={`px-2 py-4 text-center ${
							newsfeed.status === 'Archived' ? 'opacity-25' : 'opacity-100'
						}`}
					>
						<div>{updated_at.format('D MMM YYYY')}</div>
						<div className="text-primary">{updated_at.fromNow()}</div>
					</td>
				)
			},
		},
		{
			key: 'action',
			content: (newsfeed) => {
				const classes = 'px-4 py-2 block hover:bg-blueSecondary'
				const actions = [
					{
						key: 'viewEdit',
						helper: (
							<li className={classes}>
								<Link to={`/newsfeed/edit/${newsfeed.news_id}`}>
									View & Edit
								</Link>
							</li>
						),
					},
					{
						key: 'archived',
						path: 'Archived',
						helper: (
							<li
								className={classes}
								onClick={() => props.onArchive(newsfeed)}
							>
								Archive
							</li>
						),
					},
					{
						key: 'delete',
						helper: (
							<li className={classes} onClick={() => props.onDelete(newsfeed)}>
								Delete
							</li>
						),
					},
				]

				const filtered = actions.filter((action) => action.path !== newsfeed.status)
				return <ActionsRow actions={filtered} />
			},
		},
	]

	return (
		<div className="bg-white shadow-md overflow-x-auto mx-2 md:mx-0">
			<table className="min-w-full">
				<TableHeader
					columns={columns}
					sortColumn={props.sortColumn}
					onSort={props.onSort}
				/>
				<TableBody data={props.newsfeeds} columns={columns} />
			</table>
		</div>
	)
}

export default NewsfeedTable
