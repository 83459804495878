import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import { Redirect } from 'react-router-dom'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as EmailIcon } from 'assets/icons/campaign.svg'
import Loading from 'components/commons/Loading'
import { getListEmailCampaign, deleteEmailCampaign } from 'libs/apis/emailCampaign'
import EmailListTable from '../../components/emailList/EmailListTable'
import alert from 'services/alert'
import PaginationPage from 'components/commons/PaginationPage'
import { failedFetch } from 'libs/utils/messages'
import Searchbar from 'components/commons/Searchbar'

export default function ListEmailCampaign(props) {
	const [listEmailCampaign, setlistEmailCampaign] = useState([])
	const [errorMessage, setErrorMessage] = useState('')
	const [currentPage, setCurrentPage] = useState(0)
	const [lastPage, setLastPage] = useState(1)
	const [isLoading, setIsLoading] = useState(false)
	const [isRedirect, setIsRedirect] = useState(false)
	const [filterKeyword, setFilterKeyword] = useState('')
	const [orderBy, setOrderBy] = useState('')
	const [orderDir, setOrderDir] = useState('')

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [{ url: '', title: 'List of Emails' }]
	const page = props.location.search ? queryString.parse(props.location.search).page : 1
	const parsed = queryString.parse(props.location.search)
	const active = `${props.location.pathname}?page=${parsed.page || 1}`

	const fetchListEmailCampaign = React.useCallback(async () => {
		try {
			setIsLoading(true)
			const response = await getListEmailCampaign(page, filterKeyword, orderBy, orderDir)

			const { data, current_page, last_page } = response.data.data

			if (response.data.api_status) {
				setlistEmailCampaign(data)
				setCurrentPage(current_page)
				setLastPage(last_page)
			} else {
				setIsLoading(false)
				setErrorMessage(failedFetch)
			}
			setIsLoading(false)
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.removeItem('token')
					setIsRedirect(true)
				} else if (error.response.status >= 500) {
					setIsLoading(false)
					setErrorMessage(failedFetch)
				}
			}
		}
	}, [page, filterKeyword, orderBy, orderDir])

	const handleDelete = async id => {
		let res1 = await alert.confirmDelete()
		if (res1.value) {
			let res = await deleteEmailCampaign(id)

			if (res.data.api_status) {
				alert.succeed(`Success`, res.data.message)
				fetchListEmailCampaign()
			} else {
				alert.failed(`Failed`, `Cannot delete email campaign`)
			}
		} else {
			return
		}
	}

	const handleSort = sort => {
		if (orderBy !== sort) {
			setOrderBy(sort)
			setOrderDir('asc')
		} else {
			if (orderDir === 'asc') {
				setOrderBy(sort)
				setOrderDir('desc')
			} else if (orderDir === 'desc') {
				setOrderBy(sort)
				setOrderDir('asc')
			}
		}
	}

	const handleOnSearch = event => {
		setFilterKeyword(event.target.value)
	}

	useEffect(() => {
		fetchListEmailCampaign()
	}, [page, filterKeyword, orderBy, orderDir, fetchListEmailCampaign])

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - List of Emails </title>
			</Helmet>
			<PageBreadcrumbs icon={EmailIcon} title="List of Emails" breads={breads} />
			<div>
				<Searchbar filterKeyword={filterKeyword} handleOnSearch={handleOnSearch} />
				{isLoading && !errorMessage && <Loading />}
				{!isLoading && !errorMessage && listEmailCampaign.length > 0 && (
					<div>
						<EmailListTable list={listEmailCampaign} onDelete={handleDelete} handleOnSort={handleSort} />
						<PaginationPage currentPage={currentPage} lastPage={lastPage} activeLink={active} url={props.location.pathname} />
					</div>
				)}
				{!isLoading && !errorMessage && !listEmailCampaign.length && <div className="text-center">No data available</div>}
				{!isLoading && errorMessage && <div className="text-center">{errorMessage}</div>}
			</div>
		</div>
	)
}
