import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import { Redirect } from 'react-router-dom'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as EmailIcon } from 'assets/icons/campaign.svg'
import Loading from 'components/commons/Loading'
import PaginationPage from 'components/commons/PaginationPage'
import { failedFetch } from 'libs/utils/messages'
import { getListPushNotification, deletePushNotif } from 'libs/apis/pushNotif'
import PushNotifTable from 'components/pusNotifList/PushNotifTable'
import alert from 'services/alert'
import Searchbar from 'components/commons/Searchbar'

function ListPushNotif(props) {
	const [listPushNotification, setListPushNotification] = useState([])
	const [errorMessage, setErrorMessage] = useState('')
	const [currentPage, setCurrentPage] = useState(0)
	const [lastPage, setLastPage] = useState(1)
	const [isLoading, setIsLoading] = useState(false)
	const [isRedirect, setIsRedirect] = useState(false)
	const [filterKeyword, setFilterKeyword] = useState('')
	const [orderBy, setOrderBy] = useState('')
	const [orderDir, setOrderDir] = useState('')

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [{ url: '', title: 'List of Push Notifications' }]

	const parsed = queryString.parse(props.location.search)
	const active = `${props.location.pathname}?page=${parsed.page || 1}`
	const page = props.location.search ? queryString.parse(props.location.search).page : 1

	const fetchListPushNotification = React.useCallback(async () => {
		try {
			setIsLoading(true)

			const response = await getListPushNotification(page, filterKeyword, orderBy, orderDir)

			if (response.data.api_status) {
				const { data, current_page, last_page } = response.data.data

				setListPushNotification(data)
				setCurrentPage(current_page)
				setLastPage(last_page)

				setIsLoading(false)
			} else {
				setIsLoading(false)
				setErrorMessage(failedFetch)
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
					setIsRedirect(true)
				} else if (error.response.status >= 500) {
					setIsLoading(false)
					setErrorMessage(failedFetch)
				}
			}
		}
	}, [page, filterKeyword, orderBy, orderDir])

	const handleSort = sort => {
		if (orderBy !== sort) {
			setOrderBy(sort)
			setOrderDir('asc')
		} else {
			if (orderDir === 'asc') {
				setOrderBy(sort)
				setOrderDir('desc')
			} else if (orderDir === 'desc') {
				setOrderBy(sort)
				setOrderDir('asc')
			}
		}
	}

	const handleOnSearch = event => {
		setFilterKeyword(event.target.value)
	}

	const handleDelete = async id => {
		let res = await alert.confirmDelete()
		if (res.value) {
			let res = await deletePushNotif(id)

			if (res.data.api_status) {
				alert.succeed(`Success`, res.data.message)
				fetchListPushNotification()
			} else {
				alert.failed(`Failed`, `Cannot delete push notification`)
			}
		} else {
			return
		}
	}

	useEffect(() => {
		fetchListPushNotification()
	}, [fetchListPushNotification, page, filterKeyword, orderBy, orderDir])

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - List of Push Notifications </title>
			</Helmet>
			<PageBreadcrumbs icon={EmailIcon} title="List of Push Notifications" breads={breads} />
			<div>
				<Searchbar filterKeyword={filterKeyword} handleOnSearch={handleOnSearch} />
				{isLoading && !errorMessage && <Loading />}
				{!isLoading && !errorMessage && listPushNotification.length > 0 && (
					<div>
						<PushNotifTable list={listPushNotification} onDelete={handleDelete} handleOnSort={handleSort} />
						<PaginationPage currentPage={currentPage} lastPage={lastPage} activeLink={active} length={listPushNotification.length} url={props.location.pathname} />
					</div>
				)}
				{!isLoading && !errorMessage && !listPushNotification.length && <div className="text-center">No Data Available</div>}
				{!isLoading && errorMessage && <div className="text-center">{errorMessage}</div>}
			</div>
		</div>
	)
}

export default ListPushNotif
