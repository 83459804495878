import React, { Component } from 'react'

class TableHeader extends Component {
	raiseSort = path => {
		const sortColumn = { ...this.props.sortColumn }
		if (sortColumn.path === path) {
			sortColumn.order = sortColumn.order === 'asc' ? 'desc' : 'asc'
		} else {
			sortColumn.path = path
			sortColumn.order = 'asc'
		}
		this.props.onSort(sortColumn)
	}

	renderSortIcon = column => {
		const { sortColumn } = this.props
		if (column.key) return null
		if (column.path !== sortColumn.path)
			return (
				<i
					className="fas fa-sort cursor-pointer"
					aria-hidden="true"
					onClick={() => this.raiseSort(column.path)}
				></i>
			)
		if (sortColumn.order === 'asc')
			return (
				<i
					className="fas fa-sort-up cursor-pointer"
					aria-hidden="true"
					onClick={() => this.raiseSort(column.path)}
				></i>
			)
		return (
			<i
				className="fas fa-sort-down cursor-pointer"
				aria-hidden="true"
				onClick={() => this.raiseSort(column.path)}
			></i>
		)
	}

	render() {
		return (
			<thead className="bt-blueSecondary border-2 border-solid">
				<tr>
					{this.props.columns.map(column => {
						if (column.length) {
							return column.map(item => <th key={item.key}>{item.label}</th>)
						}
						return (
							<th key={column.key || column.label} className="px-2 py-4">
								{column.label} {this.renderSortIcon(column)}
							</th>
						)
					})}
				</tr>
			</thead>
		)
	}
}

export default TableHeader
