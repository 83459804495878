import React, { useContext, useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { MinistryContext } from 'screens/ministry/ListMinistry'
import { partial } from 'libs/utils/utils'

function MinistryTable({ handleSort, handleSortByStatus, handleCloseGroup }) {
	const handleSortName = partial(handleSort, 'ministry_name')
	const handleSortCreatedAt = partial(handleSort, 'created_at')
	const handleSortStatus = partial(handleSortByStatus, 'filter_status')

	return (
		<div className="bg-white shadow-md">
			<table className="w-full rounded-lg mt-10">
				<MinistryTableHead
					handleSortName={handleSortName}
					handleSortCreatedAt={handleSortCreatedAt}
					handleSortStatus={handleSortStatus}
				/>
				<MinistryTableBody handleCloseGroup={handleCloseGroup} />
			</table>
		</div>
	)
}

export default MinistryTable

const MinistryTableHead = ({ handleSortName, handleSortCreatedAt, handleSortStatus }) => {
	return (
		<thead className="bt-blueSecondary border-2 border-solid">
			<tr>
				<th className="text-left pl-8 py-3">
					MINISTRY NAME
					<i
						className="ml-1 text-center fas fa-sort sortby cursor-pointer"
						onClick={handleSortName}
					/>
				</th>
				<th className="text-left">MINISTRY HEAD</th>
				<th className="text-center">
					RECRUITMENT STATUS
					<i
						className="ml-1 text-center fas fa-sort sortby cursor-pointer"
						onClick={handleSortStatus}
					/>
				</th>
				<th className="text-center">
					CREATED ON
					<i
						className="ml-1 text-center fas fa-sort sortby cursor-pointer"
						onClick={handleSortCreatedAt}
					/>
				</th>
				<th className="py-4" />
			</tr>
		</thead>
	)
}

const MinistryTableBody = ({ handleCloseGroup }) => {
	const ministry = useContext(MinistryContext)

	return (
		<tbody className="border-2 border-solid">
			{ministry.map(value => (
				<MinistryTableRow
					key={value.ministry_id}
					{...value}
					handleCloseGroup={() => handleCloseGroup(value.ministry_id)}
				/>
			))}
		</tbody>
	)
}

const MinistryTableRow = ({
	ministry_id,
	ministry_name,
	leader_name,
	status,
	created_at,
	created_by_name,
	handleCloseGroup,
}) => {
	const [dropDownOpen, setDropDownOpen] = useState(false)
	const wrapperRef = useRef(null)

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside, false)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside, false)
		}
	}, [])

	const handleClickOutside = event => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setDropDownOpen(false)
		}
	}

	const handleToggle = () => {
		setDropDownOpen(!dropDownOpen)
	}

	const styleDropDownOpen = `z-20 shadow-md absolute right-20px ${
		dropDownOpen ? 'block' : 'hidden'
	}`

	return (
		<tr className="border border-solid bt-blueSecondary">
			<td className="text-left pl-8 capitalize">{ministry_name}</td>
			<td className="text-left capitalize">{leader_name}</td>
			<td className="text-center capitalize">{status}</td>

			<td className="text-center capitalize">
				{created_at}
				<p className="text-primary capitalize">{created_by_name}</p>
			</td>

			<td className="px-2 py-4">
				<div className="cursor-pointer" onClick={handleToggle}>
					<i className="dropdown-trigger fas fa-ellipsis-v" />
					<div className={styleDropDownOpen}>
						<ul ref={wrapperRef} className="bg-white">
							<li>
								<Link
									className="px-4 py-2 block hover:bg-blueSecondary"
									to={`/ministry/detail/${ministry_id}`}
								>
									View
								</Link>
							</li>
							<Link
								className="px-4 py-2 block hover:bg-blueSecondary"
								to={`/ministry/edit/${ministry_id}`}
							>
								Edit
							</Link>
							<li
								className="px-4 py-2 block hover:bg-blueSecondary"
								onClick={handleCloseGroup}
							>
								Close Ministry
							</li>
						</ul>
					</div>
				</div>
			</td>
		</tr>
	)
}
