import React from 'react'
import { Helmet } from 'react-helmet'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg'
import OverviewsReport from 'components/dataReport/OverviewsReport'
import ServiceAttendance from 'components/dataReport/ServiceAttendance'
import MonthlyActiveUser from 'components/dataReport/MonthlyActiveUser'
import MarriageContainer from 'components/dataReport/MarriageContainer'
import GenderContainer from 'components/dataReport/GenderContainer'
import AgeGroupContainer from 'components/dataReport/AgeGroupContainer'
import ExportServiceAttendance from 'components/dataReport/ExportServiceAttendance'
import SmallGroupReport from 'components/dataReport/SmallGroupReport'
import SmallGroupAttendance from 'screens/smallGroup/SmallGroupAttendance'
import MemberAndVisitor from '../smallGroup/MemberAndVisitor'
import MemberAttendanceRecord from '../smallGroup/MemberAttendanceRecord'

function DataReport({ history }) {
	const church = JSON.parse(localStorage.getItem('church'))

	return (
		<div className="main-content bg-gray-100 pb-24 md:p-6 lg:w-5/6 w-full">
			<Helmet>
				<title>{church.church_name} - Data Report</title>
			</Helmet>
			<PageBreadcrumbs icon={HomeIcon} title="Data Report" breads={[{ url: '', title: 'Data Report' }]} />
			<OverviewsReport />
			<div className="flex mb-6">
				<div className="w-2/3">
					<div className="mr-6">
						<div className="flex flex-col h-full">
							<ServiceAttendance history={history} />
							<MonthlyActiveUser history={history} />
							<SmallGroupAttendance history={history} />
						</div>
					</div>
				</div>
				<div className="w-1/3 flex-col justify-between">
					<MarriageContainer history={history} />
					<GenderContainer history={history} />
					<AgeGroupContainer history={history} />
					<MemberAttendanceRecord history={history} />
					<MemberAndVisitor history={history} />
				</div>
			</div>
			<ExportServiceAttendance history={history} />
			<SmallGroupReport history={history} />
		</div>
	)
}

export default DataReport
