import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Field, FieldArray } from 'formik'
import Loading from 'components/commons/Loading'
import ImageUpload from 'components/commons/ImageUpload'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import HashTagsForm from 'components/commons/HashTagsForm'
import DateTimeSelect from 'components/commons/DateTimeSelect'
import NewsfeedPreview from 'components/formik/NewsfeedPreview'
import { cancelConfirmation } from 'libs/utils/confirmation'
import { createEmailCampaign } from '../../libs/apis/emailCampaign'
import { createPushNotif } from '../../libs/apis/pushNotif'
import alert from 'services/alert'
import moment from 'moment'

class DevotionForm extends Component {
	state = {
		isRedirect: false,
		sendAsCampaign: false,
		sendAsCampaignValue: '',
	}

	handleCheckSendAsCampaign = (e) => {
		this.setState({
			...this.state,
			sendAsCampaign: !this.state.sendAsCampaign,
		})
	}

	handleSendAsCampaign = (e) => {
		this.setState({ ...this.state, sendAsCampaignValue: e.target.value })
	}

	handleCancel = async (event) => {
		event.preventDefault()
		const response = await cancelConfirmation()
		if (response.value) {
			this.setState({ isRedirect: true })
		}
	}

	handleOnDraft = (e) => {
		const { setFieldValue, handleSubmit } = this.props
		setFieldValue('isDraft', true)
		handleSubmit(e)
	}

	handleOnCheck = (e) => {
		const { setFieldValue, values } = this.props
		setFieldValue('endDateCheck', !values.endDateCheck)

		if (values.endDateCheck) {
			setFieldValue('endDate', '')
		}
	}

	renderFieldCheck = (field) => {
		const { values } = this.props
		return <input {...field} type="checkbox" className="form-checkbox border-2" value={values.endDateCheck} checked={values.endDateCheck} onChange={this.handleOnCheck} />
	}

	handleOnDescription = (value) => {
		this.props.setFieldValue('description', value)
	}

	handleOnSubmit = async () => {
		const res = await alert.confirmComplete('Are you sure wanna proceed the data')

		if (res.value) {
			if (this.state.sendAsCampaignValue === 'createCampaignByEmail') {
				this.setState({ ...this.state, isSubmitting: true })

				let formdata = {
					subject: this.props.values.title,
					content: this.props.values.description,
					schedule: this.props.values.startDate,
				}

				let res = await createEmailCampaign(formdata)

				if (res.data.api_status) {
					this.props.handleSubmit()

					this.setState({ ...this.state, isSubmitting: false })
				} else {
					alert.failed(`Failed`, res.data.message)
					this.setState({ ...this.state, isSubmitting: false })
				}
			}
			if (this.state.sendAsCampaignValue === 'createCampaignByNotif') {
				this.setState({ ...this.state, isSubmitting: true })

				let formdata = {
					title: this.props.values.title,
					body: this.props.values.description,
					schedule: this.props.values.startDate,
				}
				let res = await createPushNotif(formdata)
				if (res.data.api_status) {
					this.props.handleSubmit()

					this.setState({ ...this.state, isSubmitting: false })
				} else {
					alert.failed(`Failed`, res.data.message)
					this.setState({ ...this.state, isSubmitting: false })
				}
			}
			if (this.state.sendAsCampaignValue === 'createBothCampaigns') {
				this.setState({ ...this.state, isSubmitting: true })

				let formdataCampaign = {
					subject: this.props.values.title,
					content: this.props.values.description,
					schedule: this.props.values.startDate,
				}

				let formdataPushNotif = {
					title: this.props.values.title,
					body: this.props.values.description,
					schedule: this.props.values.startDate,
				}

				let resCreateEmailCampaign = await createEmailCampaign(formdataCampaign)
				let resCreatePushNotif = await createPushNotif(formdataPushNotif)
				if (resCreateEmailCampaign.data.api_status && resCreatePushNotif.data.api_status) {
					this.props.handleSubmit()
				} else {
					alert.failed(`Failed`, resCreateEmailCampaign.data.message)

					this.setState({ ...this.state, isSubmitting: false })
				}
			}
			if (this.state.sendAsCampaignValue === '') {
				this.props.handleSubmit()
			}
		} else {
			this.setState({ ...this.state, isSubmitting: false })
		}
	}

	renderImageUpload = () => {
		const { errors } = this.props
		return (
			<div className="mb-6">
				<label className="block text-gray-700 text-sm font-bold mb-2">UPLOAD IMAGE</label>
				<Field name="image" component={ImageUpload} />
				{errors.image && <div className="text-red-500 text-xs italic">{errors.image}</div>}
			</div>
		)
	}

	renderVideoUpload = () => {
		const { errors } = this.props
		const style = `appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none ${errors.video ? 'border-red-500' : 'border-secondary'}`.trim()

		return (
			<div className="mb-6">
				<label className="block text-gray-700 text-sm font-bold mb-2">VIDEO URL</label>
				<Field type="text" name="video" className={style} placeholder="Ex: http://www.abcdefghijklmn.com" />
				{errors.video && <div className="text-red-500 text-xs italic">{errors.video}</div>}
			</div>
		)
	}

	renderEndSharingOn = () => {
		const { values } = this.props
		return <Field name="endDate" minDate={values.startDate} component={DateTimeSelect} />
	}

	renderSendAsCampaign = () => {
		return (
			<div className="flex pt-5">
				<div className="w-1/2">
					<select className="form-select block w-full border-2 rounded-lg border-secondary" onChange={this.handleSendAsCampaign}>
						<option value="">Choose</option>
						<option value="createCampaignByEmail">Create campaign by email</option>
						<option value="createCampaignByNotif">Create campaign notification</option>
						<option value="createBothCampaigns">Create both campaigns</option>
					</select>
				</div>
			</div>
		)
	}

	handleStartSharing = (date) => {
		this.props.setFieldValue('start_post', moment(date[0]).format('DD/MM/YYYY HH:mm'))
	}

	render() {
		const { isRedirect } = this.state
		const { values, errors, isSubmitting } = this.props

		if (isRedirect) {
			return <Redirect to="/devotion/list" />
		}

		return (
			<div className="flex">
				<div className="w-3/4 mr-6">
					<div className="bg-white p-6 shadow-md">
						<div className="mb-6">
							<label className="block text-gray-700 text-sm font-bold mb-2">TYPE</label>
							<Field component="select" className="form-select block w-full border-secondary border-2 rounded-lg" name="media">
								<option value="Image">Article & Image</option>
								<option value="Video">Article & Video</option>
							</Field>
						</div>
						{values.media === 'Image' ? this.renderImageUpload() : this.renderVideoUpload()}
						<div className="mb-6">
							<label className="block text-gray-700 text-sm font-bold mb-2">TAGS</label>
							<FieldArray name="hashtags" component={HashTagsForm} validateOnChange={false} />
						</div>
						<div className="mb-6">
							<label className="block text-gray-700 text-sm font-bold mb-2">TITLE</label>
							<Field name="title" type="text" className="appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none" />
							{errors.title && <div className="text-red-500 text-xs italic">{errors.title}</div>}
						</div>
						<div className="mb-6">
							<label className="block text-gray-700 text-sm font-bold mb-2">ARTICLE</label>
							<ReactQuill value={values.description} onChange={this.handleOnDescription} />
						</div>
						<div className="flex mb-4">
							<div className="w-1/2">
								<div className="mb-6 mr-4">
									<label className="block text-gray-700 text-sm font-bold mb-2">START SHARING ON</label>
									<Field name="startDate" component={DateTimeSelect} />
								</div>
							</div>
							<div className="w-1/2">
								<div className="mb-6">
									<label className="inline-flex items-center mb-2">
										<Field name="endDateCheck">{({ field }) => this.renderFieldCheck(field)}</Field>
										<span className="text-gray-700 text-sm font-bold ml-2">END SHARING ON</span>
									</label>
									{values.endDateCheck && this.renderEndSharingOn()}
								</div>
							</div>
						</div>
						<div className="mb-6">
							<label className="inline-flex items-center">
								<Field className="form-checkbox border-2" type="checkbox" name="isPublish" checked={values.isPublish} />
								<span className="ml-2">Share to Newsfeed</span>
							</label>
						</div>
						<div className="mb-6">
							<label className="inline-flex items-center">
								<Field className="form-checkbox border-2" type="checkbox" name="isHighLight" checked={values.isHighLight} />
								<span className="ml-2">Set as highlight</span>
							</label>
						</div>
						<div className="mb-6">
							<label className="items-center mb-2">
								<input type="checkbox" name="sendAsCampaign" className="form-checkbox border-2" onClick={this.handleCheckSendAsCampaign} />
								<span className="text-gray-700 text-md ml-2">Send as campaign</span>
								{this.state.sendAsCampaign ? this.renderSendAsCampaign() : null}
							</label>
						</div>
						<div className="flex">
							<div className="w-1/2">
								<button type="submit" className="font-bold bg-grey-300 text-white rounded-lg py-2 px-6 focus:outline-none" onClick={this.handleCancel}>
									{isSubmitting ? <Loading secondary /> : 'Cancel'}
								</button>
							</div>
							<div className="w-1/2">
								<div className="flex justify-end">
									<button
										type="submit"
										className="mr-3 font-bold bg-white border-primary border-solid border-2 text-primary rounded-lg py-2 px-6 focus:outline-none"
										onClick={this.handleOnDraft}
										disabled={isSubmitting}
									>
										{isSubmitting ? <Loading primary /> : 'Save as Draft'}
									</button>
									<button
										type="submit"
										className="ml-3 font-bold bg-primary rounded-lg py-2 px-6 text-white focus:outline-none"
										onClick={this.handleOnSubmit}
										disabled={isSubmitting}
									>
										{isSubmitting ? <Loading secondary /> : 'Publish'}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="w-1/4">
					<div className="bg-white p-6 shadow-md">
						<NewsfeedPreview {...this.props} />
					</div>
				</div>
			</div>
		)
	}
}

export default DevotionForm
