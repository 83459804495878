import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as DevIcon } from 'assets/icons/devotion.svg'
import Loading from 'components/commons/Loading'
import { devotionDetails } from 'libs/apis/devotion'
import { devotionCategories } from 'libs/apis/devotion'
import DevotionFormik from 'components/formik/DevotionFormik'

class EditDev extends Component {
	state = {
		isLoading: false,
		isRedirect: false,
		errorMessage: '',
		categories: [],
		category: '',
		title: '',
		id: '',
		description: '',
		startDate: '',
		endDate: '',
		hashtags: [],
		media: '',
		video: '',
		image: '',
		isPublish: false,
		isHighLight: false,
	}

	componentDidMount() {
		this.setState({ isLoading: true }, async () => {
			const errorMessage = 'An unexpected error has occurred. We are currently investigating the issue, please try again later.'

			try {
				const id = this.props.match.params.id
				const responseDevotion = await devotionDetails(id)
				const responseCategories = await devotionCategories()
				if (responseDevotion.data.api_status && responseCategories.data.api_status) {
					const devotion = responseDevotion.data.data
					const categories = responseCategories.data.data
					const description = devotion.description
					const media = devotion.media_type
					const video = media === 'Video' ? devotion.media_url : ''
					const image = media === 'Image' ? devotion.media_url : ''
					this.setState({
						isLoading: false,
						id: id,
						title: devotion.title,
						startDate: devotion.start_post,
						endDate: devotion.end_post,
						hashtags: devotion.hashtags,
						description,
						video,
						image,
						media,
						category: devotion.category_id,
						isPublish: devotion.is_publish,
						categories,
						isHighLight: devotion.is_highlight,
					})
				} else {
					this.setState({ isLoading: false, errorMessage: errorMessage })
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						await localStorage.removeItem('token')
						this.setState({ isRedirect: true })
					} else if (error.response.status >= 500) {
						this.setState({
							isLoading: false,
							errorMessage: errorMessage,
							isRedirect: true,
						})
					}
				}
			}
		})
	}

	render() {
		const { isLoading, isRedirect, errorMessage, id, title, description, startDate, endDate, hashtags, media, video, image, categories, category, isPublish, isHighLight } = this.state

		const church = JSON.parse(localStorage.getItem('church'))
		const name = church.church_name ? church.church_name : '-'
		const breads = [
			{ url: '/devotion/list', title: 'List of All' },
			{ url: '', title: 'Edit Devotion' },
		]

		const shouldDisplayLoad = isLoading && !errorMessage
		const shouldDisplayForm = !isLoading && !errorMessage
		const shouldDisplayError = !isLoading && errorMessage

		if (isRedirect) {
			return <Redirect to="/login" />
		}

		return (
			<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
				<Helmet>
					<title>{name} - Edit Devotion</title>
				</Helmet>
				<PageBreadcrumbs icon={DevIcon} title="Edit Devotion" breads={breads} />
				{shouldDisplayLoad && <Loading />}
				{shouldDisplayForm && (
					<DevotionFormik
						{...this.props}
						id={id}
						title={title}
						description={description}
						startDate={startDate}
						endDate={endDate}
						hashtags={hashtags}
						media={media}
						video={video}
						image={image}
						isCreateForm={false}
						categories={categories}
						category={category}
						isPublish={isPublish}
						isHighLight={isHighLight}
					/>
				)}
				{shouldDisplayError && <div className="align-center">{errorMessage}</div>}
			</div>
		)
	}
}

export default EditDev
