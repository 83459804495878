import React from 'react'
import Flatpickr from 'react-flatpickr'
import Loading from 'components/commons/Loading'

const PrayerReqSearch = ({
	handleOnDate,
	handleOnCategory,
	category,
	categories,
	handleOnExport,
	isExport,
}) => (
	<div className="mb-6">
		<div className="inline-block">
			<div className="shadow-md flex rounded-lg bg-white px-3 py-2 mr-6">
				<Flatpickr
					placeholder="All Times"
					onChange={handleOnDate}
					options={{
						mode: 'range',
						dateFormat: 'YYYY-MM-DD',
						altInput: true,
						altFormat: 'd M Y',
						enableTime: false,
					}}
				/>
				<div className="input-group-prepend">
					<i className="far fa-calendar-alt" />
				</div>
			</div>
		</div>
		<div className="inline-block">
			<select
				className="form-select border-0 rounded-lg shadow-md"
				name="category"
				onChange={handleOnCategory}
				value={category}
			>
				<option value="">All Items</option>
				{categories.length > 0 &&
					categories.map(item => (
						<option key={item.id} value={item.id}>
							{item.title}
						</option>
					))}
			</select>
		</div>
		<div className="inline-block float-right">
			<button
				className="bg-greyExport text-white rounded-lg py-2 px-6"
				disabled={isExport}
				onClick={handleOnExport}
			>
				{isExport ? <Loading secondary /> : 'Export'}
			</button>
		</div>
	</div>
)

export default PrayerReqSearch
