import React from 'react'
import moment from 'moment'

const CourseInfoCard = ({ color, courseName, startDate, endDate, startTime, endTime, venueName, venueAddress }) => {
	return (
		<div className="flex bg-white shadow-md rounded-lg" style={{ height: '330px' }}>
			<div className="w-5 mr-5">
				<div className={`${color} rounded-l-lg h-full`} />
			</div>
			<div className="w-10/12">
				<div className="py-4 break-words">
					<div>
						<label className="font-bold text-sm">Class Name:</label>
						<p>{courseName}</p>
					</div>
					<div className="mt-2">
						<label className="font-bold text-sm">Date & Time:</label>
						{startDate && endDate && <div>{`${moment(startDate, 'DD/MM/YYYY').format('DD MMMM YYYY')} - ${moment(endDate, 'DD/MM/YYYY').format('DD MMMM YYYY')}`}</div>}
						<div>{startTime && endTime ? `${startTime} - ${endTime}` : ''}</div>
					</div>
					<div className="mt-2">
						<label className="font-bold text-sm">Venue:</label>
						<p>{venueName}</p>
						<p>{venueAddress}</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CourseInfoCard
