import React from 'react'
import ServiceItem from './ServiceItem'
import { ServiceListContainer } from './styles'

const ServiceList = ({ list, isEditServices, handleOnDelete, handleOnEditService, handleOnCancelEditService, isSaved1 }) => (
  <ServiceListContainer>
    {list
      .filter((item) => !item.is_special)
      .map((service) => (
        <ServiceItem
          // isSaved1={isSaved1}
          key={service.id}
          id={service.id}
          title={service.title}
          isEditServices={isEditServices}
          handleOnDelete={handleOnDelete}
          handleOnEditService={handleOnEditService}
          handleOnCancelEditService={handleOnCancelEditService}
        />
      ))}
  </ServiceListContainer>
)

export default ServiceList
