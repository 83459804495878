import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as EventIcon } from 'assets/icons/events.svg'
import Loading from 'components/commons/Loading'
import PaginationPage from 'components/commons/PaginationPage'
import EventListTable from 'components/events/EventListTable'
import { eventList, archiveEvent, highglightEvent, unhighlightEvent } from 'libs/apis/events'
import { findEventById, setEventArchive, updateEvents, setEventHighlight, setEventUnHighlight } from 'libs/utils/eventshelper'
import { archiveItemConfirm } from 'libs/utils/confirmation'
import Searchbar from 'components/commons/Searchbar'
import Select from 'components/commons/Select'
import { constants } from 'constants/constant'

class ListEvent extends Component {
	state = {
		isloading: false,
		isredirect: false,
		filterKeyword: '',
		filterStatus: '',
		orderby: '',
		orderdir: '',
		events: [],
		currentpage: 0,
		lastpage: 1,
		error: '',
	}

	componentDidMount() {
		this.setState({ isloading: true }, async () => {
			await this.handleLoadDatas()
		})
	}

	componentDidUpdate(prevProps) {
		const { location } = this.props
		if (location.search && location.search !== prevProps.location.search) {
			this.setState({ isloading: true }, async () => {
				await this.handleLoadDatas()
			})
		}
	}

	handleOnSearch = (event) => {
		const { name, value } = event.target
		const { history, location } = this.props

		this.setState({ [name]: value, isloading: true }, async () => {
			history.push(`${location.pathname}?page=1`)
			await this.handleLoadDatas()
		})
	}

	async handleLoadDatas() {
		try {
			const { filterKeyword, filterStatus, orderby, orderdir } = this.state
			const { search } = this.props.location
			const page = search ? queryString.parse(search).page : 1
			const response = await eventList(page, filterKeyword, filterStatus, orderby, orderdir)
			if (response.data.api_status) {
				this.setState({
					isloading: false,
					currentpage: response.data.data.current_page,
					lastpage: response.data.data.last_page,
					events: response.data.data.data,
				})
			} else {
				this.setState({
					isloading: false,
					error: 'An unexpected error has occurred. We are currently investigating the issue, please try again later.',
				})
			}
		} catch (error) {
			if (error.response) {
				if (error.response.filterstatus === 401) {
					await localStorage.removeItem('token')
					this.setState({ isredirect: true })
				} else {
					this.setState({
						isloading: false,
						error: 'An unexpected error has occurred. We are currently investigating the issue, please try again later.',
					})
				}
			}
		}
	}

	handleOnSort = (sort) => {
		const order = this.state.orderdir === 'asc' && this.state.orderby === sort ? 'desc' : 'asc'
		this.setState({ isloading: true, orderby: sort, orderdir: order }, async () => {
			try {
				const { currentpage, filterKeyword, filterStatus, orderby, orderdir } = this.state
				const events = await eventList(currentpage, filterKeyword, filterStatus, orderby, orderdir)

				if (events.data.api_status) {
					this.setState({ isloading: false, events: events.data.data.data })
				} else {
					this.setState({
						isloading: false,
						error: 'An unexpected error has occurred. We are currently investigating the issue, please try again later.',
					})
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						await localStorage.removeItem('token')
						this.setState({ isredirect: true })
					} else {
						this.setState({
							isloading: false,
							error: 'An unexpected error has occurred. We are currently investigating the issue, please try again later.',
						})
					}
				}
			}
		})
	}

	handleOnArchive = async (id) => {
		const confirm = await archiveItemConfirm()
		if (confirm.value) {
			this.setState({ isloading: true }, async () => {
				try {
					const { events } = this.state
					const response = await archiveEvent(id)
					if (response.data.api_status) {
						const event = findEventById(id, events)
						const updatedEvent = setEventArchive(event)
						const updatedEvents = updateEvents(events, updatedEvent)
						this.setState({ events: updatedEvents, isloading: false })
					} else {
						this.setState({ isloading: false })
					}
				} catch (error) {
					if (error.response) {
						if (error.response.filterstatus === 401) {
							await localStorage.removeItem('token')
							this.setState({ isredirect: true })
						} else {
							this.setState({
								isloading: false,
								error: 'An unexpected error has occurred. We are currently investigating the issue, please try again later.',
							})
						}
					}
				}
			})
		}
	}

	handleOnHighlight = (id) => {
		this.setState({ isloading: true }, async () => {
			try {
				const { events } = this.state
				const response = await highglightEvent(id)
				if (response.data.api_status) {
					const event = findEventById(id, events)
					const updatedEvent = setEventHighlight(event)
					const updatedEvents = updateEvents(events, updatedEvent)
					this.setState({ isloading: false, events: updatedEvents })
				} else {
					this.setState({ isloading: false })
				}
			} catch (error) {
				if (error.response) {
					if (error.response.filterstatus === 401) {
						await localStorage.removeItem('token')
						this.setState({ isredirect: true })
					} else {
						this.setState({
							isloading: false,
							error: 'An unexpected error has occurred. We are currently investigating the issue, please try again later.',
						})
					}
				}
			}
		})
	}

	handleOnUnhighlight = (id) => {
		this.setState({ isloading: true }, async () => {
			try {
				const { events } = this.state
				const response = await unhighlightEvent(id)
				if (response.data.api_status) {
					const event = findEventById(id, events)
					const updatedEvent = setEventUnHighlight(event)
					const updatedEvents = updateEvents(events, updatedEvent)
					this.setState({ isloading: false, events: updatedEvents })
				} else {
					this.setState({ isloading: false })
				}
			} catch (error) {
				if (error.response) {
					if (error.response.filterstatus === 401) {
						await localStorage.removeItem('token')
						this.setState({ isredirect: true })
					} else {
						this.setState({
							isloading: false,
							error: 'An unexpected error has occurred. We are currently investigating the issue, please try again later.',
						})
					}
				}
			}
		})
	}

	renderData = () => {
		const { currentpage, lastpage, events } = this.state
		const { location } = this.props
		const parsed = queryString.parse(location.search)
		const active = `${location.pathname}?page=${parsed.page || 1}`
		return (
			<div>
				<EventListTable
					events={events}
					handleArchive={this.handleOnArchive}
					handleOnSort={this.handleOnSort}
					handleOnHighlight={this.handleOnHighlight}
					handleOnUnhighlight={this.handleOnUnhighlight}
				/>
				<PaginationPage currentPage={currentpage} lastPage={lastpage} activeLink={active} length={events.length} url={location.pathname} />
			</div>
		)
	}

	render() {
		const { isredirect, isloading, filterKeyword, filterStatus, error, events } = this.state
		const church = JSON.parse(localStorage.getItem('church'))
		const name = church.church_name ? church.church_name : '-'
		const breads = [{ url: '', title: 'List of All' }]

		const displayLoading = isloading && !error
		const displayData = !isloading && !error && events.length > 0
		const displayNoData = !isloading && !error && !events.length
		const displayError = !isloading && error

		if (isredirect) {
			return <Redirect to="/login" />
		}

		return (
			<div className="main-content bg-gray-100 pb-24 md:p-6 lg:w-5/6 w-full">
				<Helmet>
					<title>{name} - Events</title>
				</Helmet>
				<PageBreadcrumbs icon={EventIcon} title="List of All Events" breads={breads} />
				<div>
					<div className="flex mt-4 mb-8">
						<Searchbar filterKeyword={filterKeyword} handleOnSearch={this.handleOnSearch} />
						<Select name="filterStatus" value={filterStatus} onChange={this.handleOnSearch} options={constants.options1} />
					</div>
					{displayLoading && <Loading />}
					{displayData && this.renderData()}
					{displayNoData && <div className="text-center">No Data available</div>}
					{displayError && <div className="text-center">{error}</div>}
				</div>
			</div>
		)
	}
}

export default ListEvent
