import axios from 'axios'

export const churchProfile = async () =>
  await axios({
    method: 'get',
    url: `${process.env.REACT_APP_ENDPOINT}/church`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })

export const updateChurchProfile = async (
  church_name,
  church_code,
  church_qr_code_link,
  office_address,
  office_phone_number,
  office_email,
  support_email
) =>
  await axios({
    method: 'post',
    url: `${process.env.REACT_APP_ENDPOINT}/church`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    data: {
      church_name,
      church_code,
      church_qr_code_link,
      office_address,
      office_phone_number,
      office_email,
      support_email
    }
  })

export const createCampus = async (campus_name, address, longitude, latitude) =>
  await axios({
    method: 'post',
    url: `${process.env.REACT_APP_ENDPOINT}/campus/create`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    data: { campus_name, address, longitude, latitude }
  })

export const getListCampuses = async () =>
  await axios({
    method: 'get',
    url: `${process.env.REACT_APP_ENDPOINT}/campus`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })

export const updateCampus = async (campus_id, campus_name, address, longitude, latitude) =>
  await axios({
    method: 'post',
    url: `${process.env.REACT_APP_ENDPOINT}/campus/update`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    data: { campus_id, campus_name, address, longitude, latitude }
  })

export const getServiceTypes = async (campus_id) =>
  await axios({
    method: 'get',
    url: `${process.env.REACT_APP_ENDPOINT}/campus/service/types/${campus_id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })

export const createServiceTypes = async (campus_id, service_type) =>
  await axios({
    method: 'post',
    url: `${process.env.REACT_APP_ENDPOINT}/campus/service/type/create`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    data: { campus_id, service_type }
  })

export const deleteServiceTypes = async (service_type_id) =>
  await axios({
    method: 'post',
    url: `${process.env.REACT_APP_ENDPOINT}/campus/service/type/delete`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    data: { service_type_id }
  })

export const updateServiceTypes = async (campus_id, service_type_id, service_type) =>
  await axios({
    method: 'post',
    url: `${process.env.REACT_APP_ENDPOINT}/campus/service/type/update`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    data: { campus_id, service_type_id, service_type }
  })

export const getCampusDetail = async (id) =>
  await axios({
    method: 'get',
    url: `${process.env.REACT_APP_ENDPOINT}/campus/detail/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })

export const createServiceTime = async (campus_id, service_time_day, service_time_hours, service_time_minutes) =>
  await axios({
    method: 'post',
    url: `${process.env.REACT_APP_ENDPOINT}/campus/service/create`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    data: { campus_id, service_time_day, service_time_hours, service_time_minutes }
  })

export const deleteServiceTime = async (service_id) =>
  await axios({
    method: 'post',
    url: `${process.env.REACT_APP_ENDPOINT}/campus/service/delete`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    data: { service_id }
  })

export const updateServiceTime = async (service_id, service_time_day, service_time_hours, service_time_minutes) =>
  await axios({
    method: 'post',
    url: `${process.env.REACT_APP_ENDPOINT}/campus/service/update`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    data: { service_id, service_time_day, service_time_hours, service_time_minutes }
  })

export const createSpecialServiceType = async (campus_id, service_type, service_time_day, service_time_hours, service_time_minutes) =>
  await axios({
    method: 'post',
    url: `${process.env.REACT_APP_ENDPOINT}/campus/service/type/createspecial`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    data: { campus_id, service_type, service_time_day, service_time_hours, service_time_minutes }
  })

export const deleteSpecialServiceType = async (service_type_id) =>
  await axios({
    method: 'post',
    url: `${process.env.REACT_APP_ENDPOINT}/campus/service/type/deletespecial`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    data: { service_type_id }
  })

export const updateSpecialServiceType = async (
  campus_id,
  service_type_id,
  service_type,
  service_time_day,
  service_time_hours,
  service_time_minutes
) =>
  await axios({
    method: 'post',
    url: `${process.env.REACT_APP_ENDPOINT}/campus/service/type/updatespecial`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    data: { campus_id, service_type_id, service_type, service_time_day, service_time_hours, service_time_minutes }
  })

export const campusList = async () =>
  await axios({
    method: 'get',
    url: `${process.env.REACT_APP_ENDPOINT}/attendance/campuses`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })

export const getCampusServiceList = async (id, year, week) =>
  await axios({
    method: 'get',
    url: `${process.env.REACT_APP_ENDPOINT}/attendance/services/${id}/${year}/${week}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })

export const saveCounterAttendance = async (week_of_year, year, attendances) =>
  await axios({
    method: 'post',
    url: `${process.env.REACT_APP_ENDPOINT}/attendance/service/save`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    data: { week_of_year, year, attendances }
  })

export const deleteCampus = async (campus_id) =>
  await axios({
    method: 'post',
    url: `${process.env.REACT_APP_ENDPOINT}/campus/delete`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    data: {
      campus_id
    }
  })

export const getCampusCustomServiceList = async (campusId, year, weekOfYear) =>
  await axios({
    method: 'get',
    url: `${process.env.REACT_APP_ENDPOINT}/attendance/custom/services/${campusId}/${year}/${weekOfYear}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })

export const saveCampusCustomServiceList = async (campus_id, service_label, service_date, attendances) =>
  await axios({
    method: 'post',
    url: `${process.env.REACT_APP_ENDPOINT}/attendance/custom/service/save`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    data: {
      campus_id,
      service_label,
      service_date,
      attendances
    }
  })

export const firstTimerAttendance = async (campusId, year, week) =>
  await axios({
    method: 'get',
    url: `${process.env.REACT_APP_ENDPOINT}/ftattendance/services/${campusId}/${year}/${week}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })

export const saveFirstAttendance = async (week_of_year, year, attendances) =>
  await axios({
    method: 'post',
    url: `${process.env.REACT_APP_ENDPOINT}/ftattendance/service/save`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    data: {
      week_of_year,
      year,
      attendances
    }
  })
