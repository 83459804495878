import React from 'react'
import PraiseItem from './PraiseItem'

const PraiseList = ({ list }) => (
  <div>
    {list.map((item) => (
      <PraiseItem
        key={item.id}
        category={item.category_name}
        created={item.created_at}
        description={item.description}
        prayer={item.prayer}
      />
    ))}
  </div>
)

export default PraiseList
