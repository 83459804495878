import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import moment from 'moment'
import Loading from 'components/commons/Loading'
import PaginationPage from 'components/commons/PaginationPage'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as PrayerPraiseIcon } from 'assets/icons/prayerpraise.svg'
import PraiseRepTable from 'components/prayerRequest/PraiseRepTable'
import PrayerReqSearch from 'components/prayerRequest/PrayerReqSearch'
import { prayerCategories, praiseReport, exportPraise } from 'libs/apis/prayerpraise'

export default function PraiseReport(props) {
	const [data, setData] = useState([])
	const [categories, setCategories] = useState([])
	const [category, setCategory] = useState('')
	const [dateFrom, setdateFrom] = useState('')
	const [dateTo, setdateTo] = useState('')
	const [orderBy, setorderBy] = useState('')
	const [orderDir, setorderDir] = useState('')
	const [isLoad, setisLoad] = useState(false)
	const [isRedirect, setIsRedirect] = useState(false)
	const [currentPage, setcurrentPage] = useState(0)
	const [lastPage, setlastPage] = useState(1)
	const [isExport, setisExport] = useState(false)
	const [error, setError] = useState('')

	const church = JSON.parse(localStorage.getItem('church'))
	const { location } = props
	const breads = [{ url: '', title: 'Praise Report' }]
	const parsed = queryString.parse(location.search)
	const active = `${location.pathname}?page=${parsed.page || 1}`

	const shouldDisplayLoad = isLoad && !error
	const shouldDisplayNoData = !isLoad && !error && !data.length
	const shouldDisplayData = !isLoad && !error && data.length > 0
	const shouldDisplayErr = !isLoad && error

	useEffect(() => {
		const handleFetchData = async () => {
			const parsed = queryString.parse(props.location.search)
			const page = parsed.page || 1

			try {
				setisLoad(true)
				const res = await praiseReport(page, dateFrom, dateTo, category, orderBy, orderDir)
				const categories = await prayerCategories()
				if (res.data.api_status) {
					const { data, current_page, last_page } = res.data.data
					setisLoad(false)
					setData(data)
					setcurrentPage(current_page)
					setlastPage(last_page)
					setCategories(categories.data.data)
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						await localStorage.removeItem('token')
						setIsRedirect(true)
					} else if (error.response.status >= 500) {
						setisLoad(false)
						setError('An unexpected error has occurred. We are currently investigating the issue, please try again later.')
					}
				}
			}
		}

		handleFetchData()
	}, [props.location.search, orderBy, orderDir, dateFrom, dateTo, category])

	const handleSort = sort => {
		if (orderBy !== sort) {
			setorderBy(sort)
			setorderDir('asc')
		} else {
			if (orderDir === 'asc') {
				setorderBy(sort)
				setorderDir('desc')
			} else if (orderDir === 'desc') {
				setorderBy(sort)
				setorderDir('asc')
			}
		}
	}

	const handleDate = date => {
		if (date.length === 2) {
			setdateFrom(moment(date[0]).format('YYYY-MM-DD'))
			setdateTo(moment(date[1]).format('YYYY-MM-DD'))
		} else if (!date.length) {
			setdateFrom('')
			setdateTo('')
		}
	}

	const handleCat = event => {
		setCategory(event.target.value)
	}

	const handleExport = async () => {
		try {
			setisExport(true)
			const response = await exportPraise(dateFrom, dateTo, category, orderBy, orderDir)
			if (response.data.api_status) {
				const link = document.createElement('a')
				link.href = response.data.data
				document.body.appendChild(link)
				link.click()
				setisExport(false)
			} else {
				setisExport(false)
			}
		} catch (error) {}
	}

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{church.church_name} - Praise Report</title>
			</Helmet>
			<PageBreadcrumbs icon={PrayerPraiseIcon} title="Praise Report" breads={breads} />
			<div>
				<PrayerReqSearch handleOnDate={handleDate} category={category} categories={categories} handleOnCategory={handleCat} handleOnExport={handleExport} isExport={isExport} />
				{shouldDisplayLoad && <Loading />}
				{shouldDisplayNoData && <div className="text-center">No data available</div>}
				{shouldDisplayErr && <div className="text-center">{error}</div>}
				{shouldDisplayData && (
					<div>
						<PraiseRepTable list={data} handleSort={handleSort} />
						<PaginationPage currentPage={currentPage} lastPage={lastPage} activeLink={active} length={data.length} url={location.pathname} />
					</div>
				)}
			</div>
		</div>
	)
}
