import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { partial } from 'libs/utils/utils'

class UserItem extends Component {
	constructor() {
		super()
		this.state = {
			dropDownOpen: false,
		}
		this.setWrapperRef = this.setWrapperRef.bind(this)
		this.handleClickOutside = this.handleClickOutside.bind(this)
	}

	handleToggle = () => {
		this.setState({ dropDownOpen: !this.state.dropDownOpen })
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside)
	}

	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({ dropDownOpen: false })
		}
	}

	setWrapperRef(node) {
		this.wrapperRef = node
	}

	render() {
		const { id, name, email, phone, gender, role, status, lastactive, lastactivefrom, checked, handleOnChecked, handleActive, handleDeactivate } = this.props
		const handleChecked = partial(handleOnChecked, id)
		const active = partial(handleActive, id)
		const deactivate = partial(handleDeactivate, id)
		const { dropDownOpen } = this.state

		const styleDropDownOpen = `z-20 shadow-md absolute right-20px ${dropDownOpen ? 'block' : 'hidden'}`
		const styleStatus = `fas fa-circle ${status === 'Active' ? 'text-green-500' : 'text-red-500'}`
		return (
			<tr className="border border-solid bt-blueSecondary">
				<td className="py-4 px-2">
					<input className="form-checkbox border-2 border-secondary h-5 w-5 text-primary" type="checkbox" value={checked} checked={checked} onChange={handleChecked} />
				</td>
				<td className="py-4 px-2">
					<Link to={`/user-management/users/${id}`}>{name}</Link>
				</td>
				<td className="py-4 px-2">{email}</td>
				<td className="py-4 px-2">{phone}</td>
				<td className="py-4 px-2">{gender}</td>
				<td className="py-4 px-2">{role}</td>
				<td className="py-4 px-2">
					<i className={styleStatus} /> {status}
				</td>
				<td className="py-4 px-2">
					<div className="text-center">{lastactive}</div>
					<div className="text-center text-primary">{lastactivefrom}</div>
				</td>
				<td className="py-4 px-2">
					<div className="cursor-pointer" onClick={this.handleToggle}>
						<i className="dropdown-trigger fas fa-ellipsis-v" />
						<div className={styleDropDownOpen}>
							<ul ref={this.setWrapperRef} className="bg-white">
								<li>
									<Link className="px-4 py-2 block hover:bg-blueSecondary" to={`/user-management/users/${id}`}>
										View User
									</Link>
								</li>
								<li className="px-4 py-2 block hover:bg-blueSecondary" onClick={active}>
									Active User
								</li>
								<li className="px-4 py-2 block hover:bg-blueSecondary" onClick={deactivate}>
									Deactivate User
								</li>
							</ul>
						</div>
					</div>
				</td>
			</tr>
		)
	}
}

export default UserItem
