export const findUserById = (id, list) => list.find((item) => item.user_id === id)
export const findSelected = (id, list) => list.find((item) => item === id)
export const filterSelected = (id, list) => list.filter((item) => item !== id)
export const toggleUser = (data) => ({ ...data, checked: !data.checked })
export const toggleActive = (list) => ({ ...list, is_active_desc: 'Active' })
export const toggleDeactivate = (list) => ({ ...list, is_active_desc: 'Inactive' })
export const updateData = (list, updated) => {
  const updatedIndex = list.findIndex((item) => item.user_id === updated.user_id)
  return [...list.slice(0, updatedIndex), updated, ...list.slice(updatedIndex + 1)]
}
export const updatecheckAll = (list) => list.map((item) => ({ ...item, checked: true }))
export const updateUnCheckAll = (list) => list.map((item) => ({ ...item, checked: false }))
export const findAllId = (list) => list.map((item) => item.user_id)
export const updateAllActive = (list, selected) => {
  const data = [].concat(list).map((item) => {
    if (selected.includes(item.user_id)) {
      item = { ...item, is_active_desc: 'Active' }
    }
    return item
  })
  return data
}
export const updateAllDeactivate = (list, selected) => {
  const data = [].concat(list).map((item) => {
    if (selected.includes(item.user_id)) {
      item = { ...item, is_active_desc: 'Inactive' }
    }
    return item
  })
  return data
}
