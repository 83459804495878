const getYoutubeId = (youtubeUrl) => {
  let ID = ''
  youtubeUrl = youtubeUrl.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
  if (youtubeUrl[2] !== undefined) {
    ID = youtubeUrl[2].split(/[^0-9a-z_-]/i)
    ID = ID[0]
  } else {
    ID = youtubeUrl
  }
  return ID
}

export default getYoutubeId
