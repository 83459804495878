import React, { useState, useRef, useEffect } from 'react'
import moment from 'moment'
import FielViewEdit from 'screens/file/FileViewEdit'

export default function FileListTable(props) {
	return (
		<div className="bg-white shadow-md">
			<table className="w-full rounded-lg mt-10">
				<TableHeadFile />
				<TableBody {...props} />
			</table>
		</div>
	)
}

const TableHeadFile = () => {
	return (
		<thead className="bt-blueSecondary border-2 border-solid">
			<tr>
				<th className="text-left justify-center pl-10 py-4">NAME</th>
				<th className="text-left">CREATED ON</th>
				<th className="text-left">LINK</th>
				<th className="py-4" />
			</tr>
		</thead>
	)
}

const TableBody = ({ data, handleDelete, handleLoadData }) => {
	return (
		<tbody className="border-2 border-solid pl-5">
			{data.map(value => (
				<FileListRow
					key={value.resources_file_id}
					{...value}
					handleDelete={handleDelete}
					handleLoadData={handleLoadData}
				/>
			))}
		</tbody>
	)
}

const FileListRow = ({
	resources_file_id,
	created_at,
	name,
	handleDelete,
	handleLoadData,
	aws_url,
}) => {
	const [dropDownOpen, setDropDownOpen] = useState(false)
	const node = useRef()

	useEffect(() => {
		document.addEventListener('mousedown', handleClick)
		return () => {
			document.removeEventListener('mousedown', handleClick)
		}
	}, [])

	const handleClick = e => {
		if (node.current.contains(e.target)) {
			return
		}
		setDropDownOpen(false)
	}
	const handleToggle = () => {
		setDropDownOpen(!dropDownOpen)
	}

	const styleDropDownOpen = `z-20 shadow-md absolute right-20px ${
		dropDownOpen ? 'block' : 'hidden'
	}`

	return (
		<tr className="border border-solid bt-blueSecondary">
			<td className="text-left pl-10">{name}</td>
			<td className="text-left">{moment(created_at).format('LL')}</td>
			<td className="text-left">
				<a href={aws_url} target={aws_url}>
					<div className="fa fa-download" />
				</a>
			</td>

			<td className="px-2 py-4">
				<div className="cursor-pointer" onClick={handleToggle}>
					<i className="dropdown-trigger fas fa-ellipsis-v" />
					<div className={styleDropDownOpen}>
						<ul ref={node} className="bg-white">
							<li className="px-4 py-2 block hover:bg-blueSecondary">
								<FielViewEdit
									fileName={name}
									handleLoadData={handleLoadData}
									fileId={resources_file_id}
								/>
							</li>
							<li
								className="px-4 py-2 block hover:bg-blueSecondary"
								onClick={() => {
									handleDelete(resources_file_id)
								}}
							>
								Delete
							</li>
						</ul>
					</div>
				</div>
			</td>
		</tr>
	)
}
