import React, { useEffect } from 'react'

export const Editable = ({
	text,
	type,
	placeholder,
	children,
	childRef,
	isEditing,
	handleEditing,
	...props
}) => {
	useEffect(() => {
		if (childRef && childRef.current && isEditing === true) {
			childRef.current.focus()
		}
	}, [isEditing, childRef])

	const handleKeyDown = (event, type) => {
		const { key } = event
		const keys = ['Escape', 'Tab']
		const enterKey = 'Enter'
		const allKeys = [...keys, enterKey]
		if (
			(type === 'textarea' && keys.indexOf(key) > -1) ||
			(type !== 'textarea' && allKeys.indexOf(key) > -1)
		) {
			handleEditing(!isEditing)
		}
	}

	return (
		<section {...props}>
			{isEditing ? (
				<div
					onBlur={() => handleEditing(false)}
					onKeyDown={e => handleKeyDown(e, type)}
				>
					{children}
				</div>
			) : (
				<div
					className={`rounded py-2 px-2 text-gray-700 leading-tight whitespace-pre-wrap editable-${type}`}
					onClick={() => handleEditing(true)}
				>
					<span className={`${text ? 'text-gray-500' : 'text-gray-500'}`}>
						{text || placeholder || 'Editable content'}
					</span>
				</div>
			)}
		</section>
	)
}
