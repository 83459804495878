import Swal from 'sweetalert2'
import { failedFetch } from './messages'

const confirmButtonColor = '#4da5dc'
const cancelButtonColor = '#989898'

export const successMessage = async () =>
	Swal.fire({
		icon: 'success',
		title: 'Success',
		text: 'Your item has been saved.',
		focusConfirm: false,
		confirmButtonColor,
	})

export const failedMessage = async message =>
	Swal.fire({
		icon: 'error',
		title: 'Error',
		text: message,
		focusConfirm: false,
		confirmButtonColor,
	})

export const cancelConfirmation = async () =>
	Swal.fire({
		text: 'Cancel means all data input will be deleted. Cancel now?',
		showCancelButton: true,
		focusCancel: false,
		focusConfirm: false,
		reverseButtons: true,
		cancelButtonText: 'No',
		confirmButtonText: 'Yes',
		confirmButtonColor,
		cancelButtonColor,
	})

export const saveDraftConfirmation = async () =>
	Swal.fire({
		text: 'Are you sure you want to save this item as draft ?',
		showCancelButton: true,
		focusCancel: false,
		focusConfirm: false,
		reverseButtons: true,
		cancelButtonText: 'No',
		confirmButtonText: 'Yes',
		confirmButtonColor,
		cancelButtonColor,
	})

export const deleteItemConfirmation = async () =>
	Swal.fire({
		text: 'Are you sure you want to delete this item?',
		showCancelButton: true,
		focusCancel: false,
		focusConfirm: false,
		reverseButtons: true,
		cancelButtonText: 'No',
		confirmButtonText: 'Yes',
		confirmButtonColor,
		cancelButtonColor,
	})

export const deleteItemSuccess = async () =>
	Swal.fire({
		type: 'success',
		title: 'Success',
		text: 'Your item has been deleted.',
		focusConfirm: false,
		confirmButtonColor,
	})

export const somethingWrong = async () =>
	Swal.fire({
		icon: 'error',
		text:
			'An unexpected error has occurred. We are currently investigating the issue, please try again later.',
		showCancelButton: false,
		focusConfirm: false,
		confirmButtonText: 'OK',
		confirmButtonColor,
	})

export const completeClass = async () => {
	return Swal.fire({
		text: 'Completed class cannot be reopened. Are you sure this class is completed?',
		showCancelButton: true,
		focusCancel: false,
		focusConfirm: false,
		reverseButtons: true,
		cancelButtonText: 'No',
		confirmButtonText: 'Yes',
		confirmButtonColor,
		cancelButtonColor,
	})
}

export const successComplete = async message =>
	Swal.fire({
		icon: 'success',
		title: 'Success',
		text: `${message}`,
		focusConfirm: false,
		confirmButtonColor: '#4da5dc',
	})

export const failedCompleteClass = async message => {
	Swal.fire({
		icon: 'error',
		text: `${message}`,
		showCancelButton: false,
		focusConfirm: false,
		confirmButtonText: 'OK',
		cancelButtonColor: '#989898',
	})
}

export const archiveItemConfirm = async () => {
	return Swal.fire({
		text: 'Are you sure you want to archive this item ?',
		showCancelButton: true,
		focusCancel: false,
		focusConfirm: false,
		reverseButtons: true,
		cancelButtonText: 'No',
		confirmButtonText: 'Yes',
		confirmButtonColor: 'var(--blueOne)',
		cancelButtonColor: 'var(--grayTwo)',
	})
}

export const confirmSendEmail = async () =>
	Swal.fire({
		text: 'Are you sure you want to resend confirmation email?',
		showCancelButton: true,
		focusCancel: false,
		focusConfirm: false,
		reverseButtons: true,
		cancelButtonText: 'No',
		confirmButtonText: 'Yes',
		confirmButtonColor: '#4da5dc',
		cancelButtonColor: '#989898',
	})

export const confirmRemoveRegistrant = async () =>
	Swal.fire({
		text: 'Are you sure you want to reject this registrant ?',
		showCancelButton: true,
		focusCancel: false,
		focusConfirm: false,
		reverseButtons: true,
		cancelButtonText: 'No',
		confirmButtonText: 'Yes',
		confirmButtonColor: '#4da5dc',
		cancelButtonColor: '#989898',
	})

export const confirmCloseGroup = async () =>
	Swal.fire({
		text: 'Are you sure you want to close this group ?',
		showCancelButton: true,
		focusCancel: false,
		focusConfirm: false,
		reverseButtons: true,
		cancelButtonText: 'No',
		confirmButtonText: 'Yes',
		confirmButtonColor: '#4da5dc',
		cancelButtonColor: '#989898',
	})

export const emailSuccess = async () =>
	Swal.fire({
		icon: 'success',
		title: 'Success',
		text: 'Email Sent!',
		focusConfirm: false,
		confirmButtonColor: '#4da5dc',
	})

// hapus
export const defaultErrorMessage = async () =>
	Swal.fire({
		icon: 'error',
		title: 'Failed',
		text: 'Sorry something went wrong, please try again.',
		focusConfirm: false,
		confirmButtonColor,
	})

// hapus
export const errorMessage = async res => {
	return Swal.fire({
		icon: 'error',
		title: 'Failed',
		text: res.data.message,
		focusConfirm: false,
		confirmButtonColor: 'var(--blueOne)',
	})
}

export const activeUserConfirmation = async () => {
	return Swal.fire({
		icon: 'info',
		title: 'Activate User(s)',
		text: 'Account activation means users can use all the provided services.',
		showCancelButton: true,
		focusCancel: false,
		focusConfirm: false,
		reverseButtons: true,
		confirmButtonColor: 'var(--blueOne)',
		cancelButtonColor: 'var(--grayTwo)',
	})
}

export const deactiveUserConfirmation = async () => {
	return Swal.fire({
		icon: 'info',
		title: 'Deactivate User(s)',
		text: 'Account deactivation means users can no longer use the provided services.',
		showCancelButton: true,
		focusCancel: false,
		focusConfirm: false,
		reverseButtons: true,
		confirmButtonColor: 'var(--blueOne)',
		cancelButtonColor: 'var(--grayTwo)',
	})
}

export const logOutConfirmation = async () =>
	Swal.fire({
		text: 'Are you sure you want to log out?',
		showCancelButton: true,
		focusCancel: false,
		focusConfirm: false,
		reverseButtons: true,
		cancelButtonText: 'No',
		confirmButtonText: 'Yes',
		confirmButtonColor: 'var(--blueOne)',
		cancelButtonColor: 'var(--grayTwo)',
	})

export const failedPost = async () =>
	Swal.fire({
		icon: 'error',
		text: failedFetch,
		showCancelButton: false,
		focusConfirm: false,
		confirmButtonText: 'OK',
		cancelButtonColor,
	})
