import React, { Component } from 'react'
import moment from 'moment'
import Loading from 'components/commons/Loading'
import SelectOptions from '../commons/SelectOptions'
import SmallGroupReportCard from '../smallGroup/SmallGroupReportCard'
import optionsMonth from 'services/optionsMonth'
import optionsYear from 'services/optionsYear'
import { getSmallGroupReport } from 'services/reportServices'

class SmallGroupReport extends Component {
	state = {
		isLoading: false,
		months: optionsMonth(),
		years: optionsYear(),
		monthFrom: moment().format('M'),
		monthAfter: moment().format('M'),
		yearFrom: moment().format('YYYY'),
		yearAfter: moment().format('YYYY'),
		approved: 0,
		declined: 0,
		pending: 0,
		cancelled: 0,
	}

	componentDidMount() {
		this.doLoad()
	}

	doLoad = async () => {
		this.setState({ isLoading: true }, async () => {
			try {
				const { monthFrom, monthAfter, yearFrom, yearAfter } = this.state
				const {
					data: { data },
				} = await getSmallGroupReport(monthFrom, yearFrom, monthAfter, yearAfter)

				this.setState({
					approved: data.approved,
					pending: data.pending,
					cancelled: data.cancelled,
					declined: data.declined,
					isLoading: false,
				})
			} catch (error) {
				if (error.response && error.response.status === 401) {
					this.props.history.replace('/login')
				}
				this.setState({ isLoading: false })
			}
		})
	}

	handleChange = ({ currentTarget: input }) => {
		this.setState({ [input.name]: input.value }, () => {
			this.doLoad()
		})
	}

	renderOptions() {
		const { months, years, monthFrom, monthAfter, yearFrom, yearAfter } = this.state
		return (
			<div className="flex mb-6">
				<div className="pr-6">
					<SelectOptions options={months} value={monthFrom} name="monthFrom" onChange={this.handleChange} />
				</div>
				<div className="pr-6">
					<SelectOptions options={years} value={yearFrom} name="yearFrom" onChange={this.handleChange} />
				</div>
				<div className="pr-6">
					<SelectOptions options={months} value={monthAfter} name="monthAfter" onChange={this.handleChange} />
				</div>
				<div className="pr-6">
					<SelectOptions options={years} value={yearAfter} name="yearAfter" onChange={this.handleChange} />
				</div>
			</div>
		)
	}

	renderReport() {
		const { declined, approved, cancelled, pending } = this.state
		return (
			<div className="flex">
				<div className="w-1/4">
					<SmallGroupReportCard value={declined} label="Declined" />
				</div>
				<div className="w-1/4">
					<SmallGroupReportCard value={approved} label="Approved" />
				</div>
				<div className="w-1/4">
					<SmallGroupReportCard value={cancelled} label="Cancelled" />
				</div>
				<div className="w-1/4">
					<SmallGroupReportCard value={pending} label="Pending" />
				</div>
			</div>
		)
	}

	render() {
		return (
			<div className="bg-white rounded-lg shadow-md p-6 mt-6">
				<h3 className="font-bold mb-6">Small Group</h3>
				{this.renderOptions()}
				{this.state.isLoading ? <Loading /> : this.renderReport()}
			</div>
		)
	}
}

export default SmallGroupReport
