import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { ReactComponent as MinistryIcon } from 'assets/icons/agreement.svg'
import { createMinistry, getListVolunteers } from 'libs/apis/ministry'
import MinistryForm from 'components/forms/MinistryForm'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import alert from 'services/alert'
import { useHistory } from 'react-router-dom'
import { cancelConfirmation } from 'libs/utils/confirmation'

function CreateMinistry() {
	const [ministryName, setMinistryName] = useState('')
	const [ministryHead, setMinistryHead] = useState('')
	const [ministryDescription, setMinistryDescription] = useState('')
	const [ministryStatus, setMinistryStatus] = useState()
	const [ministryStatusValue, setMinistryStatusValue] = useState('')
	const [ministryImageFile, setMinistryImageFile] = useState('')
	const [imagePreview, setImagePreview] = useState('')
	const [errorMessage] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const history = useHistory()

	const statuses = [
		{ value: 'Open', label: 'Open' },
		{ value: 'Closed', label: 'Closed' },
	]

	useEffect(() => {
		handleOnLoadLeaders()
	}, [])

	const handleOnLoadLeaders = async (value) => {
		const response = await getListVolunteers(value, 0)
		if (response.data.api_status) {
			const options = response.data.data.data.map((user) => ({
				value: user.user_id,
				label: `${user.fullname} - ${user.email}`,
			}))
			return options.filter((i) => i.label.toLowerCase().includes(value))
		}
	}

	const handleDescription = (value) => {
		setMinistryDescription(value)
	}

	const handleChangeSelect = (e) => {
		setMinistryStatus({
			value: e.value,
			label: e.value,
		})
		setMinistryStatusValue(e.value)
	}

	const handleMinistryName = (e) => {
		setMinistryName(e.target.value)
	}

	const handleFileChange = (e) => {
		setImagePreview(URL.createObjectURL(e.target.files[0]))
		setMinistryImageFile(e.target.files[0])
	}

	const handleOnLeaderChange = (input) => {
		setMinistryHead(input)
	}

	const handleCancel = async () => {
		const res = await cancelConfirmation()
		if (res.value) {
			history.push('/ministry')
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		try {
			setIsLoading(true)
			if (ministryHead.length !== 0) {
				let data = ministryHead.map((val) => val.value)
				let formdata = new FormData()
				formdata.append('ministry_name', ministryName)
				formdata.append('description', ministryDescription)
				formdata.append('image_file', ministryImageFile)
				formdata.append('status', ministryStatusValue)
				for (let i = 0; i < data.length; i++) {
					formdata.append(`ministry_head_id[${i}]`, data[i])
				}
				let res = await createMinistry(formdata)
				if (res.data.api_status) {
					setIsLoading(false)

					alert.succeed('Success', 'Ministry has been created')

					history.push('/ministry')
				} else {
					alert.failed(`Failed`, res.data.message)
					setIsLoading(false)
				}
			} else {
				alert.failed('Failed', 'Ministry Head Cannot be Empty')
			}
		} catch (error) {
			alert.failed(
				'Oops',
				'There something wrong, Cannot create ministry. Try again later'
			)
			console.log(error)
			setIsLoading(false)
		}
	}

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [{ url: '', title: 'Create Ministry' }]

	return (
		<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - Create Ministry</title>
			</Helmet>
			<PageBreadcrumbs icon={MinistryIcon} title="Create Ministry" breads={breads} />
			<MinistryForm
				ministryName={ministryName}
				ministryHead={ministryHead}
				ministryImageURL={ministryImageFile}
				ministryDescription={ministryDescription}
				ministryStatus={ministryStatus}
				statuses={statuses}
				handleMinistryName={handleMinistryName}
				handleOnLeaderChange={handleOnLeaderChange}
				handleOnLoadLeaders={handleOnLoadLeaders}
				handleDescription={handleDescription}
				handleChangeSelect={handleChangeSelect}
				handleFileChange={handleFileChange}
				handleCancel={handleCancel}
				handleSubmit={handleSubmit}
				errorMessage={errorMessage}
				imagePreview={imagePreview}
				isLoading={isLoading}
			/>
		</div>
	)
}

export default CreateMinistry
