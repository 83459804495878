import React from 'react'
import moment from 'moment'
import { partial } from 'libs/utils/utils'
import PraiseRepItem from './PraiseRepItem'

export default function PraiseRepTable({ list, handleSort }) {
	const handleSortDate = partial(handleSort, 'created_at')
	const handleSortName = partial(handleSort, 'user_name')
	const handleSortEmail = partial(handleSort, 'user_email')
	const handleSortCat = partial(handleSort, 'category_name')

	return (
		<div className="bg-white shadow-md">
			<table className="w-full">
				<TableHeader
					handleSortDate={handleSortDate}
					handleSortName={handleSortName}
					handleSortEmail={handleSortEmail}
					handleSortCat={handleSortCat}
				/>
				<TableBody list={list} />
			</table>
		</div>
	)
}

function TableHeader({ handleSortName, handleSortEmail, handleSortCat, handleSortDate }) {
	return (
		<thead className="border-2">
			<tr>
				<th className="py-4 text-left pl-10">
					NAME
					<i
						className="fas fa-sort sortby cursor-pointer pl-1"
						onClick={handleSortName}
					/>
				</th>
				<th className="text-left pl-2 py-4">
					EMAIL
					<i
						className="fas fa-sort sortby cursor-pointer pl-1"
						onClick={handleSortEmail}
					/>
				</th>
				<th className="text-left pl-2 py-4 pl-1">
					CATEGORY
					<i
						className="fas fa-sort sortby pl-1 cursor-pointer"
						onClick={handleSortCat}
					/>
				</th>
				<th className="text-left py-4 pl-2">REPORT</th>
				<th className="text-left py-4 pl-2">
					DATE SUBMITTED
					<i
						className="fas fa-sort sortby cursor-pointer pl-1"
						onClick={handleSortDate}
					/>
				</th>
			</tr>
		</thead>
	)
}

function TableBody({ list }) {
	return (
		<tbody className="border-2 border-solid">
			{list.map(item => (
				<PraiseRepItem
					key={item.id}
					user_name={item.user_name}
					user_email={item.user_email}
					category_name={item.category_name}
					description={item.description}
					created_at={moment(item.created_at, 'YYYY-MM-DD').format('D MMM YYYY')}
					id={item.user_id}
				/>
			))}
		</tbody>
	)
}
