import React, { Component } from 'react'
import HeadTitle from 'components/commons/HeadTitle'
import BreadCrumb from 'components/commons/Breadcrumb'
import Loading from 'components/commons/Loading'
import Pagination from 'components/commons/Pagination'
import CourseInfoCard from 'components/courses/CourseInfoCard'
import CourseStatusCard from 'components/courses/CourseStatusCard'
import EventRegistrantTable from 'components/events/EventRegistrantTable'
import { ReactComponent as EventIcon } from 'assets/icons/events.svg'
import { ReactComponent as AppliedIcon } from 'assets/icons/applied.svg'
import { ReactComponent as ApprovedIcon } from 'assets/icons/check-approved.svg'
import { ReactComponent as QuotaIcon } from 'assets/icons/quota.svg'
import { ReactComponent as PendingIcon } from 'assets/icons/pending.svg'
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg'
import { ReactComponent as PassFailIcon } from 'assets/icons/pass-fail.svg'
import { eventRegistrantDashboard, eventRegistrantList, eventRegistrantExport, eventConfirmation, eventReject, attendanceToggle } from 'services/eventServices'
import alert from 'services/alert'
import Searchbar from 'components/commons/Searchbar'
import Select from 'components/commons/Select'
import { Button } from 'components/commons/Button'
import { constants } from 'constants/constant'

class EventRegistrants extends Component {
	state = {
		eventName: '',
		startDate: '',
		endDate: '',
		startTime: '',
		endTime: '',
		venueName: '',
		venueAddress: '',
		registrantCounter: {},
		participantQouta: 0,
		filterKeyword: '',
		filterStatus: '',
		isExported: false,
		registrants: [],
		currentPage: 1,
		lastPage: 1,
		isLoading: false,
		sortColumn: { path: '', order: '' },
	}

	componentDidMount() {
		this.setState({ isLoading: true }, async () => {
			try {
				const id = this.props.match.params.id

				const {
					data: { data: dashboard },
				} = await eventRegistrantDashboard(id)

				this.doLoad()
				this.setState({
					eventName: dashboard.event_detail.title,
					startDate: dashboard.event_detail.start_date,
					endDate: dashboard.event_detail.end_date,
					startTime: dashboard.event_detail.start_time,
					endTime: dashboard.event_detail.end_time,
					venueName: dashboard.event_detail.venue_name,
					venueAddress: dashboard.event_detail.venue_address,
					registrantCounter: dashboard.registrant_counter,
					participantQouta: dashboard.event_detail.participant_quota,
					isLoading: false,
				})
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						return this.props.history.replace('/login')
					}
				}
				this.setState({ isLoading: false })
			}
		})
	}

	doLoad = async (page) => {
		const id = this.props.match.params.id
		const { currentPage, filterKeyword, filterStatus, sortColumn } = this.state
		this.setState({ isLoading: true }, async () => {
			try {
				const {
					data: { data: registrant },
				} = await eventRegistrantList(id, page ? page : currentPage, filterKeyword, filterStatus, sortColumn.path, sortColumn.order)
				this.setState({
					isLoading: false,
					currentPage: registrant.current_page,
					lastPage: registrant.last_page,
					registrants: registrant.data,
				})
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						return this.props.history.replace('/login')
					}
				}
				this.setState({ isLoading: false })
			}
		})
	}

	handleSearch = ({ currentTarget: input }) => {
		this.setState({ [input.name]: input.value, currentPage: 1 }, () => {
			this.doLoad()
		})
	}

	handleSort = (sortColumn) => {
		this.setState({ sortColumn }, () => {
			this.doLoad()
		})
	}

	handleExport = () => {
		this.setState({ isExported: true }, async () => {
			try {
				const { filterKeyword, filterStatus, sortColumn } = this.state
				const { data } = await eventRegistrantExport(this.props.match.params.id, filterKeyword, filterStatus, sortColumn.order, sortColumn.path)
				if (data.api_status) {
					const link = document.createElement('a')
					link.href = data.data
					document.body.appendChild(link)
					link.click()
				}
				this.setState({ isExported: false })
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						return this.props.history.replace('/login')
					}
				}
				this.setState({ isExported: false })
			}
		})
	}

	handleResendEmail = async (registrant) => {
		const response = await alert.confirmResendEmail()
		if (response.value) {
			try {
				const { data: response } = await eventConfirmation(registrant.event_registration_id)
				if (response.api_status) {
					alert.succeed('Success', 'Email has been sent.')
				} else {
					alert.failed('Failed', response.message)
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						return this.props.history.replace('/login')
					}
				}
			}
		}
	}

	handleReject = async (registrant) => {
		const response = await alert.confirmReject()
		if (response.value) {
			try {
				const { data: response } = await eventReject(registrant.event_registration_id)
				if (response.api_status) {
					if (this.state.navTab === 'registrant') {
						const registrants = [...this.state.registrants]
						const index = registrants.indexOf(registrant)
						registrants[index] = { ...registrants[index] }
						registrants[index].status = 'Rejected'
						this.setState({ registrants })
					} else {
						const { currentPage, lastPage, attendances } = this.state
						if (currentPage === lastPage && attendances.length === 1) {
							this.setState({ currentPage: currentPage - 1 })
						}
						this.doLoadAttendance(this.state.currentPage)
					}
				} else {
					alert.failed('Failed', response.message)
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						return this.props.history.replace('/login')
					}
				}
			}
		}
	}

	handlePageChange = (page) => {
		this.setState({ currentPage: page }, async () => {
			this.doLoad()
		})
	}

	handleCheck = async (registrant, v) => {
		try {
			let event_id = parseInt(this.props.match.params.id)
			let formdata = new FormData()
			formdata.append('event_id', event_id)
			formdata.append('registrant_id', registrant.event_registration_id)

			const { data: response } = await attendanceToggle(formdata)

			if (response.api_status) {
				const registrants = [...this.state.registrants]

				const index = registrants.indexOf(registrant)

				registrants[index] = { ...registrants[index] }
				registrants[index].attendance = v

				this.setState({ registrants })
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					return this.props.history.replace('/login')
				}
			}
		}
	}

	handleUnCheck = async (registrant, v) => {
		try {
			let event_id = parseInt(this.props.match.params.id)

			let formdata = new FormData()
			formdata.append('event_id', event_id)
			formdata.append('registrant_id', registrant.event_registration_id)

			const { data: response } = await attendanceToggle(formdata)

			if (response.api_status) {
				const registrants = [...this.state.registrants]

				const index = registrants.indexOf(registrant)

				registrants[index] = { ...registrants[index] }
				registrants[index].attendance = v

				this.setState({ registrants })
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					return this.props.history.replace('/login')
				}
			}
		}
	}

	renderOverview() {
		const { eventName, startDate, endDate, startTime, endTime, venueName, venueAddress, registrantCounter, participantQouta } = this.state
		return (
			<div className="flex -mx-2">
				<div className="md:w-4/12 px-2">
					<CourseInfoCard
						color="bg-greyExport"
						courseName={eventName}
						startDate={startDate}
						endDate={endDate}
						startTime={startTime}
						endTime={endTime}
						venueName={venueName}
						venueAddress={venueAddress}
					/>
				</div>
				<div className="md:w-8/12 px-2">
					<div className="flex flex-wrap -mb-4 content-between h-full">
						<CourseStatusCard color="bg-yellow-300" title="Applied" value={registrantCounter.applied} icon={<AppliedIcon fill="#fde81a" />} />
						<CourseStatusCard color="bg-green-300" title="Approved" value={registrantCounter.approved} icon={<ApprovedIcon fill="#58c340" />} />
						<CourseStatusCard color="bg-primary" title="Quota" value={participantQouta} icon={<QuotaIcon fill="#4da5dc" />} />
						<CourseStatusCard color="bg-grey-300" title="Pending" value={registrantCounter.pending} icon={<PendingIcon fill="#989898" />} />
						<CourseStatusCard color="bg-red-500" title="Rejected" value={registrantCounter.rejected} icon={<CancelIcon fill="#e34f4f" />} />
						<CourseStatusCard
							color="bg-black"
							title="Pass | Fail"
							// value={`${registrantCounter.passed} | ${registrantCounter.failed}`}
							icon={<PassFailIcon fill="#404040" />}
						/>
					</div>
				</div>
			</div>
		)
	}

	renderRegistrantHelper() {
		const { filterKeyword, filterStatus, isExported } = this.state

		return (
			<div className="flex mb-6 mt-6">
				<Searchbar filterKeyword={filterKeyword} filterStatus={filterStatus} handleOnSearch={this.handleSearch} />
				<Select options={constants.options2} name="filterStatus" value={filterStatus} onChange={this.handleSearch} />
				<div className="inline-block m-auto" />
				<div className="inline-block">
					{/* <button className="bg-greyExport text-white rounded-lg py-2 px-6" onClick={this.handleExport} disabled={isExported}>
						{isExported ? <Loading secondary /> : 'Export'}
					</button> */}
					<Button type="export" handleClick={this.handleExport}>
						{isExported ? <Loading secondary /> : 'Export'}
					</Button>
				</div>
			</div>
		)
	}

	renderData() {
		const { length: count } = this.state.registrants
		const { currentPage, lastPage, registrants, sortColumn, isLoading } = this.state

		if (isLoading) return <Loading />
		if (!count) return <div className="text-center">No data available</div>
		return (
			<div>
				<EventRegistrantTable
					registrants={registrants}
					sortColumn={sortColumn}
					onSort={this.handleSort}
					onResendEmail={this.handleResendEmail}
					onReject={this.handleReject}
					handleCheck={this.handleCheck}
					handleUnCheck={this.handleUnCheck}
				/>
				<Pagination currentPage={currentPage} lastPage={lastPage} onPageChange={this.handlePageChange} />
			</div>
		)
	}

	render() {
		const name = JSON.parse(localStorage.getItem('church')).church_name ? JSON.parse(localStorage.getItem('church')).church_name : '-'
		const breads = [
			{ path: '/events/list', label: 'List of All Events' },
			{
				key: 'listofall',
				label: 'Registrant List',
			},
		]
		return (
			<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
				<HeadTitle title={`${name} - Event`} />
				<BreadCrumb title="Registrant List" breads={breads} icon={EventIcon} />
				{this.renderOverview()}
				{this.renderRegistrantHelper()}
				{this.renderData()}
			</div>
		)
	}
}

export default EventRegistrants
