import Form from 'components/commons/Form'
import { constants } from 'constants/constant'

class CourseForm extends Form {
	handlePublish = (e) => {
		e.preventDefault()
		this.setState({ isDraft: false }, () => {
			this.handleSave()
		})
	}

	handleDraft = (e) => {
		e.preventDefault()
		this.setState({ isDraft: true }, () => {
			this.handleSave()
		})
	}

	handleSave = () => {
		const errors = this.validate()
		this.setState({ errors: errors || {} })
		if (errors) return
		this.doSubmit()
	}

	handleChangeCourseDay = ({ currentTarget: input }) => {
		const data = { ...this.state.data }
		const isInclude = data[input.name].includes(parseInt(input.value))

		if (isInclude) {
			data[input.name] = data[input.name].filter((item) => item !== parseInt(input.value))
		} else {
			data[input.name] = [...data[input.name], parseInt(input.value)]
		}
		this.setState({ data })
	}

	handleChangeCourseRole = ({ currentTarget: input }) => {
		const data = { ...this.state.data }
		const isInclude = data[input.name].includes(parseInt(input.value))

		if (isInclude) {
			data[input.name] = data[input.name].filter((item) => item !== parseInt(input.value))
			data['visibleToAll'] = false
		} else {
			data[input.name] = [...data[input.name], parseInt(input.value)]
			data['visibleToAll'] = false
		}
		this.setState({ data })
	}

	handleCheckRoleAll = ({ currentTarget: input }) => {
		const { visibleToAll } = { ...this.state.data }

		const { roles } = this.state

		let selectedRoles = roles.map((role) => role.role_id)

		const data = { ...this.state.data }
		if (visibleToAll) {
			data[input.name] = false
			data['filter_role'] = []
		} else {
			data[input.name] = true
			data['filter_role'] = selectedRoles
		}
		this.setState({ data })
	}

	handleMaritalAll = ({ currentTarget: input }) => {
		const { maritalStatusAll } = { ...this.state.data }
		const data = { ...this.state.data }
		const { maritalStatus } = constants

		let maritals = maritalStatus.map((marital) => marital.maritalValue)
		if (maritalStatusAll) {
			data[input.name] = false
			data['marital_status'] = []
		} else {
			data[input.name] = true
			data['marital_status'] = maritals
		}
		this.setState({ data })
	}

	handleChangeMarital = ({ currentTarget: input }) => {
		const data = { ...this.state.data }
		const isInclude = data[input.name].includes(input.value)

		if (isInclude) {
			data[input.name] = data[input.name].filter((item) => item !== input.value)
			data['maritalStatusAll'] = false
		} else {
			data[input.name] = [...data[input.name], input.value]
			data['maritalStatusAll'] = false
		}
		this.setState({ data })
	}
}

export default CourseForm
