import * as Yup from "yup";

const validationSchema = () =>
  Yup.object().shape({
    title: Yup.string().required("Required"),
    media: Yup.string().required("Required"),
    video: Yup.string().when("media", {
      is: "Video",
      then: Yup.string()
        .matches(/^https()?:\/\/?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/, {
          message: "Invalid Youtube URL"
        })
        .required("Required"),
      otherwise: Yup.string().notRequired()
    }),
    image: Yup.mixed().when("media", {
      is: "Image",
      then: Yup.mixed()
        .required("Required")
        .test(
          "imageSize",
          "Image size is too large, max 5 MB",
          value => (value && value.size <= 5242880) || typeof value === "string"
        ),
      otherwise: Yup.mixed().notRequired()
    }),
    startDate: Yup.string().required("Required")
  });

export default validationSchema;
