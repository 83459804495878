import axios from 'axios'

export const createPushNotif = async formdata => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/pushnotif/create`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: formdata,
	})
}

export const updatePushNotif = async (id, formdata) => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/pushnotif/${id}`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: formdata,
	})
}

export const deletePushNotif = async id => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/pushnotif/delete/${id}`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const getPushNotif = async (status, keyword, order_by, order_dir) => {
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/pushnotif`,
		params: { status, keyword, order_by, order_dir },
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const getPushNotifDetail = async id => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/pushnotif/detail/${id}`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const getListPushNotification = async (page, filter_keyword, orderBy, orderDir, status) => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/pushnotif`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: { page, filter_keyword, orderBy, orderDir, status },
	})
}

export const testPushNotif = async formdata => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/pushnotif/testSendNotif`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: formdata,
	})
}
