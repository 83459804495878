import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import SmallGroupNew from 'components/forms/SmallGroupNew'
import SmallGroupMulti from 'components/forms/SmallGroupMulti'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import { smallGroupCategories, smallGroupStatuses, smallGroupRegencies } from 'libs/apis/smallGroup'

class CreateSmallGroup extends Component {
	state = {
		isLoading: false,
		isRedirect: false,
		errorMessage: '',
		tab: 'new',
		categories: [],
		statuses: [],
		regencies: [],
	}

	async componentDidMount() {
		try {
			const categories = await smallGroupCategories()
			const statuses = await smallGroupStatuses()
			const regencies = await smallGroupRegencies()
			this.setState({
				categories: categories.data.data,
				statuses: statuses.data.data,
				regencies: regencies.data.data,
			})
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
					this.setState({ isRedirect: true })
				} else {
					this.setState({
						errorMessage: 'An unexpected error has occurred. We are currently investigating the issue, please try again later.',
					})
				}
			}
		}
	}

	handleOnTab = event => {
		this.setState({ tab: event.target.id })
	}

	render() {
		const { tab, errorMessage, categories, statuses, isRedirect, regencies } = this.state
		const styleNew = `nav-link cursor-pointer ${tab === 'new' ? 'active' : ''}`.trim()
		const styleMulti = `nav-link cursor-pointer ${tab === 'multi' ? 'active' : ''}`.trim()
		const displayNewTab = tab === 'new' && !errorMessage
		const displayMultiTab = tab === 'multi' && !errorMessage
		const church = JSON.parse(localStorage.getItem('church'))
		const name = church.church_name ? church.church_name : '-'
		const breads = [{ url: '', title: 'Create Group' }]

		if (isRedirect) {
			return <Redirect to="/login" />
		}

		return (
			<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
				<Helmet>
					<title>{name} - Create Small Group</title>
				</Helmet>
				<PageBreadcrumbs icon={SGIcon} title="Create Group" breads={breads} />
				<ul className="flex border-b">
					<li className="-mb-px mr-1">
						<div className={styleNew} name="tab" id="new" onClick={this.handleOnTab}>
							NEW
						</div>
					</li>
					<li className="mr-1">
						<div className={styleMulti} name="tab" id="multi" onClick={this.handleOnTab}>
							MULTIPLICATION
						</div>
					</li>
				</ul>
				<div>
					{errorMessage && <div className="text-center">{errorMessage}</div>}
					{displayNewTab && <SmallGroupNew {...this.props} categories={categories} statuses={statuses} regencies={regencies} />}
					{displayMultiTab && <SmallGroupMulti {...this.props} categories={categories} statuses={statuses} regencies={regencies} />}
				</div>
			</div>
		)
	}
}

export default CreateSmallGroup
