import http from './httpServices'
import axios from 'axios'

export const loginUser = async (email, password, remember_me) => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/login`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: { email, password, remember_me },
		timeout: 10000,
	})
}

export const forgotPassword = async email => {
	return await http.post('/password/forget', { email })
}

export const resetPassword = async (token, new_password) => {
	return await http.post('/password/reset', { token, new_password })
}
