import React from 'react'
import AsyncSelect from 'react-select/async'

export function DropdownAsync({ label, size, loadOption, onChange, value }) {
	const customStyles = {
		control: styles => ({
			...styles,
			backgroundColor: 'white',
			border: 'none',
			color: 'white',
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: data.value,
			':hover': {
				backgroundColor: 'red',
				color: 'white',
			},
		}),
		multiValue: styles => ({
			...styles,
			backgroundColor: '#4da5dc',
			color: 'white',
			borderRadius: '5px',
		}),

		multiValueLabel: styles => ({
			...styles,
			color: 'white',
		}),
	}

	const sizeList = {
		small: 'w-64',
		medium: 'w-3/6',
		large: 'w-full',
	}

	const defaultStyle = 'rounded-lg pl-2 bg-white border-gray-200 border-solid border-2'

	const style = [defaultStyle, sizeList[size]].join(' ')

	return (
		<div className="flex flex-col mb-4 mt-4">
			<label className="mb-3 ml-1 font-bold">{label}</label>
			<AsyncSelect className={style} styles={customStyles} isMulti onChange={onChange} value={value} loadOptions={loadOption} defaultOptions />
		</div>
	)
}
