import axios from 'axios'
import search from './search'

export const prayerCategories = async () =>
  await axios({
    method: 'get',
    url: `${process.env.REACT_APP_ENDPOINT}/prayer/categories`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })

export const prayerRequest = async (page, filter_date_from, filter_date_to, filter_category, order_by, order_dir) => {
  return await search(
    `${
      process.env.REACT_APP_ENDPOINT
    }/prayer?page=${page}&filter_date_from=${filter_date_from}&filter_date_to=${filter_date_to}&filter_category=${filter_category}&order_by=${order_by}&order_dir=${order_dir}`
  )
}

export const praiseReport = async (page, filter_date_from, filter_date_to, filter_category, order_by, order_dir) => {
  return await search(
    `${
      process.env.REACT_APP_ENDPOINT
    }/praise?page=${page}&filter_date_from=${filter_date_from}&filter_date_to=${filter_date_to}&filter_category=${filter_category}&order_by=${order_by}&order_dir=${order_dir}`
  )
}

export const exportPrayer = async (filter_date_from, filter_date_to, filter_category, order_by, order_dir) =>
  await axios({
    method: 'get',
    url: `${process.env.REACT_APP_ENDPOINT}/prayer/export`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    params: { filter_date_from, filter_date_to, filter_category, order_by, order_dir }
  })

export const exportPraise = async (filter_date_from, filter_date_to, filter_category, order_by, order_dir) =>
  await axios({
    method: 'get',
    url: `${process.env.REACT_APP_ENDPOINT}/praise/export`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    params: { filter_date_from, filter_date_to, filter_category, order_by, order_dir }
  })

export const prayerListUser = async (page, id) =>
  await axios({
    method: 'get',
    url: `${process.env.REACT_APP_ENDPOINT}/prayer/user/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    params: { page }
  })

export const praiseListUser = async (page, id) =>
  await axios({
    method: 'get',
    url: `${process.env.REACT_APP_ENDPOINT}/praise/user/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    params: { page }
  })
