import React, { Component } from "react";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import "react-quill/dist/quill.snow.css";

class DateTimeSelect extends Component {
  handleOnChange = date => {
    const { form, field } = this.props;

    form.setFieldValue(field.name, moment(date[0]).format("DD/MM/YYYY HH:mm"));
  };

  render() {
    const { form, field } = this.props;

    const placeHolderText = `${
      !field.value
        ? "Choose date"
        : moment(field.value, "DD/MM/YYYY HH:mm").format("DD MMM YYYY")
    }`;

    return (
      <div>
        <Flatpickr
          value={field.value}
          data-enable-time
          className="form-select block w-full border-2 rounded-lg"
          placeholder={placeHolderText}
          options={{
            defaultHour: moment().format("H"),
            defaultMinute: moment().format("m"),
            dateFormat: "d/m/Y H:i",
            altFormat: "d M Y H:i",
            time_24hr: true,
            altInput: true
          }}
          onValueUpdate={this.handleOnChange}
        />
        {form.errors[field.name] && (
          <div className="text-invalid">{form.errors[field.name]}</div>
        )}
      </div>
    );
  }
}

export default DateTimeSelect;
