import React from 'react'
import moment from 'moment'

const PrayerItem = ({ category, created, description, praise }) => (
  <div className="bg-white shadow-md mb-4 p-4">
    <div>
      <p className="font-bold">
        {category} | {moment(created, 'YYYY-MM-DD').format('DD MMMM YYYY')}
      </p>
      <p>{description}</p>
    </div>
    {praise && (
      <div>
        <p className="font-bold">Praise</p>
        <p>{praise}</p>
      </div>
    )}
  </div>
)

export default PrayerItem
