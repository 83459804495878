import React from 'react'
import TableBody from '../commons/TableBody'
import ActionsRow from '../commons/ActionsRow'
import TableHeader from '../commons/TableHeader'

const AttendanceTable = (props) => {
	const sessions = [...Array(props.sessions)].map((session, index) => {
		return {
			key: index + 1,
			label: index + 1,
			content: (registrant, index) => {
				if (registrant.attendance.includes(index + 1)) {
					return (
						<td className="px-2 py-4 text-center">
							<button onClick={() => props.handleUnCheck(registrant, index + 1)} className="btn-check-attendance check-green">
								<i className="far fa-check-square" />
							</button>
						</td>
					)
				}

				return (
					<td className="px-2 py-4 text-center">
						<button onClick={() => props.handleCheck(registrant, index + 1)} className="btn-check-attendance check-white">
							<i className="far fa-square" />
						</button>
					</td>
				)
			},
		}
	})

	const columns = [
		{ label: 'Name', path: 'fullname', key: 'fullname' },
		{
			label: 'GROUP NAME',
			key: 'small_group_name',
			content: (registrant) => {
				return <td className="px-2 py-4">{registrant.small_group_name ? registrant.small_group_name : '-'}</td>
			},
		},
		sessions,
		{
			label: 'STATUS',
			path: 'status',
			content: (registrant) => {
				let style = ''
				switch (registrant.status) {
					case 'Approved':
						style = 'text-green-500'
						break
					case 'Applied':
						style = 'text-yellow-500'
						break
					case 'Pending':
						style = 'text-grey-300'
						break
					case 'Rejected':
						style = 'text-red-500'
						break
					case 'Failed':
						style = 'text-red-500'
						break
					case 'Passed':
						style = 'text-green-500'
						break
					default:
						break
				}
				return (
					<td className="px-2 py-4">
						<i className={`fas fa-circle ${style}`}></i>
						{` ${registrant.status}`}
					</td>
				)
			},
		},
		{
			key: 'action',
			content: (registrant) => {
				const classes = 'px-4 py-2 block hover:bg-blueSecondary'
				const actions = [
					{
						key: 'resend',
						path: 'resend',
						helper: (
							<li className={classes} onClick={() => props.onResendEmail(registrant)}>
								Resend Confirmation Email
							</li>
						),
					},
					{
						key: 'reject',
						path: 'reject',
						helper: (
							<li className={classes} onClick={() => props.onReject(registrant)}>
								Reject Remove Registrant
							</li>
						),
					},
				]
				return <ActionsRow actions={actions} />
			},
		},
	]

	return (
		<div className="bg-white shadow-md overflow-x-auto mx-2 md:mx-0 whitespace-no-wrap">
			<table className="min-w-full">
				<TableHeader columns={columns} sortColumn={props.sortColumn} onSort={props.onSort} />
				<TableBody data={props.attendances} columns={columns} />
			</table>
		</div>
	)
}

export default AttendanceTable
