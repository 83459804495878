import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Tippy from '@tippy.js/react'

class SmallGroupItem extends Component {
	constructor() {
		super()
		this.state = {
			dropDownOpen: false,
		}
		this.setWrapperRef = this.setWrapperRef.bind(this)
		this.handleClickOutside = this.handleClickOutside.bind(this)
	}

	handleToggle = () => {
		this.setState({ dropDownOpen: !this.state.dropDownOpen })
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside)
	}

	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({ dropDownOpen: false })
		}
	}

	setWrapperRef(node) {
		this.wrapperRef = node
	}

	handleClose = () => {
		this.props.handleOnClose(this.props.id)
	}

	render() {
		const { id, name, category, leader, members, day, time, created, status } = this.props

		const { dropDownOpen } = this.state

		const styleDropDownOpen = `z-20 shadow-md absolute right-20px ${
			dropDownOpen ? 'block' : 'hidden'
		}`

		const leaderEmpty = `${leader === null ? 'opacity-50' : 'opacity-100'}`
		const styleTdStatus = status ? 'relative mr-6' : 'absolute mr-6'

		return (
			<tr className="border border-solid bt-blueSecondary">
				<td className={styleTdStatus}>
					{status === 'Closed' && (
						<Tippy content="Closed">
							<div className="status-post-tag">
								<div className="ribbon-black-triagle" />
							</div>
						</Tippy>
					)}
				</td>
				<td className="pl-8">
					<Link className={leaderEmpty} to={`/small-group/edit/${id}`}>
						{name}
					</Link>
				</td>
				<td>
					<div className={leaderEmpty}>{category}</div>
				</td>
				<td>
					<div className={leaderEmpty}>{leader}</div>
				</td>
				<td className="pl-10">
					<div className={leaderEmpty}>{members}</div>
				</td>
				<td>
					<div className={leaderEmpty}>{day}</div>
				</td>
				<td className="px-2 py-4 text-left">
					<div className={leaderEmpty}>{time}</div>
				</td>
				<td className="px-2 py-4 pl-6">
					<div className={leaderEmpty}>
						{moment(created, 'DD/MM/YYYY HH:mm').format('D MMM YYYY')}
					</div>
				</td>
				<td className="px-2 py-4">
					<div className={leaderEmpty}>{status}</div>
				</td>
				<td className="px-2 py-4">
					{status !== 'Closed' && (
						<div className="cursor-pointer" onClick={this.handleToggle}>
							<i className="dropdown-trigger fas fa-ellipsis-v" />
							<div className={styleDropDownOpen}>
								<ul ref={this.setWrapperRef} className="bg-white">
									<li>
										<Link
											className="px-4 py-2 block hover:bg-blueSecondary"
											to={`/small-group/view/${id}`}
										>
											View
										</Link>
									</li>
									<li>
										<Link
											className="px-4 py-2 block hover:bg-blueSecondary"
											to={`/small-group/edit/${id}`}
										>
											Edit
										</Link>
									</li>
									<li>
										<Link
											className="px-4 py-2 block hover:bg-blueSecondary"
											to={`/small-group/request/${id}`}
										>
											Request
										</Link>
									</li>
									<li
										className="px-4 py-2 block hover:bg-blueSecondary"
										onClick={this.handleClose}
									>
										Close Group
									</li>
								</ul>
							</div>
						</div>
					)}
				</td>
			</tr>
		)
	}
}

export default SmallGroupItem
