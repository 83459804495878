import React, { useState, useRef, useEffect, useCallback, useContext } from 'react'
import moment from 'moment'
import photoplaceholder from 'assets/images/photo-placeholder.png'
import styled from 'styled-components'
import { MinistryContext } from 'screens/ministry/ViewMinistry'
import { removeMember } from 'libs/apis/ministry'
import alert from 'services/alert'

const RoleRibbon = styled.span`
	color: ${props => (props.value ? 'white' : 'white')};
	position: relative;
	top: ${props => (props.value ? '-60px' : '-50px')};
	width: 0;
	left: 20px;
	text-align: center;
	font-size: 18px;
	font-family: arial;
	display: block;
	transform: rotate(-45deg);
`

const RoleContainer = styled.div`
	width: 0;
	height: 0;
	border-top: 10px solid;
	border-bottom: 50px solid transparent;
	border-right: 90px solid transparent;
	margin-bottom: -100px;
	border-top: 90px solid ${props => (props.value ? '#1f3c88' : '#5893d4')};
`

const MinistryMemberCard = props => {
	const { ministry_id } = useContext(MinistryContext)
	const [openDetails, setOpenDetails] = useState(false)
	const [dropDownOpen, setDropDownOpen] = useState(false)
	const wrapperRef = useRef(null)

	const handleClickOutside = useCallback(event => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setDropDownOpen(false)
		}
	}, [])

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, false)
		return () => {
			document.removeEventListener('click', handleClickOutside, false)
		}
	}, [handleClickOutside])

	const handleOpenUserDetails = () => {
		setOpenDetails(!openDetails)
	}

	const handleDropDown = () => {
		setDropDownOpen(!dropDownOpen)
	}

	const handleRemoveMember = async user_id => {
		/*
			- user_id (from props)
			_ ministry_id (from ministry context)
		*/
		let res = await alert.confirmDeleteMemberMinistry()
		if (res.value) {
			let res = await removeMember(ministry_id, user_id)
			if (res.data.api_status) {
				alert.succeed('Success', 'Member has been removed')
				props.handleMinistryDetail()
			}
		}
	}

	return (
		<div className="w-2/6">
			<div className="bg-white mb-4 mr-2 pb-2 relative">
				{props.leader || props.member ? (
					<RoleContainer value={props.leader}>
						<RoleRibbon value={props.leader}>{props.leader ? 'MH' : 'Member'}</RoleRibbon>
					</RoleContainer>
				) : null}
				<div className="pt-4 flex justify-center">
					<img src={props.profile_photo || photoplaceholder} className="rounded-full flex items-center justify-center w-32 h-32" alt="ministryMember" />
					{props.leader || props.member ? (
						<div className="cursor-pointer absolute top-0 right-0 mr-4 text-xl mt-4" onClick={handleDropDown} ref={wrapperRef}>
							<i className="dropdown-trigger fas fa-ellipsis-v" />
							<div className={`z-10 shadow-md absolute ${dropDownOpen ? 'block' : 'hidden'}`}>
								<ul className="bg-white">
									<li className="px-4 py-2 block hover:bg-blueSecondary text-sm" onClick={() => handleRemoveMember(props.user_id)}>
										Remove
									</li>
								</ul>
							</div>
						</div>
					) : (
						<div className="cursor-pointer absolute top-0 right-0 mr-4 text-xl mt-4" onClick={handleDropDown} ref={wrapperRef}>
							<i className="dropdown-trigger fas fa-ellipsis-v" />
							<div className={`z-20 shadow-md absolute ${dropDownOpen ? 'block' : 'hidden'}`}>
								<ul className="bg-white">
									<li className="px-4 py-2 block hover:bg-blueSecondary text-sm" onClick={() => props.handleAccept(props.user_id)}>
										Accept
									</li>
									<li className="px-4 py-2 block hover:bg-blueSecondary text-sm" onClick={() => props.handleReject(props.user_id)}>
										Decline
									</li>
								</ul>
							</div>
						</div>
					)}
				</div>
				<div className="font-bold text-center my-2">{props.fullname}</div>
				<hr className="border-2 border-blueSecondary mx-4" />

				<i className={`fa ${openDetails ? 'fa-sort-up' : 'fa-sort-down'} flex justify-center`} onClick={handleOpenUserDetails} />
				{openDetails ? <MinistryCardDetails {...props} /> : null}
			</div>
		</div>
	)
}

export default MinistryMemberCard

export const MinistryCardDetails = props => {
	return (
		<div className="ml-5">
			<div className="mt-4">
				<label className="text-sm font-bold">Phone Number :</label>
				<p>{props.phone_number || '-'}</p>
			</div>

			<div className="mt-4">
				<label className="text-sm font-bold">Name :</label>
				<p>{props.fullname || '-'}</p>
			</div>

			<div className="mt-4">
				<label className="text-sm font-bold">Email :</label>
				<p>{props.email || '-'}</p>
			</div>

			<div className="mt-4">
				<label className="text-sm font-bold">Baptism Date:</label>
				<p>{props.baptism.date || '-'}</p>
			</div>

			<div className="mt-4">
				<label className="text-sm font-bold">Small Group:</label>
				<p>{props.small_group.name || '-'}</p>
			</div>

			<div className="mt-4">
				<label className="text-sm font-bold">Join Small Group:</label>
				<p>{props.small_group.sg_since || '-'}</p>
			</div>

			<div className="mt-4">
				<label className="text-sm font-bold">Small Group Role:</label>
				<p>{props.small_group.role || '-'}</p>
			</div>
			<div className="mt-4">
				<label className="text-sm font-bold">Classes :</label>
				<ul>
					{props.class === 0 || props.class === undefined ? (
						<div />
					) : (
						props.class.map(val => (
							<li key="classname" className="list-disc ml-8">
								{val.class_name} - {moment(val.class_date).format('ll')}
							</li>
						))
					)}
				</ul>
			</div>
		</div>
	)
}
